var CrossBrowserCtrl = function(){
    this.binds();
}

CrossBrowserCtrl.prototype.binds = function(){
    this.manageModalHeight();
    $(document).on('click keydown', this.manageModalHeight)
    $(window).on('resize', this.manageModalHeight)
}

// Bootstrap modals verticaly align only if you set modal-dialog height to 100%
// Problem is if the modal has more than 100% height it starts cutting off
// In this instance set height to auto
CrossBrowserCtrl.prototype.manageModalHeight = function(){
    $modalsContent = $('.modal-content');
    $modalsContent.each(function(i, el){
        var $modalContent = $(el);
        setTimeout(function(){
            if ($modalContent.height() > $(window).height() - 80) {
                $modalContent.closest('.modal').css('height', 'auto');
                $modalContent.closest('.modal-dialog').css('margin', '1.75rem auto');
            } else {
                $modalContent.closest('.modal').css('height', '100vh');
                $modalContent.closest('.modal-dialog').css('margin', '0 auto');
            }
        }, 300)
    })
}