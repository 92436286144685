// Add some global functionality to all modals
// A lot of this is UI adjustments that are PKM specific
// But there are general methods like cleanForm() that will clear all inputs inside of it regarless of app

var ModalCore = function(){
    this.$modals = $('.modal-container');
    this.modalInits();
    this.modalBinds();
    this.responsiveDialogCentering()
    this.fixedHeaderAndFooter();
    this.binds();
}

ModalCore.prototype.modalInits = function(){
    this.$modals.each(function (i, el) {
        var $closeBtn = $(el).find('.modal-header button.modal-close');
        $closeBtn.appendTo($(el).find('.modal'));

        $buttons = $(el).find('.modal-footer button'); // THIS IS WHAT CLOSES MODALS
        $buttons.on('click', function () {
            if (!$(this).hasClass('cancel-modal-close'))
                $closeBtn.click();
        })
    });
}

ModalCore.prototype.modalBinds = function(){
    var _this = this;

    this.$modals.find('.confirm-btn').on('mouseup keyup', function (e) {
        if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;
        var $modal = $(this).closest('.modal-container')
        if (_this.isFormValid($modal)) {
            $(this).removeClass('cancel-modal-close')
            // cancel submission - modal stays open and ingredient not added
        } else {
            $(this).addClass('cancel-modal-close')
        }
    })
}



ModalCore.prototype.responsiveDialogCentering = function () {
    if ($(window).width() < 768) {
        $('.modal-dialog').removeClass('modal-dialog-centered');
    } else {
        if (!$('.modal-dialog').hasClass('modal-dialog-centered'))
            $('.modal-dialog').addClass('modal-dialog-centered');
    }
}
// TODO: Use fixed bottom divs to transport these around ( havent applied to all elements)
ModalCore.prototype.fixedHeaderAndFooter = function () {
    this.$modals.each(function (i, el) {
        $modal = $(el);
        // if ($(window).width() < 768) {
        //     $modal.find('.modal-dialog').after($modal.find('.modal-content .modal-header .modal-title'));
        //     $modal.find('.modal-dialog').after($modal.find('.modal-content .modal-footer'));
        //     $modal.find('.fixed-bottom-dest-2').append($modal.find('.c-fixed-bottom'));
        // } else {
        //     $modal.find('.modal-header').prepend($modal.find('.modal-title'));
        //     $modal.find('.modal-body').after($modal.find('.modal-footer'));
        //     $modal.find('.fixed-bottom-dest-1').append($modal.find('.c-fixed-bottom'));
        // }
    });
}



ModalCore.prototype.isFormValid = function ($modal) {
    var $errors = $modal.find('.error');
    var formValid = true;
    $errors.each(function (i, el) {
        if ($(el).text() !== '')
            formValid = false;
    })
    return formValid;
}

ModalCore.prototype.cleanForm = function ($modal) {
    $modal.find('input').val('');
    $modal.find('.error').text('')
    $modal.find('input').val('')
    $modal.find('select').children('option:eq(0)').prop('selected', true);
}

ModalCore.prototype.binds = function () {
    var _this = this;

    $(window).resize(this.responsiveDialogCentering.bind(this));
    $(window).resize(this.fixedHeaderAndFooter.bind(this));

    // Clean Form ON MODAL CLOSE
    $('.modal-container').on('mouseup', function () {
        var $modal = $(this);
        setTimeout(function () {
            var modalHidden = !$modal.find('.modal').hasClass('show');
            if (modalHidden) {
                _this.cleanForm($modal);
            }
        }, 300);
    })
}


