/**
 * RECIPE CALCULATOR SERVICE
 * 
 * Keeps track of every value needed to calculatte recipes properties. 
 * 
 * 1) Use data to access properties
 * 2) Use set methods to set data, and update affected properties
 * 3) You shouldn't have a need to access "update" methods publicly. Consider these private.
 * 
 */

/**
 * Recipe Calculator
 * 
 * @param {Object}  options 
 * @param {Float}   options.yield        Recipe yield = Set by user
 * @param {Float}   options.servingSize  Recipe Serving Size = Set by user
 * @param {Float}   options.recipeCost   Total Recipe Cost = Sum of all ingredient costs
 * 
 *      - Most properties can be calculated with the existing properties set initially by the user (yield, servingSize & recipe cost )
 *      - All these calculations are performed via "calculate" methods which reassign and return the value, so they work like getters as well
 *      - Private properties are used to keep track of state & perform inner & getter calculations
 *      
 */
var RecipeCalculator = function(options) {
    var _Util = Util;

    var recipeYield = _Util.cleanNumber( options.yield );
    var servingSize = _Util.convertToFourDecimals( options.servingSize );
    var recipeCost = _Util.cleanNumber( options.recipeCost );
    var _SERVINGCHANGEPCT = null;
    var menuPrice = null;
    var servingNumber=0;
    var numberOfServings = (typeof options.numberOfServings !== 'undefined' || servingNumber === '0')
        ? _Util.cleanNumber( options.numberOfServings )
        : _Util.cleanNumber( recipeYield / servingSize );
    var costPerServing = _Util.cleanNumber( recipeCost * (servingSize / recipeYield) );
    var foodCostPercentage = null;
    var margin = null;

    var _localSmartConvert  = SmartConvert.getInstance();
    // Setters
    this.setServingSize = function(val) {
        val = _Util.cleanNumber(val);
        servingSize = val;
    };
    this.setYield = function(val) {
        val = _Util.cleanNumber(val);
        recipeYield = val;
    };


    


    // this is for updating the # of services, work backwards to get yield factor and then apply factor to all ingredients.
    // val is the new # of servings
    this.setNumberOfServings = function(val) {
        
        val = _Util.cleanNumber(val);
        oldNumberOfServings = $( '#number-of-servings' ).attr( 'data-original' );
        newNumberOfServings = val;
		$( '#number-of-servings' ).attr( 'data-original', newNumberOfServings );
		console.log("Old and new serving counts: " + oldNumberOfServings,newNumberOfServings);
        numberOfServings = _Util.cleanNumber( val );
		
		var currentConversion = $('.serving-size').attr( 'data-currentconversion' );
        var servingSizeUnits = $('.serving-size').attr('data-unitname'+currentConversion);
        var yieldUnits = $('.yield-control').attr('data-unitname'+currentConversion);

        this.scaleFromServingSize(  oldNumberOfServings, newNumberOfServings, servingSizeUnits, yieldUnits );
        $( '#number-of-servings-original' ).val( newNumberOfServings );
    };

    this.updateYield = function ( val )
    {
        val = _Util.cleanNumber(val);
		var data2test = $( 'div.yield-control' ).attr( 'data-amt2' );
		var yieldUnit = $("select#yield-unit-select option:selected").text();
		if(wholeUnitNames.includes( yieldUnit ) == true){
			var updateBoth = true;	
		}
		
        //if( $( 'div.yield-control' ).attr( 'data-currentconversion' ) == '2' ){
		if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
			oldYield = $( 'div.yield-control' ).attr( 'data-amt2' );
			newYield = val;
        	this.scaleFromNewYield(  oldYield, newYield );
			$( 'div.yield-control' ).attr( 'data-amt2', newYield );
			if(updateBoth == true){ $( 'div.yield-control' ).attr( 'data-amt1', newYield ); }
		} else if (typeof( data2test ) == "undefined" ) {
			oldYield = $( 'div.yield-control' ).attr( 'data-amt' );
			newYield = val;
        	this.scaleFromNewYield(  oldYield, newYield );
			$( 'div.yield-control' ).attr( 'data-amt', newYield );
		} else{ 
			oldYield = $( 'div.yield-control' ).attr( 'data-amt1' );
			newYield = val;
        	this.scaleFromNewYield(  oldYield, newYield );
			$( 'div.yield-control' ).attr( 'data-amt1', newYield );
			if(updateBoth == true){ $( 'div.yield-control' ).attr( 'data-amt2', newYield ); }
		}
		//$('#yield-input').attr( "value", val )
    }


    this.setMenuPrice = function(val) {
        val = _Util.cleanNumber(val);
        menuPrice = parseFloat(val);
        this.updateMenuPricing( 'pricing' );
    };
    this.setFoodCostPercentage = function(val) {
        val = _Util.cleanNumber(val);
        foodCostPercentage = val;
        this.updateMenuPricing( 'foodcost' );
    };

    this.setMargin = function(val) {
        val = _Util.cleanNumber(val);
        margin = val;
        this.updateMenuPricing( 'margin' );
    }

    // Getters
    this.getRecipeYield = function(){ return recipeYield; }
    this.getServingSize = function(){ return servingSize;}
    this.getRecipeCost = function(){ return recipeCost; }
    this.getMenuPrice = function(){ return menuPrice; }
    this.getServingSize = function(){ return servingSize; }

    this.scaleFromNewYield = function ( oldYield, newYield )
    {
		console.log("scaleFromNewYield", oldYield, newYield);
        newYield = parseFloat( newYield );
        oldYield = parseFloat( oldYield );

        var servingChangePct = Math.round(100000*(( newYield - oldYield ) / oldYield))/100000;
		var originalServings = parseFloat(  $( '.number-of-servings-edit' ).attr( 'data-original' ) );
		var numServingsBeforechange = $( '.number-of-servings-edit' ).attr( 'data-original' );
        if( !numServingsBeforechange ) numServingsBeforechange = originalServings;
        numServingsBeforechange = parseFloat( numServingsBeforechange );
		//console.log("Serving change percentage "+servingChangePct+" = ( "+newYield+" - "+oldYield+" ) / "+oldYield);
         _SERVINGCHANGEPCT  = servingChangePct;
        window._SERVINGCHANGEPCT = servingChangePct;
		
		if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
			var fromUnitID = $('.yield-control').attr('data-unitcode2');
			var fromUnitName = $('.yield-control').attr('data-unitname2');
			var toUnitID = $('.serving-size').attr('data-unitcode2');
		} else {
			var fromUnitID = $('.yield-control').attr('data-unitcode1');
			var fromUnitName = $('.yield-control').attr('data-unitname1');
			var toUnitID = $('.serving-size').attr('data-unitcode1');
		}
		
		//if the yield unit matches the serving size unit, just do a division to find number of servings
		//If they don't match, do an AJAX conversion
		//non-units (each, dozen) scale straight across
		/*if(fromUnitName == 'ea' || fromUnitName == 'dozen'){
			_SERVINGCHANGEPCT = 1;
			window._SERVINGCHANGEPCT = 1;
			this.scaleIngredients(  );
		} else */if(toUnitID == fromUnitID || wholeUnitIds.includes( Number( fromUnitID ) ) == true){
			var newServings = Math.floor( newYield / $('.serving-size .amt').html() );
        	var newServingsNoFloor = newYield / $('.serving-size .amt').html();
			$( '#number-of-servings-original' ).val( newServingsNoFloor );
			
			
			$( '.number-of-servings-edit' ).val( newServings );
			$( '.number-of-servings-edit' ).attr( 'data-original', newServingsNoFloor );
			
			var instancePctChange = ( newServingsNoFloor - numServingsBeforechange ) / numServingsBeforechange;        
	
			_SERVINGCHANGEPCT  = instancePctChange;
			window._SERVINGCHANGEPCT = instancePctChange;
	
			 this.scaleIngredients(  );
			 
		} else {
			console.log("AJAX start unit is "+fromUnitID+" / /"+fromUnitName+" and target unit is "+toUnitID+" / /"+$('.serving-size .unit-code').html());
			
			var ajaxAmt = _localSmartConvert.cleanNumber( newYield );
			var thisRef = this;
			_AjaxConversion.convertStd( {
				params: {
					toId: toUnitID,
					fromId: fromUnitID,
					amount: newYield
				}
			}).then(function(res){
				ajaxResult = JSON.parse( res );
				console.log(ajaxResult);
				//if(!ajaxResult.Data) return;
				if( !ajaxResult.Data || (ajaxResult.Response['Status-code'] != "0" &&  ajaxResult.Response['Status-code'] != "200" )){
					var conv = ajaxAmt;
				} else{
					var conv = JSON.parse( ajaxResult.Data );     
				}
				console.log("AJAX conversion result was "+conv);
				console.log("AJAX thinks the new serving number should be " + Math.floor(conv/$('.serving-size .amt').html()));
				
				var newServings = Math.floor(conv/$('.serving-size .amt').html());
        		var newServingsNoFloor = conv/$('.serving-size .amt').html();
				
				$( '.number-of-servings-edit' ).val( newServings );
				$( '.number-of-servings-edit' ).attr( 'data-original', newServingsNoFloor );
				
				var instancePctChange = ( newServingsNoFloor - numServingsBeforechange ) / numServingsBeforechange;  
				console.log("Percentage change should be "+ instancePctChange +" = ( "+ newServingsNoFloor +" - "+ numServingsBeforechange +" ) / "+ numServingsBeforechange);      
		
				_SERVINGCHANGEPCT  = instancePctChange;
				window._SERVINGCHANGEPCT = instancePctChange;
		
				 thisRef.scaleIngredients(  );
			});
			
			
		}
		
        //var newServings = Math.floor( originalServings + ( originalServings * servingChangePct ) );
        //var newServingsNoFloor = ( originalServings + ( originalServings * servingChangePct )  );
		//console.log("New servings "+newServings+" = "+originalServings+" +( "+originalServings+" * "+servingChangePct+" )");
    	
		//console.log("Original servings ("+originalServings+") plus "+Math.round(servingChangePct*100)+"% is "+newServings+" servings.");
    };

   


    // User changed serving size, so get a factor and multiply all ingredients 
    this.scaleFromServingSize = function( oldNumberOfServings, newNumberOfServings, servingSizeUnits, yieldUnits )
    {
		console.log("scaleFromServingSize", oldNumberOfServings, newNumberOfServings, servingSizeUnits, yieldUnits);
        newNumberOfServings = parseFloat( newNumberOfServings );
        oldNumberOfServings = parseFloat( oldNumberOfServings );
        var servingChangePct = ( newNumberOfServings - oldNumberOfServings ) / oldNumberOfServings;
		console.log("Old and new serving counts: " + oldNumberOfServings,newNumberOfServings);
		console.log("Percent difference: " + servingChangePct);

     
        var originalYield;
		var oldYield
		var newYield
		var yieldUnit = $("select#yield-unit-select option:selected").text();
		if(wholeUnitNames.includes( yieldUnit ) == true){
			var updateBoth = true;	
		}
		if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric' /*$( 'div.serving-size' ).attr( 'data-currentconversion' ) == '2'*/ ) {
            //originalYield = $( 'div.yield-control' ).attr( 'data-original2' );
			oldYield = parseFloat( $( '.yield-control' ).attr('data-amt2' ) );
			newYield = oldYield + ( oldYield * servingChangePct );
			console.log("Old Yield: "+oldYield+" New Yield: "+newYield+" = "+oldYield+" * "+Math.round(servingChangePct*100)+"% Metric");
			$( '.yield-control' ).attr('data-amt2', newYield);
			if(updateBoth == true) { $( '.yield-control' ).attr('data-amt1', newYield); }
		} else {
            //originalYield = $( 'div.yield-control' ).attr( 'data-original' );
			oldYield = parseFloat( $( '.yield-control' ).attr('data-amt1' ) );
			newYield = oldYield + ( oldYield * servingChangePct );
			console.log("Old Yield: "+oldYield+" New Yield: "+newYield+" = "+oldYield+" * "+Math.round(servingChangePct*100)+"% Standard");
			$( '.yield-control' ).attr('data-amt1', newYield);
			if(updateBoth == true) { $( '.yield-control' ).attr('data-amt2', newYield); }
		}

        //$( '.yield-control' ).attr( 'data-amt', newYield);
        $( '#yield-input' ).val( Util.cleanNumber( newYield ) );
		setTimeout(function() {
			$( '#print-recipe-cost .recipe-yield' ).html( $( '#yield-input' ).val() );
		}, 100);

        var instancePctChange = ( newYield - oldYield ) / oldYield;
		
		_SERVINGCHANGEPCT  = instancePctChange;
        window._SERVINGCHANGEPCT = instancePctChange;
       
        //
        // if( $( '.yield-control' ).attr( 'data-amt') ){
        //     var oldYield = parseFloat( $( '.yield-control' ).attr( 'data-amt') );
        //     var newYield = oldYield + ( oldYield * servingChangePct );
        //     $( '.yield-control' ).attr( 'data-amt', newYield);
        //     $( '.yield-control' ).attr('data-amt', newYield);
        //     $( '#yield-input' ).val( newYield );
        // }

        // if( $( '.yield-control' ).attr( 'data-amt1') ){
        //     var oldYield1 = parseFloat( $( '.yield-control' ).attr( 'data-amt1') );
        //     var newYield1 = oldYield1 + ( oldYield1 * servingChangePct );
        //     $( '.yield-control' ).attr( 'data-amt1', newYield1  );
        //     $( '.yield-control' ).attr('data-amt1', newYield1 );
        //     $( '#yield-input' ).val( newYield1.toFixed( 2 ) );
        // }

        // else if( $( '.yield-control' ).attr( 'data-amt2') ){
        //      var oldYield2 = parseFloat( $( '.yield-control' ).attr( 'data-amt1') );
        //     var newYield2 = oldYield2 + ( oldYield2 * servingChangePct );
            
        //     $( '.yield-control' ).attr( 'data-amt2', newYield2 );
        //     $( '.yield-control' ).attr('data-amt2', newYield2 );
        //     $( '#yield-input' ).val( newYield2.toFixed( 2 ) );
        // }
      var self = this;

      this.scaleIngredients(  );
    };


    this.scaleIngredients = function (  )
    {
        var self = this;
        var servingChangePct = _SERVINGCHANGEPCT;
		//console.log(_SERVINGCHANGEPCT + '____' + window._SERVINGCHANGEPCT);
        $( '.ingredients-list div.nested'  ).children( '.item' ).each( function( i, obj ){
			if(!$( this ).hasClass('sub-list')){
				var currentConversion = $( 'div.serving-control' ).attr( 'data-currentconversion' );
				var data2test = $( this ).attr( 'data-amt2' );
				
				//console.log($( this ).data());
				
				//if we're in metric mode, use amt2 unless it's missing, which means this ingredient uses a unique unit
				if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric' && typeof( data2test ) != "undefined" /*currentConversion == 2*/ ){
					var curIngAmt = $( this ).attr( 'data-amt2' ) ;
					var curUnit = $( this ).attr( 'data-unit2' ) ;
				}
				else if (typeof( data2test ) == "undefined" )
				{
					var curIngAmt = _localSmartConvert.cleanNumber( $( this ).attr( 'data-amt' )) ;
					var curUnit = $( this ).attr( 'data-unit' ) ;
					//console.log($( this ).find('.amt').text());
					if($( this ).find('.amt').text() == ''){
						console.log($( this ).find( '.name-container p' ).text() + " will remain blank");
						curIngAmt = "-";
					}
					
					/*$( this ).find('.amt').each(function(){
						if($( this ).text() == ''){
							console.log($( this ).attr( 'data-txtprep' ) + " will remain blank");
							curIngAmt = "-";
						}
					});*/
				} else {
					var curIngAmt = $( this ).attr( 'data-amt1' ) ;
					var curUnit = $( this ).attr( 'data-unit1' ) ;
				}
	
				var newIngAmt;
	
	
				/*if( wholeUnitIds.includes( Number( curUnit ) ) == true ){
					newIngAmt = Math.ceil( (_localSmartConvert.cleanNumber(curIngAmt) * servingChangePct) + _localSmartConvert.cleanNumber(curIngAmt) );
					console.log(newIngAmt +'= Math.ceil( '+curIngAmt +' * '+ servingChangePct +' + '+ curIngAmt +')');
				}
				else
				{*/
					if( typeof( curIngAmt ) == 'undefined' || curIngAmt == "-"   || isNaN( curIngAmt ))
					{
						newIngAmt = "-";   
					}
					else
					{
						var cleanQuantity = _localSmartConvert.cleanNumber( curIngAmt );
						var newQty = cleanQuantity * servingChangePct + cleanQuantity;
						newIngAmt = Number( parseFloat( newQty ).toFixed( 2 ) );
					   
	
						// The metric button has not yet been pressed if this is still undefined
						if( typeof( data2test ) == "undefined" )
						{
							$( this ).attr( 'data-unit', curUnit ) ;
							
							/*$( this ).attr( 'data-amt1' , curIngAmt ) ;
							$( this ).attr( 'data-amt1',  curIngAmt );
							$( this ).attr( 'data-unit1', curUnit ) ;
							$( this ).attr( 'data-unit1',  curUnit );*/
						}
						else
						{
							var amt1 = Number ( $( this ).attr( 'data-amt1' ) );
							var amt2 = Number(  $( this ).attr( 'data-amt2' ) );
							
							var newAmt1  = amt1 * servingChangePct + amt1;
							var newAmt2  = amt2 * servingChangePct + amt2;
							
							$( this ).attr( 'data-amt1', newAmt1 );
							$( this ).attr( 'data-amt2', newAmt2 );
						}
	
	
					}
					//newIngAmt = newIngAmt.toFixed( 2 );   
				//}
				
				$( this ).attr( 'data-amt', newIngAmt ) ;
				
				var ingId = $( this ).attr( 'data-nrecingid' );
	
				// this is the visible label
				 if( noPriceUnitIds.includes( curUnit ) )
				{
					  $( this ).find( 'div.amount p span.amt' ).html( '' );
				} else if( wholeUnitIds.includes( Number( curUnit ) ) == true ){
					$( this ).find( 'div.amount p span.amt' ).html(Math.ceil(Number(newIngAmt)));
				} else{
						//console.log(newIngAmt, curUnit);
						unitUpscaleFraction =  _localSmartConvert.doConvert( newIngAmt, curUnit ) ;
						if(  unitUpscaleFraction.newUnit.fail==false && unitUpscaleFraction.newUnit.quantityFraction ){
							 $( this ).find( 'div.amount p span.amt' ).html( unitUpscaleFraction.newUnit.quantityFraction  );     
						}
						else
						{
							 $( this ).find( 'div.amount p span.amt' ).html( Number(Number( newIngAmt ).toFixed( 2 ))  );
						}
						if(  unitUpscaleFraction.newUnit.fail==false && unitUpscaleFraction.newUnit.unitName )
						{
						   $( this ).find( 'span.unit' ).html( unitUpscaleFraction.newUnit.unitName );
						//   $( this ).find( 'span.unit' ).html( '&#8531;' );
					   }
				}
			   
				 // if valid price
				var unitUpscaleFraction;
			}
     } );

    }

  

    // Used in getAllProperties
    this.calculateYield = function(){
        if(numberOfServings && servingSize)
            return recipeYield = _Util.cleanNumber( servingSize * numberOfServings );
    }
    this.calculateNumberOfServings = function () {
        if(numberOfServings) return numberOfServings;
        if( $('#number-of-servings').val(  ) ) return _Util.cleanNumber( $('input#number-of-servings').val(  ) );
        if(recipeYield && servingSize) 
            return numberOfServings = _Util.cleanNumber( recipeYield / servingSize );
    }
    this.calculateCostPerServing = function() {
        if(servingSize && recipeYield)
            return costPerServing = _Util.cleanNumber( recipeCost * (servingSize / recipeYield) );
    }
    this.calculateFoodCostPercentage = function () {
        if (costPerServing && menuPrice && menuPrice !== 0)
            return foodCostPercentage = _Util.cleanNumber( costPerServing / menuPrice );
    }
    this.calculateMargin = function() {
        if(menuPrice && costPerServing)
            return margin = _Util.cleanNumber( menuPrice - costPerServing );
    }
    this.calculateServingSize = function () {
        if(recipeYield && numberOfServings)
            return  servingSize = recipeYield / numberOfServings;
    }

    this.getAllProperties = function(){
        var properties = {}
        if (this.getRecipeYield()) properties.yield = this.getRecipeYield();
        if (this.getServingSize()) properties.servingSize = this.getServingSize();
        if (this.getRecipeCost()) properties.recipeCost = this.getRecipeCost();
        if (this.getMenuPrice()) properties.menuPrice = this.getMenuPrice();
        else if (this.getMenuPrice() === 0 || this.getMenuPrice() === "0" ) properties.menuPrice = 0;
        if (this.calculateNumberOfServings()) properties.numberOfServings = this.calculateNumberOfServings();
        if (this.calculateCostPerServing()) properties.costPerServing = this.calculateCostPerServing();
        
        if (this.getFoodCostPercentage()) properties.foodCostPercentage = this.getFoodCostPercentage();
        properties.servingChangePct = _SERVINGCHANGEPCT;
        if (typeof this.getMargin() !== 'undefined' && this.getMargin() !== null ) properties.margin = this.getMargin();
        
        return properties;
    }



    this.getNumberOfServings = function (  )
    {
        var numServ = this.calculateNumberOfServings();
        if( numServ <=0 ) numServ = 1;
        return numServ;
    };


    this.updateMenuPricing = function ( updated )
    {
      
        var totalRecipeCost = _Util.cleanNumber( $( '#modal-recipe-cost').find('p.total-recipe-cost' ).text(  ) );
        var costPerServing = _Util.cleanNumber( $( '#modal-recipe-cost').find('p.cost-per-serving' ).text(  ) );

        if( !costPerServing )
        {
            var guestCount = this.getNumberOfServings(  );
            var recipeCost = this.getRecipeCost();
            costPerServing = recipeCost / guestCount;
        }

        var newMenuPrice = null;
        var newCostPercentage = null;
        var newMargin = null;

        if( updated == "margin" && margin )
        {
            newMargin = margin;
            newMenuPrice = costPerServing + newMargin;
              newCostPercentage =  parseFloat( costPerServing / newMenuPrice );
        }
        if( updated == "pricing" && menuPrice )
        {
            newMenuPrice = menuPrice;
            newMargin = newMenuPrice - costPerServing;
            newCostPercentage =  parseFloat( costPerServing / newMenuPrice );
            
        }
        else if ( updated == "pricing" && ( menuPrice === 0 || menuPrice === "0"  ) )
        {
            newMenuPrice = 0;
            return;
        }
        if( updated == "foodcost" && foodCostPercentage )
        {
            newCostPercentage = foodCostPercentage;
            if( newCostPercentage == "< 1%" )
            { 
                newCostPercentage = 0.009;
            }
           
            newCostPercentage = parseFloat( String( newCostPercentage ).replace( "%","" ) );

            newMenuPrice = Number( costPerServing ) / Number(newCostPercentage);
            newMargin = newMenuPrice - costPerServing;
            newCostPercentage = newCostPercentage ;
           
        }

        if( newCostPercentage < 0.009 &&  newCostPercentage !== 0 && newCostPercentage !== "0" ) newCostPercentage = "< 1%";
        else newCostPercentage = Number(  newCostPercentage.toFixed( 2 ) );

        menuPrice = newMenuPrice;
        foodCostPercentage = newCostPercentage;
        margin = newMargin;
        console.log( "Update menu pricing: ", menuPrice,        foodCostPercentage,         margin );
        //if( menuPrice )
        
    }

    this.getFoodCostPercentage = function(  )
    {
        return foodCostPercentage;
    }
    this.getMargin = function(  )
    {
        return margin;
    }
    this.getMenuPrice = function(  )
    {
        return menuPrice;
    }


    // Private functions
    var updateMenuPriceWithMargin = function () {
        
        menuPrice = costPerServing + margin;
        var foodCostPct = ( costPerServing / menuPrice ) * 100 ;
        foodCostPct = Number( foodCostPct.toFixed( 2 ) );

    }
    var updateMenuPriceWithFoodCost = function () {
        var costPerServing = this.calculateCostPerServing();
        menuPrice = costPerServing / foodCostPercentage;
        margin = menuPrice - costPerServing;
    }

     var updateMenuPriceWithPrice = function () {
        var costPerServing = this.calculateCostPerServing();
        menuPrice = costPerServing / foodCostPercentage;
        margin = menuPrice - costPerServing;
    }

}



// var recipeCalculator = new RecipeCalculator({
//     yield: 1,
//     servingSize: 2,
//     recipeCost: 5.50
// });


// TEST THE CALCULATOR
// costPerServing = 11
// numberOfServings = .5

// recipeCalculator.setMargin(2);
// // menuPrice = 11 + 2 = 13 
// // foodCostPercentage = 11 / 13 = .85

// recipeCalculator.setFoodCostPercentage(.55)
// menuPrice = costPerServing / .55 =  20
// margin = menuPrice - costPerServing = 20 - 11 = 9

// recipeCalculator.setMenuPrice(22);
// foodCostPercentage = costPerServing / menuPrice =  11 / 22 = 50%;
// margin = menuPrice - costPerServing = 22 - 11 = 11;

// recipeCalculator.setServingSize(1);
// numberOfServings = recipeYield / servingSize = 1 / 1 = 5.50;
// costPerServings = recipeCost * (servingSize / recipeYield) = 5.50 * (1 / 1) = 5.50;