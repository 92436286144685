var ShareRecipeModal;
$(document).ready(function(){
    
    ShareRecipeModal = (function(){
        var _Clipboard = Clipboard.getInstance();
        var _AjaxRecipe = new AjaxRecipe();
        var _ModalCore = new ModalCore();
        var instance;
        var _GenericAlert = new GenericAlert();

        var _ShareRecipeModal = function(){
            this.$ = $('.modal-share-recipe');
            this.originalUrl = $('#email-share-url').val(); 
        }
        _ShareRecipeModal.prototype.binds = function(){
            var _this = this;
            this.$.find('.btn-copy').on('click', function(){
                _Clipboard.copy($(this).siblings('input')[0]);
            });
            this.$.find('.btn-lg-primary').on('click', function(){
                var $modal = $(this).closest('.modal-container');
                if(_ModalCore.isFormValid($modal)){ 
                    _this.share();
                }
            });
            this.$.find('#email-name').on('blur', function(){
                _this.addNameToShareUrl();
            });
            $('a.share').on('click', function(){
                _this.refreshUrl();
                $( '#modal-share-recipe.modal  textarea#email-msg.message-edit' ).val('');
                $('footer').attr('aria-hidden','true');
                $('#main').attr('aria-hidden','true');
                $('nav').attr('aria-hidden','true');
                 $('header').attr('aria-hidden','true'); 
                $('#main').find('.container div').attr('aria-hidden','true');
                setTimeout(function() {
                    $('.modal-title').focus();
                }, 1000);
            });
            this.$.on('hidden.bs.modal', function() { 
                $('footer').attr('aria-hidden','false');
                $('#main').attr('aria-hidden','false');
                $('nav').attr('aria-hidden','false');
                $('header').attr('aria-hidden','false');
                $('#main').find('.container div').attr('aria-hidden','false');
            });
        }
        _ShareRecipeModal.prototype.refreshUrl = function(){
            $('#email-share-url').val(this.originalUrl)
        }
        _ShareRecipeModal.prototype.isFormValid = function(){
            var $errors = this.$.find('.error');
            var formValid = true;
            $errors.each(function(i, el) {
                if($(el).text() !== '')
                    formValid = false;
            })
            return formValid;
        }
        _ShareRecipeModal.prototype.addNameToShareUrl = function(){
            this.originalUrl = $('#email-share-url').val(); 
            var newUrl = this.originalUrl + btoa( $('#email-name').val()); // btoa converts to BASE64
            
            $('#email-share-url').val(newUrl)
        }
        _ShareRecipeModal.prototype.scrapeFormData = function(){
            var name = this.$.find('#email-name').val();
            var shareUrl = this.$.find('#email-share-url').val();
            var from = this.$.find('#email-from').val();
            var to = this.$.find('#email-to').val();
            var message = this.$.find('#email-msg').val();
            var recipeName = $('.recipe-data #vcName').text();
            console.log("newUrl URL ",shareUrl);
          
            return {
                name: name,
                shareUrl: shareUrl,
                from: from,
                to: to,
                message: message,
                recipeName: recipeName
            }
        }
        _ShareRecipeModal.prototype.share = function(){
            // get form data
            var emailInfo = this.scrapeFormData();
 
            // Format request & send
            var emailJson = JSON.stringify(emailInfo)

            _AjaxRecipe.share({
                authToken: '12345',
                params: {
                    emailInfo: emailJson
                }
            }).then(function(res) {
                
                // var res = JSON.parse(res);
                var emailSent = JSON.parse(res).Response.Message.toLowerCase().includes('success');
                if(emailSent) {
                    _GenericAlert.show({
                        title: 'Email sent',
                        msg: 'Your recipe has been succesfully sent to ' + emailInfo.name,
                    });
                } else {
                    // _GenericAlert.show({
                    //     title: 'Email not sent',
                    //     msg: 'There was a problem sending your email, please try again later.',
                    // });
                }
            })
        }

        return {
            getInstance: function(){
               if(!instance) instance = new _ShareRecipeModal();
               return instance;
            }
        }
    })()

    var _ShareRecipeModal  = ShareRecipeModal.getInstance();
    _ShareRecipeModal.binds();
    



});