// Use this class to add FUNCTIONALITY to an existing like button
// Just pass in the jquery element.

// Necessary because we add recipes with like buttons dynamically through AJAX

var LikeBtn;
$(document).ready(function () {
    var _EventEmitter =  EventEmitter.getInstance();
    var srcNotLiked = ASSET_DIR+'/components/Button-Like-Outline@2x.png';
    var srcLiked = ASSET_DIR +'/components/Button-Like-Solid@2x.png';

    LikeBtn = function($likeBtn){
        this.$ = $likeBtn;
        this.$.on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            toggleRecipeLike($(this));
            updateLikeImage($(this));
            _EventEmitter.emit('recipe-card-like-btn:clicked', $(this));
        });
        updateLikeImage(this.$);
    };
    
    var updateLikeImage = function($likeBtn) {
        var liked = parseInt( $likeBtn.closest('.card').find('.liked').text() );
		var title = $likeBtn.closest('.card').find('.title').text();
        if (liked) {
            $likeBtn.attr('src', srcLiked);
            $likeBtn.attr('alt', 'Like Button. Item Liked for ' + title);
			$likeBtn.attr('title', 'Unlike');
        } else {
            $likeBtn.attr('src', srcNotLiked);
            $likeBtn.attr('alt', 'Like Button. Item Not Liked for ' + title);
			$likeBtn.attr('title', 'Like');
        }
    }

    var toggleRecipeLike = function($likeBtn) {
        $likedDiv = $likeBtn.closest('.card').find('.liked');
        var liked = parseInt( $likedDiv.text() );
        if (liked)
            $likedDiv.text(0);
        else
            $likedDiv.text(1);
    }
});