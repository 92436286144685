$(document).ready(function () {
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();
    var _localSmartConvert  = SmartConvert.getInstance();

    UpdateRecipeModal = {};
    UpdateRecipeModal.$ = $('#modal-menu-edit-recipe');
    UpdateRecipeModal.rowToUpdate;

    UpdateRecipeModal.refreshInstance = function () {
        UpdateRecipeModal.$ = $('#modal-menu-edit-recipe');
    }
    UpdateRecipeModal.getFormData = function () {
        var data = {};
        var $form = UpdateRecipeModal.$.find('.modal-content');
        data.recipeId = $form.attr('data-recipeid');
        data.name = UpdateRecipeModal.$.find('#recipe_edit').val();
        data.servings = UpdateRecipeModal.$.find('#servings_edit').val();
        return data;
    }

    UpdateRecipeModal.update = function () {
        UpdateRecipeModal.refreshInstance();
        var formData = UpdateRecipeModal.getFormData();
        _MenuAndIngredientList.updateIngredient(UpdateRecipeModal.rowChild, formData, {
            newIngredient: false
        });
    }

    UpdateRecipeModal.deleteRecipe = function () {
        var $form = UpdateRecipeModal.$.find('.modal-content');
        var recipeId = $form.attr('data-recipeid');
        
        $('.item[data-id="' + recipeId + '"]').remove();
    }

    UpdateRecipeModal.updateForm = function () {
        $form = UpdateRecipeModal.$.find('.modal-content');
        $ingredient = UpdateRecipeModal.rowChild.closest('.item');

        var theUnit = $ingredient.attr('data-unit');
        if( !isNaN( theUnit ) )
        {
            theUnit = _localSmartConvert.getUnitName( theUnit );
        }

        var id = $ingredient.attr('data-id');
        if(!id) {
            var randomNum = Math.floor((Math.random() * 1000000) + 1);

            id = randomNum;
            $ingredient.attr('data-id', randomNum);
        }
        var name = $ingredient.attr('data-name');
        var servings = $ingredient.attr('data-servings');
        
        try{
            var unitStr = $ingredient.attr('data-unitList');
            
            unitStr =  unitStr.replace(/"'"/g, '');
            var unitList = unitStr.split( "|" );

            if( !unitList.includes( theUnit ) ) unitList.push( theUnit );

            $( '#unit_edit' )
             .find('option').remove();

            for (var i = 0; i < unitList.length; i++) {
                var itemName = unitList[i];
                itemName =  itemName.replace(/'/g, '');
                var selectThis = 0;
                if( itemName ==="" ) continue;
                $( '#unit_edit' ).append(  new Option( itemName, itemName, 0 , selectThis ) );
            }
        }
        catch( e )
        { }
        $( '#unit_edit' ).val( theUnit );
        
   

        var allSublist = UpdateRecipeModal.getListOfSublists(  );
        $('select#sublist-dropdown_edit').empty(  );

        $('select#sublist-dropdown_edit').append('<option value=""></option>');
         allSublist.forEach(function ( sublistName ) {
            $('select#sublist-dropdown_edit').append('<option value="' + sublistName + '">' + sublistName + '</option>');
        });


        $form.attr('data-recipeid', id);
        $form.attr('data-recipename', name);
        $form.find('#recipe_edit').val(name);
        $form.find('#servings_edit').val(servings);
    };

    UpdateRecipeModal.getListOfSublists = function(  )
    {
        var sublistNames = [];
        $('div.item.sub-list').each(function(index, obj)
        {
            var n = $(this).data( "name" );
            if( n ) sublistNames.push( n );
        });

        return sublistNames;

    }

    UpdateRecipeModal.binds = function(){
        UpdateRecipeModal.$.find('.delete-recipe-btn').on('mouseup keyup', function (e) {
            if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;
            UpdateRecipeModal.deleteRecipe();
        })
    
        UpdateRecipeModal.$.find('.btn-lg-primary').on('mouseup keyup', function (e) {
            if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;
            UpdateRecipeModal.update();
        })
    
        $(document).on('click', '.edit-toggle', function (e) {
            UpdateRecipeModal.refreshInstance();
            _MenuAndIngredientList.theOpener = e.target;
            _MenuAndIngredientList.addOrEdit = "edit";
            UpdateRecipeModal.rowChild = $(this);
            UpdateRecipeModal.updateForm();
        })
    }
    UpdateRecipeModal.binds();

});