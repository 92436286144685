// Menu and ingredient list in Get, Edit & Add Recipes
// It adds, updates and delete UI rows on it
// At the moment only modals use this as a helper when the user updates anything on the menu or ingredient list

// Singleton because we don't want to perform more updates than we need to

var MenuAndIngredientList = (function(){
    var instance;
    var _UnitCodes;
    var _DragNDrop;

    var theOpener;
    var addOrEdit;

    var _imgLoc;
      var _localSmartConvert ;
          _localSmartConvert  = SmartConvert.getInstance();
    var _MenuAndIngredientList = function(){
        this.$ = $('.ingredients-list');
        this.initDependencies();
        this.updateAllRows($('.ingredients-list'));
        this.updateOddRows();
    
    }

    _MenuAndIngredientList.prototype.initDependencies = function(){
        _UnitCodes = new UnitCodes;
        _DragNDrop = new DragNDrop(false);
    }

    _MenuAndIngredientList.prototype.refreshInstance = function () {
        this.$ = $('.ingredients-list');
    }

    _MenuAndIngredientList.prototype.updateRow = function ($row) {
        var name = $row.attr('data-name');
        var amt = $row.attr('data-amt');
        var img = $row.attr('data-img');
        var txtprep = $row.attr( 'data-txtPrep' );

        var unitList = $row.attr( 'data-unitList' );

        console.log( "Menu/ing row:" );
       
        var unit = (typeof _UnitCodes.getTable()[$row.attr('data-unit') - 1] !== 'undefined')
            ? new UnitCodes().getTable()[$row.attr('data-unit') - 1].single
            : $row.attr ('data-unit');

         var unitCode = $row.attr ('data-unit');

        //var servings = $row.attr('data-servings'); 
        var servings = $row.attr('data-amt'); 

        var $name = $row.find('> .name p');
        var $prep = "";
        if( txtprep ){
           // txtprep = txtprep.replace(/(^\()|(\)$)/g, "");
            txtprep = txtprep.replace(/(^\s)|(\s$)/g, "");
            txtprep = "("+ txtprep +")";

            if( txtprep.slice( 0, 2 ) == "((" && txtprep.slice( -2 ) == "))" ){
                txtprep = txtprep.slice( 1, -1 );
            }


            $prep = " <span style='font-weight:400;font-size:0.820rem;'>"+ txtprep +"</span>";
        }


        if ($name.length === 0) $name = $row.find('> .name a');
        if ($name.length > 0) $name.html(name + $prep);
        

        $row.find( "img.recipe-img" ).attr( "src", this._imgLoc );
        
        $row.find( "img.recipe-img" ).on('error', 
                        function() { $(this).attr( "src",  ASSET_DIR + "/recipe-thumbnail-square.png" ); });
           

        var $unitSection = $row.find('> .amount').not('.servings');
        if ($unitSection.length) {
            if(  noPriceUnitIds.indexOf( Number( unitCode ) ) >= 0 ){
                $unitSection.find('.unit').text(unit);
                if(unit == 'oz' && $unitSection.find('.amt').text() == '1'){
                    $unitSection.find('.unit').attr('aria-label', 'ounce');
                }else if(unit == 'oz' && $unitSection.find('.amt').text() != '1'){
                    $unitSection.find('.unit').attr('aria-label', 'ounces');
                }
            }
            // else{
            //      unitUpscaleFraction =  _localSmartConvert.doConvert( servings, unitCode ) ;
            //      if( unitUpscaleFraction.newUnit.fail === false  && unitUpscaleFraction.newUnit.quantityFraction ){
            //              $unitSection.find('.amt').html( unitUpscaleFraction.newUnit.quantityFraction  );     
            //         }
            //         else
            //         { 
            //             $unitSection.find('.amt').text(amt);
            //         }
            //         if(  unitUpscaleFraction.newUnit.fail === false && unitUpscaleFraction.newUnit.unitName )
            //         {
            //            $unitSection.find('.unit').html( unitUpscaleFraction.newUnit.unitName );
            //         //   $( this ).find( 'span.unit' ).html( '&#8531;' );
            //        }
            //        else
            //        {
            //             $unitSection.find('.unit').text(unit);
            //        }


            // }
            else{
                 unitUpscaleFraction =  _localSmartConvert.doConvert( servings, unitCode ) ;
                 if( unitUpscaleFraction.newUnit.fail === false  && unitUpscaleFraction.newUnit.quantityFraction ){
                        $unitSection.find('.amt').html( unitUpscaleFraction.newUnit.quantityFraction  );
                        var ret = servings.split(".")
                        if(ret.length >= 2) {
                        var str1 = ret[0];

                        if(str1 == 0)
                        {
                            str1='';
                            str1 = str1 + "";
                        }
                        else
                        {
                           str1 = str1 + " and "; 
                        }
                        
                        var str2 = ret[1];
                        if(str2 == 1)
                        {
                            str2='one tenth';
                        }
                        if(str2 == 2)
                        {
                            str2='two fifths';
                        }
                        if(str2 == 25)
                        {
                            str2='one quarter';
                        }

                        if(str2 ==3)
                        {
                            str2='three tenths';
                        }
                        if(str2 == 4)
                        {
                            str2='two fifths';
                        }
                        if(str2 == 5)
                        {
                            str2='one half';
                        }
                        if(str2 == 6)
                        {
                            str2='three fifths';
                        }
                        if(str2 == 7)
                        {
                            str2='seven tenths';
                        }

                        if(str2 == 75)
                        {
                            str2='three quarters';
                        }
                        if(str2 == 8)
                        {
                            str2='four fifths';
                        }
                        if(str2 == 9)
                        {
                            str2='nine tenths';
                        }
                        $unitSection.find('.amt').attr("aria-label", ""+ str1 +""+ str2 +"");
                        }

                    }
                    else
                    { 
                        $unitSection.find('.amt').text(amt);
                    }
                    if(  unitUpscaleFraction.newUnit.fail === false && unitUpscaleFraction.newUnit.unitName )
                    {
                       $unitSection.find('.unit').html( unitUpscaleFraction.newUnit.unitName );
                       if(unit == 'oz' && $unitSection.find('.amt').text() == '1'){
                            $unitSection.find('.unit').attr('aria-label', 'ounce');
                        }else if(unit == 'oz' && $unitSection.find('.amt').text() != '1'){
                            $unitSection.find('.unit').attr('aria-label', 'ounces');
                        }
                    //   $( this ).find( 'span.unit' ).html( '&#8531;' );
                   }
                   else
                   {
                        $unitSection.find('.unit').text(unit);
                        if(unit == 'oz' && $unitSection.find('.amt').text() == '1'){
                            $unitSection.find('.unit').attr('aria-label', 'ounce');
                        }else if(unit == 'oz' && $unitSection.find('.amt').text() != '1'){
                            $unitSection.find('.unit').attr('aria-label', 'ounces');
                        }
                   }


            }
           
        } 

        var $servings = $row.find('.servings p');
        if ($servings.length) 
        {
            if( noPriceUnitIds.indexOf( unitCode ) >= 0 )
            {
            }
            else{
                $servings.text(servings);
            }
        }
    }

    _MenuAndIngredientList.prototype.updateAllRows = function ($list) {
        var _this = this;
        $list.find('.item').each(function (i, item) {
            _this.updateRow($(item));
        })
    }

    _MenuAndIngredientList.prototype.getRowData = function ($row) {
        var data = {};
        data.name = $row.attr('data-name');
        data.amt = $row.attr('data-amt');
		data.amt1 = $row.attr('data-amt1');
		data.unit1 = $row.attr('data-unit1');
        data.unit = (typeof _UnitCodes[$row.attr('data-unit')-1] !== 'undefined')
            ? _UnitCodes[$row.attr('data-unit') - 1].single
            : $row.attr('data-unit');

        data.category = $row.attr('data-category');
        data.perunitprice = $row.attr('data-perunitprice');
        data.unit2 = $row.attr('data-unit2' );
        data.amt2 = $row.attr('data-amt2' );
        data.currentConversion = $row.attr( 'data-currentconversion' );
        data.unitcode2 = $row.attr('data-unitcode2' );

        return data;
    }

    _MenuAndIngredientList.prototype.getAllData = function ($list, options) {
        var _this = this;
        var getSublists = options.getSublists;
        var data = [];
        var $items;

        if (getSublists)
            $items = $list.find('.item').not('.placeholder')
        else
            $items = $list.find('.item').not('.placeholder').not('.sub-list');

        $items.each(function (i, item) {
            var rowData = _this.getRowData($(item));
            data.push(rowData);
        })
        return data;
    }

    // Move the ingredient to it's new position (if applicable)
    _MenuAndIngredientList.prototype.moveIngredient = function ($ingredient, ingredientData, options) {
        var $sublist;
        if (ingredientData.sublistName)
            $sublist = this.$.find('[data-name="' + ingredientData.sublistName + '"]');

        if ($sublist && $sublist.length > 0) {
            $sublist.find('.nested').append($ingredient);
        } else if (options.newIngredient) {
            this.$.find('> .nested').append($ingredient);
        }

    }

    // Need to return it to update the reference
    _MenuAndIngredientList.prototype.assignUpdatedIngredientData = function ($ingredient, ingredientData) {
        console.log(ingredientData);
        var $updatedIngredient = $ingredient;
        if (ingredientData.ingredientId) $updatedIngredient.attr('data-nrecingid', ingredientData.ingredientId);
        if (ingredientData.name) $updatedIngredient.attr('data-name', ingredientData.name);
        if (ingredientData.prepNotes) $updatedIngredient.attr('data-txtprep', ingredientData.prepNotes);
        if (ingredientData.servings) $updatedIngredient.attr('data-servings', ingredientData.servings);
        if (ingredientData.amt) $updatedIngredient.attr('data-amt', ingredientData.amt);
        if (ingredientData.unit) $updatedIngredient.attr('data-unit', ingredientData.unit);
        if (ingredientData.parentName) $updatedIngredient.attr('data-simplegroupparent', ingredientData.parentName);

        if (ingredientData.servings)
            $($ingredient).find('p.number-of-servings').text( ingredientData.servings );

        var uList = JSON.stringify(  ingredientData.unitList );
        if (ingredientData.unitList) $updatedIngredient.attr('data-unitList', uList.replace(/"/g, "'") );
        return $updatedIngredient;
    }

    /**
     * Takes in the ingredient row OR a child of it, and performs updates on it
     * 
     * @param {jQuery Obj}  $el = jQuery Row Obj  
     * @param {Object} ingredientData - You can pass only the values you need updated
     * @param {Object} ingredientData.name 
     * @param {Object} ingredientData.servings 
     * @param {Object} ingredientData.amt
     * @param {Object} ingredientData.unit 
     * @param {Object} ingredientData.sublistName - not used in element yet, but use in this.MoveIngredient to move to a new position in the list 
     * @param {Object} options 
     * @param {Object} options.newIngredient - Are you creating a new ingredient? or updating an existing one?
     */
    _MenuAndIngredientList.prototype.updateIngredient = function ($el, ingredientData, options) {
        var newIngredient = options.newIngredient;
        var $ingredientRow = $el;
        if (!$ingredientRow.hasClass('.item')) $ingredientRow = $el.closest('.item');
        // assign first, and then force update
        $ingredientRow = this.assignUpdatedIngredientData($ingredientRow, ingredientData);
        this.updateRow($ingredientRow);
        // will more the ingredient in case you assigned it a new sublist
        this.moveIngredient($ingredientRow, ingredientData, {
            newIngredient: newIngredient
        });
    }

    _MenuAndIngredientList.prototype.addIngredient = function (ingredientData) {
        this.refreshInstance();
        $ingredientRow = this.$.find('.item.placeholder').not('.sub-list').clone().removeClass('placeholder');
        this.updateIngredient($ingredientRow, ingredientData, {
            newIngredient: true,
        });
    }

    _MenuAndIngredientList.prototype.updateSublistData = function ($sublist, ingredientData) {
        var $updatedSublist = $sublist;
        $sublist.attr('data-name', ingredientData.name);
        $sublist.attr('data-simplegroupname', ingredientData.name);
        return $updatedSublist;
    }

    // Need to return it to update the reference
    _MenuAndIngredientList.prototype.updateSublist = function ($sublistRow, sublistData) {
        var $updatedSublist = this.updateSublistData($sublistRow, sublistData);
        this.updateRow($updatedSublist);
    }

    _MenuAndIngredientList.prototype.addSublist = function (sublistData) {
        this.refreshInstance();
        $sublistRow = this.$.find('.item.sub-list.placeholder').clone().removeClass('placeholder');
        var listOfSublists = this.getListOfSublists(  );
        if( listOfSublists.indexOf( sublistData.name ) > -1 ) return;
        this.updateSublist($sublistRow, sublistData);
        this.$.find('> .nested').append($sublistRow);
        if (typeof _DragNDrop !== 'undefined') _DragNDrop.refreshDragula();
    }


     _MenuAndIngredientList.prototype.getListOfSublists = function(  )
    {
        var sublistNames = [];
        $('div.item.sub-list').each(function(index, obj)
        {
            var n = $(this).attr("data-name" );
            if( n ) sublistNames.push( n );
        });

        return sublistNames;
    }

    _MenuAndIngredientList.prototype.updateRecipeData = function ($sublist, recipeData) {
        $sublist.attr('data-name', recipeData.name);
        $sublist.attr('data-servings', recipeData.servings);
        var recipeStuff = recipeData.recipeId;
        var parts = recipeStuff.split( ":" );
        
        var imgUrl =  parts[1];

        if( imgUrl )
        {
            imgUrl =  IMG_ROOT_URL  + imgUrl ;
            console.log( "RECIPE IMAGE UPDATE: " );
            console.log( imgUrl );
            
        }
        else
        {
            imgUrl = ASSET_DIR + "/generic-recipe.jpg";
        }
        $.attr('data-img', imgUrl );
         this._imgLoc = imgUrl;
    }
    _MenuAndIngredientList.prototype.updateRecipe = function ($recipeRow, recipeData) {
        this.updateRecipeData($recipeRow, recipeData);
        this.updateRow($recipeRow);
    }


    _MenuAndIngredientList.prototype.addRecipe = function (recipeData) {
        this.refreshInstance();
        $recipeRow = this.$.find('.item.placeholder').clone().removeClass('placeholder');
        this.updateRecipe($recipeRow, recipeData);
        this.$.find('> .nested').append($recipeRow);
        $($recipeRow).find(".number-of-servings").text(recipeData.servings);
    }

    _MenuAndIngredientList.prototype.updateOddRows = function () {
        $('.ingredients-list.ingredients').not('.edit').find('.item').each(function (i, el) {
            if (i % 2) {
                $(el).css('backgroundColor', '#FBFBFB');
            } else {
                $(el).css('backgroundColor', 'white');
            }
        });

    }


    return {
        getInstance: function() {
            if(!instance) instance = new _MenuAndIngredientList();
            return instance;
        }
    }
})()

console.log(MenuAndIngredientList);
