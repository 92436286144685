/* 
    Here's the HTML you'd need to make this dropdown menu work. Obviously the image is not
    necessary but adds a nice touch.
    <div class="c-dropdown-menu">
        <button class="btn btn-lg btn-lg-basic c-dropdown-toggle" aria-haspopup="true">
            MORE
            <img class="caret c-dropdown-toggle" src="{$smarty.const.DIR_ASSETS}/style/Icon-Caret-Down@2x.png" alt="Icon Caret Down">
        </button>
        <div class="dropdown-content">
            <a href="#" class="" data-toggle="modal" data-target="#alert-recipe-delete">DELETE</a>
        </div>
    </div>
*/

// DROPDOWN MENUS ---------------------------------------------------------
DropdownMenu = function(){
    this.$ = $('.c-dropdown-toggle');
    this.binds();
};
DropdownMenu.prototype.toggle = function ($menu) {
    $menu.toggle();
}
DropdownMenu.prototype.binds = function(){
    var _this = this;
    this.$.on('click', function () {
        _this.toggle($(this).siblings('.dropdown-content'));
    })

    // Close the dropdown if the user clicks outside of it
    $(document).on('click', function (e) {
        //console.log(e);
        if (!$(e.target).hasClass('c-dropdown-toggle')) {
            var $dropdowns = $(".dropdown-content");
            $dropdowns.each(function (i, el) {
                $(el).hide();
            })
        }
    });
}
