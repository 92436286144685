$(document).ready(function(){
    if(document.location.href.includes('myRecipes')) {
        var _CacheManager = CacheManager.getInstance();
        var _Util = Util;

        var searchQuery = '';
        var cachedFilters = _CacheManager.getValue('filterIds:' + window.location.pathname + '-'+ USER_ID);
        var categoryFilters = {
            courseIds: [],
            cookingMethodIds: [],
            cuisineIds: [],
        }
        var bookFilters = {
            bookIds:[],
            chapterNums: []
        }
 
        // Fill category and book filters if they are cached
        if(cachedFilters) {
            cachedFilters.forEach(function(id){
                var $filter = $('[id="'+ id +'"]');
                var id = $filter.attr('data-id');
                var filterType = $filter.data('filtertype');
                
                if(filterType === 'course')
                    categoryFilters.courseIds.push(id);
                if(filterType === 'cooking-method')
                    categoryFilters.cookingMethodIds.push(id);
                if(filterType === 'cuisine')
                    categoryFilters.cuisineIds.push(id);
                if(filterType === 'chapter'){
                    var chapter = $filter.attr('data-chapter');
                    bookFilters.bookIds.push(id);
                    bookFilters.chapterNums.push(chapter);
                }

            });
        }

        // Get Search Query from url param if it's there
        if(_Util.getUrlParam('query')) searchQuery = _Util.getUrlParam('query');

        PaginationDataHandler.getInstance({
            itemToRender: 'recipeCard',
            getItemsUrl: SVC_URL + 'searchFilterSort/getMyRecipes',
            getMoreItemsUrl: SVC_URL + 'searchFilterSort/getMoreRecipes',
            ajaxRequestDefaults: {
                authToken: '12345',
                searchQuery: searchQuery,
                perPage: 20,
                batchSize: 200,
                orderSelection: 'last_applied',
                likeStatus: 0,
                categoryFilters: categoryFilters,
                bookFilters: bookFilters
            }
        });
    }
});
