// Conversion API documentation copied and pasted form Dan's API doc
function AjaxConversion(){
    var _AjaxBuilder = new AjaxBuilder();
    this.buildIngredientTable = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/buildIngredientTable',
        eventSuccessKey: 'ajax-conversion:buildIngredientTable-success',
        eventFailKey: 'ajax-conversion:buildIngredientTable-error'
    })

    this.checkConversionValid = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/checkConversionValid',
        eventSuccessKey: 'ajax-conversion:checkConversionValid-success',
        eventFailKey: 'ajax-conversion:checkConversionValid-error'
    })

    this.checkConversionValid_editRecipe = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/checkConversionValid_editRecipe',
        eventSuccessKey: 'ajax-conversion:checkConversionValid-success',
        eventFailKey: 'ajax-conversion:checkConversionValid-error'
    })


    this.buildIngredientTableFromRecipeId = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/buildIngredientTableFromRecipeId',
        eventSuccessKey: 'ajax-conversion:buildIngredientTableFromRecipeId-success',
        eventFailKey: 'ajax-conversion:buildIngredientTableFromRecipeId-error'
    })

    this.convertFactorList = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/convertFactorList',
        eventSuccessKey: 'ajax-conversion:convertAmount-success',
        eventFailKey: 'ajax-conversion:convertAmount-error'
    })

    this.convertAmount = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/convertAmount',
        eventSuccessKey: 'ajax-conversion:convertAmount-success',
        eventFailKey: 'ajax-conversion:convertAmount-error'
    })

    this.convertStd = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/convertStd',
        eventSuccessKey: 'ajax-conversion:convertStd-success',
        eventFailKey: 'ajax-conversion:convertStd-error'
    })

    this.getUnitTable = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/getUnitTable',
        eventSuccessKey: 'ajax-menu:getUnitTable-success',
        eventFailKey: 'ajax-menu:getUnitTable-error'
    })


     this.getCombinedConversion = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/combinedConversion',
        eventSuccessKey: 'ajax-menu:getCombinedConversion-success',
        eventFailKey: 'ajax-menu:getCombinedConversion-error'
    })

    this.getConstConversionTable = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/getConstConversionTable',
        eventSuccessKey: 'ajax-menu:getConstConversionTable-success',
        eventFailKey: 'ajax-menu:getConstConversionTable-error'
    })

    this.getUnitIdFromName = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/getUnitIdFromName',
        eventSuccessKey: 'ajax-menu:getUnitIdFromName-success',
        eventFailKey: 'ajax-menu:getUnitIdFromName-error'
    })

    this.getUnitNameFromId = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/getUnitNameFromId',
        eventSuccessKey: 'ajax-menu:getUnitNameFromId-success',
        eventFailKey: 'ajax-menu:getUnitNameFromId-error'
    })

    this.getUnitConversion = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/getUnitConversion',
        eventSuccessKey: 'ajax-menu:getUnitConversion-success',
        eventFailKey: 'ajax-menu:getUnitConversion-error'
    })
}


