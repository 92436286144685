// LINKS the checkboxes in the pagination filters
// With the filter badges that appear at the top of the items
// These are very tightly coupled and not really reusable

var FilterCheckbox = function(){
    this.binds();
};

// When you click on a checkbox this function removes the filter badge associate with it if the names match
FilterCheckbox.prototype.removeAssociatedFilters = function ($checkbox) {
    var id = $checkbox.find('input').attr('id');
    var $filterToRemove = $("[data-triggerid='" + id + "']")
    $filterToRemove.find('.filter-close').trigger('click', {
        clickFromOutsideComponent: true
    });
};

// When you click on checkbox add a filter to existing
FilterCheckbox.prototype.addFilterToExistingCategory = function ($checkbox) {
    // Get Metadata for new filter
    var filterId = $checkbox.find('input').attr('id');
    var filterName = $checkbox.find('label').text();

    // Get Category
    var category = $checkbox.closest('.collapse-content').siblings('.collapse-ctrl').find('p').text();
    var categoryClass = Util.convertToCSSClass(category)
    var $filterBadgeCategory = $('.' + categoryClass)
    var $filterBadge = $filterBadgeCategory.closest('.filter-badge');

    // Check if filter exists
    var filterExists = $filterBadgeCategory.find("[data-triggerid='" + filterId + "']").length > 0;
    if (filterExists) return;

      var categoryClass = Util.convertToCSSClass(category); 

    // Create the filter & Append to Existing Category
    var filterHTML = '<div class="filter ' + categoryClass + ' " data-triggerid="' + filterId + '">'
        + '<div style="display:inline-block;" tabindex="0">' +filterName
        + '</div><img class="filter-close" src="'+ASSET_DIR+'/style/Icon-Remove-sm@2x.png" alt="" title="Close" tabindex="0" role="button" aria-label="Press Enter key to Remove' + filterName + 'Filter">'
        + '</div>';
    $filterBadge.append(filterHTML)
}

// When you click on checkbox add both the category and filter
FilterCheckbox.prototype.addCategoryAndFilter = function ($checkbox) {
    // Get Metadata for new filter
    var filterId = $checkbox.find('input').attr('id');
    var filterName = $checkbox.find('label').text();

    // Get metadata for new Category
    var category = $checkbox.closest('.collapse-content').siblings('.collapse-ctrl').find('p').text();
    var categoryClass = Util.convertToCSSClass(category);

    // Get the FilterBadgeCollection
    var $filterBadgeCollection = $('.filter-badge-collection');

    // Create new category & filter and Append
        var filterHTML = '<div class="filter-badge">'
        + '<div class="group-name">' + category + ':</div>'
        + '<div class="filter filter-badge-'+filterId +'" data-triggerid="' + filterId + '">'
        + '<div style="display:inline-block;">' +filterName
        + '</div><img class="filter-close" src="'+ASSET_DIR+'/style/Icon-Remove-sm@2x.png" alt="" title="Close" tabindex="0" role="button" aria-label="Press Enter key to Remove' + filterName + 'Filter">'
        + '</div>'
        + '</div>'
        + '</div>';
    $filterBadgeCollection.removeClass('hidden').find('.filters').append($(filterHTML))
}

FilterCheckbox.prototype.addOrRemoveFilterPipe = function($categoryFilter){
    var $input = $categoryFilter.find('input');

    if (!$input.prop('checked')) {
        this.removeAssociatedFilters($categoryFilter);
    } else {
        var category = $categoryFilter.closest('.collapse-content').siblings('.collapse-ctrl').find('p').text();
        var categoryClass = Util.convertToCSSClass(category)
        categoryClass = categoryClass.replace( ':',"_" );
        var $filterBadgeCategory = $('.' + categoryClass);
        var categoryExists = $filterBadgeCategory.length > 0

        if (categoryExists) 
            this.addFilterToExistingCategory($categoryFilter);
        else 
            this.addCategoryAndFilter($categoryFilter);
    }
}

FilterCheckbox.prototype.binds = function(){
    var _this = this;
    // Click on checkbox input row
    // DOESNT CHECK OR UNCHECK 
    // Only adds the FILTER BADGE based on the current checkbox status
    $('.filters-component .category-filter').mouseup(function (e) {
        var $categoryFilter = $(this);
        console.log( $categoryFilter );
        setTimeout(function(){
             console.log( $categoryFilter );
            _this.addOrRemoveFilterPipe($categoryFilter);
        }, 1)
    });

    // When you close a filter badge this will click the checkbox associated with it.
    $(document).on('click', '.filter-badge .filter-close', function (e, params) {
        var clickFromOutsideComponent = (params && typeof params.clickFromOutsideComponent !== 'undefined')
                ? params.clickFromOutsideComponent
                : false;
        console.log('click on filter');
        console.log('from outside? ' + clickFromOutsideComponent);
        if(!clickFromOutsideComponent) {
            var filterID = $(this).closest('.filter').data('triggerid');
            $("#"+filterID).trigger('click');
        }
    });
}

