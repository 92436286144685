// /**
//  * Category Filter 
//  * that works in conjuction with JPLIST
//  * 
//  * Filter basically does 3 things.
//  * 1) Keeps track of the current searchQuery.
//  * 2) Refreshes items (UI) whenever the you search for a new item or delete the search query.
//  * 
//  * Has a small dependency on Util.convertToCSSClass(str) from util.js
//  * 
//  */

// Filter.Search = (function () {
//     var $filteredItems;
//     var activeQuery = '';

//     function init(_$jpList) {
//         binds();
//     }

//     // These binds are used to add and remove filters
//     function binds() {
//         $('.search-bar .search-btn').on('click', function () {
//             activeQuery = $(this).siblings('.field-input').find('input').val();
//             Filter.refreshItems();
//             updateResultsFound();
//         });
//         $('.results .close-icon').on('click', function () {
//             resetQuery();
//             Filter.refreshItems();
//         })
//     }

//     function resetQuery() {
//         activeQuery = '';
//     }

//     function runFilter($items) {
//         return $items.filter(function () {
//             var reg = new RegExp(activeQuery, "i");
//             return reg.test($(this).text());
//         });
//     }


//     function updateResultsFound() {
//         var $results = $('.results');
//         var qty = (Filter.$itemsInView.length);
//         var msg = ' results found for ';
//         var $qty = $results.find('.qty');
//         var $msg = $results.find('.msg');
//         var $query = $results.find('.search-query');
        
//         $results.removeClass('hidden')

//         $qty.text(qty)
//         $msg.text(msg)
//         $query.text(activeQuery)
//     }

//     return {
//         init: init,
//         runFilter: runFilter
//     }
// })();