
var EditRecipeModalValidation;
$(document).ready(function(){
    var _Validation = Validation.getInstance();
    var _AjaxRecipe = new AjaxRecipe();
    var _EventEmitter = EventEmitter.getInstance();
    var _Util = Util;

    EditRecipeModalValidation = function(){
        this.binds();
    }
    EditRecipeModalValidation.prototype.name = function() {
        var errFound = false;
        var errMsg = '';
        var $modal = $('.edit-recipe-modal.show');
        var $nameInput = $modal.find('.name-edit')
        if($nameInput.val() === '') {
            errFound = true;
            errMsg = 'Please enter a recipe name.'
        } else {
            //this.checkIfRecipeExists($nameInput.val(), $nameInput);
            _Validation.handleError(errFound, '', $nameInput);
            return
        }
        
        _Validation.handleError(errFound, errMsg, $nameInput);
    }

    EditRecipeModalValidation.prototype.checkIfRecipeExists = function(name, $nameInput){
        _AjaxRecipe.checkIfExists({
            params: {
                authToken: '12345'
            },
            params: {
                recipeName: name
            }
        }).then(function(res){
            var recipeId = JSON.parse(res).Data;
            var isNewRecipe = recipeId === -1;

            var errFound = false;
            var errMsg = '';

            if(isNewRecipe) {
                errFound = true;
                errMsg = 'Please select an ingredient from one of the drop down values.';
            }
            _Validation.handleError(errFound, errMsg, $nameInput);

        })
    }
    EditRecipeModalValidation.prototype.servings = function () {
        var errFound = false;
        var errMsg = '';
        var $modal = $('.edit-recipe-modal.show');
        var $servings = $modal.find('.number-of-servings-edit')

        if($servings.val() === '') {
            errFound = true;
            errMsg = 'Please enter a serving amount.';
        } else if (isNaN($servings.val())) {
            errFound = true;
            errMsg = 'Please enter a numeric value.';
        }
        
        _Validation.handleError(errFound, errMsg, $servings);
    }

    var recipeExists = function() {
        var errFound = false;
        
        // Get data
        var $modal = $('.edit-recipe-modal.show');
        var $ingredientsList = $('.ingredients-list .item').not('.placeholder').not('.hidden');
        var editedIngredientName = $modal.find('.name-edit').val();
        
        // Check how many ingredient name matches there are in the ingredient list
        var ingredientExists = false;
        var matchesFound = 0;
        $ingredientsList.each(function(i, el){
            var name = $(el).attr('data-name');

            if(name === editedIngredientName) matchesFound++;
        });
    
        // check if we are in edit or add ingredient modal
        var editModalOpen = $modal.find('.modal-content').attr('data-recipename');
        var addModalOpen = typeof $modal.find('.modal-content').attr('data-recipename') === 'undefined';
        
        // Decide if the ingredient exists
        if(editModalOpen){
            var openedFrom = $modal.find('.modal-content').attr('data-recipename');
            
            if(editedIngredientName === openedFrom && matchesFound > 1)
                ingredientExists = true;
            else if (editedIngredientName !== openedFrom && matchesFound > 0)
                ingredientExists = true;

        } else if (addModalOpen && matchesFound > 0) {
            ingredientExists = true;
        }
        if(ingredientExists) {
            errFound = true;
        }
        return errFound;
    }

    EditRecipeModalValidation.prototype.all = function(){
        this.name();
        this.servings();
    }

    EditRecipeModalValidation.prototype.binds = function(){
        var _this = this;
        
        $('.edit-recipe-modal .name-edit').on('blur', this.name.bind(this));
        $('.edit-recipe-modal .number-of-servings-edit').on('blur', this.servings);

        var $saveBtn = $('.edit-recipe-modal').find('.confirm-btn');
        $($saveBtn).on('mousedown keydown click', function(e){
            if( _Util.isHumanClickEvent(e) ) return; // human click does a double validation so dont allow 
            _this.all();
        })
    }

    // INIT
    new EditRecipeModalValidation()

})