// Back end sends unit codes instead of the strings. This is the table to convert those units
var UnitCodeTable = [
    {
        // order: '1',
        single: '#10 Can',
        plural: '#10 Cans',
    },
    {
        // order: '2',
        single: 'as needed',
        plural: 'as needed',
    },
    {
        // order: '3',
        single: 'bag',
        plural: 'bags',
        
    },
    {
        // order: '4',
        single: 'bottle',
        plural: 'bottles',
    },
    {
        // order: '5',
        single: 'box',
        plural: 'boxes',
        
    },
    {
        // order: '6',
        single: 'bunch',
        plural: 'bunches',
        
    },
    {
        // order: '7',
        single: 'can',
        plural: 'cans',
    },
    {
        // order: '8',
        single: 'case', 
        plural: 'cases',
    },
    {
        // order: '9',
        single: 'clove', 
        plural: 'cloves',
    },
    {
        // order: '10',
        single: 'container', 
        plural: 'containers',
    },
    {
        // order: '11',
        single: 'cup', 
        plural: 'cups',
    },
    {
        // order: '12',
        single: 'ea', 
        plural: 'ea',
    },
    {
        // order: '13',
        single: 'fl oz', 
        plural: '',
    },
    {
        // order: '14',
        single: 'g', 
        plural: 'g',
    },
    {
        // order: '15',
        single: 'gal', 
        plural: '',
    },
    {
        // order: '16',
        single: 'head', 
        plural: 'heads',
    },
    {
        // order: '17',
        single: 'jar', 
        plural: 'jars',
    },
    {
        // order: '18',
        single: 'kg', 
        plural: 'kg',
    },
    {
        // order: '19',
        single: 'L', 
        plural: 'L',
    },
    {
        // order: '20',
        single: 'lb', 
        plural: 'lb',
    },
    {
        // order: '21',
        single: 'leaf', 
        plural: 'leaves',
    },
    {
        // order: '22',
        single: 'loaf', 
        plural: 'loaves',
    },
    {
        // order: '23',
        single: 'mL', 
        plural: 'mL',
    },
    {
        // order: '24',
        single: 'No Unit', 
        plural: 'No Unit',
    },
    {
        // order: '25',
        single: 'oz', 
        plural: 'oz',
    },
    {
        // order: '26',
        single: 'pinch', 
        plural: 'pinches',
    },
    {
        // order: '27',
        single: 'pt', 
        plural: 'pt',
    },
    {
        // order: '28',
        single: 'qt', 
        plural: 'qt',
    },
    {
        // order: '29',
        single: 'scoop', 
        plural: 'scoops',
    },
    {
        // order: '30',
        single: 'sheet', 
        plural: 'sheets',
    },
    {
        // order: '31',
        single: 'sprig', 
        plural: 'sprigs',
    },
    {
        // order: '32',
        single: 'tbsp', 
        plural: 'tbsp',
    },
    {
        // order: '33',
        single: 'to taste', 
        plural: 'to taste',
    },
    {
        // order: '34',
        single: 'tsp', 
        plural: 'tsp',
    },
    {
        // order: '35',
        single: 'wedge', 
        plural: 'wedges',
    },
    {
        // order: '36',
        single: 'slice', 
        plural: 'slices',
    },
    {
        // order: '37',
        single: 'N/A', 
        plural: 'N/A',
    },
    {
        // order: '38',
        single: 'drop', 
        plural: 'drops',
    },
    {
        // order: '39',
        single: '½ sheet', 
        plural: '½ sheet',
    },
    {
        // order: '40',
        single: 'round', 
        plural: 'round',
    },
    {
        // order: '41',
        single: 'hotel pan', 
        plural: 'hotel pan',
    },
    {
        // order: '42',
        single: 'recipe', 
        plural: 'recipes',
    },
    {
        // order: '43',
        single: 'hour', 
        plural: 'hours',
    },
    {
        // order: '44',
        single: '½ hotel pan', 
        plural: '½ hotel pan',
    },
    {
        // order: '45',
        single: 'disk', 
        plural: 'disks',
    },
    {
        // order: '46',
        single: 'stalk', 
        plural: 'stalks',
    },
 
    {
        single: 'per', 
        plural: 'per',
    },
    {
        single: 'portion', 
        plural: 'portions',
    },
    {
        single: 'per half',
        plural: 'per halves',
    },
    {
        single: 'dozen', 
        plural: 'dozens',
    },
    {
        single: 'in', 
        plural: 'inches',
    },
    {
        single: 'a few drops', 
        plural: 'a few drops',
    },
    {
        single: 'as desired',
        plural: 'as desired',
    },
    {
        single: 'as needed', 
        plural: 'as needed',
    },
    {
        single: 'as needed for breading', 
        plural: 'as needed for breading', 
    },
    {
        single: 'as needed for garnish', 
        plural: 'as needed for garnish', 
    },
    {
        single: 'as needed for storage', 
        plural: 'as needed for storage', 
    },
    {
        single: 'to color', 
        plural: 'to color'
    },
        {
        single: 'dash', 
        plural: 'dash'
    },
    {
        // order: '60',
        single: 'half', 
        plural: 'halves',
    },
]
