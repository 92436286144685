/**
 *  Pagination Component UI adjustments
 */

$('document').ready(function () {
    var $win = $(window);

    var PaginationUI = function(){};

    // Reorder bottom controls components on mobile view
    PaginationUI.prototype.reorderBottomPagination = function() {
        if ($win.width() < 768) {
            if ($('.jplist-panel-bottom').children(':first').hasClass('jplist-pagination'))
                $('.jplist-panel-bottom .jplist-label').after($('.jplist-panel-bottom .jplist-pagination'));
        } else {
            if ($('.jplist-panel-bottom').children(':first').hasClass('jplist-label'))
                $('.jplist-panel-bottom .jplist-pagination').after($('.jplist-panel-bottom .jplist-label'));
        }
    }

    // SETUP A FLEXIBLE CONTAINER OVER THE ITEMS LIST TO ALWAYS KEEP THEM CENTERED
    // This is necessary because
    //  1) Items have to be left aligned
    //  2) This means we have to center their parent, but width fit-content doesnt work on IE11
    // 
    // We set adjust customerContainer width to fit the max amount of items possible on current screen size
    PaginationUI.prototype.keepItemsCentered = function() {
        $('.custom-container').css('width', 'auto'); //reset

        var $parentContainer = $('.pagination-component');

        var windowPadding = parseInt( $parentContainer.css('padding-right'))*2;
        var windowWidth = $parentContainer.outerWidth(true) + windowPadding;
        // var blockPadding = parseInt( $('.custom-container-child').css('padding-right'))*2;
        // var blockMargin = parseInt( $('.custom-container-child').css('margin-right'))*2;
        var blockWidth = $('.custom-container-child').outerWidth(true) ;
        var maxBoxPerRow = Math.floor(windowWidth / blockWidth);

        // console.log({
        //     windowPadding: windowPadding,
        //     windowWidth: windowWidth,
        //     // blockPadding: blockPadding,
        //     // blockMargin: blockMargin,
        //     realBlockWidth: $('.custom-container-child').outerWidth(true),
        //     blockWidth: blockWidth,
        //     maxBoxPerRow: maxBoxPerRow,
        //     calculatedWidth: maxBoxPerRow * blockWidth
        // });

        if (maxBoxPerRow > 0)
            $('.custom-container').width(maxBoxPerRow * blockWidth);
        else
            $('.custom-container').width('100%');
    }

    PaginationUI.prototype.binds = function(){
        var _this = this;
        $(window).resize(function () {
            _this.keepItemsCentered();
            _this.reorderBottomPagination();
        });
    }

    var JPListDropdown = function(){}

    JPListDropdown.prototype.toggleHandler = function(){
        $(this).find('[role="listbox"]').toggle();
		if($(this).attr('aria-expanded') == 'true'){
			$(this).attr('aria-expanded', 'false');
		} else {
			$(this).attr('aria-expanded', 'true');
		}
    }
    JPListDropdown.prototype.updateSelection = function(){
        var newSelection = $(this).find('span').text();
        var $list = $(this).closest('ul');
        $list.siblings('.jplist-dd-panel').text(newSelection)
    }
    JPListDropdown.prototype.adjustSortByCaret = function() {
        var $dropdown = $('#items-per-page.jplist-drop-down .jplist-dd-panel');

        if ($dropdown.text().trim() === 'View all') {
            $dropdown[0].style.setProperty('width', '75px', 'important');
        } else {
            $dropdown[0].style.setProperty('width', '50px', 'important');
        }
    }
    JPListDropdown.prototype.binds = function(){
        $('.jplist-drop-down').on('click', this.toggleHandler);
        $('.jplist-drop-down ul li').on('click', this.updateSelection)
        $('#items-per-page.jplist-drop-down ul').on('click', this.adjustSortByCaret);
        $(window).on('click', this.outsideClickHandler);
    }
    JPListDropdown.prototype.outsideClickHandler = function(e){
        var $dropdowns = $(".jplist-submenu");

        if ($(e.target).hasClass('jplist-dd-panel')) {
            var $thisDropdown = $(e.target).siblings('.jplist-submenu');
            $dropdowns = $dropdowns.not($thisDropdown[0]);
			$('.jplist-dd-panel').each(function () {
				if($(this).parent().attr('id') != $(e.target).parent().attr('id')){
					$(this).parent().attr('aria-expanded', 'false');	
				}
			});
        } else {
            $('.jplist-drop-down').attr('aria-expanded', 'false');
        }
        $dropdowns.each(function (i, dropdown) {
            $(dropdown).hide();
        });
    }

    // INIT
    var _JPListDropdown = new JPListDropdown();
    var _PaginationUI = new PaginationUI();

    _JPListDropdown.binds();
    _PaginationUI.keepItemsCentered();
    _PaginationUI.reorderBottomPagination();
    _PaginationUI.binds();
});