// Used in pages requiring live unit conversion
// 06 Recipe && 08 My Recipe

$(document).ready(function(){
    var _AjaxConversion = new AjaxConversion();
    var _Util = Util;
    var _ConversionModel = ConversionModel.getInstance();
    var _RecipePresenter = RecipePresenter.getInstance();
	var _localSmartConvert  = SmartConvert.getInstance();
    var onEditPage = ( $('.yield-control select').length && $('.serving-control select').length);
    var onGetPage = ( !$('.yield-control select').length || !$('.serving-control select').length);
	var _MetricConversionHelper = new MetricConversionHelper();
    _MetricConversionHelper.init();
    _MetricConversionHelper.renderUnitCodes();
    _MetricConversionHelper.binds();
    setTimeout(function(){
        _MetricConversionHelper.cacheCtrlUnitConversions();
        _MetricConversionHelper.handleMeasurementOptionHiding();
    }, 500)

    var UI = (function(){
        var currentSystem;
        var $yieldCtrl = $('.yield-control');
        var $servingCtrl = $('.serving-control');

        var init = function(){
            currentSystem = $('.system-selection input:checked').val();
        }

        var cacheInitialConversionValues = function(measurementData){
            var measurementData = (typeof measurementData !== 'undefined') 
                ? measurementData
                : _RecipePresenter.scrapeMeasurementControlValues();
            
            // Cache conversion 1 values
            $yieldCtrl.attr('data-amt1', measurementData.yieldAmt);
            $yieldCtrl.attr('data-unitname1', measurementData.yieldUnitName);
            $yieldCtrl.attr('data-unitcode1', measurementData.yieldUnitCode);
            $servingCtrl.attr('data-amt1', measurementData.servingSizeAmt);
            $servingCtrl.attr('data-unitname1', measurementData.servingUnitName);
            $servingCtrl.attr('data-unitcode1', measurementData.servingUnitCode);
        }

        // Call this every time the user changes an field in the metric conversion system so we can restart the system with the new values
        var cacheCtrlUnitConversions  = function(){
            var measurementData = _RecipePresenter.scrapeMeasurementControlValues();
            cacheInitialConversionValues();

            // LOOP through them and make the necessary AJAX calls to get & cache the second conversions
            var conversionData = [
                { $ctrl: $yieldCtrl, amt: measurementData.yieldAmt, unitCode: measurementData.yieldUnitCode, unitName: measurementData.yieldUnitName },
                { $ctrl: $servingCtrl, amt: measurementData.servingSizeAmt, unitCode: measurementData.servingUnitCode, unitName: measurementData.servingUnitName }
            ]

            conversionData.forEach(function(data){
                // AJAX CALL 1
                _AjaxConversion.getUnitConversion({ 
                    params: { 
                        unitName: data.unitName.replace(/\s/g, '')
                    }
                }).then(function(res){
                    var res = JSON.parse(res);
                    if(!res.Data) return;
                    var oppositeUnit = JSON.parse( res.Data );

                    // AJAX CALL 2
                    _AjaxConversion.convertStd({
                        params: {
                            fromId: data.unitName,
                            toId: oppositeUnit,
                            amount: data.amt
                        }
                    }).then(function(res2){
                        console.log(res2);
                        var res = JSON.parse(res2);
                        if(!res.Data) return;
                        var amount = _Util.convertToFourDecimals(res.Data);

                        // AJAX CALL 3
                        _AjaxConversion.getUnitIdFromName({
                            params: {
                                unitName: oppositeUnit
                            }
                        }).then(function(res3){
                            console.log(res3);
                            var res = JSON.parse(res3);
                            if(!res.Data) return;
                            var oppositeUnitId = JSON.parse(res.Data);
                            // console.log('----------------------------------------');
                            // console.log(amount);
                            // console.log(oppositeUnit);
                            // console.log(oppositeUnitId);
                            // console.log('----------------------------------------');
                            
                            // Cache second conversion values for each controller
                            data.$ctrl.attr('data-amt2', amount);
                            data.$ctrl.attr('data-unitname2', oppositeUnit);
                            data.$ctrl.attr('data-unitcode2', oppositeUnitId);
                            data.$ctrl.attr('data-currentconversion', '1');  
                        })
                                                
                    })
                });
            }); 
             
        }

        // UPDATE UI
        var crossConvertCtrlUnits = function(){
            if(onGetPage) {
                var $controllers = [$yieldCtrl];
                // Update serving controller manually
                //var currentConversion = $servingCtrl.attr('data-currentconversion');
				var currentConversion = 2;
				if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
					currentConversion = 1;
				}

                if(currentConversion == 1) {
                    $servingCtrl.find('.amt').text($servingCtrl.attr('data-amt2'));
                    $servingCtrl.find('.serving-unit').text($servingCtrl.attr('data-unitname2'));
                    $servingCtrl.attr('data-currentconversion', '2'); 
                } else if (currentConversion == 2) {
                    $servingCtrl.find('.amt').text($servingCtrl.attr('data-amt1'));
                    $servingCtrl.find('.serving-unit').text($servingCtrl.attr('data-unitname1'));
                    $servingCtrl.attr('data-currentconversion', '1'); 
                }
                console.log(currentConversion);
            } else if (onEditPage) {
                var $controllers = [$yieldCtrl, $servingCtrl];
            }

            $controllers.forEach(function($ctrl) {
                //var currentConversion = $ctrl.attr('data-currentconversion');
				var currentConversion = 2;
				if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
					currentConversion = 1;
				}
                if(currentConversion == 1) {
                    $ctrl.find('.amt-input').val($ctrl.attr('data-amt2'));
                    $ctrl.find('select').attr('data-selected', $ctrl.attr('data-unitcode2'));
                    $ctrl.find('select').val($ctrl.attr('data-unitcode2'));
                    $ctrl.attr('data-currentconversion', '2'); 
                } else if (currentConversion == 2) {
                    $ctrl.find('.amt-input').val($ctrl.attr('data-amt1'));
                    $ctrl.find('select').attr('data-selected', $ctrl.attr('data-unitcode1'));
                    $ctrl.find('select').val($ctrl.attr('data-unitcode1'));
                    $ctrl.attr('data-currentconversion', '1'); 
                }
            });  
        }

        var crossConvertIngredients = function(){
            var $ingredients = $('.ingredients-list.ingredients').find('.item').not('.placeholder').not('.hidden');
            $ingredients.each(function(i, ingredient){

                // Initial conversion then just use data- attributes to toggle values
                if(typeof $(ingredient).attr('data-amt1') === 'undefined') {
                    var ingredientUnitName = $(ingredient).find('.amount .unit').text();
                    var ingredientAmount = $(ingredient).find('.amount .amt').text();
                    $(ingredient).attr('data-amt1', ingredientAmount);
                    $(ingredient).attr('data-unit1', ingredientUnitName);
                    // var isRecipe = $(ingredient).data('category').toLowerCase().includes('recipe'); 
                    // var ingredientUnitId = $(ingredient).data('unit');
                    // var ingredientId = $(ingredient).data('nrecingid');
                    // if(isRecipe) ingredientId = -1;
                    
                    _AjaxConversion.getUnitConversion({ 
                        params: { 
                            unitName: ingredientUnitName.replace(/\s/g, '')
                        }
                    }).then(function(res){
                        // 
                        var res = JSON.parse(res);
                        if(!res.Data) return;
                        var oppositeUnit = JSON.parse( res.Data );

                        /*_AjaxConversion.convertStd({
                            params: {
                                fromId: ingredientUnitName,
                                toId: oppositeUnit,
                                amount: ingredientAmount
                            }
                        }).then(function(res2){
                            var res = JSON.parse(res2);
                            if(!res.Data) return;
                            var amount = _Util.convertToFourDecimals(res.Data);*/
							
							converted = _localSmartConvert.doConvert( ingredientAmount, oppositeUnit );

                            $(ingredient).find('.amount .amt').text(converted.newUnit.quantityFraction);
                            $(ingredient).find('.amount .unit').text(converted.newUnit.unitName);
                            $(ingredient).attr('data-amt2', ingredientAmount);
                            $(ingredient).attr('data-unit2', oppositeUnit);
                            $(ingredient).attr('data-currentconversion', '2');
                            
                        //})
                    });
                } else {
                    //var currentConversion = $(ingredient).attr('data-currentconversion');
					var currentConversion = 2;
					if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
						currentConversion = 1;
					}

                    if(currentConversion == 1) {
						converted = _localSmartConvert.doConvert( $(ingredient).attr('data-amt1'), $(ingredient).attr('data-unit2') );
                        $(ingredient).find('.amount .amt').text( converted.newUnit.quantityFraction );
                        $(ingredient).find('.amount .unit').text( converted.newUnit.unitName );
                        $(ingredient).attr('data-currentconversion', '2');
                    } else if (currentConversion == 2) {
						converted = _localSmartConvert.doConvert( $(ingredient).attr('data-amt2'), $(ingredient).attr('data-unit1') );
                        $(ingredient).find('.amount .amt').text( converted.newUnit.quantityFraction );
                        $(ingredient).find('.amount .unit').text( converted.newUnit.unitName );
                        $(ingredient).attr('data-currentconversion', '1');
                    }
                }
            })
        }


        // convert unit codes numbers to their corresponding text
        var renderUnitCodes = function() {
            // Units codes in spans
            var unitCodesTable = new UnitCodes().getTable();
            var $unitCodeSpans = $('.unit-code');
            console.log($unitCodeSpans);
            $unitCodeSpans.each(function (i, el) {
                var unitCode = $(el).data('unit-code');
                var unit = unitCodesTable[unitCode - 1];
                $(el).text(unit.single);
            })
        }

        // If the user clicks on metric hide american values and vice versa
        var handleMeasurementOptionHiding = function(){
            var selectedSystem = $('.measurement-system-radio:checked').val();
            if(selectedSystem === 'american'){
                var unitsToHide = _ConversionModel.getMetricUnits();
                _MetricConversionHelper.hideUnitsFromSelect(unitsToHide)
            } else if (selectedSystem === 'metric') {
                var unitsToHide = _ConversionModel.getAmericanUnits();
                _MetricConversionHelper.hideUnitsFromSelect(unitsToHide)
            }
        }
        var hideUnitsFromSelect = function(unitsToHide){
            $('.measurement-controls option').show();
            unitsToHide.forEach(function(unit){
                var $matchingOptions = $('.measurement-controls option').filter(function () { 
                    return $(this).text().toLowerCase() == unit.toLowerCase(); 
                });
                $matchingOptions.hide();
            })
        }
        var binds = function(){
            $('.measurement-system-radio').on('click', function () {
                var selectedSystem = $(this).val();
                if(selectedSystem !== currentSystem) {
                    currentSystem = selectedSystem;
                    _MetricConversionHelper.crossConvertCtrlUnits();
                    _MetricConversionHelper.crossConvertIngredients();
                }
                handleMeasurementOptionHiding()
            });
            $('#yield-input').on('change', function () {
                _MetricConversionHelper.cacheCtrlUnitConversions();
            });
            $('#serving-input').on('change', function () {
                _MetricConversionHelper.cacheCtrlUnitConversions();
            });
            $('#yield-unit-select').on('change', function () {
                setTimeout(function() {
                    _MetricConversionHelper.cacheCtrlUnitConversions();
                });
            });
            $('#serving-unit-select').on('change', function () {
                console.log('caching');
                setTimeout(function() {
                    _MetricConversionHelper.cacheCtrlUnitConversions();
                }, 100);
            });
        }
        return {
            init: init,
            cacheCtrlUnitConversions: cacheCtrlUnitConversions,
            handleMeasurementOptionHiding: handleMeasurementOptionHiding,
            hideUnitsFromSelect: hideUnitsFromSelect,
            crossConvertIngredients: crossConvertIngredients,
            crossConvertCtrlUnits: crossConvertCtrlUnits,
            renderUnitCodes: renderUnitCodes,
            binds: binds
        }
    })()

    // INIT
    if ($('.recipe-title').length > 0 && $('.ingredients-list.menu').length === 0) {
        // Render unit conversion table in the backend
        _AjaxConversion.buildIngredientTableFromRecipeId({
            params: {    
                recipeId: $('#nRecipeId').text()
            }
        });
        UI.init();
        UI.renderUnitCodes();
        UI.binds();
        setTimeout(function(){
            UI.cacheCtrlUnitConversions();
            UI.handleMeasurementOptionHiding();
        }, 500)

    }

    // TEST
    // 
    // var unitsToConvert = [
    //     // cross system conversions
    //     { qty: 1, unit: 'oz', to: 'metric' },
    //     { qty: 3, unit: 'tbl', to: 'metric' },
    //     { qty: 3, unit: 'tsp', to: 'metric' },
    //     { qty: 1, unit: 'g', to: 'american' },
    //     { qty: 1, unit: 'mL', to: 'american' },
    //     { qty: 1, unit: 'oz', to: 'metric', toUnit: 'g' },
    //     { qty: 1, unit: 'oz', to: 'metric', toUnit: 'mgg' },
    //     { qty: 1, unit: 'oz', to: 'metric', toUnit: 'mg' },
    //     { qty: 1, unit: 'mg', to: 'metric', toUnit: 'oz' },
    //     { qty: 1, unit: 'mg', to: 'metric', toUnit: 'oz' },
        
    //     // same system conversions
    //     { qty: 1, unit: 'oz', to: 'lb', withSystem: 'american' },
    //     { qty: 1, unit: 'lb', to: 'oz', withSystem: 'american' },
    //     { qty: 3, unit: 'oz', to: 'oz', withSystem: 'american' },
    //     { qty: 4, unit: 'lb', to: 'oz', withSystem: 'american' },
    // ]
    // var result = unitConverter.bulkConversion(unitsToConvert);
    // console.log(result)
});