
var AjaxSiteWideSearch = function(){
    var _AjaxBuilder = new AjaxBuilder();
    
    this.search = _AjaxBuilder.buildFn({
        url: SVC_URL + 'searchFilterSort/siteWideSearch',
        eventSucessKey: 'ajax-menu:search-success',
        eventFailKey: 'ajax-menu:search-error'
    })
}



