// These dependencies will be called on every page
var ViewRecipeCtrl = function(){
    this.initDependencies();
}

ViewRecipeCtrl.prototype.initDependencies = function(){
    new DynamicWidthDropdown();

    _RecipePresenter = RecipePresenter.getInstance();
    _RecipePresenter.init();

    new GoBackBtn();
    new DropdownMenu();
    new MeasurementControls();

    var _MetricConversionHelper = new MetricConversionHelper();
    _MetricConversionHelper.init();
    _MetricConversionHelper.renderUnitCodes();
    _MetricConversionHelper.binds();
    setTimeout(function(){
        _MetricConversionHelper.cacheCtrlUnitConversions();
        _MetricConversionHelper.handleMeasurementOptionHiding();
    }, 500)

}