
$(document).ready(function(){
    var _UnitCodes = new UnitCodes().getTable();
    var _AjaxGroup = new AjaxGroup();
    var _LikeBtn = LikeBtn;
    var _Util = Util;
    var _GenericAlert = new GenericAlert();
    var _EventEmitter = EventEmitter.getInstance();

    GroupPresenter = (function(){
        var instance;

        var _GroupPresenter = function(){
            this.savedGroupId;
        }

        _GroupPresenter.prototype.scrapEditGroupInfo = function(){
            var groupId = $('#ngroupId').text();
            var groupTitle = $('.group-title-edit').val();
           
            var selectedGroupRecipeId = null;
            $('input[name="groupRecipe"]:checked').each(function() {
                selectedGroupRecipeId = $(this).data('recipeid');
            });

            var selectedStudentIds = [];
            $('.student-checkboxes input:checked').each(function() {
                 selectedStudentIds.push($(this).data('studentid'));
            });

            return {     
                nGroupId: groupId,
                nvGroupTitle: groupTitle,
                students: selectedStudentIds,
                nRecipeId: selectedGroupRecipeId,  
            }
        }
    
        _GroupPresenter.prototype.AjaxSaveGroup = function(){
            var _this = this;
            var groupInfo = this.scrapEditGroupInfo()
            var groupJson =  JSON.stringify(groupInfo)

            var groupTitle = groupInfo['nvGroupTitle'];
            var nGroupId = groupInfo['nGroupId'];
            _AjaxGroup.nameExistsOrNot({
                params: {
                    authToken: '12345',
                    groupTitle: groupTitle
                }
            }).then(function(res){
                console.log(res);
                var data = JSON.parse(res).Data;
                if(data.groupId != -1 && nGroupId == "-1") {
                    _GenericAlert.show({
                        title: 'Group Name Check',
                        msg: groupInfo.nvGroupTitle + ' Already Exists.'
                    });
                }
                else{
                    _AjaxGroup.save({
                        params: {
                            authToken: '12345',
                            group: groupJson
                        }
                    }).then(function(res){
                        console.log(res);
                        var data = JSON.parse(res).Data;
                        _this.savedGroupId = data;
                        console.log(data);
                        if(data) {
                            _GenericAlert.show({
                                title: 'Group Saved',
                                msg: groupInfo.nvGroupTitle + ' has been saved successfully.'
                            });
                        }
                    });
                }
            });
            
        }
        
        _GroupPresenter.prototype.AjaxDeleteGroup = function(){
            var groupId = $('#hiddenGroupId').text();
            
            _AjaxGroup.delete({
                params: {
                    authToken: '12345',
                    groupId: groupId
                }
            }).then(function(res){ 
                var noError =  _Util.isJSON(res);                
                
                if(noError) {
                    _GenericAlert.show({
                        title: 'Group Deleted',
                        msg: 'Your Group has been deleted.'
                    })
                    setTimeout(function(){
                        window.location.href = SITE_URL + 'group/mygroups'; 
                    },1000)
                }          
            })
        }

        _GroupPresenter.prototype.AjaxResetGroupRecipe = function(){
            var groupRecipeId = $('#hiddenGroupRecipeId').text();
            var groupId = $('#hiddenGroupId').text();

            _AjaxGroup.resetGroupRecipe({
                params: {
                    authToken: '12345',
                    groupRecipeId: groupRecipeId
                }
            }).then(function(res){ 
                var noError =  _Util.isJSON(res);                
                this.savedGroupId = groupId;
                if(noError) {
                    _GenericAlert.show({
                        title: 'Recipe Reset is Done',
                        msg: 'Group Recipe Data Reset successful.'
                    });
                    setTimeout(function(){
                        window.location.reload(); // Refresh the page
                    }, 1000);
                }          
            })
        }

        _GroupPresenter.prototype.redirectToGroup = function(){
            if(typeof this.savedGroupId !== 'undefined') {
                window.location.href = SITE_URL + 'group/getMyGroup/' + this.savedGroupId; 
            }
        }

        return {
            getInstance: function(){
                if(!instance) instance = new _GroupPresenter();
                return instance;
            }
        }
    })();
    
    // INIT
    
    if(document.location.href.includes('/group/')) {
        
        var _GroupPresenter = GroupPresenter.getInstance();
       // var _EditGroupValidation = new EditGroupValidation();
       // var _UIBehaviors = new UIBehaviors();

        _EventEmitter.subscribe('ajax-group:checkIfExists-success', _GroupPresenter.processGroupExistsRes);
        $('#alert-generic').on('click', function(){ _GroupPresenter.redirectToGroup(); })

        $('.save-group-btn').on('click', function(){
            var errorsOnPage = false;
            $('.container .error').not('.main-error').each(function(i, el) {
                if( $(el).text() !== '') 
                    errorsOnPage = true;
            })
            if(errorsOnPage)
                $('.product-controls .error').text('Please correct the errors below before saving.')
            else {
                $('.product-controls .error').text('');
                _GroupPresenter.AjaxSaveGroup()
            }
        });

      
        $('.delete-group-btn').on('click', function(){
            _GroupPresenter.AjaxDeleteGroup();
        });

        // $('.reset-group-btn').on('click', function(){
        //     _GroupPresenter.AjaxDeleteGroup();
        // });

        $('#unlockButton').on('click', function(){
            var groupRecipeId = $('#ngroupRecipeId').text();
           _AjaxGroup.unlock({
                params: {
                    authToken: '12345',
                    groupRecipeId: groupRecipeId
                }
            });
            var lockedMessage = document.getElementById("lockedMessage");
            if (lockedMessage) {
                lockedMessage.parentNode.removeChild(lockedMessage); 
            }
            
            var unlockButton = document.getElementById("unlockButton");
            if (unlockButton) {
                unlockButton.parentNode.removeChild(unlockButton);
            }
        });
        $('.group-recipe-reset-btn').on('click', function(){
            _GroupPresenter.AjaxResetGroupRecipe();
        });

        //Search
        $('.sub-navbar .search-btn').on('click', function() {          
            var searchStr =  $('#pagination-search').val();
            window.location.href = SITE_URL + 'group/myGroups/?query=' + searchStr;
        });

        //search-clear
        $('.inline-block .close-icon').on('click',function(){
            window.location.href = SITE_URL + 'group/myGroups/' ;
        });
    }

});

