var Tokenizer = (function(){
    var instance;
    
    var token;
    var _Tokenizer = function(){
        token = '12345';
    }

    _Tokenizer.prototype.getToken = function(){
        if(this.tokenExpired()) this.refreshToken();
        return token;
    }
    _Tokenizer.prototype.refreshToken = function(){
        // bla bla refresh token
    }

    _Tokenizer.prototype.tokenExpired = function(){
        return false;
    }

    return {
        getInstance: function(){
            if(!instance)
                instance = new _Tokenizer();

            return instance;
        }
    }
})()
