$(document).ready(function(){
    var _Validation = Validation.getInstance();

    EditGroupValidation = function(){
        this.binds();
    }

    EditGroupValidation.prototype.name = function () {
        var errFound = false;
        var errMsg = '';
        var $this = $('.group-title-edit');
        var groupName = $this.val().trim();

        if($this.val() === '') {
            errFound = true;
            errMsg = 'Please enter a group name.';
        } else if (groupName === "" || groupName.startsWith(' ')) {
            errFound = true;
            errMsg = 'Group name cannot start with white space.';
        }

        _Validation.handleError(errFound, errMsg, $this);
    }

    EditGroupValidation.prototype.groupRecipe = function () {
        var errFound = false;
        var errMsg = '';
        var $checkedGroupRecipes = $('input[name="groupRecipe"]:checked');
        
        if ($checkedGroupRecipes.length === 0) {
            errFound = true;
            errMsg = 'Please select a group recipe.';
        }

        _Validation.handleError(errFound, errMsg, $('.cooking-radios'));
    }

    EditGroupValidation.prototype.selectedStudents = function () {
        var errFound = false;
        var errMsg = '';
        var $checkedStudents = $('.student-checkboxes input:checked');
        
        if ($checkedStudents.length === 0) {
            errFound = true;
            errMsg = 'Please select at least one student.';
        }

        _Validation.handleError(errFound, errMsg, $('.student-checkboxes'));
    }

    EditGroupValidation.prototype.all = function () {
        this.name();
        this.groupRecipe();
        this.selectedStudents();
    }

    EditGroupValidation.prototype.binds = function(){
        var _this = this;
    
        $('.group-title-edit').on('blur', function(){ _this.name() });
        $('input[name="groupRecipe"]').on('change', function() { _this.groupRecipe() });
        $('.student-checkboxes input[type="checkbox"]').on('change', function() { _this.selectedStudents() });
        $('.save-group-btn').on('mousedown keydown', function(){ _this.all() });
    }

    // INIT
    new EditGroupValidation();
});