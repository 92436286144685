/*var RecipePresenter;

$(document).ready(function(){
    var _UnitCodes = new UnitCodes().getTable();
    var _AjaxRecipe = new AjaxRecipe();//Ajax.Recipe.getInstance();
    var _LikeBtn = LikeBtn;
    var _EditRecipeValidation = new EditRecipeValidation()
    var _Util = Util;
    var _GenericAlert = new GenericAlert();
    var _EventEmitter = EventEmitter.getInstance();
    var _Tokenizer = Tokenizer.getInstance();
    var _SaveAsModal = SaveAsModal;
    var _UIBehaviors = new UIBehaviors()

    var onEditPage = ( $('.yield-control select').length && $('.serving-control select').length);
    var onGetPage = ( !$('.yield-control select').length || !$('.serving-control select').length);
    var existingRecipeId = -1;
    var recipeExists = false

    RecipePresenter = (function(){
        var instance;

        var _RecipePresenter = function(){
            this.savedRecipeId;
        }

        _RecipePresenter.prototype.init = function(){
            var _this = this;
            this.yieldSelectInit();
            this.yieldSelectSetDefault();
            this.servingSelectInit();
            this.servingSelectSetDefault();
            this.initLikeButtons();
            setTimeout(function() {
                _this.initValidationWatchers();
            }, 500);
        }
        // Setup all the yield selection options by looping through the unit codes table
        _RecipePresenter.prototype.yieldSelectInit = function(){
            $unitSelect = $('.generate-units');
            for (var i = 0; i < _UnitCodes.length; i++) {
                var unitCode = _UnitCodes[i];
                var dbIndex = parseInt(i)+1;
                var optionHTML = '<option value="'+dbIndex+'">'+unitCode.single+'</option>';
                $unitSelect.append($(optionHTML));
            }
        }
        // Set the selected yield 
        _RecipePresenter.prototype.yieldSelectSetDefault = function() { 
         
            $unitSelect = $('#yield-unit-select');
            var selectedUnit = $unitSelect.data('selected');
            if(selectedUnit) {
                $unitSelect.find('option[value="' + selectedUnit + '"]').prop('selected', true);  
                console.log('yield change'); 
                $unitSelect.trigger('change')     
            }
        }

        _RecipePresenter.prototype.servingSelectInit = function(){
            $unitSelect = $('#serving-unit-select');
            for (var i = 0; i < _UnitCodes.length; i++) {
                var unitCode = _UnitCodes[i];
                var dbIndex = parseInt(i)+1;
                var optionHTML = '<option value="'+dbIndex+'">'+unitCode.single+'</option>';
                $unitSelect.append($(optionHTML));
            }
        }
        _RecipePresenter.prototype.servingSelectSetDefault = function() {  
            $unitSelect = $('#serving-unit-select');
            var selectedUnit = $unitSelect.data('selected');
            if(selectedUnit) {
                $unitSelect.find('option[value="' + selectedUnit + '"]').prop('selected', true);   
                $unitSelect.trigger('change')
            }     
        }

        _RecipePresenter.prototype.processRecipeCardLike = function($likeBtn){
            var $recipeCard = $likeBtn.closest('.card')
            var recipeId = $recipeCard.find('.id').text();
            var likeStatus = $recipeCard.find('.liked').text();
            if(likeStatus != 1 && likeStatus != 0) return;
            if(recipeId == '') return;
            this.AjaxLikeRecipe(recipeId, likeStatus);
        }


        _RecipePresenter.prototype.AjaxLikeRecipe = function(recipeId, likeStatus){
            var recipeId = (typeof recipeId !== 'undefined') ? recipeId : $('#nRecipeId').text();
            var likeStatus = (typeof likeStatus !== 'undefined') ? likeStatus : 1;
            if(likeStatus == null || likeStatus === '') likeStatus = 1;

            // LIKE STATUS IS WHAT YOU WANT TO SET IT TO
            _AjaxRecipe.like({
                params: {
                    authToken: _Tokenizer.getToken(),
                    likeRecipe: JSON.stringify({
                        nRecipeId: recipeId,
                        nLikeStatus: likeStatus
                    })
                }
            }).then(function(res){
                _EventEmitter.emit('filters-presenter:refresh-counts');

            })
        }

        _RecipePresenter.prototype.scrapeMeasurementControlValues = function(){
            var $yieldCtrl = $('.yield-control');
            var $servingCtrl = $('.serving-control');

            // GET AMOUNTS
            var yieldAmt = $('.yield-control .yield-amt').val();


            var servingSizeAmt;
            var numOfServings;
            if (onGetPage) {
                servingSizeAmt = $('.serving-control .amt').text();
                numOfServings = _Util.fractionStrToDecimal($('.number-of-servings-edit').val())                
            } else if(onEditPage){
                servingSizeAmt = $('.serving-control .serving-amt').val();
                numOfServings = $('.servings .num-of-servings').text();
            }

            // Get UNIT DATA
            var yieldUnitCode = $yieldCtrl.find('select').attr('data-selected');
            var yieldUnitName = $yieldCtrl.find('select option[value="'+yieldUnitCode+'"]:first').text();
            var servingUnitCode;
            var servingUnitName;

            if(onGetPage) {
                servingUnitCode = $servingCtrl.find('.serving-unit').attr('data-unit-code');
                servingUnitName = $servingCtrl.find('.serving-unit').text();
            } else if (onEditPage) {
                servingUnitCode = $servingCtrl.find('#serving-unit-select').attr('data-selected');
                servingUnitName = $servingCtrl.find('#serving-unit-select option[value="'+servingUnitCode+'"]').text();
            }

            // console.log({
            //     yieldAmt: yieldAmt,
            //     yieldUnitCode: yieldUnitCode,
            //     yieldUnitName: yieldUnitName,
            //     servingSizeAmt: servingSizeAmt,
            //     servingUnitCode: servingUnitCode,
            //     servingUnitName: servingUnitName,
            //     numOfServings: numOfServings,
            // });
            return {
                yieldAmt: yieldAmt,
                yieldUnitCode: yieldUnitCode,
                yieldUnitName: yieldUnitName,
                servingSizeAmt: servingSizeAmt,
                servingUnitCode: servingUnitCode,
                servingUnitName: servingUnitName,
                numOfServings: numOfServings,
            }
        }

        _RecipePresenter.prototype.scrapeGetRecipeInfo = function(){
            var measurementData = this.scrapeMeasurementControlValues();
            var dtotalYieldAmount = parseFloat( measurementData.yieldAmt );
            var nTotalYieldUnitId = parseInt( measurementData.yieldUnitCode );
            var dServicesizeamount = 1;
            var nServingsizeUnitId = parseInt( measurementData.servingUnitCode );
            var dNumberOfServings = parseInt( measurementData.numOfServings );

            var nRecipeTypeId = 1; // 2 user created - 3 user edited - 4 migrated from old site
            var nRecipePrivacyTypeId = 1; // 1 private - 2 shared
            var nReferenceRecipeId = 0; // we dont know what this does
            var nRecipeId = $('#nRecipeId').text();
            var recipeImg = _Util.fileName( $('.product-img img').attr('src') );
            var vcRecipeName = $('#vcName').text();
            var txtDirections = $('.recipe-directions').html();
            var nCourseId = 1;
            var nCuisineId = $('#nCuisineId').text();
            var nCookingMethodId = $('#nCookingMethodId').text();
            var nTextBookId = 1;
            var nViewingbyId = $('#nViewingbyId').text();
            var nIsScalable = 1;
            var nChecksum = $('#nChecksum').text();
            var nCalBakerPe = 1;
            var dBakersPercentage = $('#bBakersPercent').text();
            var ingredientsList = [];
            var $ingredients = $('.ingredients-list .item').not('.placeholder').not('.hidden')

            $ingredients.each(function(i, el){
                var $ingredient = $(el);
                var isSublist = $ingredient.hasClass('sub-list');
                var ingredientSublisted = $ingredient.closest('.sub-list').not('.placeholder').length > 0;
                var simpleGroupName = '';
                var simpleGroupParentName = '';
                
                if(isSublist) 
                    simpleGroupName = $ingredient.attr('data-simplegroupname');
                if(ingredientSublisted)
                    simpleGroupParentName = $ingredient.closest('.sub-list').attr('data-simplegroupname');

                var ingredientObj = {
                    nRecIngId: $ingredient.attr('data-nRecIngId'),
                    vcName: $ingredient.attr('data-name'),
                    vcIngredientAmount: $ingredient.attr('data-amt'),
                    nIngredientUnitId: $ingredient.attr('data-unit'),
                    txtPrep: $ingredient.attr('data-txtPrep'),
                    bIsSubRecipe: $ingredient.attr('data-bIsSubRecipe'),
                    nIngredientGroupId: $ingredient.attr('data-nIngredientGroupId'),
                    vcGroupName: $ingredient.attr('data-category'),
                    nOrder: $ingredient.attr('data-nOrder'),
                    nUserId: $ingredient.attr('data-nUserId'),
                    dBakersPercentage: 1,
                    simpleGroupName: simpleGroupName,
                    simpleGroupParentName: simpleGroupParentName,
                }
                ingredientsList.push(ingredientObj);
            })

            return {     
                nRecipeTypeId: nRecipeTypeId,
                nRecipePrivacyTypeId: nRecipePrivacyTypeId,
                nReferenceRecipeId: nReferenceRecipeId,
                nRecipeId: nRecipeId,
                recipeImg: recipeImg,
                vcRecipeName: vcRecipeName,
                txtDirections: txtDirections,
                dtotalYieldAmount: dtotalYieldAmount,
                nTotalYieldUnitId: nTotalYieldUnitId,
                dServicesizeamount: dServicesizeamount,
                nbServingsizeUnitId: nServingsizeUnitId,
                dNumberOfServings: dNumberOfServings,
                nViewingbyId: nViewingbyId,
                nIsScalable: nIsScalable,
                nChecksum: nChecksum,
                nCalBakerPe: nCalBakerPe,
                dBakersPercentage: dBakersPercentage,
                nCourseId: nCourseId,
                nCuisineId: nCuisineId,
                nCookingMethodId: nCookingMethodId,
                nTextBookId: nTextBookId,
                oIngredientsList: ingredientsList
            }
        }
        _RecipePresenter.prototype.scrapEditRecipeInfo = function(){
            var nRecipeTypeId = 1; // 2 user created - 3 user edited - 4 migrated from old site
            var nRecipePrivacyTypeId = 1; // 1 private - 2 shared
            var nReferenceRecipeId = 0; // we dont know what this does
    
            var nRecipeId = $('#nRecipeId').text();
            var vcRecipeName = $('.recipe-name').val();

            var dNumberOfServings = $('.num-of-servings').text();
            var nCuisineId = $('.cuisine-checkboxes input:checked').data('courseid');
            var nCookingMethodId = $('.cooking-checkboxes input:checked').data('courseid');
            var nTextBookId = $('#nTextBookId').text();
            if(nTextBookId == '' || typeof nTextBookId == 'undefined') nTextBookId = '0';
            var nViewingbyId = $('#nViewingbyId').text();
            if(nViewingbyId == '' || typeof nViewingbyId == 'undefined') nViewingbyId = '0';

            var nIsScalable = $('#nIsScalable').text();
            var nChecksum = $('#nChecksum').text();
            if(nChecksum == '' || typeof nChecksum == 'undefined') nChecksum = '0';

            var nCalBakerPe = 1;
            var dBakersPercentage = $('#bBakersPercent').text();

            // Get yield and serving units

            var yieldUnitCode = $('.yield-control').find('select').attr('data-selected');
            var servingUnitCode = $('.serving-control').find('select').attr('data-selected');
            var dtotalYieldAmount = $('#yield-input').val();
            var nTotalYieldUnitId = yieldUnitCode;
            var dServicesizeamount = $('#serving-input').val();
            var nServingsizeUnitId = servingUnitCode;

            // Get text directins
            var iframe = document.getElementById('directions_ifr');
			if ( iframe == null ){
				iframe = document.getElementById('tiny-mce-dir');
				var txtDirections = $(iframe).html();
			} else {
            	var $innerDoc = $(iframe.contentDocument) || $(iframe.contentWindow.document);
            	var $directionsTextarea = $innerDoc.find('#tinymce');
            	var txtDirections = $directionsTextarea.html();
			}

            // Get Filters
            var courseSelectClasses = ['.course', '.cooking', '.cuisine'];
            var filterIds = [ [], [] , [] ];
            courseSelectClasses.forEach(function(className, i) {
                var $selectedCourses = $(className + '-checkboxes input:checked');
                $selectedCourses.each(function(j, el){
                    var courseId = $(el).attr('data-courseid');
                    filterIds[i].push(courseId);                
                });

            })
            var nCourseId = filterIds[0];
            var nCookingMethodId = filterIds[1];
            var nCuisineId = filterIds[2];



            // Get the ingredients
            var ingredientList = [];
            var $ingredients = $('.ingredients-list .item').not('.placeholder').not('.hidden')
            $ingredients.each(function(i, el){
                var $ingredient = $(el);

                var isSublist = $ingredient.hasClass('sub-list');
                var ingredientSublisted = $ingredient.parents('.sub-list').not('.placeholder').length > 0;
                var simpleGroupName = '';
                var simpleGroupParentName = '';

                if(isSublist) 
                    simpleGroupName = $ingredient.attr('data-simplegroupname');
                if(ingredientSublisted)
                    simpleGroupParentName = $ingredient.closest('.sub-list').attr('data-simplegroupname');

                console.log({
                    
                    vcName: $ingredient.attr('data-name'),

                    ingredientSublisted: ingredientSublisted,
                    simpleGroupName: simpleGroupName,
                    simpleGroupParentName: simpleGroupParentName,
                });
                var ingredientObj = {
                    nRecipeId: $ingredient.attr('data-nrecipeid'),
                    nUserId: $ingredient.attr('data-nuserid'),
                    nRecIngId: $ingredient.attr('data-nrecingid'),
                    vcName: $ingredient.attr('data-name'),
                    vcIngredientAmount: $ingredient.attr('data-amt'),
                    nIngredientUnitId: $ingredient.attr('data-unit'),
                    txtPrep: $ingredient.attr('data-txtprep'),
                    nOrder: $ingredient.attr('data-norder'),
                    dBakersPercentage: 1,
                    nIngredientGroupId: $ingredient.attr('data-ncategoryid'),
                    vcGroupName: $ingredient.attr('data-category'),
                    bIsSubRecipe: $ingredient.attr('data-bissubrecipe'),
                    simpleGroupName: simpleGroupName,
                    simpleGroupParentName: simpleGroupParentName,
                }
                ingredientList.push(ingredientObj);
            })

            return {     
                nRecipeId: nRecipeId,
                vcRecipeName: vcRecipeName,
                txtDirections: txtDirections,
                dtotalYieldAmount: dtotalYieldAmount,
                nTotalYieldUnitId: nTotalYieldUnitId,
                dServicesizeamount: dServicesizeamount,
                nbServingsizeUnitId: nServingsizeUnitId,
                dNumberOfServings: dNumberOfServings,
                nViewingbyId: nViewingbyId,
                nIsScalable: nIsScalable,
                nChecksum: nChecksum,
                nCalBakerPe: nCalBakerPe,
                nCourseId: nCourseId,
                nCuisineId: nCuisineId,
                nCookingMethodId: nCookingMethodId,
                nTextBookId: nTextBookId,
                nRecipeTypeId: nRecipeTypeId,
                nRecipePrivacyTypeId: nRecipePrivacyTypeId,
                nReferenceRecipeId: nReferenceRecipeId,
                // nRecIngId: nRecIngId,
                // dBakersPercentage: dBakersPercentage,
                
                oIngredientsList: ingredientList
            }
        }
    
        _RecipePresenter.prototype.AjaxSaveRecipe = function(){
            var recipeInfo = this.scrapEditRecipeInfo();
            var recipeJson =  JSON.stringify(recipeInfo);
            var _this = this;
            console.log(recipeInfo);
            _AjaxRecipe.save({
                params: {
                    authToken: '12345',
                    recipe: recipeJson
                }
            }).then(function(res){
                // console.log(res);
                
                var data = JSON.parse(res).Data;
                if(data) {
                    _this.savedRecipeId = JSON.parse(data.recipe).recipeId;
                    if(typeof _this.savedRecipeId == 'undefined') _this.savedRecipeId = JSON.parse(data.recipe); 


                    var recipeName = _Util.truncateStr(recipeInfo.vcRecipeName, 45);
                    _GenericAlert.show({
                        title: 'Recipe Saved',
                        msg: recipeName + ' has been saved successfully.'
                    });
                }
            })
        }
        
        _RecipePresenter.prototype.AjaxDeleteRecipe = function(){
            var recipeId = $('#nRecipeId').text();
            
            _AjaxRecipe.delete({
                params: {
                    authToken: '12345',
                    recipeId: recipeId
                }
            }).then(function(res){  
                var noError =  _Util.isJSON(res);
                
                
                if(noError) {
                    _GenericAlert.show({
                        title: 'Recipe Deleted',
                        msg: 'Your recipe has been deleted.'
                    })
                    setTimeout(function(){
                        window.location.href = SITE_URL + 'recipe/myRecipes'; 
                    },1000)
                }       
            })
        }

        _RecipePresenter.prototype.checkIfExists = function(){
            var recipeName = $('.recipe-name').val();
            if(!recipeName) recipeName = $('.recipe-title').val();
            
            _AjaxRecipe.checkIfExists({
                params: {
                    recipeName: recipeName
                }
            });
        }
        // _RecipePresenter.prototype.processRecipeExistsRes = function(res){
        //     var res = res;
        //     if(res === 'NOT FOUND') this.AjaxSaveRecipe();
        //     else this.processRecipeExistsRes();
        // }


        _RecipePresenter.prototype.updateNOrderOfItems = function() {
            $ingredients = $('.ingredients-list .item').not('.placeholder').not('.hidden');
            $ingredients.each(function(i, el) {
                $(el).attr('data-norder', i);
            });
        } 

        // Add like button functionality to existing like buttons
        _RecipePresenter.prototype.initLikeButtons = function(){
            $likeBtns = $('.like-icon-btn');
            $likeBtns.each(function(i, el){
                var likeBtn = new _LikeBtn( $(el) );
            });
        }

        _RecipePresenter.prototype.initValidationWatchers = function(){
            // ADD/EDIT INGREDIENT 
            var _EditIngredientValidation = new EditIngredientValidation()
            $('.edit-ingredient-name').on('blur', _EditIngredientValidation.name);
            $('.edit-ingredient-amount').on('blur', _EditIngredientValidation.qty);
            $('.edit-ingredient-unit').on('blur', _EditIngredientValidation.unit);
            $ingredientModalSubmitBtn = $('.ingredient-modal .btn-lg-primary');
            $ingredientModalSubmitBtn.on('mousedown', function(){
                $('.edit-ingredient-name').trigger('blur');
                $('.edit-ingredient-amount').trigger('blur');
                $('.edit-ingredient-unit').trigger('blur');
                _EditIngredientValidation.ingredientExists();
            })

            // EDIT SUBLIST
            var _EditSublistValidation = new EditSublistValidation();
            $('.sublist-edit').on('blur', _EditSublistValidation.name);
            $sublistModalSubmitBtn = $('.sublist-modal .btn-lg-primary');
            $sublistModalSubmitBtn.on('mousedown', function(){
                $('.sublist-edit').trigger('blur')
            })

            // WHOLE RECIPE 


        }

        _RecipePresenter.prototype.ErrorScanBeforeSave = function(){
            _EditRecipeValidation.all();
            var errorsOnPage = false;
            $('.container .error').not('.main-error').each(function(i, el) {
                if( $(el).text() !== '') 
                    errorsOnPage = true;
            })
            if(errorsOnPage)
                $('.product-controls .error').text('Please correct the errors below before saving.')
            else {
                $('.product-controls .error').text('');
                this.AjaxSaveRecipe()
            }
        }

        _RecipePresenter.prototype.redirectToNewRecipe = function(){
            if(typeof this.savedRecipeId !== 'undefined') {
                window.location.href = SITE_URL + 'recipe/getMyRecipe/' + this.savedRecipeId; 
            }
        }

        _RecipePresenter.prototype.adjustCustomDropdowns = function($dropdown){
            $dropdown.width($dropdown.width() + 30);
        }

        _RecipePresenter.prototype.AjaxSaveAs = function(){
            var _this = this;
            // get all recipe data from UI
            var recipeInfo = this.scrapeGetRecipeInfo();
    
            // Update title to user specified
            $form = _SaveAsModal.$.find('.modal-content');
            var name = $form.find('#recipe-name-textarea').val();
            recipeInfo.vcRecipeName = name;
            console.log(recipeInfo);
            
            // format data & send request
            var recipeJson = JSON.stringify(recipeInfo);
            _AjaxRecipe.saveAs({
                authToken: '12345',
                params: {
                    recipe: recipeJson,
                    currentId: recipeInfo.nRecipeId,
                    myRecipeId: existingRecipeId,
                    exists: recipeExists,
                }
            }).then(function(res) {
                // console.log(res);
                
                var myRecipeId = JSON.parse(res).Data.nRecipeId;
                if(myRecipeId){
                    _this.savedRecipeId = myRecipeId;
                    // console.log(_RecipePresenter);
                    var data = JSON.parse(res).Data;
                    var recipeName = _Util.truncateStr(recipeInfo.vcRecipeName, 45);
                    
                    if(data) {
                        _GenericAlert.show({
                            title: 'Recipe Saved',
                            msg: '"'+recipeName + '" has been saved successfully to <strong>My Recipes</strong>.'
                        });
                    }
                }
            });
        }

        _RecipePresenter.prototype.processRecipeExistsRes = function(res){
            var recipeId = JSON.parse(res).Data.RecipeId;
            var isNewRecipe = recipeId === -1;

            if(isNewRecipe) {
                existingRecipeId = -1;
                recipeExists = false;
                this.AjaxSaveAs();
            } else {
                recipeExists = true
                existingRecipeId = recipeId
                this.overwriteRecipeConfirmation();
            } 
        }

        _RecipePresenter.prototype.overwriteRecipeConfirmation = function(){
            $('#alert-recipe-exists .recipe-name').text($('#recipe-name-textarea').val());
            $('#alert-recipe-exists').modal('show');
        }

        _RecipePresenter.prototype.checkIfMyRecipeExists = function(){
            var recipeName = $('#recipe-name-textarea').val(); 
            _AjaxRecipe.checkIfMyRecipeExists({
                params: {
                    recipeName: recipeName
                }
            });
        }

        _RecipePresenter.prototype.binds = function(){
            var _this = this;
            $('.save-recipe-btn').on('click', function(){ _this.ErrorScanBeforeSave() });
            $('#alert-generic').on('click', function(){ _this.redirectToNewRecipe(); })
            $('.like-btn').on('click', function(){ _this.AjaxLikeRecipe() });
            $('.delete-recipe-btn').on('click', function(){ _this.AjaxDeleteRecipe() });
            $(document).on('mouseup touchend', '.item', function(){ _this.updateNOrderOfItems() });
            $(document).on('mouseup touchend', '.confirm-btn', function(){ _this.updateNOrderOfItems() });
            $('input.select-one').on('click', function(){
                _UIBehaviors.allowOnlyOneChecked.call(this, '.select-one-container', '.select-one'); // specify container and select one input class
            });
            _EventEmitter.subscribe('ajax-recipe:checkIfMyRecipeExists-success', function(res){
                _this.processRecipeExistsRes(res);
            });
            // Save As
            $('.save-as-confirm-btn').on('click', _this.checkIfMyRecipeExists);
            $('#alert-recipe-exists .replace-recipe-btn').on('click', function(){
                _this.AjaxSaveAs();
            });
        }

        _RecipePresenter.prototype.subscribeToEvents = function(){
            var _this = this;
            _EventEmitter.subscribe('dynamic-width-dropdown-resized',function($dropdown){
                _this.adjustCustomDropdowns($dropdown);
            })
        }

        return {
            getInstance: function(){
                if(!instance) instance = new _RecipePresenter();
                return instance;
            }
        }
    })();

    
    // INIT
    var _RecipePresenter = RecipePresenter.getInstance();

    // handle clicks to STAR LIKE btn
    _EventEmitter.subscribe('recipe-card-like-btn:clicked',function($likeBtn){
        _RecipePresenter.processRecipeCardLike($likeBtn);
    });

    if(document.location.href.toLowerCase().includes('myrecipe') || document.location.href.toLowerCase().includes('getrecipe') 
        || document.location.href.toLowerCase().includes('editrecipe') || document.location.href.toLowerCase().includes('kitchen/recipe')) {
        _RecipePresenter.subscribeToEvents();
        _RecipePresenter.init();
        _RecipePresenter.binds();
    }
});*/


