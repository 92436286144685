$(document).ready(function () {

    var _AjaxRecipe = new AjaxRecipe()
    var _AjaxMenu = new AjaxMenu()
    var _Util = Util;
    var _RecipePresenter = RecipePresenter.getInstance();
    var _GenericAlert = new GenericAlert();

    AddToMenuModal = {};
    AddToMenuModal.$ = $('#modal-recipe-add-to-my-menus');
    AddToMenuModal.$addInputs = AddToMenuModal.$.find('.radio_group1')
    AddToMenuModal.addedMenuInfo = {};


    AddToMenuModal.refreshInstance = function () {
        AddToMenuModal.$ = $('#modal-recipe-add-to-my-menus');
    }

    AddToMenuModal.populateUserMenus = function(menus){
        menus.forEach(function(menu) {
            var id = menu.nMenuId ;
            var title = menu.nvMenuTitle; 
            if(title) {
                var optionHTML = '<option data-menuid="'+id+'" value="'+title+'">'+title+'</option>';
                $('.generate-menus').append($(optionHTML));
            }
        })
    }

    AddToMenuModal.AjaxGetUserMenus = function(){
        _AjaxMenu.getUserMenus({
            params: {}
        }).then(function(res){
            if(_Util.isJSON(res)) {
                
                
                var menus = JSON.parse(res).Data;
                if(menus)
                    AddToMenuModal.populateUserMenus(menus)
            }
        })
    }

    AddToMenuModal.keepOneSelectionChecked = function ($checkbox) {
        var $input = $checkbox.find('input');
        var $form = $input.closest('form');
                 console.log($form);
        var num = $input.data('selectnum');

        var pairNum = (num === 1) ? 2 : 1;

        // uncheck hidden checkbox, and update custom checkbox image class
        $form.find('[data-selectnum="' + pairNum + '"]').prop('checked', false);
        $form.find('[data-selectnum="' + pairNum + '"]').siblings('label').removeClass('selected');
    }

    AddToMenuModal.getSelectedMenuData = function(){
        var menuName;
        var menuId;
        var isNewMenu;

        var checkedId = AddToMenuModal.$.find('input:checked').attr('id');
        if(typeof checkedId === 'undefined') return;
        
        if(checkedId.includes('existing')) {
            isNewMenu = 0;
            menuName = AddToMenuModal.$.find("#add-to-existing-dropdown").find(':selected').text();
            menuId = AddToMenuModal.$.find("#add-to-existing-dropdown").find(':selected').attr('data-menuid');
        } else if (checkedId.includes('new')) {
            isNewMenu = 1;
            menuName = AddToMenuModal.$.find("#new-menu-input").val();
            menuId = -1;
        }
        return {
            menuName: menuName,
            menuId: menuId,
            isNewMenu: isNewMenu,
        }
    }

    AddToMenuModal.AjaxAddToMenu = function(){
        // get all recipe data from UI
        var recipeInfo = _RecipePresenter.scrapeGetRecipeInfo();

        // Add Menu name field to recipe request
        if(AddToMenuModal.menuInfo == {})
        AddToMenuModal.menuInfo = AddToMenuModal.getSelectedMenuData();
        recipeInfo.isNewMenu = AddToMenuModal.menuInfo.isNewMenu;
        recipeInfo.menuName = AddToMenuModal.menuInfo.menuName;
        recipeInfo.menuId = AddToMenuModal.menuInfo.menuId;

        console.log(recipeInfo);
        console.log('add to menu');
        // Format data & send request
        var recipeJson = JSON.stringify(recipeInfo);
        _AjaxRecipe.addToMenu({
            authToken: '12345',
            params: {
                contentType: 'application/json',
                recipe: recipeJson
            }
        }).then(function(res){
            
            var menuId = JSON.parse(res).Data;
            console.log(menuId);
            var data = JSON.parse(res).Data;
            if(data) {
                AddToMenuModal.menuInfo = {};
                _GenericAlert.show({
                    title: 'Recipe Added',
                    msg: '"'+recipeInfo.vcRecipeName + '" has been successfully added to menu "<strong>'+recipeInfo.menuName+'</strong>".'
                });
            }
            // window.location.href = SITE_URL + 'menu/getMenu/' + menuId; 
        })
    }

    AddToMenuModal.errorsInModal = function(){
        var errInModal = false;
        AddToMenuModal.$.find('.error').each(function(i, err) {
            if($(err).text() !== '') errInModal = true; 
        })
        return errInModal;
    }

    AddToMenuModal.recipeExistsInMenu = function(){
        
        if(AddToMenuModal.errorsInModal()) return;
        
        AddToMenuModal.menuInfo = AddToMenuModal.getSelectedMenuData();
        console.log(AddToMenuModal.menuInfo);
        if(AddToMenuModal.menuInfo.isNewMenu) {
            AddToMenuModal.AjaxAddToMenu();
        } else {
            var recipeId = $('#nRecipeId').text();
            var menuId = AddToMenuModal.menuInfo.menuId;

            _AjaxRecipe.recipeExistsInMenu({
                params: {
                    nRecipeId: recipeId,
                    nMenuId: menuId
                }
            }).then(function(res){
                
                var exists = JSON.parse(res).Data.exists;
                if(exists) {
                    $('#alert-menu-exists').find('.menu-name').text(AddToMenuModal.menuInfo.menuName);
                    $('#alert-menu-exists').modal('show');

                } else {

                    AddToMenuModal.AjaxAddToMenu();
                }
            });;
        }
    }

    AddToMenuModal.binds = function(){
        AddToMenuModal.$.find('.btn-lg-primary').on('click', AddToMenuModal.recipeExistsInMenu);

        $('#alert-menu-exists .replace-menu-btn').on('click', function(){
            AddToMenuModal.AjaxAddToMenu();
        });

        AddToMenuModal.$addInputs.on('click', function () {
            $this = $(this).parent();
            // cause native input takes a secont to update
            setTimeout(function () {
                AddToMenuModal.keepOneSelectionChecked($this);
            }, 50)
        });

    }

    // INIT    
    AddToMenuModal.AjaxGetUserMenus();
    AddToMenuModal.binds();

});