/*
    This is for the Measurement controls section on a recipe page. Where you see the yield, serving size and # of servings

    It basically inits the recipe calculator, so it can make calculation on these values and then we can handle re-rendering here with the data provided by it

    It also handles a somewhat complicated process of converting units that don't match. ie lb and oz. (More info on README.md)

*/


var _AjaxConversion;
var _EventEmitter;
var _RecipePresenter;
var _Util;
var recipeCalculator;
var _EditRecipeValidation;

var MeasurementControls = function(){
    this.initDependencies();
    this.yieldMultiplier;
    this.onEditPage = ( $('.yield-control select').length && $('.serving-control select').length);
    this.onGetPage = ( !$('.yield-control select').length || !$('.serving-control select').length);
    this.$yieldCtrl = $('.yield-control');
    this.$servingCtrl = $('.serving-control');
    this.matchYieldUnitsResult = undefined;
    this.checkConvertSuccess = true;        // we can start out true because both of our dropdowns are set at same thing...only on changing will fire and determine if needs to be false.
    this.checkNumbersValid_yield = false;
    this.checkNumbersValid_serving = false;
    this.firstRun  = true;
    this.init();
}

MeasurementControls.prototype.initDependencies = function(){
    _AjaxConversion = new AjaxConversion();
    _EventEmitter = EventEmitter.getInstance();
    _RecipePresenter = RecipePresenter.getInstance();
    _Util = Util;
    _EditRecipeValidation = new EditRecipeValidation(  );
}

MeasurementControls.prototype.init = function(){
    var _this = this;
    _this.firstRun = true;
    this.matchYieldAndServingSizeUnits(function(){
        _this.initCalculator();
        _this.binds();
        _this.updateUI();
    });
}

MeasurementControls.prototype.initCalculator = function(){
	console.log("initCalculator");
    var measurementData = _RecipePresenter.scrapeMeasurementControlValues();
   // measurementData.yieldAmt = this.convertYield('yield_to_serving', measurementData.yieldAmt);

   var recipeYield = measurementData.yieldAmt;
   var servingSize = measurementData.servingSizeAmt;

    var factor = 1 / this.yieldMultiplier;
    var numServings = ( parseFloat( recipeYield ) * factor) / parseFloat(servingSize);
    numServings = Math.ceil(numServings.toFixed( 2 ));
//    $( '.num-of-servings' ).val( numServings );

    recipeCalculator = new RecipeCalculator({
        yield: measurementData.yieldAmt,
        servingSize: measurementData.servingSizeAmt,
        numberOfServings: numServings,
        recipeCost: 0, // no recipe cost needed for this page's calculations
    });
    $('#radio-1').attr('disabled',false);
    $('#radio-2').attr('disabled',false);
}

MeasurementControls.prototype.convertYield = function(fromTo, yieldAmt) {
	console.log("convertYield " + fromTo + " " + yieldAmt + " " + this.$yieldCtrl.attr('data-servingunitequivalent'));
    if(fromTo === 'yield_to_serving') {
        return _Util.fractionStrToDecimal(yieldAmt * this.$yieldCtrl.attr('data-servingunitequivalent'));
    } else if (fromTo === 'serving_to_yield') {
        var m = this.$yieldCtrl.attr('data-yieldmultiplier');
        if( m ) return yieldAmt * m;
        else return yieldAmt;
    }
}

MeasurementControls.prototype.matchNewYieldUnit = function (yieldAmt, fromId, toId, onComplete) {
	console.log("matchNewYieldUnit");
    var _this = this;
    this.yieldAmt = yieldAmt;

      // console.log(measurementData);
    // AJAX CALL - WE USE CONVERSION IN THIS PAGE TO HAVE YIELD AND SERVING W MATCHING UNITS. THIS WAY OUR CALCULATOR WORKS FINE
    _AjaxConversion.convertStd({
        params: {
            fromId: fromId,
            toId:   toId,
            amount: 1
        }
    }).then(function(res){
        var res = JSON.parse(res);
        // console.log(res);
        if(res.Data && typeof( res.Data ) != 'undefined') {
            var yieldFactor = res.Data;
            //_this.yieldMultiplier = 1 * yieldAmtInServingUnit;
            //$( "input#yield-input" ).val( Number(  yieldAmt ) * yieldFactor ); <3_)~

        } else {
            _this.setYieldConversionValues(1, 1);
        }
        if(onComplete) onComplete();
         });
}


    // bust out my own version
MeasurementControls.prototype.matchYieldAndServingSizeUnits_editRecipe = function (onComplete) {
	console.log("matchYieldAndServingSizeUnits_editRecipe");
    var _this = this;
    _AjaxConversion.convertStd({
        params: {
            fromId: _this.getYieldUnit( ),
            toId: _this.getServingSizeUnit( ),
            amount: 1
        }
    }).then(function(res){
        var res = JSON.parse(res);
        if(res.Data) {
            _this.matchYieldUnitsResult = res.Data;
        }
        else
        {
            _this.matchYieldUnitsResult = false;
        }
        if(onComplete) onComplete();
    });
}

// ONLY ON EDIT PAGE SINCE GET DOESNT HAVE SERVING SIZE EDIT
// ie if yield = 1 lb and servingSize = 1oz, keep a private var stored with 16 oz for yield. 
// We need this to perform calculations for yield amt and # of servings in the UI
MeasurementControls.prototype.matchYieldAndServingSizeUnits = function (onComplete, fromUnit, toUnit) {
	console.log("matchYieldAndServingSizeUnits");
    var _this = this;
    var measurementData = _RecipePresenter.scrapeMeasurementControlValues();
	
	if(typeof(fromUnit) === 'undefined' || typeof(toUnit) === 'undefined'){
		fromUnit = measurementData.servingUnitName;
		toUnit = measurementData.yieldUnitName;
	}
    // console.log(measurementData);
    
	if(wholeUnitIds.includes( Number( $( '.yield-control' ).find('select').attr('data-selected') ) ) == true){
		//for "each", "dozen", etc, the conversion is 1 to 1, we just have to duplicate the standard values over to metric or vice versa
		setTimeout(function(){
			$('.yield-control').attr('data-amt1',$('input#yield-input').val());
			$('.yield-control').attr('data-unitname1',$("select#yield-unit-select option:selected").text());
			$('.yield-control').attr('data-unitcode1', $('#yield-unit-select').val(  ));
			$('.yield-control').attr('data-amt2',$('input#yield-input').val());
			$('.yield-control').attr('data-unitname2',$("#yield-unit-select option:selected").text());
			$('.yield-control').attr('data-unitcode2', $('#yield-unit-select').val(  ));
			
			_this.matchYieldUnitsResult = 1;
			_this.yieldMultiplier = 1;
			_this.setYieldConversionValues(1,1);
			
			if(onComplete) onComplete(1);
		},100);
	} else {
		// AJAX CALL - WE USE CONVERSION IN THIS PAGE TO HAVE YIELD AND SERVING W MATCHING UNITS. THIS WAY OUR CALCULATOR WORKS FINE
		_AjaxConversion.convertStd({
			params: {
				fromId: toUnit,
				toId: fromUnit,
				amount: 1
			}
		}).then(function(res){
			try{
				var res = JSON.parse(res);
			}
			catch( e )
			{
				//  _this.setYieldConversionValues(1, 1);
				//  errMsg = 'Your selected units of measurement are incompatible. This recipe\'s calculators might not work properly.'
				// $( '.num-of-servings' ).text( 0 );
				// _EventEmitter.emit('recipe-measurement-err', errMsg);
			}
			// console.log(res);
			if(res.Data && typeof( res.Data ) != 'undefined') {
				console.log(fromUnit + " to " + toUnit + " ratio is " + res.Data);
				var yieldAmtInServingUnit = res.Data;
				_this.matchYieldUnitsResult = res.Data;
				_this.yieldMultiplier = 1 / yieldAmtInServingUnit;
				_this.setYieldConversionValues(yieldAmtInServingUnit, _this.yieldMultiplier);
	
			} else {
				
				// _this.setYieldConversionValues(1, 1);
				//  errMsg = 'Your selected units of measurement are incompatible. This recipe\'s calculators might not work properly.'
				// $( '.num-of-servings' ).text( 0 );
				// _EventEmitter.emit('recipe-measurement-err', errMsg);
			}
			if(onComplete) onComplete(res.Data);
		});
	};
}

MeasurementControls.prototype.setYieldConversionValues = function(yieldAmtInServingUnit, yieldMultiplier){
    this.$yieldCtrl.attr('data-servingunitequivalent', yieldAmtInServingUnit); // client facing yield by this to match serving 
    this.$yieldCtrl.attr('data-yieldmultiplier', yieldMultiplier); // recipecalculator yield by this to match our client facing yield
}



// this.getMatchingUnitYieldServings(  )
//     {
//         if( !isNaN( servingSize ) || ( !isNaN( recipeYield ) ) ) return;
//         var theUnitYield = $('#yield-unit-select').data( 'selected' );
//         var theUnitServings = $('#serving-input').data( 'selected' );

//         if( theUnitYield == theUnitServings ){
//             $( '.num-of-servings' ).val( recipeYield / servingSize );
//         }
//         else
//         {

//         }

        
//     }

MeasurementControls.prototype.checkConversionValid = function(onComplete){
    var measurementData = _RecipePresenter.scrapeMeasurementControlValues();

     if( measurementData.yieldUnitName.toLowerCase ()  == measurementData.servingUnitName.toLowerCase ( ) )
    {
        //_EventEmitter.emit('recipe-measurement-err', '');
        if(onComplete) onComplete();
        return;
    }


    _AjaxConversion.checkConversionValid({
        params: {
            fromUnitName: measurementData.yieldUnitName,
            toUnitName: measurementData.servingUnitName,
        }
    }).then(function(res){
        // console.log(res);
        var res = JSON.parse(res);

        if(typeof res.Data === 'undefined') 
        {
            errMsg = 'Your selected units of measurement are incompatible. This recipe\'s calculators might not work properly.'
            $( '.num-of-servings' ).text( 0 );
            _EventEmitter.emit('recipe-measurement-err', errMsg);
        }
        var conversionCompatible = res.Data;  
        
        if(conversionCompatible === 1) {
            //_EventEmitter.emit('recipe-measurement-err', '');
        } else {
            errMsg = 'Your selected units of measurement are incompatible. This recipe\'s calculators might not work properly.'
            $( '.num-of-servings' ).text( 0 );
            _EventEmitter.emit('recipe-measurement-err', errMsg);
        }

        if(onComplete) onComplete();
    });   
}



MeasurementControls.prototype.checkConversionValid_editRecipe = function(onComplete){
    var measurementData = _RecipePresenter.scrapeMeasurementControlValues();
    _this = this;

    if( measurementData.yieldUnitName.toLowerCase ()  == measurementData.servingUnitName.toLowerCase ( ) )
    {
         _EventEmitter.emit('recipe-measurement-err', '');
        if(onComplete) onComplete();
        return;
    }

    _AjaxConversion.checkConversionValid_editRecipe({
        params: {
            fromUnitName: measurementData.yieldUnitName,
            toUnitName: measurementData.servingUnitName,
        }
    }).then(function(res){
        // console.log(res);
        var res = JSON.parse(res);
        if(typeof res.Data === 'undefined') return;
       
         var conversionCompatible = res.Data;  
        
        if(conversionCompatible === 1) {
            _EventEmitter.emit('recipe-measurement-err', '');
            _this.checkConvertSuccess = true;
        } else {
            errMsg = 'Your selected units of measurement are incompatible. This recipe\'s calculators might not work properly.';
            _this.checkConvertSuccess = true;
            $( '.num-of-servings' ).text( 0 );
            _EventEmitter.emit('recipe-measurement-err', errMsg);
        }

        if(onComplete) onComplete();
    });

    
}

MeasurementControls.prototype.updatePrintVals = function(  )
{
      if( this.onGetPage )
    {
        $('#main > div.container > div.measurement-controls > div > div > div.measurement-group.yield.col-6.col-sm-4 > div.print-only > p > span.yield')
        .text( $('input#yield-input').val(  ) );

        

           $('#main > div.container > div.measurement-controls > div > div > div.measurement-group.yield.col-6.col-sm-4 > div.print-only > p > span.unit-code')
        .text( $("select#yield-unit-select option:selected").text() );


        $('#main > div.container > div.measurement-controls > div > div > div.measurement-group.number-of-servings-control.col-6.col-sm-4 > div.print-only > p')
        .text( $("#number-of-servings").val() );

    }
}

// grab all data from UI send to calculator. Update UI with received data
// Call after user changes any value in the measurement controls inputs
MeasurementControls.prototype.updateUI = function (values) {
	console.log("updateUI");
    var values = (typeof values !== 'undefined') ? values : recipeCalculator.getAllProperties();
	
    if(this.onGetPage) { 
        if (values.numberOfServings){ 
            // leave this value alone if we are only here because of cross conversion, bc rounding error differences were causing the messup
            if(  values.source ==  "crossConvert" ){}
            else{
                $('.number-of-servings-control .number-of-servings-edit').val(values.numberOfServings);
            }
        }
        if (values.servingSize){
            $('.serving-control .amt').text(values.servingSize);
        }
        if (values.yield) {
            var yieldAmt = this.convertYield('serving_to_yield', values.yield);
			console.log("Updating yield-input to " + yieldAmt + " 361");
            $('#yield-input').val(_Util.cleanNumber(yieldAmt ));
            this.matchYieldAndServingSizeUnits(); // to update data-yield2
        }
    } else if (this.onEditPage) {
        if (values.numberOfServings){
            // $('.num-of-servings').text(
            //     _Util.cleanNumber( 
            //         _Util.convertToTwoDecimals( values.numberOfServings) 
            //     ) 
            // );
        }
        if (values.servingSize){
            $('.serving-amt').val(values.servingSize);
        }
        if (values.yield) {
            //var yieldAmt = this.convertYield('serving_to_yield', values.yield);
            //$('#yield-input').val(yieldAmt);
           // this.matchYieldAndServingSizeUnits(); // to update data-yield2
        }
    }

  
    // console.log(values);
}

MeasurementControls.prototype.changeYieldUnit =function(  )
    {
		console.log("changeYieldUnit");
        _this = this;
        var recipeYield = $('#yield-unit-select').val(  );
        
         var theNewYieldUnit = recipeYield;
         var theOldYieldUnit= $( '.yield-control' ).data( "unit" );
         var theOldYieldAmt = $( '.yield-control' ).data( "amt" );
         var theNewUnitAmount = null;
         // case 1 - units match, simple division.
         if( theNewYieldUnit == theOldYieldUnit ){
            theNewUnitAmount = theOldYieldAmt;
         }
         else
         {
            _this.matchNewYieldUnit( theOldYieldAmt, theOldYieldUnit, theNewYieldUnit, function(){
                // var factor = this.yieldMultiplier;
                // var numServings = (recipeYield * factor) / servingSize;
                //  $( '.num-of-servings' ).val( numServings );
               //_this.initCalculator();
               // _this.updateUI();
                _this.checkConversionValid();
                $( '.yield-control' ).data( "unit" , $('#yield-unit-select').val(  ) ); // set new unit
                 $( '.yield-control' ).data( "amt" , $( 'input#yield-input' ).val(  ) );
            });
         }
     }

MeasurementControls.prototype.changeMeasurementUnits =function(  )
    {
		console.log("changeMeasurementUnits");
        _this = this;
        var servingSize =$('input#number-of-servings').val(  )
        var recipeYield = $('#yield-unit-select').val(  );
        if( isNaN( servingSize ) || ( isNaN( recipeYield ) ) ) return;
        
         var theUnitYield = $('#yield-unit-select').data( 'selected' );
         var theUnitServings = $('.serving-unit.unit-code').data( 'unit-code' );

        var theOldYieldUnit= $( '.yield-control' ).data( "unit" );

        if( recipeYield ==  theOldYieldUnit ) return;
         // case 1 - units match, simple division.
         if( theUnitYield == theUnitServings ){
             //$( '.num-of-servings' ).val( recipeYield / servingSize );
         }
         else
         {
            _this.matchYieldAndServingSizeUnits(function(){
                // var factor = this.yieldMultiplier;
                // var numServings = (recipeYield * factor) / servingSize;
                //  $( '.num-of-servings' ).val( numServings );
               _this.initCalculator();
                _this.updateUI();
                _this.checkConversionValid();

                
            });
         }
    }


MeasurementControls.prototype.changeMeasurementAmounts_editPage =function(  )
{
	console.log("changeMeasurementAmounts_editPage");
    var yieldUnit = this.getYieldUnit( );
    var servingsUnit = this.getServingSizeUnit( );

    var yieldAmt = this.getYieldAmount(  );
    var servingsAmt = this.getServingSizeAmount(  );

    $( '.yield-control' ).data( "unit" , this.getYieldUnit(  ) ); // set new unit
    $( '.yield-control' ).data( "amt" , this.getYieldAmount(  ) );

    _this = this;
    if( this.checkConvertSuccess == true && this.checkNumbersValid_serving == true )
    {
        var updateAmounts = function(  )
        {
            var factor= _this.matchYieldUnitsResult;
            if( factor ) 
            {
                var yieldInServingAmt = yieldAmt * factor;
                var numServings = yieldInServingAmt / servingsAmt ;
                $('.num-of-servings').text( Number( numServings.toFixed( 2 ) ) );
            }
        }
        this.matchYieldAndServingSizeUnits_editRecipe( updateAmounts );
    }
    else
    {
            $('.num-of-servings').text( Number( 0 ) );
    }        

}


MeasurementControls.prototype.changeMeasurementUnits_editPage =function(  )
    {
		console.log("changeMeasurementUnits_editPage");
        _this = this;
        
        var theUnitYield = _this.getYieldUnit(  );
        var theUnitServings = _this.getServingSizeUnit(  );

        //var theOldYieldUnit= $( '.yield-control' ).data( "unit" );

        //if( theUnitYield ==  theOldYieldUnit ) return;
         // case 1 - units match, simple division.
		 if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
			 var theOldYieldUnit = $( '.yield-control' ).attr( "data-unitname2");
		 } else {
			 var theOldYieldUnit = $( '.yield-control' ).attr( "data-unitname1");
		 }
        // _this.matchYieldAndServingSizeUnits(function(){
                _this.initCalculator();
                //_this.updateUI();
				
				var values = recipeCalculator.getAllProperties();
				var yieldUnitCode = $( '.yield-control' ).find('select').attr('data-selected');
				_this.matchYieldAndServingSizeUnits(function(factor){
					var yieldAmt = _this.convertYield('serving_to_yield', values.yield);
					console.log("Updating yield-input to " + yieldAmt + " 509");
					$('#yield-input').val(_Util.cleanNumber(yieldAmt ));
					
					_this.checkConversionValid_editRecipe();
					$( '.yield-control' ).data( "unit" , _this.getYieldUnit(  ) ); // set new unit
					$( '.yield-control' ).data( "amt" , _this.getYieldAmount(  ) );
					//_this.changeMeasurementAmounts_editPage(  );
					
					var servingsAmt = _this.getServingSizeAmount(  );
					var yieldInServingAmt = yieldAmt * factor;
					var numServings = yieldInServingAmt / servingsAmt ;
                	$('.num-of-servings').text( Number( numServings.toFixed( 2 ) ) );
					
				}, theOldYieldUnit, $( '.yield-control' ).find('select option[value="'+yieldUnitCode+'"]:first').text() );
        //});
    }





MeasurementControls.prototype.getYieldAmount = function(  )
{
    var amt = parseFloat( $('input#yield-input').val() );  
    if( !isNaN( amt ) ) {
        return amt;
    }
    else return "";
}

MeasurementControls.prototype.getServingSizeAmount = function(  )
{
    var amt = parseFloat( $('input#serving-input').val() );
    if( !isNaN( amt ) ) return amt;
    else return "";
}


MeasurementControls.prototype.getYieldUnit = function (  )
{
    var unit = parseInt( $('#yield-unit-select').find('option:selected').val() );
     if( !isNaN( unit ) ) return unit;
    else return "";
}

MeasurementControls.prototype.getServingSizeUnit = function (  )
{
    var unit =  parseInt( $('#serving-unit-select').find('option:selected').val() );
     if( !isNaN( unit ) ) return unit;
    else return "";
}



MeasurementControls.prototype.binds = function(){
    var _this = this;

    
    // change yield amount
    $('#yield-input').on('change', function(){
		console.log("yield-input onchange");
        // this is where yield is fired
        var input = this;

        if( _EditRecipeValidation.testPostiveNumber( $('input#yield-input') , "Yield amount invalid." , $( "#recipe-errors" )))
        {
            _this.checkNumbersValid_yield = false;
            return -1;       
        }
        else
        {
            _this.checkNumbersValid_yield = true;


            if(this.onEditPage && _EditRecipeValidation.testPostiveNumber( $('#serving-input') , "Serving size invalid." , $( "#recipe-errors" )))
            {
                _this.checkNumbersValid_serving = false;
                return -1;       
            }
            else
            {
                _this.checkNumbersValid_serving = true;
            }


        }
		if( window.location.href.indexOf( "editRecipe" ) > 0 )
		{
			_this.changeMeasurementAmounts_editPage( 'yield' );
		}
		else{
			// _this.changeMeasurementUnits(  );
			recipeCalculator.updateYield( $('#yield-input').val(  ) );
		}
		_this.updatePrintVals(  );
	//   
	});

    $('#serving-input').on('change', function(){
		console.log("serving-input onchange");
        var servingSize = $('#serving-input').val();

        if( _EditRecipeValidation.testPostiveNumber( $('#serving-input') , "Serving size invalid." , $( "#recipe-errors" )))
        {
            _this.checkNumbersValid_serving = false;
            return -1;       
        }
        else
        {
            _this.checkNumbersValid_serving = true;
            if( _EditRecipeValidation.testPostiveNumber( $('input#yield-input') , "Yield amount invalid." , $( "#recipe-errors" )))
            {
                _this.checkNumbersValid_yield = false;
                return -1;       
            }
            else
            {
                 _this.checkNumbersValid_yield = true;
             }

        }

        if( window.location.href.indexOf( "editRecipe" ) > 0 )
         {
            _this.changeMeasurementAmounts_editPage( 'serving' );
         }
        _this.updatePrintVals(  );
        // recipeCalculator.setServingSize(servingSize);
        // _this.changeMeasurementUnits(  );
    });

    $('.measurement-bottom select').on('change', function() {
		console.log("measurement-bottom select onchange");
        var select = this;
        idClicked = $(select).attr( 'id' );

        $(select).attr('data-selected', select.value);
         /*if( window.location.href.indexOf( "getRecipe" ) >-1 )
         {
            _this.changeYieldUnit(  );   
         }
         else
         {*/
            // edit page
              _this.changeMeasurementUnits_editPage( idClicked );
         //};
    })


    $('.number-of-servings-control .number-of-servings-edit').on('change', function(){
		console.log("number-of-servings-control onchange");
        // this is what fires when servings is changed.
        var numOfServings = $('.number-of-servings-control .number-of-servings-edit').val();
       
        if( _EditRecipeValidation.testPostiveNumber( $('#number-of-servings') , "Serving size invalid." , $( "#recipe-errors" ))) return -1;

        recipeCalculator.setNumberOfServings(numOfServings);
        _this.updatePrintVals(  );
      //  _this.updateUI();
    });

    
    $('.measurement-system-radio').on('click', function(){
        // Set a timeout because we need the unit selects & their data attributes to update for matchYield fn to  use them.
        setTimeout(function(){
			
            _this.matchYieldAndServingSizeUnits(function(){
                _this.initCalculator();
				if($('.yield-control').attr('data-amt1') != $('.yield-control').attr('data-amt2')){
                	_this.checkConversionValid();
				}
                // Only update number of servings, we don't want the other two inputs to change
                var values = recipeCalculator.getAllProperties();
                _this.updateUI({
                    numberOfServings: values.numberOfServings, 
                    source:"crossConvert"
                })
            });     
        }, 300)
    });

    
}





