var UnitCodes = function(){
    this.table = UnitCodeTable
}; 

UnitCodes.prototype.getTable = function(){
    return this.table;
}

UnitCodes.prototype.getItemIndex = function(unitStr) {
    var index = -1;

    table.forEach(function(unitObj) {
        for (var key in unitObj) {
            var unitCode = unitObj[key]
            if (unitCode.replace(/\s/g, '') === unitStr.replace(/\s/g, '')) {
                index = table.indexOf(unitObj)+1;
            }
        }
    })
    
    return index;
}