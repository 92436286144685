$(document).ready(function () {

    var _ModalCore = new ModalCore();
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();

    AddSublistModal = {};
    AddSublistModal.$ = $('#modal-create-sub-list');

    AddSublistModal.refreshInstance = function () {
        AddSublistModal.$ = $('#modal-create-sub-list');
    }
    AddSublistModal.getFormData = function () {
        var data = {};
        data.name = AddSublistModal.$.find('#sublist').val();
        return data;
    }
    AddSublistModal.create = function () {
        AddSublistModal.refreshInstance();
        var formData = AddSublistModal.getFormData();
        _MenuAndIngredientList.addSublist(formData);
    }

    AddSublistModal.$.find('.btn-lg-primary').on('click', function (e) {
        if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;
        var $modal = AddSublistModal.$.closest('.modal-container');
        if(_ModalCore.isFormValid($modal)){  
            AddSublistModal.create();
        }
    })
});