var SaveAsModal;
$(document).ready(function () {
    // var _AjaxRecipe = new AjaxRecipe();
    // var _RecipePresenter = RecipePresenter.getInstance();
    // var _EventEmitter = EventEmitter.getInstance();
    // var _GenericAlert = new GenericAlert();
    // var _Util = Util;

    SaveAsModal = {};
    SaveAsModal.$ = $('#modal-save-as');
    SaveAsModal.rowToUpdate;


    SaveAsModal.refreshInstance = function () {
        SaveAsModal.$ = $('#modal-save-as');
    }

    SaveAsModal.updateForm = function () {
        $form = SaveAsModal.$.find('.modal-content');
        var name = $('.recipe-title').text();
        $form.find('#recipe-name-textarea').val(name);
    }

    SaveAsModal.binds = function(){
        // Open modal and populate form info
        $(document).on('click', '.save-as-btn', function (e) {
            SaveAsModal.refreshInstance();
            SaveAsModal.updateForm();
        })
    }


    // INIT
    SaveAsModal.binds();

});