$(document).ready(function(){
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");

    function PrintElem(elem) {
        var originalWindow = window;
        window.name = "parent";

        var formPrice = $( '#menu-price' ).val(  );
        var formMargin = $( '#margin-measurement' ).val(  );
        var formFoodCost = $( '#food-cost-percent' ).val(  );

        var mywindow = window.open('', 'modalPrint', 'height='+screen.height+',width='+screen.width+'');

        mywindow.document.write('<html><head>');
        mywindow.document.write(libsCSS)
        mywindow.document.write(appCSS)
        mywindow.document.write(libsJS)
        mywindow.document.write(siteUrlJS)
        mywindow.document.write(appJS)
        mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1 tabindex="0">' + document.title + '</h1>');
        // mywindow.document.write('<a id="link" href="#">Return to Parent </a>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        setTimeout( function(  ) {
         if(formPrice) mywindow.document.getElementById( 'menu-price'  ).value = formPrice;
        if( formMargin )        mywindow.document.getElementById( 'margin-measurement'  ).value = formMargin;
        if( formFoodCost ) mywindow.document.getElementById( 'food-cost-percent'  ).value = formFoodCost;
    },200);
       
        // mywindow.document.write(refocusJs);

        setTimeout( function(  ) {
             mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
              mywindow.focus(); // necessary for IE >= 10*/
          }, 500);
        
    }


    $('.print-target').on('click', function () {
        console.log('printprintprintprint he!');
        var id = $(this).attr('data-print-id');
        var frame = $(this).attr('data-print-frame');
        var css = $(this).attr('data-print-css');
        var assetDir = SITE_URL + 'app/theme/public/clientPKM/build/assets/';

        libsCSS = '<link href="'+assetDir + 'css/libs/bootstrap.css" rel="stylesheet" type="text/css">';
        appCSS = '<link href="'+assetDir+'css/styles.css" rel="stylesheet" type="text/css">';
        libsJS = '<script src="'+assetDir+'/js/libs/jquery.min.js"></script>';
        siteUrlJS = '<script> var SITE_URL="' + SITE_URL + '";</script>';
        appJS = '<script src="'+assetDir+'/js/outside-scripts/print-modal-outside-script.js"></script>';
        PrintElem(id);
    });
        
})
