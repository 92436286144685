var drake; // used in accesibility.js

var DragNDrop = function(shouldInit){
    if (typeof dragula === 'undefined' ||  $('.dragula-component').length === 0) return;

    var _this = this;
    console.log(shouldInit);
    if(typeof shouldInit === 'undefined' || shouldInit) {
        this.addEditIcons();
        this.binds();
        setTimeout(function(){
            _this.initDragula();
            _this.horizontalDragFeature({
                sublistClass: 'sub-list',
                clippingOffset: 10
            });
        }, 1000)
    }
};

DragNDrop.prototype.binds = function() {}

    
DragNDrop.prototype.refreshDragula = function(){
    drake.destroy();
    this.initDragula();
}

DragNDrop.prototype.initDragula = function(){
    drake = dragula([].slice.apply(document.querySelectorAll('.nested')),
        {
            // Make drag handles to control drag & drop
            moves: function (el, container, handle) {
                var isHandle = $(handle).hasClass('drag-handle');
                if (!isHandle) isHandle = $(handle).parent().hasClass('drag-handle');
                return isHandle;
            },
            // MAKE SURE SUBLIST CANNOT BE SUBNESTED
            accepts: function (el, target, source, sibling) {
                var elIsSublist = $(el).hasClass('sub-list');
                var targetIsNotSublist = $(target).closest('.sub-list').length === 0;

                if(elIsSublist && targetIsNotSublist) {
                    return true;
                } else if(!elIsSublist) {
                    return true;
                }
            }
        }
    );
}

// Add edit icons to menu and recipe lists that are in edit mode
DragNDrop.prototype.addEditIcons = function() {
    console.log('add edit icons');
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        var imgHTML = '<span class="drag-handle" style="background-image:url('+SITE_URL+'app/theme/public/clientPKM/build/assets/images/style/Icon-Sort@2x.png);" role="combobox" aria-haspopup="true" aria-label="Button to drag item up or down. Double Tap to open sub menu." tabindex="0"></span>';
    } else {
        var imgHTML = '<span class="drag-handle" style="background-image:url('+SITE_URL+'app/theme/public/clientPKM/build/assets/images/style/Icon-Sort@2x.png);" role="combobox" aria-haspopup="true" aria-label="Button to drag item up or down. Press space bar to open sub menu." tabindex="0"></span>';
    }
    // These are edit toggle with different modal triggers
    var editMenuHTML = '<img class="edit-toggle" src="'+ASSET_DIR+'/style/Icon-Edit@2x.png" tabindex="0" data-toggle="modal" data-target="#modal-menu-edit-recipe" aria-label="Edit Recipe Button"/>';
    var editRecipeHTML = '<img class="edit-toggle" src="' + ASSET_DIR +'/style/Icon-Edit@2x.png" tabindex="0" data-toggle="modal" data-target="#modal-recipe-edit-ingredient" aria-label="Edit Ingredient Button"/>';
    var editSublistHTML = '<img class="edit-toggle" src="' + ASSET_DIR +'/style/Icon-Edit@2x.png" tabindex="0" data-toggle="modal" data-target="#modal-edit-sublist" alt="Edit Sublist Button" aria-label="Edit Sublist Button"/>';
    $('.ingredients-list.edit').find('.name-container').prepend(imgHTML);
    $('.ingredients-list.edit .sub-list > .name').append(editSublistHTML); // Can use menu or recipe edit
    $('.ingredients-list.menu.edit').find('.amount').append(editMenuHTML);
    $('.ingredients-list.ingredients.edit').find('.amount').append(editRecipeHTML);
}

/**
 * FEATURE ADDED ATOP DRAGULAJS
 * 
 * Place or remove from nested list on horizontal movement & even if nested list if at the bottom of the list
 * WHAT THIS DOES IS if the sublist is empty, it adds a space below it while another item is passing by it so you can nest inside of it.
 * 
 */       
DragNDrop.prototype.horizontalDragFeature = function(config){
    var mouseDown = false;
    var isDragging = false;
    var startX = 0;
    var $itemInTransit = null;
    var sublistClass = config.sublistClass
    var clippingOffset = config.clippingOffset; // how much do you drag forward or backward for item to clip and unclip from nested element
    $(document)
        .on('mousedown touchstart', '.item', function (e) {
            mouseDown = true;
            isDragging = false;
            startX = e.pageX;
        })
        .on('mousemove touchmove', '.item', function (e) {
            var currentX = e.pageX;

            if (mouseDown) {
                $itemInTransit = $('.item.gu-transit');

                // If the previous item has a sub-list (is nestable) try to get it
                var $previousItem = $itemInTransit.prev()
                if (!$previousItem.hasClass('sub-list')) $previousItem = $itemInTransit.closest('.'+sublistClass)

                // If the previous item is a sublist (meaning our item is nested)
                var prevItemIsSublist = $previousItem.length > 0 && !$itemInTransit.hasClass(sublistClass);
                if (prevItemIsSublist) {
                    // Append item in sublist
                    if (currentX > startX + clippingOffset) {
                        $previousItem.find('.nested').addClass('force-sublist-height');
                        $previousItem.find('.nested').append($itemInTransit);
                    } else {
                        // Remove item from sublist
                        $('.nested').removeClass('force-sublist-height');
                        if (currentX < startX - clippingOffset) {
                            $previousItem.after($itemInTransit);
                        }
                    }
                } else {
                    $('.nested').removeClass('force-sublist-height');
                }
                isDragging = true;
            }
        })
        .on('mouseup touchend', '.item', function (e) {
            $('.nested').removeClass('force-sublist-height');
            mouseDown = false;
            isDragging = false;
            $itemInTransit = null;
        })
}