/*
    Pretty self explanatory. This just makes sure that the footer sticks to the bottom
    We use this class in conjuction w the following CSS trick
    
    #main {
        box-sizing: border-box;
        min-height: 100%;
        padding: 0 0 100px;
    }

    footer {
        position: absolute;
        bottom: 0;
        height: auto;
        left: 0;
        width: 100%;
    }
*/

var Footer = function(){
    this.stickToBottomAdjustments();
    this.binds();
};

Footer.prototype.stickToBottomAdjustments = function(){
    var footerHeight = $('footer').outerHeight();
    $('#main').css( {
        paddingBottom: footerHeight
    });
}

Footer.prototype.binds = function(){
    $(window).on('resize', this.stickToBottomAdjustments);
}

