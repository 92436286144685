
$(document).ready(function () {

    ShoppingList = {};
    ShoppingList.$ = $('.modal-shopping-list');
    var _UnitCodes = UnitCodes;
    var UnitsTable = new UnitCodes().getTable();
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();
    var _localSmartConvert  = SmartConvert.getInstance();
    var start=0;
    /**
     * Order of this item processor is
     * 
     * 1. AddItem() - abstraction layer - creates or gets item and calls AddItemToCategory()
     * 2. CreateItem() - (optional) if item doesnt exist this get called to create it
     * 3. AddItemToCategory() - gets or creates category and adds item to the group
     * 4. CreateCategory() - (optional) Called at least once. If category doesnt exist this gets called to create it AND adds it to the list UI
     * 
     * Items get added into category, and in turn new categories are added to the UI if they dont exist.
     */

     $( '#open-menu-shopping-list' ).on( 'click', function(  )
     {
        if( start == 1 ) return;
        $( '#modal-menu-shopping-list' ).find( '.ingredient-group' ).find( 'div.amt' ).each( function( e, el )
        {
             var amt = $( el ).find( 'span.amt' ).text(  );
            if( !isNaN( parseFloat(amt) ) )
            {
                var unitCode = $( el ).find('span.unit-code').data( 'unit-code' );
                var newAmounts = _localSmartConvert.doConvert( amt, unitCode ) ;
                $( el ).find( 'span.amt' ).text( newAmounts.newUnit.quantityFraction );
                $( el ).find('span.unit-code').data( 'unit-code', newAmounts.newUnit.unitIndex);
                $( el ).find('span.unit-code').text( newAmounts.newUnit.unitName );
            }
        });
        start = 1;
     } );

     $('#open-shopping-list').on( 'click', function(  ){

        
        $('#modal-recipe-shopping-list').find('.modal-body').html( 
        '<div class="ingredient-group placeholder" data-groupcategory="">' +
                            '<div tabindex="0">'+
                                '<strong class="full-width text-center category" tabindex="-1"></strong>' +
                            '</div>' +
                            '<div class="ingredients">' +
                                '<!-- Ingredient Level -->' +

                            '</div>' +
                        '</div>' +
                        '<!-- Ingredient Placeholder -->' +
                        '<div class="row item placeholder" data-name="" data-price="" data-category="" data-amt="" tabindex="0">' +
                            '<div class="col-8 col-sm-9 name" tabindex="-1"></div>' +
                            '<div class="col-4 col-sm-3 amt" tabindex="-1">' +
                                '<span class="qty"></span>' +
                                '<span class="unit"></span>' +
                            '</div>' + '</div>');
        var allIngredientsData = _MenuAndIngredientList.getAllData($('.ingredients-list'), {
            getSublists: false
        } );
        
        allIngredientsData.forEach(function (ingredientData) {
            ShoppingList.addItemWData(ingredientData);
        } );
        
        ShoppingList.renderUnitCodes();

      });



        $('.mobile-shopping-list').on( 'click', function(  ){
        
        $('#modal-recipe-shopping-list').find('.modal-body').html( 
        '<div class="ingredient-group placeholder" data-groupcategory="">' +
                            '<div tabindex="0">'+
                                '<strong class="full-width text-center category" tabindex="-1"></strong>' +
                            '</div>' +
                            '<div class="ingredients">' +
                                '<!-- Ingredient Level -->' +

                            '</div>' +
                        '</div>' +
                        '<!-- Ingredient Placeholder -->' +
                        '<div class="row item placeholder" data-name="" data-price="" data-category="" data-amt="" tabindex="0">' +
                            '<div class="col-8 col-sm-9 name" tabindex="-1"></div>' +
                            '<div class="col-4 col-sm-3 amt" tabindex="-1">' +
                                '<span class="qty"></span>' +
                                '<span class="unit"></span>' +
                            '</div>' + '</div>');
        var allIngredientsData = _MenuAndIngredientList.getAllData($('.ingredients-list'), {
            getSublists: false
        } );
        
        allIngredientsData.forEach(function (ingredientData) {
            ShoppingList.addItemWData(ingredientData);
        } );
        
        ShoppingList.renderUnitCodes()
      });


    ShoppingList.addCategoryToList = function ($category) {
        ShoppingList.$.find('.modal-body').append($category);
    }

    ShoppingList.createCategory = function (categoryName) {
        var $category = ShoppingList.$.find('.ingredient-group.placeholder').clone().removeClass('placeholder');
        if( categoryName=='N/A' )
            $category.find('strong.category').text("Misc");
        else
            $category.find('strong.category').text(categoryName);
        $category.attr('data-groupcategory', categoryName);
        ShoppingList.addCategoryToList($category);
        return $category;
    }

    ShoppingList.addItemToCategory = function ($item) {
        var categoryName = $item.data('category');
        if(!categoryName) { categoryName = 'Misc' }
        var $category = ShoppingList.$.find('[data-groupcategory="' + categoryName + '"]');
        if ($category.length === 0) $category = ShoppingList.createCategory(categoryName);

        $category.append($item);
        return $category;
    }

    ShoppingList.createItemWData = function (itemData) {
        var $item = ShoppingList.$.find('.item.placeholder').clone().removeClass('placeholder');
        for (var key in itemData) {
            if (itemData.hasOwnProperty(key))
                $item.data(key, itemData[key]); // add all original item data to shopping alist item
        }

        // for example "to taste" has not units, so in this case unit "is probably the best bet". Not completely checked for edge casess 
        // if(itemData.amt === '') itemData.amt = itemData.unit;
        var convertedUnit = UnitsTable[itemData.unit-1];

        if( noPriceUnitIds.indexOf(parseInt( itemData.unit )) >= 0 )
        {
            $item.find('.name').text(itemData.name);
            $item.find('.amt .qty').text('');
            $item.find('.amt .unit').text(convertedUnit.single);

        }
        else
        {
            $item.find('.name').text(itemData.name);
            if(  itemData.unit !== 'ea' )
            
            var newAmt = _localSmartConvert.doConvert( itemData.amt , itemData.unit ) ;
            if( !newAmt.newUnit.fail )
            {
                $item.find('.amt .qty').text( String( newAmt.newUnit.quantityFraction ) + " " );
                $item.find('.amt .unit').text( newAmt.newUnit.unitName );
                
                if( newAmt.newUnit.unitName =="ea" || newAmt.newUnit.unitName =="each" ){
                    $item.find('.amt .qty').text( itemData.amt );
                    $item.find('.amt .unit').text( ' ' + newAmt.newUnit.unitName );
                };
            }
            else
            {
                $item.find('.amt .qty').text(itemData.amt);
                $item.find('.amt .unit').text(' ' + convertedUnit.single);
            }
        }
        // clobber if we have a convert = 2

        // here we do the clobber...
        if( typeof( itemData.currentConversion ) !== 'undefined' &&  itemData.currentConversion == 2)
        {
                unitUpscaleFractionMetric =  _localSmartConvert.doConvert( itemData.amt2, itemData.unit2 ) ;
            $item.find('.amt .unit').text( ' ' + unitUpscaleFractionMetric.newUnit.unitName  );
            $item.find('.amt .qty').text( unitUpscaleFractionMetric.newUnit.quantityFraction );
        }

        return $item;
    }
    // Adds new items to list
    // NOTE: SEE RETURN STAEMENT. DOESNT NOT HANDLE DUPLICATE ITEM PROCESSING ie add item prices and ingredient amounts
    ShoppingList.addItemWData = function (itemData) {
        var $item = ShoppingList.$.find('[data-name="' + itemData.name + '"]');
        if ($item.length === 0) $item = ShoppingList.createItemWData(itemData);
        else return; //TODO: In case of duplicate items this is where we would handle updates that require adding prices and quantities

        ShoppingList.addItemToCategory($item);
    }

    // convert unit codes numbers to their corresponding text
    ShoppingList.renderUnitCodes = function() {
       /* // Units codes in spans
        var unitCodesTable = new UnitCodes().getTable();
        var $unitCodeSpans = $('.unit-code');
        $unitCodeSpans.each(function (i, el) {
            var unitCode = $(el).data('unit-code');
            var unit = unitCodesTable[unitCode - 1];
            $(el).text(unit.single);
        })*/
    }

    // INIT
    if(document.location.href.toLowerCase().indexOf('/recipe/') >= 0 ) {
        var allIngredientsData = _MenuAndIngredientList.getAllData($('.ingredients-list'), {
            getSublists: false
        });
        allIngredientsData.forEach(function (ingredientData) {
            ShoppingList.addItemWData(ingredientData);
        })
    }
    ShoppingList.renderUnitCodes()


});