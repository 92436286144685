var EventEmitter = (function EventEmitter(){
    var instance;

    function _EventEmitter(){
        this.events = {};
    }
    _EventEmitter.prototype.subscribe = function (eventName, fn) {
        if (!this.events[eventName])
            this.events[eventName] = [];

        this.events[eventName].push(fn);
    }

    _EventEmitter.prototype.emit = function (eventName, data) {
        var event = this.events[eventName];
        console.log( "%cEVENT EMITTER: " + eventName, "color:white;background-color:darkslateblue;" );
        if (event) {
            event.forEach(function (fn) {
                fn.call(null, data);
            })
        }
    }

    _EventEmitter.prototype.unsubscribe = function (eventName) {
        var event = this.events[eventName];
        if (event) {
            this.events[eventName] = [];
        }
    }

    return {
        getInstance: function(){
            if(!instance)
                instance = new _EventEmitter();
            return instance;
        }
    }
})()

