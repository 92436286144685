/**
 * Accesibility JS
 * 
 * Does a few things...
 * 
 * 1. Adds keyboard responses to elements that don't natively have it. 
 *      ENTER & SPACE to click
 *      ESCAPE to exit
 * 2. Redirects focus to correct elements on above actions
 * 3. Drag and Drop Keyboard Controls for Dragula js
 * 4. Adds a few dynamic aria-labels
 * 5. Handle skip content components visibility (used to skip to main content or footer)
 * 6. Add ARROWUP & ARROWDOWN navigation to JPLIST dropdowns & Dragulajs keyboard controls
 */

var KEY = {
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    ESCAPE: 27,
    SPACE: 32,
    ARROWUP: 38,
    ARROWDOWN: 40,
};


function iosBlockBackground (  )
{
    $( "#overlay" ).on( "touchstart click", function(e){
        e.preventDefault();
        $('#move-item-name').click().focus();   
        $('#move-item-name').focus();
    }


        );
    //if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
        //$( "div#main" ).disable(  );                                                                  
        $( "#overlay" ).css( "z-index", '999' ).css("visibility", "visible").css("display", "inline");
       //  $("body").append('<div id="overlay" style="background-color:grey;position:absolute;top:0;left:0;height:100%;width:100%;z-index:999"></div>')
        $( ".accessibility-controls" ).css( 'z-index', '1100'  );
  //  }
    $( ".accessibility-controls" ).parents(  ).find( ".item" ).removeClass( 'z-max' );
	$('.hideFromPopup').each(function(){
		$(this).attr("aria-hidden","true");
	});
	$('.tox-tinymce').each(function(){
		$(this).attr("aria-hidden","true");
	});
	$('#tiny-mce-dir_ifr').attr("aria-hidden","true");
	$('#tiny-mce-dir_ifr').attr("tabindex","-1");
	$('#tiny-mce-dir_ifr').css("display","none");
}


function iosReleaseBackground (  )
{
	$('.hideFromPopup').each(function(){
		$(this).attr("aria-hidden","false")
	});
	$('.tox-tinymce').each(function(){
		$(this).attr("aria-hidden","false");
	});
	$('#tiny-mce-dir_ifr').attr("aria-hidden","false");
	$('#tiny-mce-dir_ifr').attr("tabindex","0");
	$('#tiny-mce-dir_ifr').css("display","block");
	$('#ingredientsHeader').focus();
//  if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
        //$( "div#main" ).disable(  );
                                                                                                      
        $( "#overlay" ).css( "z-index", '-999' ).css("visibility", "hidden").css("display", "none");
       //  $("body").append('<div id="overlay" style="background-color:grey;position:absolute;top:0;left:0;height:100%;width:100%;z-index:999"></div>')
         $( ".accessibility-controls" ).css( 'z-index', '1'  );
  //  }
}


var AccessibilityCtrl = function(){
    this.backtab = false;
    this.listenForBacktabs();
    this.accesibilityElementSelectors = [
        '.jplist-next',
        '.jplist-prev',
        '.filter-close',
        '.close-icon',
        '.collapse-ctrl',
        '.edit-toggle',
        'a.export',
        'a.print',
        'input[type="checkbox"]',
        '.like-icon-btn'
    ];
    this.addKeyboardControlsTo(this.accesibilityElementSelectors);
    this.addJpListArrowAriaLabels();
    this.dragNDrop();
    this.binds();
}

AccessibilityCtrl.prototype.addJpListArrowAriaLabels = function(){
    // Add aria labels to JP List pagination buttons
    // These are dynamically added to the page so we need JS for this
    $('.jplist-prev').attr('aria-label', 'In this page. See previous group of items');
    $('.jplist-next').attr('aria-label', 'In this page. See next group of items');
}

AccessibilityCtrl.prototype.addKeyboardControlsTo = function(accesibilityElementSelectors) {
    accesibilityElementSelectors.forEach(function (elClass) {
        $(document).on('keydown', elClass, function (e) {
            var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);

            if (spaceOrEnterPressed) {
                e.preventDefault();
                e.stopPropagation();
                $(this).click();
            }
        })

    })
}

AccessibilityCtrl.prototype.binds = function(){
    var _this = this;
    $(document).on('keydown', this.keyDownEventChain.bind(this));
    $('.top-search-form').on('keydown', function(e){ _this.keyDownTopSearchForm(e, this) });
    $(window).on('keyup', this.outsideElClicks.bind(this));

    // Navigation and Main Content Skip Menu
    // Triggered on first tab on document 
    $('.skip-to a').on('focus', function () {
        $(this).closest('.skip-to').addClass('show-skip-to');
    })
    // Close the Skip To Menu
    $(document)[0].addEventListener('keyup', function (e) {
        if ($('.skip-to a:focus').length === 0 && $('.skip-to').hasClass('show-skip-to'))
            $('.skip-to').removeClass('show-skip-to');
    });

    $(document)[0].addEventListener('keydown', this.escapeSkipToOnBacktab.bind(this));




    // Collapse aria labels
    var $collapseCtrls = $('.collapse-ctrl');
    $collapseCtrls.on('click', function () {
        if ($(this).attr('aria-expanded') === "true")
            $(this).attr('aria-expanded', 'false');
        else
            $(this).attr('aria-expanded', 'true');

        var $content = $(this).siblings('.collapse-content')
        if ($content.attr('aria-hidden') === "true")
            $content.attr('aria-hidden', 'false');
        else
            $content.attr('aria-hidden', 'true');
    })

    // Add tabindex to filter badges on mobile so user
    // can scroll the overflowed content with keyboard
    $(window).on('resize', function () {
        if ($(window).innerWidth() < 768) {
            $('.filter-badge-collection').attr('tabindex', '0');
        } else {
            $('.filter-badge-collection').removeAttr('tabindex');
        }
    })

}

AccessibilityCtrl.prototype.escapeSkipToOnBacktab = function() {
    if(this.backtab) {
        if ($('.skip-to ul li:first-child a:focus').length > 0) {
            $('.skip-to').removeClass('show-skip-to');
            
        }
    }
}

// Detect click outside of & close element
AccessibilityCtrl.prototype.outsideElClicks = function(e) {
    if (e.keyCode === KEY.TAB) {
        var $focusedEl = $(':focus');

        var outsideIngredientAccesibilityControl = $focusedEl.closest('.accessibility-controls').length === 0;
        if (outsideIngredientAccesibilityControl) {
            if (!$('.accessibility-controls').hasClass('hidden')) {
                $('.accessibility-controls .close-icon').click();
            }
        }

        var outsideTopSearchForm = $focusedEl.closest('.top-search-form').length === 0;
        if (outsideTopSearchForm) {
            if ($('.top-search-form').css('display') === 'block') {
                $('.top-search-form .close-icon').click();
            }
        }

        var outsideFullScreenFilter = $focusedEl.closest('.filters-component').length === 0;
        if (outsideFullScreenFilter) {
            if ($('.filters-component').css('display') === 'block' && $(window).innerWidth() < 992) {
                $('.filters-component .close-icon').click();
            }
        }
    }
}

// Close nav search form with escape btn, except when focused in its dropdown
AccessibilityCtrl.prototype.keyDownTopSearchForm = function(e, topSearchForm){
    if (e.keyCode === KEY.ESCAPE && $('.top-search-form .dropdown select:focus').length === 0 )
        $(topSearchForm).find('.close-icon').click();
}

AccessibilityCtrl.prototype.keyDownEventChain = function(e){
    this.keyDownJpListDropdown(e);
    this.keyDownJpListKeyboardControls(e);
    this.keyDownFilterCheckbox(e);
    this.keyDownNavSearchBtn(e);
    this.keyDownCustomDropdown(e);
    this.keyDownFilterComponent(e);
}

    

AccessibilityCtrl.prototype.listenForBacktabs = function(){
    var _this = this;
    var shiftPressed = false;
    var tabPressed = false;

    $(document).keydown(function(e){
        if(e.keyCode === KEY.SHIFT) {
            shiftPressed = true;
        }
        if(e.keyCode === KEY.TAB) {
            tabPressed = true;
        }
        if(tabPressed && shiftPressed) {
            _this.backtab = true;
        }
    });
    $(document).keyup(function (e) {
        if (e.keyCode === KEY.SHIFT) {
            shiftPressed = false;
            _this.backtab = false;
        }
        if (e.keyCode === KEY.TAB) {
            tabPressed = false;
            _this.backtab = false
        }
    });
}

AccessibilityCtrl.prototype.keyDownJpListDropdown = function(e){
    var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);

    // JPLIST OPEN AND CLOSE DROPDOWNS
    if ($(".dd-panel-trigger:focus").length !== 0 && spaceOrEnterPressed) {
        e.preventDefault();
        e.stopPropagation();
        $(".dd-panel-trigger:focus").find('.jplist-dd-panel').click();
        $('.dd-panel-trigger:focus').find('ul > div').first().focus();
        return;
    }
    if ($(".jplist-drop-down:focus").length !== 0 && spaceOrEnterPressed) {
        e.preventDefault();
        e.stopPropagation();
        $(".jplist-drop-down:focus").find('.jplist-dd-panel').click();
        $('.jplist-drop-down:focus').find('li').first().focus();
        return;
    }

    // JPLIST DROPDOWN OPEN AND CLOSE DROPDOWNS FROM LI
    if ($(".jplist-drop-down ul > div:focus").length !== 0 && spaceOrEnterPressed) {
        e.preventDefault();
        e.stopPropagation();
        var $ddPanel = $(".jplist-drop-down ul > div:focus").closest('.jplist-drop-down')
        $(".jplist-drop-down ul > div:focus li").click();
        $ddPanel.focus();
        return;
    }
}

// JP LIST SUB MENU CONTROLS
// Allows you to use up and down arrow keys to navigate the selection.
AccessibilityCtrl.prototype.keyDownJpListKeyboardControls = function(e){
    var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);

    if ($(".jplist-drop-down ul > div:focus").length !== 0 && (e.keyCode === KEY.ESCAPE)
        || $(".jplist-drop-down ul > div:focus").length !== 0 && (e.keyCode === KEY.TAB)) {
        
        $('.jplist-drop-down ul > div:focus').closest('.jplist-drop-down').click();
        $(".jplist-drop-down ul > div:focus").closest('.jplist-drop-down').focus()
        return;
    }

    if ($(".jplist-drop-down ul > div:focus").length !== 0 && (e.keyCode === KEY.ARROWDOWN)) {
        e.preventDefault();
        e.stopPropagation();
        var $first = $('.jplist-drop-down ul > div:focus').closest('.jplist-drop-down').find('ul > div').first();
        var $next = $('.jplist-drop-down ul > div:focus').next();
        if ($next.length === 1) $next.focus();
        else $first.focus();
        return;
    }
    if ($(".jplist-drop-down ul > div:focus").length !== 0 && (e.keyCode === KEY.ARROWUP)) {
        e.preventDefault();
        e.stopPropagation();
        var $first = $('.jplist-drop-down ul > div:focus').closest('.jplist-drop-down').find('ul > div').last();
        var $next = $('.jplist-drop-down ul > div:focus').prev();
        if ($next.length === 1) $next.focus();
        else $first.focus();
        return;
    }
}

// Trigger the Filter Badge 
AccessibilityCtrl.prototype.keyDownFilterCheckbox = function(e){
    var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);

    // Click custom checkbox divs to check and uncheck them
    if ($("input[type='checkbox']:focus").length !== 0 && spaceOrEnterPressed) {
        $input = $("input[type='checkbox']:focus")

        setTimeout(function(){
            $input.closest('.category-filter').trigger('mouseup'); // creates the filter badge
        }, 220)
        return;
    }
}

AccessibilityCtrl.prototype.keyDownNavSearchBtn = function(e){
    var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);

    // Click custom checkbox divs to check and uncheck them
    // Focus first element if you open top search form
    if ($(".btn-nav-search:focus").length !== 0 && spaceOrEnterPressed) {
        setTimeout(function () {
            $('.top-search-form').focus();
        }, 200)
        return;
    }
}

AccessibilityCtrl.prototype.keyDownNavSearchForm = function(e){
    var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);

    // Click custom checkbox divs to check and uncheck them
    // Focus first element if you open top search form
    if ($(".btn-nav-search:focus").length !== 0 && spaceOrEnterPressed) {
        setTimeout(function () {
            $('.top-search-form h4').focus();
        }, 200)
        return;
    }
}

AccessibilityCtrl.prototype.keyDownCustomDropdown = function(e){
    var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);
    
    // Focus first elements in dropdown toggle
    if ($('.c-dropdown-toggle:focus').length > 0 && spaceOrEnterPressed) {
        setTimeout(function () {
            $('.c-dropdown-toggle:focus').siblings('.dropdown-content').find('a').first().focus();
        }, 100);
        return;
    }
    
    // Close dropdown toggle if you hit escape
    if ($('.dropdown-content a:focus').length > 0 && e.keyCode === KEY.ESCAPE) {
        iosReleaseBackground(  );
        $toggle = $('.dropdown-content a:focus').closest('.dropdown-content').siblings('.c-dropdown-toggle');
        $toggle.click();
        $toggle.focus();
        return;
    }
}
AccessibilityCtrl.prototype.keyDownFilterComponent = function(e){        
    // Close full screen filter component if you hit escape 
    if ($('.filters-component').css('display') === 'block' && $(window).innerWidth() < 992 && e.keyCode === KEY.ESCAPE) {
        
        $('.filters-component .close-icon').click();
        return;
    }
}

// TODO: major refactoring
AccessibilityCtrl.prototype.dragNDrop = function(){
    // DRAG AND DROP DRAGULA JS
    // https://stackoverflow.com/questions/35675322/dragula-js-move-from-one-list-to-another-with-on-click-event
    // if (typeof drake === 'undefined') return;
    // Close Accesibility controls with Close Icon

    $('div.accessibility-controls').find("h3").on('keydown', function (e)
    {
            if ( e.keyCode===9 && e.shiftKey  ) {
                $('.ingredients-list .accessibility-controls .close-icon').focus();
                 e.preventDefault();
            }
    });


    $('div.accessibility-controls').focusout( function( e )
    {
      //  alert( "LOST FOCUS" );
        try{
          
            var target = e.originalEvent.relatedTarget.className;
           //console.log( "Focus out fired.", target );
          if( target == "drag-handle" )
          {
                //console.log( "FOCUS SET TO DRAG HANDLE try and cancel" );
                $('#move-item-name').focus();
                e.preventDefault( );    
        }
            
        }
        catch( except )
        {}

    } );


    var _this = this;
    $('.ingredients-list .accessibility-controls .close-icon').on('click keydown', function (e) {
        var clicked = e.type === 'click';
        var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);

          if (e.keyCode===9) {
            first.focus();
           e.preventDefault();
        }


        // Close accesibility controls
        if (spaceOrEnterPressed || clicked) {
           
            e.stopPropagation();
            e.preventDefault();
			$('.hideFromPopup').each(function(){
				$(this).attr("aria-hidden","false")
			});
			$('.tox-tinymce').each(function(){
				$(this).attr("aria-hidden","false");
			});
			$('#tiny-mce-dir_ifr').attr("aria-hidden","false");
			$('#tiny-mce-dir_ifr').attr("tabindex","0");
			$('#tiny-mce-dir_ifr').css("display","block");
			$('#ingredientsHeader').focus();
            var $keyboardCtrl = $(this).closest('.accessibility-controls');
            $keyboardCtrl.closest('.item').find('.drag-handle').focus();
            $keyboardCtrl.addClass('hidden');
            $keyboardCtrl.closest('.item').removeClass('z-max');
            var nestedItem = $(this).closest('.sub-list').length > 0;
            if (nestedItem) {
                $(this).closest('.sub-list').removeClass('z-max');
            }
        }
    })

    // Close Accesibility controls with Escape key
    $('.ingredients-list .accessibility-controls').on('keydown', function (e) {
        // Close accesibility controls
        if (e.keyCode === KEY.ESCAPE) {
            iosReleaseBackground(  );
			
            $(this).addClass('hidden');
			
            $(this).closest('.item').removeClass('z-max');
            var nestedItem = $(this).closest('.sub-list').length > 0
            if (nestedItem) {
                $(this).closest('.sub-list').removeClass('z-max');
            }
        }
    });

    $('.ingredients-list .accessibility-controls .position-buttons button').on( 'mousedown touchstart', function(e){
        var newEvent = jQuery.Event("keydown");
        newEvent.keyCode = KEY.SPACE;                     
        $(this).trigger(newEvent);   
    });

    // Accesibility controls - Place Elements After, Into or Before option
    $('.ingredients-list .accessibility-controls .position-buttons button').on('click keydown', function (e) {
		var clicked = e.type === 'click';
        var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);
        // This class is used to determine if selected element in accesibility menu
        // should be placed before or after the target position
		
        if (spaceOrEnterPressed || clicked) {
			
            e.stopPropagation();
            e.preventDefault();
            $('.ingredients-list .accessibility-controls .position-buttons button').removeClass('selected');
            $(this).addClass('selected');
			
			//update aria label to reflect which button is selected
			/*if($(this).hasClass('before-btn')){
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').attr("aria-label", "Before Button, selected. If activated this places an item before the selected ingredient");
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').attr("aria-label", "Into Button. If activated this places an item into an existing sublist");
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').attr("aria-label", "After Button. If activated this places an item after the selected ingredient");
			} else if($(this).hasClass('into-btn')){
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').attr("aria-label", "Before Button. If activated this places an item before the selected ingredient");
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').attr("aria-label", "Into Button, selected. If activated this places an item into an existing sublist");
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').attr("aria-label", "After Button. If activated this places an item after the selected ingredient");
			} else if($(this).hasClass('after-btn')){
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').attr("aria-label", "Before Button. If activated this places an item before the selected ingredient");
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').attr("aria-label", "Into Button. If activated this places an item into an existing sublist");
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').attr("aria-label", "After Button, selected. If activated this places an item after the selected ingredient");
			}*/
			if($(this).hasClass('before-btn')){
				$('#buttonLog').html("Before button pressed.");
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').attr("aria-pressed", "true");
				
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').removeAttr("role");
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').attr("aria-pressed", "false");
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').attr("role", "button");
				
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').removeAttr("role");
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').attr("aria-pressed", "false");
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').attr("role", "button");
			} else if($(this).hasClass('into-btn')){
				$('#buttonLog').html("Into button pressed.");
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').removeAttr("role");
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').attr("aria-pressed", "false");
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').attr("role", "button");
				
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').attr("aria-pressed", "true");
				
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').removeAttr("role");
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').attr("aria-pressed", "false");
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').attr("role", "button");
			} else if($(this).hasClass('after-btn')){
				$('#buttonLog').html("After button pressed.");
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').removeAttr("role");
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').attr("aria-pressed", "false");
				$('.ingredients-list .accessibility-controls .position-buttons .before-btn').attr("role", "button");
				
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').removeAttr("role");
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').attr("aria-pressed", "false");
				$('.ingredients-list .accessibility-controls .position-buttons .into-btn').attr("role", "button");
				
				$('.ingredients-list .accessibility-controls .position-buttons .after-btn').attr("aria-pressed", "true");
			}
        }
    })


    // this is the open the box command for mobile
    $(document).on( 'mousedown touchstart', '.drag-handle', function(e){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            
           // $(".ingredients-list .drag-handle").focus();
            var newEvent = jQuery.Event("keydown");
            newEvent.keyCode = KEY.SPACE;                     
            $(this).trigger(newEvent);   
            /*setTimeout( function(  ) {
                    $('.ingredients-list .accessibility-controls .move-item-name').parent("h3").focus();            
                }, 300 );*/
          /*setTimeout( function(  ) {
			  $('#move-item-name').focus();  
			}, 300 );*/
        }
    });

// Accesibility Controls Options    
    $(document).on('keydown', '.ingredients-list .drag-handle', function (e) {
        var _parentThis = _this;
        var $dragHandle = $(this);
        var $list = $('.ingredients-list');
        var $keyboardCtrl = $list.find('.accessibility-controls');
        var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);
        if (spaceOrEnterPressed) {
             
            e.stopPropagation();
            e.preventDefault();
            var _this = this;
            var $itemToMove = $(this).closest('.item');
            var $positionBtns = $keyboardCtrl.find('.position-buttons')
            var $accesibleItemPlaceholder = $keyboardCtrl.find('.item-accessible.placeholder');
            var itemIsNested = $itemToMove.closest('.sub-list').length > 0;
            // Make sure we don't get nested items if we select a sublist. Cant place a sublist into one of its children
            var $otherItems = (itemIsNested)
                ? $list.find('.item').not($itemToMove).not('.sub-list .item').not('.placeholder')
                : $list.find('.item').not($itemToMove).not('.placeholder');

            emptyKeyboardCtrl($keyboardCtrl);

            // Show Accesibility Controller and assign it its header title
            if (itemIsNested) {
                $itemToMove.closest('.sub-list').addClass('z-max');
            }
            $itemToMove.addClass('z-max');
            $itemToMove.prepend($keyboardCtrl);
            var itemToMoveName = $itemToMove.find('.name-container p').text()
            if(itemToMoveName === '') itemToMoveName = $itemToMove.find('.name-container a').text()
            
            $('#move-item-name').text("Move " + itemToMoveName);
            $keyboardCtrl.removeClass('hidden');
			
			$('#move-item-name').focus();
            iosBlockBackground(  );

            // Create the Accesibility Control items and add them to it
            $otherItems.each(function addItemsToKeyboardCtrl(i, item) {
                var $item = $(item)
                var itemIsSublist = $item.hasClass('sub-list')
                var itemIsNested = $item.parent().closest('.sub-list').length > 0;
                var id = i;
                var $titleEl = $item.find('.name').first();
                var name = $titleEl.text();
                var $newKeyboardItem = $accesibleItemPlaceholder.clone().removeClass('placeholder');

                $newKeyboardItem.find('.name-accessible').text(name);
                $newKeyboardItem.find('.id-accessible').text(id);
                $keyboardCtrl.append($newKeyboardItem);
                // if (i === 0) $newKeyboardItem.focus();
                if (itemIsSublist)
                    $newKeyboardItem.find('.name-accessible').text(name + ' ' + '(sublist)');
                if (itemIsNested) $newKeyboardItem.addClass('nested');

                $item.attr('id', id);
            });

            var close = $( '.close-icon.x-menu' );
            $( '.close-icon.x-menu' ).remove();
            $keyboardCtrl.append( close );

             $( 'img.close-icon' ).on( 'mouseup touchend', function( e )
            {
                var newEvent = jQuery.Event("keydown");
                    newEvent.keyCode = KEY.ESCAPE;                     
                    $('.ingredients-list .accessibility-controls').trigger(newEvent);   
            } );

            $( 'img.close-icon' ).on( 'keydown', function( e )
            {
                if ( e.keyCode == KEY.ESCAPE || e.keyCode == KEY.SPACE ||  e.keyCode == KEY.ENTER ) {
                      var newEvent = jQuery.Event("keydown");
                    newEvent.keyCode = KEY.ESCAPE;          
                    $('.ingredients-list .accessibility-controls').trigger(newEvent);   
                }
            } );


                /*setTimeout( function(  ) {
                    $('#move-item-name').focus();
                }, 200 );*/


            // Handle placing an item on a new position
            var $keyboardItems = $keyboardCtrl.find('.item-accessible').not('.placeholder');
            
            // this is the scroll thing
            $keyboardItems.on( 'mousedown touchstart', function(e){
                 var newTrigger = jQuery.Event("keydown");
                newTrigger.keyCode = KEY.SPACE;                     
                $(this).trigger(newTrigger);   

            });

            $keyboardItems.on('keydown', function dragItem(e) {

                var spaceOrEnterPressed = (e.keyCode === KEY.ENTER) || (e.keyCode === KEY.SPACE);
                var navKeyPressed = (e.keyCode === KEY.ESCAPE) || (e.keyCode === KEY.ARROWDOWN) || (e.keyCode === KEY.ARROWUP)
                if (spaceOrEnterPressed || navKeyPressed) {
                    e.stopPropagation();
                    e.preventDefault();
                }
                if (spaceOrEnterPressed) {

                    var id = $(this).find('.id-accessible').text();
                    var $userSelection = $('#' + id);
                    var selectedPosition = $positionBtns.find('.selected').text()

                    drake.start(_this);
                    if (selectedPosition.toLowerCase() === 'after')
                        $userSelection.after($itemToMove);
                    else if (selectedPosition.toLowerCase() === 'before')
                        $userSelection.before($itemToMove);
                    else if (selectedPosition.toLowerCase() === 'into') {
                        if ($userSelection.find('.nested').length === 1)
                            $userSelection.find('.nested').append($itemToMove);
                    }
                    drake.end();
					
					//deselect before/into/after buttons
					$('.ingredients-list .accessibility-controls .position-buttons button').removeClass('selected');
					$('.ingredients-list .accessibility-controls .position-buttons .btn').removeAttr("role");
					$('.ingredients-list .accessibility-controls .position-buttons .btn').attr("aria-pressed", "false");
					$('.ingredients-list .accessibility-controls .position-buttons .btn').attr("role", "button");

                    //$keyboardCtrl.find('.accessibility-ingredients-header').not('.placeholder').first().focus();

                    $otherItems.removeClass('z-max');
                    var nestedItem = $itemToMove.closest('.sub-list').length > 0
                    if (nestedItem) {
                        $itemToMove.closest('.sub-list').addClass('z-max');
                    }

                    var newEvent = jQuery.Event("keydown");
                    newEvent.keyCode = KEY.ESCAPE;                     
                    $('.ingredients-list .accessibility-controls').trigger(newEvent);  

                }

                // Up and down navigation for items (not buttons or exit icon)
                if (e.keyCode === KEY.ARROWDOWN) {
                    var $first = $keyboardItems.first();
                    var $next = $(this).next();
                    if ($next.length === 1) $next.focus();
                    else $first.focus();
                }
                if (e.keyCode === KEY.ARROWUP) {
                    var $last = $keyboardItems.last();
                    var $prev = $(this).prev();

                    if ($prev.length === 1 && !$prev.hasClass('placeholder')) $prev.focus();
                    else $last.focus();
                }
            });

            $keyboardCtrl.on('keyup', function (e) {
                // Close accesibility controls
                if (e.keyCode === KEY.ESCAPE) {
                    $keyboardCtrl.find('.close-icon.x-menu').click()
                    $dragHandle.focus();
                }
            });
            
          
            return false;
        }

    });

    // Remove all items from accesibility controller
    function emptyKeyboardCtrl($ctrl) {
        $items = $ctrl.find('.item-accessible').not('.placeholder');
        $items.remove();
    }
}