/* 

    This handles the filtering component for the app on the library pages


    One of the more important things that it does is cache filter selections. It creates a 
    unique key 'pageFilterName = filterIds:' + window.location.pathname + '-'+ USER_ID; 

    It saves the array of selected filter ids under this key like so 
    _CacheManager.setValue(pageFilterName, filterIdsArr);

    This component also handles simple UI functionality like toggling the filter when we're on 
    mobile view.
    Clearing all checkboxes
    
*/

var FilterComponent = (function(){
    var _fp;
    var instance;
    var _CacheManager;
    var pageFilterName = '';
    var _FilterComponent = function(){
        var _this = this;
        this.initDependencies();
        this.$ = $('.filters-component');
        this.binds();
        this.setPageFilterName();
        setTimeout(function(){
            _this.toggleCachedFilters()
        }, 10);
    };

    _FilterComponent.prototype.initDependencies = function(){
        _CacheManager = CacheManager.getInstance();
       $(document).ready(function(){
            _fp = new FiltersPresenter(  );
        });
    }

    // Keep Filter Cache Page Specific
    _FilterComponent.prototype.setPageFilterName = function(){
        pageFilterName = 'filterIds:' + window.location.pathname + '-'+ USER_ID;
    }

    // Clear all checkboxes inside the filter
    _FilterComponent.prototype.clearAll = function () {
        var _this = this;
        $checkedInputs = this.$.find('input[type="checkbox"]:checked');
        $checkedInputs.prop('checked', false);

        // remove the filter badges
        $checkedInputs.closest('.category-filter').trigger('mouseup');
        $checkedInputs.each(function(i, checkbox) {
            _this.cacheFilterSelection($(checkbox))
        })
    }

    // Our filter is a modal on mobile. 
    // We make the outer body non-scrollable so only content inside the full screen filter is scrollable
    _FilterComponent.prototype.modalize = function () {
        // on desktop
        if (this.$.css('display') === 'block') {
            $('body').css('overflow-y', 'hidden');
        } else {
            $('body').css('overflow-y', 'scroll')
        }
    }

    // Toggle and hide are for mobile, where the filter will take up the whole screen when turned on
    // This way we can show or hide it via event listeners
    _FilterComponent.prototype.toggle = function () {
        this.$.toggle();
        $('.liked-filter input').focus();
        this.modalize();
    }

    _FilterComponent.prototype.hide = function () {
        this.$.hide();
        $('.filter-toggle .btn-filter-toggle').focus()
        this.modalize();
    }

    _FilterComponent.prototype.binds = function () {
        var _this = this;
        $('.clear-all').on('click', function(){
            _this.clearAll();
            location.reload(  );
        });
        $('.clear-all-badges').on('click', function(){
            _this.clearAll();
            location.reload(  );

        });
        $('.filter-toggle .btn-filter-toggle').on('click', function(){
            _this.toggle()
        });
        $('.close-filter-btn').on('click', function(){
            _this.hide()
        });
        this.$.find('.checkbox-normal').on('click', function(){
            _this.cacheFilterSelection($(this));

             var clickedId = $(this).attr('id');
            var isChecked = $(this).is(':checked');

             clickedId.replace( 'courses','course' );
            //if( category == "courses" ) fixCategory = 'course';

            var badgeClass = '.filter-badge-' + clickedId;
            if( !isChecked )  $( badgeClass ).parent().remove(  );
          
        });
    }

    // More tightly coupled
    // We trigger the checkbox to make sure it's real checkbox updates (the blue checkbox on top is just an image)
    // We click the category filter row so that it triggers the filter badge with its mouseup event.
    _FilterComponent.prototype.toggleCheckboxBySelector = function(id){
        // click on the checkbox and then on the row (row click trigger the filter badge)
        var $checkbox = this.$.find('[id="'+ id +'"]')
        var $categoryFilter = $checkbox.closest('.category-filter');        
        // $checkbox.attr('data-cancelajax', 'true');
        $checkbox.trigger('click');
        $categoryFilter.trigger('mouseup');
    }

    // Takes in the filter that was clicked.
    // The filtersIdArr keeps track of all the filters, and we update and keep track of this value through our cacheManager
    // So that means that we just need to add or remove our filter from this array,
    // and save the array via the cacheManager
    _FilterComponent.prototype.cacheFilterSelection = function($clickedFilter){
        var id = $clickedFilter.attr('id');
        var filterIsChecked = $clickedFilter.is(':checked');
        var filterIdsArr = this.getCachedFilterIds();

        // Add FilterId to arr
        if(filterIsChecked) {
            var filterExists = false;
            filterIdsArr.forEach(function(existingId){
                if(id === existingId) filterExists = true;
            })
            if(!filterExists) filterIdsArr.push(id);
        // Remove FilterId from arr 
        } else {
            var filterExists = false;
            filterIdsArr.forEach(function(existingId){
                if(id === existingId) filterExists = true;
            })
            
            if(filterExists) {
                var i = filterIdsArr.indexOf(id);
                filterIdsArr.splice(i, 1);
            } 
        }

        _CacheManager.setValue(pageFilterName, filterIdsArr);
          setTimeout( function(){_fp.AjaxUpdateFilterCount(  );}, 750);
    }
    _FilterComponent.prototype.getCachedFilterIds = function(){
        return ( Array.isArray(_CacheManager.getValue(pageFilterName)) )
            ? _CacheManager.getValue(pageFilterName)
            : _CacheManager.setValue(pageFilterName, []) //returns empty arr
    }
    
    _FilterComponent.prototype.toggleCachedFilters = function(){
        var _this = this;
        var filterIdsArr = this.getCachedFilterIds();
        filterIdsArr.forEach(function(filterId) {
            _this.toggleCheckboxBySelector(filterId);
        });
    }

    // instance = new _FilterComponent();
    return {
        getInstance: function(){
            if(!instance) instance = new _FilterComponent();
            return instance;
        }
    }
})()
