// NAV UI helper


var _AjaxSiteWideSearch;

var Nav = function(){
    this.$ = $('nav');
    this.initDependencies();
    this.showBarAccent();
    this.reorderMenuItems($(window).outerWidth());
    this.binds();
}

Nav.prototype.initDependencies = function(){
    _AjaxSiteWideSearch = new AjaxSiteWideSearch();

}

// UI specs reordered the nav sections on mobile
// So we are reordering them here based no breakpoint
// ps: this jquery function $('.section-4').after($('.section-2')) reads...place section-2 after .section-4
Nav.prototype.reorderMenuItems = function(winWidth) {
    if (winWidth < 768) {
        $('.section-4').after($('.section-2'));
        $('nav').after($('.navbar-collapse'));
        $('nav').after($('.top-search-form'));
    } else {
        $('.section-1').after($('.section-2'));
        $('nav > .btn-nav-search').after($('.navbar-collapse'));        
        $('nav > .btn-nav-search').after($('.top-search-form'));
    }
}

// Show the second accent color which is required on pages with a subheader
Nav.prototype.showBarAccent = function(){
    // TODO: change based on sub-header selector
    var pageHasSubHeader = $('.sub-navbar').length > 0;
    if (!pageHasSubHeader) {
        $('nav').after('<div class="nav-accent-extra"></div>')
        this.$.addClass('nav-accent');
    }
}

// Bootstrap Patch - hamburger menu won't reset itself on window resize
Nav.prototype.changeMenuOnResize = function(winWidth){
    if (winWidth >= 768) {
        $('.navbar-collapse').removeClass('show');
        $('.navbar-toggler').addClass('collapsed');
    }
};
Nav.prototype.toggleSearchBar = function(e){
    console.log('toggle before');
    // not blank.
    if (e.keyCode !== undefined && e.keyCode)
    {
        if(e.keyCode !== 13 && e.keyCode !== 32)  return;
    }
  
     
    $searchBar = $('.top-search-form');
    $searchBar.toggle();

    // PLace the search bar in the correct spot so that if you continue tabbing
    // You'll go to the correct next element in the DOM tree
    if ($(window).width() < 768) {
        $('nav').after($searchBar);
    } else {
        $('.web-right-side-top').after($searchBar);
    }
    
    // Focus the form
    setTimeout(function () {
        $('.btn-nav-search').blur();
        $('.top-search-form h4').focus();
    }, 1)
}

// Close popup menu on outside click
Nav.prototype.closeOnOutsideClick = function(e){
    // clickedNavbar = $('.navbar-collapse')
    var target = e.target;
    var clickedSearchBtn = $(e.target).hasClass('btn-nav-search');
    var clickedSearch = $(e.target).closest('.top-search-form').length > 0;
    var clickedHamburger = $(e.target).hasClass('navbar-toggler-icon');
    var clickedSubMenu = $(e.target).closest('.navbar-collapse').length > 0;

    var clickOutside = !clickedSearchBtn && !clickedSearch && !clickedHamburger && !clickedSubMenu;

    if (clickOutside)
        this.closeAll();
}

// The Crazy Samsung bug. 
// 1. Keyboard resizes the screen and refreshes the input position on top search bar. 
// 2. This makes the input lose focus and the keyboard closes as soon as it opens
// 3. User can't type anything because NO KEYBOARD!
Nav.prototype.theCrazySamsungBug = function () {
    var width = $(window).outerWidth();
    
    if ($(document.activeElement).attr('type') === 'text') {
        // Logic for while keyboard is shown
    } else {
        // Logic for while keyboard is hidden
        this.changeMenuOnResize(width);
    }
}

Nav.prototype.binds = function(){
    var _this = this;
    console.log('nav binds');
    $win = $(window);
    $win.on('resize', this.theCrazySamsungBug.bind(this));
    $(document).on('click', _this.closeOnOutsideClick.bind(this));

    $('.btn-nav-search').on('mousedown keydown', this.toggleSearchBar);
    $('.top-search-form .close-icon').on('click', this.toggleSearchBar);

    $('.btn-nav-search').on('click', this.closeHamburger.bind(this));
    $('.navbar-toggler').on('click', this.closeTopSearch.bind(this))
}


Nav.prototype.closeAll = function(){
    this.closeTopSearch();
    this.closeHamburger();
}

Nav.prototype.closeTopSearch = function(){
    $('.top-search-form').hide();
}

Nav.prototype.closeHamburger = function(){
    $('.navbar-collapse').collapse('hide');
}