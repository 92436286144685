// Class to give UI components certain pre-determined behaviors

var UIBehaviors = function(){}

// Allow only one checkbox to be selected just like to radios
// give checkbox a common class
// give parent container a different class to limit behavior to its children
UIBehaviors.prototype.allowOnlyOneChecked = function(parentClass, elClass){    
    var $parent = $(this).closest(parentClass);
    var $checkboxes = $parent.find(elClass);  
    $checkboxes.prop('checked', false);
    $(this).prop('checked', true);
}
