$(document).ready(function () {

    var _ModalCore = new ModalCore();
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();

    AddRecipeModal = {};
    AddRecipeModal.$ = $('#modal-menu-add-recipe');

    AddRecipeModal.refreshInstance = function () {
        AddRecipeModal.$ = $('#modal-menu-add-recipe');
    }
    AddRecipeModal.getFormData = function () {
        var data = {};
        var $form = UpdateRecipeModal.$.find('.modal-content');
        data.recipeId = $form.attr('data-recipeid');
        if( !data.recipeId ) data.recipeId = $( '#recipe_id_save' ).val( );

        data.name = AddRecipeModal.$.find('#recipe').val();
        data.servings = AddRecipeModal.$.find('#servings').val();
        return data;
    }
    AddRecipeModal.create = function () {
        AddRecipeModal.refreshInstance();
        var formData = AddRecipeModal.getFormData();
        _MenuAndIngredientList.addRecipe(formData);
    }

    AddRecipeModal.$.find('.btn-lg-primary').on('mouseup keyup', function (e) {
        if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;
        
        var $modal = AddRecipeModal.$.closest('.modal-container');
        if(!_ModalCore.isFormValid($modal)) return;

        AddRecipeModal.create();
    })


});