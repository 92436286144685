// These dependencies will be called on every page
var AddGroupCtrl = function(){
    this.initDependencies();
}

AddGroupCtrl.prototype.initDependencies = function(){
    new GoBackBtn();
    //new DropdownMenu();
    //new DragNDrop();
    //new TinyMceWrapper();

    //var _AwesompleteWrapper = new AwesompleteWrapper();
    //_AwesompleteWrapper.initRecipes();

}
