// These dependencies will be called on every page
var EditMenuCtrl = function(){
    this.initDependencies();
}

EditMenuCtrl.prototype.initDependencies = function(){
    MenuAndIngredientList.getInstance();
    new GoBackBtn();
    new DropdownMenu();
    new DragNDrop();
    new TinyMceWrapper();

    var _AwesompleteWrapper = new AwesompleteWrapper();
    _AwesompleteWrapper.initRecipes();

}