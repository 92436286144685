/*
    Very handy class. We take a boostrap alert & add an id of alert-generic.
    Then we update it's values with 

    let genericAlert = new GenericAlert();
    genericAlert.show({
        title: 'Delete Confirmation',
        msg: 'Do you want to delete this recipe?',
        class: 'delete-alert'
    })

    <!-- OUT OF FLOW - GENERIC ALERT -->
    <div class="modal fade alert-modal generic-alert" id="alert-generic" tabindex="-1" role="dialog" aria-labelledby="Modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="title"></h2>
                    <p class="msg"></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-lg btn-lg-basic confirm-btn" data-dismiss="modal">OK</button>
                </div>
            </div>
        </div>
    </div>
*/

var GenericAlert = function(){
    this.$ = $('#alert-generic');
}

// we can also a class to it so we can manipulate this genericAlert further from an outside controller if needed.
GenericAlert.prototype.show = function(options) {
    this.$.modal('show');
    this.$.find('.msg').html(options.msg);
    this.$.find('.title').html(options.title);
    this.$.addClass(options.class);
}
