$(document).ready(function () {
    // Only  necessary if ids and labels for input checkboxes dont match
    // var Checkbox = {}
    // Checkbox.toggle = function () {
    //     console.log('toggle');
    //     var $checkbox = $(this).find('input[type="checkbox"]');

    //     if (!$checkbox.is(':disabled')) {
    //         var $label = $(this).find('label');
    //         var checked = $checkbox.is(':checked');

    //         if (!checked) {
    //             // $checkbox.prop('checked', true);
    //             // $label.addClass('selected')
    //         } else {
    //             // $checkbox.prop('checked', false);
    //             // $label.removeClass('selected');
    //         }
    //     }
    // }
    
    // var $checkboxComponents = $('.custom-checkbox');
    // $checkboxComponents.on('click', Checkbox.toggle);
});