$(document).ready(function () {
    var _ModalCore = new ModalCore();
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();

    EditSublistModal = {};
    EditSublistModal.$ = $('#modal-edit-sublist');
    EditSublistModal.rowChild;

    EditSublistModal.refreshInstance = function () {
        EditSublistModal.$ = $('#modal-edit-sublist');
        EditSublistModal.$.find( ".error" ).text( '' );
    }
    EditSublistModal.getFormData = function () {
        var data = {};
        data.name = EditSublistModal.$.find('#name').val();
        return data;
    }
    EditSublistModal.update = function () {
        EditSublistModal.refreshInstance();
        var formData = EditSublistModal.getFormData();
        var $sublist = EditSublistModal.rowChild.closest('.sub-list');
        _MenuAndIngredientList.updateSublist($sublist, formData);
    }

    EditSublistModal.updateForm = function () {
        $form = EditSublistModal.$.find('.modal-content');
        $sublist = EditSublistModal.rowChild.closest('.item.sub-list');
        var name = $sublist.attr('data-name');
        $form.find('#name').val(name);
        $form.attr('data-name', name);

    }

    EditSublistModal.deleteIngredient = function () {
        var $form = UpdateIngredientModal.$.find('.modal-content');
        var sublistName = $form.attr('data-name');
        var $sublist = $('.item.sub-list[data-name="' + sublistName + '"]');
        var $prevIngredient = $sublist.prev();
        var $sublistIngredients = $sublist.find('.item');
        if($prevIngredient.length > 0) {
            $sublistIngredients.after($prevIngredient);
        } else {
            $('.ingredients-list > .nested').prepend($sublistIngredients);
        }
        
        $('.item.sub-list[data-name="' + sublistName + '"]').remove();
    }

    EditSublistModal.$.find('.btn-lg-primary').on('click', function (e) {
        if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;

        var $modal = EditSublistModal.$.closest('.modal-container');
        if(_ModalCore.isFormValid($modal)){    
            EditSublistModal.update();
        }
    })

    $(document).on('click', '.item.sub-list .edit-toggle', function (e) {
        EditSublistModal.refreshInstance();
        EditSublistModal.rowChild = $(this);
        EditSublistModal.updateForm();
    })

});