// /**
//  * Filter (BASE CLASS) 
//  * Works in conjuction with JPLIST
//  * 
//  */

// Filter = {
//     $jpList: null,
//     $listItems: null,
//     $itemsInView: null,
//     $itemsOutOfView: null,
//     init: function (_$jpList, itemsClass, pluginsArr) {
//         this.$jpList = _$jpList;
//         this.$listItems = $(itemsClass);
//         this.$itemsInView = this.$listItems;
//         this.plugins.arr = pluginsArr;
//         this.plugins.init();
//     },
//     refreshItems: function () {
//         $filteredItems = this.plugins.getAllFilteredItems();
//         this.emptyList()
//         this.addItems($filteredItems);
//         this.$itemsInView = $filteredItems;
//         this.updateQtyUI();
//     },
//     updateQtyUI: function () {
//         var $results = $('.results');
//         var qty = (Filter.$itemsInView.length);
//         $results.find('.qty').text(qty);
//     },
//     // JPLIST API
//     addItems: function ($items) {
//         this.$jpList.jplist({
//             command: 'add',
//             commandData: {
//                 $items: $items,
//                 index: 0 //if index is omitted -> the items are added to the end of the list
//             }
//         });
//     },
//     // JPLIST API
//     emptyList: function () {
//         this.$jpList.jplist({
//             command: 'empty'
//         });
//     }
// }

// // Functions that can run on all "plugins"
// // that is category.js, search.js & any other filtering methods that we add
// Filter.plugins = {
//     arr: [],
//     init: function () {
//         if (typeof this.arr === 'undefined') return;

//         this.arr.forEach(function (plugin) {
//             plugin.init()
//         })
//     },
//     getAllFilteredItems: function () {
//         if (typeof this.arr === 'undefined') return;

//         $filteredItems = Filter.$listItems;

//         // run filter on all items
//         this.arr.forEach(function (plugin) {
//             $filteredItems = plugin.runFilter($filteredItems);
//             // $allFilteredItems.push( plugin.$filteredItems );
//         });
//         return $filteredItems;
//     }
// };
