var AjaxMenu = function() {
    var _AjaxBuilder = new AjaxBuilder();

    this.checkIfExists = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/checkMenuName',
        eventSucessKey: 'ajax-menu:checkIfExists-success',
        eventFailKey: 'ajax-menu:checkIfExists-error'
    })

    this.getUserMenus = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/getUserMenus',
        eventSucessKey: 'ajax-menu:getUserMenus-success',
        eventFailKey: 'ajax-menu:getUserMenus-error'
    })

    this.save = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/saveMenu/',
        eventSucessKey: 'ajax-recipe:saved-success',
        eventFailKey: 'ajax-recipe:saved-error'
    })

    this.delete = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/deleteMenu',
        eventSucessKey: 'ajax-deleteRecipe:search-success',
        eventFailKey: 'ajax-deleteRecipe:search-error'
    })
}