function AjaxFilters() {
    _AjaxBuilder = new AjaxBuilder();

    this.getFilterCountBaseline = _AjaxBuilder.buildFn({
        url: SVC_URL + 'searchFilterSort/getFilterCountBaseline',
        eventSuccessKey: 'ajax-filters:getFilterCountBaseline-data-received',
        eventFailKey: 'ajax-filters:getFilterCountBaseline-data-error'
    })
    this.getFilterCount = _AjaxBuilder.buildFn({
        url: SVC_URL + 'searchFilterSort/getFilterCount',
        eventSuccessKey: 'ajax-filters:getFilterCount-data-received',
        eventFailKey: 'ajax-filters:getFilterCount-data-error'
    })
    this.calcMenuFilters = _AjaxBuilder.buildFn({
        url: SVC_URL + 'searchFilterSort/calcMenuFilters/',
        eventSuccessKey: 'ajax-filters:calcMenuFilters-data-received',
        eventFailKey: 'ajax-filters:calcMenuFilters-data-error'
    })
}