// TARGET SPECIFIC DEVICES WITH CSS
// 
// Adds .on-device .device-name classes to html
// This should be enough to add device specific styling for any component you want. For example:
// 
// <html class="on-device device-android">
//  <div class="my-div></div>
// </html>
// target that div on android devices only w .on-device .device-android div {display: none;}

var CrossDeviceCSSHelper = function(){
    this.addDeviceClassToHTML()
}

CrossDeviceCSSHelper.prototype.addDeviceClassToHTML = function(){
    var classNames = [];
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
    if (navigator.userAgent.match(/android/i)) classNames.push('device-android');
    
    var html = document.getElementsByTagName('html')[0];
    
    if (classNames.length) classNames.push('on-device');console.log('html.classList',html.classList,'classNames',classNames)
    if (html.classList != "") {
    	html.classList.add.apply(html.classList, classNames);
    }
}

