// These dependencies will be called on every page
var EditGroupCtrl = function(){
    this.initDependencies();
}

EditGroupCtrl.prototype.initDependencies = function(){
    new GoBackBtn();
   //new DropdownMenu();
    //new DragNDrop();
   // new TinyMceWrapper();

    //var _AwesompleteWrapper = new AwesompleteWrapper();
    //_AwesompleteWrapper.initRecipes();

}