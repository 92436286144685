
if (!String.prototype.includes) {
    String.prototype.includes = function() {
        'use strict';
        return String.prototype.indexOf.apply(this, arguments) !== -1;
    };
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

(function ($) {

    $.fn.filterByData = function (prop, val) {
        var $self = this;
        if (typeof val === 'undefined') {
            return $self.filter(
                function () { return typeof $(this).data(prop) !== 'undefined'; }
            );
        }
        return $self.filter(
            function () { return $(this).data(prop) == val; }
        );
    };
})(window.jQuery);



function confirmDialog(message, title, onConfirm, deleteTarget ){
    var fClose = function(){
        modal.modal("hide");
    };
    var modal = $("#alert-generic-delete");
    modal.modal("show");
    if( !title ) title = "Please confirm deletion."; 

    
    $("#alert-generic-title").empty().append(title);
    $("#alert-generic-message").empty().append(message);
    $("#alert-generic-delete-delete-btn").unbind().one('click', function( e )
    {   
        onConfirm( deleteTarget );
    }).one('click', fClose);
    
    $("#alert-generic-delete-cancel-btn").unbind().one("click", fClose);
}



$(document).ready(function(){
    
    new Router({
        '': GlobalCtrl,
        '/': HomeCtrl,

        // Paginated Items
        '/recipe/getAllRecipes': AllRecipesCtrl,
        '/recipe/myRecipes': MyRecipesCtrl,
        '/book/titles': AllTitlesCtrl,
        '/menu/myMenus': MyMenusCtrl,
        '/group/myGroups': MyGroupsCtrl,

        // Menu
        '/menu/editMenu': AddMenuCtrl,
        '/menu/editMenu/nnnn': EditMenuCtrl,
        '/menu/getMenu': ViewMenuCtrl,

        // Recipe
        // '/recipe/editRecipe': AddRecipeCtrl,
        '/recipe/editRecipe': EditRecipeCtrl,
        '/recipe/getRecipe': ViewRecipeCtrl,
        '/recipe/getMyRecipe': ViewRecipeCtrl,


        // Group
        '/group/editGroup': AddGroupCtrl,
        '/group/editGroup/nnnn': EditGroupCtrl,
        '/group/getGroup': ViewGroupCtrl,
    
        // Terms & Videos
        '/glossary': GlossaryCtrl,
        '/video': VideosCtrl,
    });
})


