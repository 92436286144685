$(document).ready(function(){
    if(document.location.href.includes('titles')) {
        var _CacheManager = CacheManager.getInstance();
        var _Util = Util;

        var searchQuery = '';
        var categoryFilters = {
            courseIds: [],
            cookingMethodIds: [],
            cuisineIds: [],
        }
        var bookFilters = {
            bookId:0,
            chapterNums: []
        }

        // Get Search Query from url param if it's there
        if(_Util.getUrlParam('query')) searchQuery = _Util.getUrlParam('query');

        PaginationDataHandler.getInstance({
            itemToRender: 'bookCard',
            getItemsUrl: SVC_URL + 'searchFilterSort/getTitles',
            getMoreItemsUrl: SVC_URL + 'searchFilterSort/getMoreTitles',
            ajaxRequestDefaults: {
                authToken: '12345',
                searchQuery: searchQuery,
                perPage: 20,
                batchSize: 200,
                orderSelection: 'last_applied',
                likeStatus: 0,
                categoryFilters: categoryFilters,
                bookFilters: bookFilters
            }
        });
    }
});
