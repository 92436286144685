$(document).ready(function(){
    // var applyCSSToInaccessibleIframe = function(){
        // var iframe = document.getElementById('directions_ifr');
        // console.log(iframe);
        //     var $innerDoc = $(iframe.contentDocument) || $(iframe.contentWindow.document);
        //     var $directionsTextarea = $innerDoc.find('#tinymce');
        //     $directionsTextarea.css({
        //         // color: '#252525',
        //         // fontFamily: 'OpenSans',
        //         // fontSize: '16px',
        //         lineHeight: '16px',
        //         // fontStretch: 'normal',
        //         // fontWeight: '500'
        //     })
        //     $directionsTextarea[0].style.setProperty('font-size', '16px', 'important');
        //     // $directionsTextarea[0].style.setProperty('font-stretch', 'normal', 'important');
        //     $directionsTextarea[0].style.setProperty('font-weight', '500', 'important');
        //     $directionsTextarea[0].style.setProperty('color', '#252525', 'important');

       
        // var txtDirections = $directionsTextarea.text();
    // }

    // setTimeout(applyCSSToInaccessibleIframe, 500)
})