$(document).ready(function () {
    var Modal = {};
    var $modals = $('.modal-container');

    $modals.each(function (i, el) {
        var $closeBtn = $(el).find('.modal-header button.modal-close');
        $closeBtn.appendTo($(el).find('.modal'));

        $buttons = $(el).find('.modal-footer button'); // THIS IS WHAT CLOSES MODALS
        $buttons.on('click', function () {
            if(!$(this).hasClass('cancel-modal-close'))
                $closeBtn.click();
        })
    });

    Modal.responsiveDialogCentering = function () {
        if ($(window).width() < 768) {
            $('.modal-dialog').removeClass('modal-dialog-centered');
        } else {
            if (!$('.modal-dialog').hasClass('modal-dialog-centered'))
                $('.modal-dialog').addClass('modal-dialog-centered');
        }
    }
    // TODO: Use fixed bottom divs to transport these around ( havent applied to all elements)
    Modal.fixedHeaderAndFooter = function () {
        $modals.each(function (i, el) {
            $modal = $(el);
            if ($(window).width() < 768) {
                $modal.find('.modal-dialog').after($modal.find('.modal-content .modal-header .modal-title'));
                $modal.find('.modal-dialog').after($modal.find('.modal-content .modal-footer'));
                $modal.find('.fixed-bottom-dest-2').append($modal.find('.c-fixed-bottom'));
            } else {
                $modal.find('.modal-header').prepend($modal.find('.modal-title'));
                $modal.find('.modal-body').after($modal.find('.modal-footer'));
                $modal.find('.fixed-bottom-dest-1').append($modal.find('.c-fixed-bottom'));

            }
        });
    }

    $modals.find('.confirm-btn').on('mouseup keyup', function (e) {
        if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;
        var $modal = $(this).closest('.modal-container')
        if(Modal.isFormValid($modal)){            
            $(this).removeClass('cancel-modal-close')
        // cancel submissio - modal stays open and ingredient not added
        } else {      
            console.log('cancel modal close');      
            $(this).addClass('cancel-modal-close')
        }
    })

    Modal.isFormValid = function($modal){
        var $errors = $modal.find('.error');
        var formValid = true;
        $errors.each(function(i, el) {
            if($(el).text() !== '')
                formValid = false;
        })
        return formValid;
    }
    Modal.cleanForm = function($modal){
        $modal.find('input').val('');
        $modal.find('.error').text('')
        $modal.find('input').val('')
        $modal.find('select').children('option:eq(0)').prop('selected', true);
        $modal.find('textarea').val('');
        $modal.find('textarea').text('');
    }

    Modal.binds = function(){
        $(window).resize(Modal.responsiveDialogCentering);
        $(window).resize(Modal.fixedHeaderAndFooter);
            // REMOVE ALL ITEMS ON MODAL CLOSE
        $('.modal-container').on('mouseup', function(){
            var $modal = $(this);
            setTimeout(function() {
                var modalHidden = !$modal.find('.modal').hasClass('show');
                if(modalHidden) {
                    Modal.cleanForm($modal);
                }  
            }, 300);
        })
    }

    // INIT
    Modal.responsiveDialogCentering()
    Modal.fixedHeaderAndFooter();
    Modal.binds();

});