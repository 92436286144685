/*

    UI helper for the Glossary and Video pages


*/

var _Util;

var GlossaryComponent = function(){
    console.log('glossary component');
    var _this = this;
    this.initDependencies();
    this.$ = $('.glossary-component');
    this.noResultsMsg = 'There are no videos beginning in the letter ';
    this.displayNoResultsMsg();
    this.binds();
    setTimeout(_this.addLetterSeparators(), 50);
};

GlossaryComponent.prototype.initDependencies = function(){
    _Util = Util;
}

// Add no results msg on containers with no results
GlossaryComponent.prototype.displayNoResultsMsg = function () {
    $('.letter-content').each(function (i, el) {
        if (!$(el).find('.row').children().length) {
            var letter = $(el).attr('id').toUpperCase();
            $(el).append('<p>' + this.noResultsMsg + letter + '</p>')
        }
    })
}

// Add separators next to letters on top links, but no on the last el in the row
// ie A | B | C
//    D | E | D
GlossaryComponent.prototype.addLetterSeparators = function () {
    var $letters = $('.search-bar .letter');
    $letters.ready(function () {
        $letters.removeClass('separator-right');
        _Util.tagLastElementsInRow($letters);
        $('.search-bar .letter:not(.last-element)').addClass('separator-right');
    })
}

GlossaryComponent.prototype.binds = function(){
    $(window).resize(this.addLetterSeparators.bind(this));
}

