var Sticky;
$(document).ready(function(){

    Sticky = function(selector, conditions, enabledCB, disabledCB){
        console.log(selector);
        var $window = $(window),
        $stickyEl = $(selector),
        elTop = $stickyEl.offset().top;
    
        $window.scroll(function() {
            if($window.scrollTop() > elTop){
                enabledCB();
            } else {
                disabledCB()
            }
            
        });
    }
});

