var RecipeSaveAsValidation;
$(document).ready(function(){
    var _Validation = Validation.getInstance();

    RecipeSaveAsValidation = function(){
        this.$ = $('#modal-save-as');
        this.$recipeName = this.$.find('#recipe-name-textarea');
        this.$submitBtn = this.$.find('.btn-lg-primary');

        this.binds();
    }
    RecipeSaveAsValidation.prototype.name = function () {
        var errFound = false;
        var errMsg = '';

        if(this.$recipeName.val() === '') {
            errFound = true;
            errMsg = 'Please enter a recipe name.';
        }

        _Validation.handleError(errFound, errMsg, this.$recipeName);
    }

    RecipeSaveAsValidation.prototype.all = function () {
        this.name();
    }

    RecipeSaveAsValidation.prototype.binds = function(){
        var _this = this;

        this.$recipeName.on('blur', function(){
            _this.name()
        });
        this.$submitBtn.on('mousedown keydown', function(){
            _this.all();
        })
    }

    // INIT
    new RecipeSaveAsValidation();
})