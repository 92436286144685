var Router = function(routeDictionary){
    this.initRoutes(routeDictionary)
}

Router.prototype.initRoutes  = function(routeDictionary) {
    for(var path in routeDictionary) {
        var viewController = routeDictionary[path];
        this.setupRoute(path, viewController);
    }
}

Router.prototype.setupRoute = function(path, viewController) {
    if(window.location.href.includes(path)) {
        new viewController();
    }    
}