$(document).ready(function () {
    var _EventEmitter = EventEmitter.getInstance();
    
    // TODO: Place as an feature function in pagination-component.js
    function insertLineOnEachRow(itemsSelector) {
        $lastElements = $('.pagination-component .last-element');
        $lastElements.each(function (i, el) {
            if (i != $lastElements.length - 1) {
                $(el).after('<div class="line"></div>');
            }
        })
    }

    function makeLinesFullWidth() {
        var $container = $('.list');
        $lines = $container.find('.line');
        var containerWidth = $container.outerWidth(true);
        var marginLeft = parseInt($container.css('marginLeft'));
        var paddingLeft = parseInt($container.css('paddingLeft'));
        var offset = marginLeft + paddingLeft;
        $lines.css({
            "width": containerWidth,
            "marginLeft": offset * -1
        })
    }

    function renderLines(){
        $('.list').find('.line').remove();
        Util.tagLastElementsInRow($('.book-card').not('.placeholder'));
        insertLineOnEachRow();
        makeLinesFullWidth();
    }

    _EventEmitter.subscribe('cards-rendered-after-ajax', renderLines);
    $(window).resize(renderLines);
    $('body').on('click', renderLines);
    // INIT
    // renderLines();
})