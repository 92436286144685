var CacheManager = (function(){
    var instance;
    var _Util = Util;
    var _CacheManager = function(options){


        this.clearAllData = function(){
            localStorage.clear();
        }

        this.getAllData = function(){
            var cachedData = [];
            var keys = Object.keys(localStorage);
            keys.forEach(function(key){
                var item = {};
                item.key = key;
                item.value = localStorage.getItem(key);
                if(item.value) cachedData.push(item);
            });
            return cachedData;
        }
        this.setValue = function(key, value){
            localStorage.setItem(key, JSON.stringify(value));
            // console.log(value)
            // console.log([])
            return value;
        }

        this.getValue = function(key){
            return (_Util.isJSON(localStorage.getItem(key)))  
                ? JSON.parse(localStorage.getItem(key))
                : localStorage.getItem(key)
        }
    }

    return {
        getInstance: function(options) {
            if(typeof instance === 'undefined') {
                instance = new _CacheManager(options);
            }
            return instance;
        }
    }
})();

// TEST
// var _CacheManager = CacheManager.getInstance();
// _CacheManager.clearAllData()
// var data = _CacheManager.getAllData();
// _CacheManager.setValue('arr', []);
// _CacheManager.setValue('arr', ['filter1', 'fitler2']);
// _CacheManager.setValue('my', 'val');

// var data = _CacheManager.getAllData();
// console.log(data);
// console.log(_CacheManager.getValue('arr'));
// console.log(_CacheManager.getValue('arr')[0]);


