var EditMenuValidation;
$(document).ready(function(){
    var _Validation = Validation.getInstance();

    EditMenuValidation = function(){
        this.binds();
    }
    EditMenuValidation.prototype.name = function () {
        var errFound = false;
        var errMsg = '';
        var $this = $('.menu-title-edit');

        if($this.val() === '') {
            errFound = true;
            errMsg = 'Please enter a menu name.';
        }

        _Validation.handleError(errFound, errMsg, $this);
    }

    EditMenuValidation.prototype.guests = function () {
        var errFound = false;
        var errMsg = '';
        var $this = $('.guests-edit');

        if($this.val() === '') {
            errFound = true;
            errMsg = 'Please enter # of guests.';
        } else if(isNaN( $this.val() ) ) {
            errFound = true;
            errMsg = 'Please enter a numeric value.';
        } else if($this.val() < 1 ) {
            errFound = true;
            errMsg = 'Please include at least one guest.';
        }

        _Validation.handleError(errFound, errMsg, $this);
    }

    EditMenuValidation.prototype.recipeList = function () {
        var errFound = false;
        var errMsg = '';

        $ingredientList = $('.ingredients-list');
        $ingredients = $ingredientList.find('.item').not('.placeholder').not('.hidden');
        if($ingredients.length === 0) {
            errFound = true;
            errMsg = 'Please add at least one recipe.';
        }
        _Validation.handleError(errFound, errMsg, $ingredientList);
    }


    EditMenuValidation.prototype.all = function () {
        this.name();
        this.guests();
        this.recipeList();
    }

    EditMenuValidation.prototype.binds = function(){
        var _this = this;

        $('.menu-title-edit').on('blur', function(){ _this.name() });
        $('.guests-edit').on('blur',function(){ _this.guests() });
        $('.add-recipe-btn').on('mouseup', function(){ _this.recipeList() });

        $('.save-menu-btn').on('mousedown keydown', function(){ _this.all() });
    }



    // INIT
    new EditMenuValidation()

})