var Tokenizer = (function(){
    var instance;
    
    var token;
    var _Tokenizer = function(){
        token = '12345';
    }

    _Tokenizer.prototype.getToken = function(){
        return '12345'
        if(this.tokenExpired()) this.refreshToken();
        return token;
    }
    _Tokenizer.prototype.refreshToken = function(){
        // bla bla refresh token
    }

    _Tokenizer.prototype.tokenExpired = function(){
        return false;
    }

    return {
        getInstance: function(){
            if(!instance)
                instance = new _Tokenizer();

            return instance;
        }
    }
})();

var RecipePresenter = (function(){
    var instance;
    var onEditPage = ( $('.yield-control select').length && $('.serving-control select').length);
    var onGetPage = ( !$('.yield-control select').length || !$('.serving-control select').length);
    var existingRecipeId = -1;
    var recipeExists = false
    var _UnitCodes;
    var _AjaxRecipe;
    var _LikeBtn;
    var _EditRecipeValidation;
    var _Util;
    var _GenericAlert;
    var _EventEmitter;
    var _SaveAsModal;
    var _UIBehaviors;
    var _ModalCore;
    var _Tokenizer;

    var _RecipePresenter = function(){
        this.savedRecipeId;
        this.savedRecipeName;
        this.isGroupRecipe;
        this.initDependencies();
    }

    _RecipePresenter.prototype.initRecipeCardLikeListener = function(){
        var _this = this;
        // handle clicks to STAR LIKE btn
        console.log('subscribe');
        _EventEmitter.subscribe('recipe-card-like-btn:clicked',function($likeBtn){
            _this.processRecipeCardLike($likeBtn);
        });
    }

    _RecipePresenter.prototype.init = function(){
        this.subscribeToEvents();
        this.initUI();
        this.binds();
    }

    _RecipePresenter.prototype.initDependencies = function(){
        _UnitCodes = new UnitCodes().getTable();
        _AjaxRecipe = new AjaxRecipe();
        _LikeBtn = LikeBtn;
        _EditRecipeValidation = new EditRecipeValidation()
        _Util = Util;
        _GenericAlert = new GenericAlert();
        _EventEmitter = EventEmitter.getInstance();
        _SaveAsModal = SaveAsModal;
        _UIBehaviors = new UIBehaviors();
        _ModalCore = new ModalCore();
        _Tokenizer = Tokenizer.getInstance();
    }

    _RecipePresenter.prototype.initUI = function(){
        var _this = this;
        this.yieldSelectInit();
        this.yieldSelectSetDefault();
        this.servingSelectInit();
        this.servingSelectSetDefault();
        this.initLikeButtons();
    }
    // Setup all the yield selection options by looping through the unit codes table
    _RecipePresenter.prototype.yieldSelectInit = function(){
        $unitSelect = $('.generate-units');
        $unitSelect = $('#yield-unit-select');
        $( '#unit' ).empty(  );
        for (var i = 0; i < _UnitCodes.length; i++) {
            var unitCode = _UnitCodes[i];
            var dbIndex = parseInt(i)+1;
            var optionHTML = '<option value="'+dbIndex+'">'+unitCode.single+'</option>';
            $unitSelect.append($(optionHTML));
        }
    }
    // Set the selected yield 
    _RecipePresenter.prototype.yieldSelectSetDefault = function() { 
        
        $unitSelect = $('#yield-unit-select');
        var selectedUnit = $unitSelect.data('selected');
        if(selectedUnit) {
            $unitSelect.find('option[value="' + selectedUnit + '"]').prop('selected', true);  
            $unitSelect.trigger('change')     
        }
    }

    _RecipePresenter.prototype.servingSelectInit = function(){
        $unitSelect = $('#serving-unit-select');
        for (var i = 0; i < _UnitCodes.length; i++) {
            var unitCode = _UnitCodes[i];
            var dbIndex = parseInt(i)+1;
            var optionHTML = '<option value="'+dbIndex+'">'+unitCode.single+'</option>';
            $unitSelect.append($(optionHTML));
        }
    }
    _RecipePresenter.prototype.servingSelectSetDefault = function() {  
        $unitSelect = $('#serving-unit-select');
        var selectedUnit = $unitSelect.data('selected');
        if(selectedUnit) {
            $unitSelect.find('option[value="' + selectedUnit + '"]').prop('selected', true);   
            $unitSelect.trigger('change')
        }     
    }

    _RecipePresenter.prototype.processRecipeCardLike = function($likeBtn){
        var $recipeCard = $likeBtn.closest('.card')
        var recipeId = $recipeCard.find('.id').text();
        var likeStatus = $recipeCard.find('.liked').text();
        if(likeStatus != 1 && likeStatus != 0) return;
        if(recipeId == '') return;

        this.AjaxLikeRecipe(recipeId, likeStatus);
    }

    _RecipePresenter.prototype.processRecipeLike = function($likeBtn){
        var recipeId = $('#nRecipeId').text();
        var likeStatus = $('#nLikedStatus').text();
        if(likeStatus != 1 && likeStatus != 0) return;
        if(recipeId == '') return;
        console.log(likeStatus);

        if(likeStatus == 1) likeStatus = 0
        else likeStatus = 1;

        this.AjaxLikeRecipe(recipeId, likeStatus);
    }


    _RecipePresenter.prototype.AjaxLikeRecipe = function(recipeId, likeStatus){
        var _this = this;
        var recipeId = (typeof recipeId !== 'undefined') ? recipeId : $('#nRecipeId').text();
        var likeStatus = (typeof likeStatus !== 'undefined') ? likeStatus : 1;
        if(likeStatus == null || likeStatus === '') likeStatus = 1;

        // LIKE STATUS IS WHAT YOU WANT TO SET IT TO
        _AjaxRecipe.like({
            params: {
                authToken: _Tokenizer.getToken(),
                likeRecipe: JSON.stringify({
                    nRecipeId: recipeId,
                    nLikeStatus: likeStatus
                })
            }
        }).then(function(res){
            _this.updateLikeBtn(likeStatus);
            _this.updateLikeHTML(likeStatus);
            _EventEmitter.emit('filters-presenter:refresh-counts');

        })
    }

    _RecipePresenter.prototype.updateLikeHTML = function(likeStatus){
        $likeStatus = $('#nLikedStatus').text(likeStatus);
    }
    _RecipePresenter.prototype.updateLikeBtn = function(likeStatus){
        console.log(likeStatus);//debugger;
        $likeBtn = $('.like-btn');
        if(likeStatus == 1) {
            
            $likeBtn.attr("aria-live", "assertive");
            $likeBtn.text('Starred');
           // $likeBtn.attr('aria-label','Starred');
            
            setTimeout(function() {
                $likeBtn.attr("aria-live", "");
            }, 100);
        }
        else {
            $likeBtn.attr("aria-live", "assertive");
            $likeBtn.text('Star');
           // $likeBtn.attr('aria-label','assertive');
            
            setTimeout(function() {
                $likeBtn.attr("aria-live", "");
            }, 100);
        }
    }

    _RecipePresenter.prototype.scrapeMeasurementControlValues = function(){
        var $yieldCtrl = $('.yield-control');
        var $servingCtrl = $('.serving-control');

        // GET AMOUNTS
        var yieldAmt = $('.yield-control .yield-amt').val();


        var servingSizeAmt;
        var numOfServings;
        if (onGetPage) {
            servingSizeAmt = $('.serving-control .amt').text();
            var x = $('.number-of-servings-edit').val();
            if( x )
                numOfServings = _Util.fractionStrToDecimal(x);
            else
                numOfServings = 1;
        } else if(onEditPage){
            servingSizeAmt = $('.serving-control .serving-amt').val();
            numOfServings = $('.servings .num-of-servings').text();
        }

        // Get UNIT DATA
        var yieldUnitCode = $yieldCtrl.find('select').attr('data-selected');
        var yieldUnitName = $yieldCtrl.find('select option[value="'+yieldUnitCode+'"]:first').text();
        var servingUnitCode;
        var servingUnitName;

        if(onGetPage) {
            servingUnitCode = $servingCtrl.find('.serving-unit').attr('data-unit-code');
            servingUnitName = $servingCtrl.find('.serving-unit').text();
            if( servingUnitName == 'No Unit' ) servingUnitName = 'Each';
        } else if (onEditPage) {
            servingUnitCode = $servingCtrl.find('#serving-unit-select').attr('data-selected');
            servingUnitName = $servingCtrl.find('#serving-unit-select option[value="'+servingUnitCode+'"]').text();
        }
        return {
            yieldAmt: yieldAmt,
            yieldUnitCode: yieldUnitCode,
            yieldUnitName: yieldUnitName,
            servingSizeAmt: servingSizeAmt,
            servingUnitCode: servingUnitCode,
            servingUnitName: servingUnitName,
            numOfServings: numOfServings,
        }
    }

    _RecipePresenter.prototype.scrapeGetRecipeInfo = function(){
        var measurementData = this.scrapeMeasurementControlValues();
        var dtotalYieldAmount = parseFloat( measurementData.yieldAmt );
        var nTotalYieldUnitId = parseInt( measurementData.yieldUnitCode );
        var dServingSizeAmount = 1;
        var nServingsizeUnitId = parseInt( measurementData.servingUnitCode );
        var dNumberOfServings = parseInt( measurementData.numOfServings );

        var nRecipeTypeId = 1; // 2 user created - 3 user edited - 4 migrated from old site
        var nRecipePrivacyTypeId = 1; // 1 private - 2 shared
        var nReferenceRecipeId = 0; // we dont know what this does
        var nRecipeId = $('#nRecipeId').text();
        var recipeImg = _Util.fileName( $('.product-img img').attr('src') );
        var vcRecipeName = $('#vcName').text();
        var txtDirections = $('.recipe-directions').html();
        var nCourseId = 1;
        var nCuisineId = $('#nCuisineId').text();
        var nCookingMethodId = $('#nCookingMethodId').text();
        var nTextBookId = 1;
        var nViewingbyId = $('#nViewingbyId').text();
        var nIsScalable = 1;
        var nChecksum = $('#nChecksum').text();
        var nCalBakerPe = 1;
        var dBakersPercentage = $('#bBakersPercent').text();
        var ingredientsList = [];
        var $ingredients = $('.ingredients-list .item').not('.placeholder').not('.hidden')
        var checkbox = document.getElementById("grouprecipe");
        var isGroupRecipe = checkbox.value ? 1 : 0;
        this.isGroupRecipe = isGroupRecipe;
        console.log('Checkbox value:', isGroupRecipe);
        var isLocked = 0;
        var nGroupId = 0 ;
        var parentId = 0;

        $ingredients.each(function(i, el){
            var $ingredient = $(el);
            var isSublist = $ingredient.hasClass('sub-list');
            var ingredientSublisted = $ingredient.closest('.sub-list').not('.placeholder').length > 0;
            var simpleGroupName = '';
            var simpleGroupParentName = '';
            
            if(isSublist) 
                simpleGroupName = $ingredient.attr('data-simplegroupname');
            if(ingredientSublisted)
                simpleGroupParentName = $ingredient.closest('.sub-list').attr('data-simplegroupname');

            var ingredientObj = {
                nRecIngId: $ingredient.attr('data-nRecIngId'),
                vcName: $ingredient.attr('data-name'),
                vcIngredientAmount: $ingredient.attr('data-amt'),
                nIngredientUnitId: $ingredient.attr('data-unit'),
                txtPrep: $ingredient.attr('data-txtPrep'),
                bIsSubRecipe: $ingredient.attr('data-bIsSubRecipe'),
                nIngredientGroupId: $ingredient.attr('data-nIngredientGroupId'),
                vcGroupName: $ingredient.attr('data-category'),
                nOrder: $ingredient.attr('data-nOrder'),
                nUserId: $ingredient.attr('data-nUserId'),
                dBakersPercentage: 1,

                simpleGroupName: simpleGroupName,
                simpleGroupParentName: simpleGroupParentName,
            }
            ingredientsList.push(ingredientObj);
        })

        return {     
            nRecipeTypeId: nRecipeTypeId,
            nRecipePrivacyTypeId: nRecipePrivacyTypeId,
            nReferenceRecipeId: nReferenceRecipeId,
            nRecipeId: nRecipeId,
            recipeImg: recipeImg,
            vcRecipeName: vcRecipeName,
            txtDirections: txtDirections,
            dtotalYieldAmount: dtotalYieldAmount,
            nTotalYieldUnitId: nTotalYieldUnitId,
            dServingSizeAmount: dServingSizeAmount,
            nServingsizeUnitId: nServingsizeUnitId,
            dNumberOfServings: dNumberOfServings,
            nViewingbyId: nViewingbyId,
            nIsScalable: nIsScalable,
            nChecksum: nChecksum,
            nCalBakerPe: nCalBakerPe,
            dBakersPercentage: dBakersPercentage,
            nCourseId: nCourseId,
            nCuisineId: nCuisineId,
            nCookingMethodId: nCookingMethodId,
            nTextBookId: nTextBookId,
            oIngredientsList: ingredientsList,
            isGroupRecipe: isGroupRecipe,
            isLocked: isLocked,
            nGroupId: nGroupId,
            parentId: parentId

        }
    }
    
    _RecipePresenter.prototype.scrapEditRecipeInfo = function(){
        var nRecipeTypeId = 1; // 2 user created - 3 user edited - 4 migrated from old site
        var nRecipePrivacyTypeId = 1; // 1 private - 2 shared
        var nReferenceRecipeId = 0; // we dont know what this does

        var nRecipeId = $('#nRecipeId').text();
        var vcRecipeName = $('.recipe-name').val();

        var dNumberOfServings = $('.num-of-servings').text();
        var nCuisineId = $('.cuisine-checkboxes input:checked').data('courseid');
        var nCookingMethodId = $('.cooking-checkboxes input:checked').data('courseid');
        var nTextBookId = $('#nTextBookId').text();
        if(nTextBookId == '' || typeof nTextBookId == 'undefined') nTextBookId = '0';
        var nViewingbyId = $('#nViewingbyId').text();
        if(nViewingbyId == '' || typeof nViewingbyId == 'undefined') nViewingbyId = '0';

        var nIsScalable = $('#nIsScalable').text();
        var nChecksum = $('#nChecksum').text();
        if(nChecksum == '' || typeof nChecksum == 'undefined') nChecksum = '0';

        var nCalBakerPe = 1;
        var dBakersPercentage = $('#bBakersPercent').text();

        // Get yield and serving units

        var yieldUnitCode = $('.yield-control').find('select').attr('data-selected');
        var servingUnitCode = $('.serving-control').find('select').attr('data-selected');
        var dtotalYieldAmount = $('#yield-input').val();
        var nTotalYieldUnitId = yieldUnitCode;
        var dServingSizeAmount = $('#serving-input').val();
        var nServingsizeUnitId = servingUnitCode;

        // Get text directins
        //var iframe = document.getElementById('tiny-mce-dir_ifr');            
        //var $innerDoc = $(iframe.contentDocument) || $(iframe.contentWindow.document);
        var $directionsTextarea = document.getElementById('tiny-mce-dir'); //$innerDoc.find('#tinymce');
        var txtDirections = $directionsTextarea.innerHTML;

        // Get Filters
        var courseSelectClasses = ['.course', '.cooking', '.cuisine'];
        var filterIds = [ [], [] , [] ];
        courseSelectClasses.forEach(function(className, i) {
            var $selectedCourses = $(className + '-checkboxes input:checked');
            $selectedCourses.each(function(j, el){
                var courseId = $(el).attr('data-courseid');
                filterIds[i].push(courseId);                
            });

        })
        var nCourseId = filterIds[0];
        var nCookingMethodId = filterIds[1];
        var nCuisineId = filterIds[2];
        var checkbox = document.getElementById("grouprecipe");
        var isGroupRecipe = checkbox.checked ? 1 : 0;
        this.isGroupRecipe = isGroupRecipe;
        console.log('Checkbox value:', isGroupRecipe);
        var isLocked = $('#nRecipeLocked').text() ? $('#nRecipeLocked').text() : 0;
        var nGroupId = $('#nRecipeGroupId').text() ? $('#nRecipeGroupId').text() : 0;
        var parentId = $('#nRecipeParentId').text() ? $('#nRecipeParentId').text() : 0;


        // Get the ingredients
        var ingredientList = [];
        var $ingredients = $('.ingredients-list .item').not('.placeholder').not('.hidden')
        $ingredients.each(function(i, el){
            var $ingredient = $(el);

            var isSublist = $ingredient.hasClass('sub-list');
            var ingredientSublisted = $ingredient.parents('.sub-list').not('.placeholder').length > 0;
            var simpleGroupName = '';
            var simpleGroupParentName = '';

            if(isSublist) 
                simpleGroupName = $ingredient.attr('data-simplegroupname');
            if(ingredientSublisted)
                simpleGroupParentName = $ingredient.closest('.sub-list').attr('data-simplegroupname');

            var ingredientObj = {
                nRecipeId: $ingredient.attr('data-nrecipeid'),
                nUserId: $ingredient.attr('data-nuserid'),
                nRecIngId: $ingredient.attr('data-nrecingid'),
                vcName: $ingredient.attr('data-name'),
                vcIngredientAmount: $ingredient.attr('data-amt'),
                nIngredientUnitId: $ingredient.attr('data-unit'),
                txtPrep: $ingredient.attr('data-txtprep'),
                nOrder: $ingredient.attr('data-norder'),
                dBakersPercentage: 1,
                nIngredientGroupId: $ingredient.attr('data-ncategoryid'),
                vcGroupName: $ingredient.attr('data-category'),
                bIsSubRecipe: $ingredient.attr('data-bissubrecipe'),
                
                simpleGroupName: simpleGroupName,
                simpleGroupParentName: simpleGroupParentName,
            }
            ingredientList.push(ingredientObj);
        })

        return {     
            nRecipeId: nRecipeId,
            vcRecipeName: vcRecipeName,
            txtDirections: txtDirections,
            dtotalYieldAmount: dtotalYieldAmount,
            nTotalYieldUnitId: nTotalYieldUnitId,
            dServingSizeAmount: dServingSizeAmount,
            nServingsizeUnitId: nServingsizeUnitId,
            dNumberOfServings: dNumberOfServings,
            nViewingbyId: nViewingbyId,
            nIsScalable: nIsScalable,
            nChecksum: nChecksum,
            nCalBakerPe: nCalBakerPe,
            nCourseId: nCourseId,
            nCuisineId: nCuisineId,
            nCookingMethodId: nCookingMethodId,
            nTextBookId: nTextBookId,
            nRecipeTypeId: nRecipeTypeId,
            nRecipePrivacyTypeId: nRecipePrivacyTypeId,
            nReferenceRecipeId: nReferenceRecipeId,
            // nRecIngId: nRecIngId,
            // dBakersPercentage: dBakersPercentage,
            isGroupRecipe: isGroupRecipe,
            isLocked: isLocked,
            nGroupId: nGroupId,
            parentId: parentId,
            
            oIngredientsList: ingredientList
        }
    }
    
    _RecipePresenter.prototype.AjaxSaveRecipe = function(){
        var recipeInfo = this.scrapEditRecipeInfo();
        var recipeJson =  JSON.stringify(recipeInfo);
        var _this = this;
        //console.log(recipeInfo);
        _AjaxRecipe.save({
            params: {
                authToken: '12345',
                contentType: 'application/json',
                recipe: recipeJson
            }
        }).then(function(res){
            console.log(res);
            
            var data = JSON.parse(res).Data;
            if(data) {
                _this.savedRecipeId = JSON.parse(data.recipe).recipeId;
                if(typeof _this.savedRecipeId == 'undefined') _this.savedRecipeId = JSON.parse(data.recipe); 


                var recipeName = _Util.truncateStr(recipeInfo.vcRecipeName, 45);
                _GenericAlert.show({
                    title: 'Recipe Saved',
                    msg: recipeName + ' has been saved successfully.'
                });
            }
        })
    }

    _RecipePresenter.prototype.AjaxDeleteRecipe = function(){
        var recipeId = $('#nRecipeId').text();
        
        _AjaxRecipe.delete({
            params: {
                authToken: '12345',
                recipeId: recipeId
            }
        }).then(function(res){  
            var noError =  _Util.isJSON(res);
            
            
            if(noError) {
                _GenericAlert.show({
                    title: 'Recipe Deleted',
                    msg: 'Your recipe has been deleted.'
                })
                setTimeout(function(){
                    window.location.href = SITE_URL + 'recipe/myRecipes'; 
                },1000)
            }       
        })
    }

    _RecipePresenter.prototype.checkIfExists = function(){
        var recipeName = $('.recipe-name').val();
        if(!recipeName) recipeName = $('.recipe-title').val();
        
        _AjaxRecipe.checkIfExists({
            params: {
                recipeName: recipeName
            }
        });
    }

    _RecipePresenter.prototype.updateNOrderOfItems = function() {
        $ingredients = $('.ingredients-list .item').not('.placeholder').not('.hidden');
        $ingredients.each(function(i, el) {
            $(el).attr('data-norder', i);
        });
    } 

    // Add like button functionality to existing like buttons
    _RecipePresenter.prototype.initLikeButtons = function(){
        $likeBtns = $('.like-icon-btn');
        $likeBtns.each(function(i, el){
            var likeBtn = new _LikeBtn( $(el) );
        });
    }

    _RecipePresenter.prototype.ErrorScanBeforeSave = function(){
        _EditRecipeValidation.all();
        var errorsOnPage = false;
        $('.container .error').not('.main-error').each(function(i, el) {
            if( $(el).text() !== '') 
                errorsOnPage = true;
        })
        if(errorsOnPage)
            $('.product-controls .error').text('Please correct the errors below before saving.')
        else {
            $('.product-controls .error').text('');
            this.AjaxSaveRecipe()
        }
    }

    _RecipePresenter.prototype.redirectToNewRecipe = function(){
        if(typeof this.savedRecipeId !== 'undefined') {
            window.location.href = SITE_URL + 'recipe/getMyRecipe/' + this.savedRecipeId; 
        }
    }

    _RecipePresenter.prototype.adjustCustomDropdowns = function($dropdown){
        $dropdown.width($dropdown.width() + 30);
    }

    _RecipePresenter.prototype.handleSaveAs = function(){
        this.scrapeAndSaveNewRecipeName();

        if(this.isSaveAsModalValid()) {
            this.AjaxRecipeLimitReached();
        }
    }

    _RecipePresenter.prototype.isSaveAsModalValid = function(){
        var $modal = $('#modal-save-as').closest('.modal-container');
        return _ModalCore.isFormValid($modal);
    }


    _RecipePresenter.prototype.scrapeAndSaveNewRecipeName = function(){
        if(this.isSaveAsModalValid()) {
            var recipeName = $('#recipe-name-textarea').val();
            this.savedRecipeName = recipeName;
            return recipeName;
        }
    };

    // Check if the max limit of 500 recipes has been reached
    _RecipePresenter.prototype.AjaxRecipeLimitReached = function(){
        var _this = this;
        _AjaxRecipe.recipeLimitReached({
            authToken: '12345'
        })
        .then(_this.onRecipeLimitResponse.bind(_this))
    }

    _RecipePresenter.prototype.onRecipeLimitResponse = function(res) {
        try{
            var res = JSON.parse(res);
            var limitReached = res.Data[0];
            if(limitReached) {
                _GenericAlert.show({
                    title: 'Recipe Limit Reached',
                    msg: 'My Recipes is full. Please remove recipes from your My Recipes to add more.'
                });
            } else {
                this.checkIfMyRecipeExists()
            }
        }
        catch( e )
        {
                this.checkIfMyRecipeExists();
        }
    }

    _RecipePresenter.prototype.checkIfMyRecipeExists = function($this){
        console.log(this.savedRecipeName);
        var _this = this;
        console.log(this.savedRecipeName)
        console.log(_AjaxRecipe)
        _AjaxRecipe.checkIfMyRecipeExists({
            authToken: '12345',
            params: {
                recipeName: this.savedRecipeName,
                thisIs: 'that'
            }
        })
        .then( _this.onRecipeExistsResponse.bind(_this) );
    }

    _RecipePresenter.prototype.onRecipeExistsResponse = function(res){
        console.log(res)
        var recipeId = JSON.parse(res).Data.RecipeId;
        var isNewRecipe = recipeId === -1;
        console.log(this);
        if(isNewRecipe) {
            existingRecipeId = -1;
            recipeExists = false;
            this.AjaxSaveAs();
        } else {
            recipeExists = true
            existingRecipeId = recipeId
            this.showOverwriteRecipeConfirmation();
            // Event listener for overwriteRecipeModal will redirect to 
            // this.AjaxSaveAs()
        } 
    }

    _RecipePresenter.prototype.showOverwriteRecipeConfirmation = function(){
        $('#alert-recipe-exists .recipe-name').text(this.savedRecipeName);
        $('#alert-recipe-exists').modal('show');
    }

    _RecipePresenter.prototype.AjaxSaveAs = function(){
        // get all recipe data from UI
        var recipeInfo = this.scrapeGetRecipeInfo();

        // Update title to the textarea value
        $form = _SaveAsModal.$.find('.modal-content');
        if(typeof this.savedRecipeName === 'undefined')
            this.savedRecipeName = $form.find('#recipe-name-textarea').val();
        recipeInfo.vcRecipeName = this.savedRecipeName;
        
        // format data & send request
        var recipeJson = JSON.stringify(recipeInfo);
        _AjaxRecipe.saveAs({
            authToken: '12345',
            params: {
                contentType: 'application/json',
                recipe: recipeJson,
                currentId: recipeInfo.nRecipeId,
                myRecipeId: existingRecipeId,
                exists: recipeExists,
            }
        }).then( this.onRecipeSaveAsResponse.bind(this) );
    }

    _RecipePresenter.prototype.onRecipeSaveAsResponse = function(res) {
        var myRecipeId = JSON.parse(res).Data.nRecipeId;
        if(myRecipeId){
            this.savedRecipeId = myRecipeId;

            var data = JSON.parse(res).Data;
            var truncatedRecipeName = _Util.truncateStr(this.savedRecipeName, 45);
            var isGroupRecipe = this.isGroupRecipe;
            if(data && isGroupRecipe == 0) {
                _GenericAlert.show({
                    title: 'Recipe Saved',
                    msg: '"'+ truncatedRecipeName + '" has been saved successfully to <strong>My Recipes</strong>.'
                });
            } else {
                _GenericAlert.show({
                    title: 'Recipe Saved',
                    msg: '"'+ truncatedRecipeName + '" has been saved successfully as Group Recipe to <strong>My Recipes</strong>.'
                });
            }
        }
    }

    _RecipePresenter.prototype.binds = function(){
        var _this = this;
        $('.save-recipe-btn').on('click', function(){ _this.ErrorScanBeforeSave() });
        $('#alert-generic').on('click', function(){ _this.redirectToNewRecipe(); })
        $('.like-btn').on('click', function(){ _this.processRecipeLike() });
        $('.delete-recipe-btn').on('click', function(){ _this.AjaxDeleteRecipe() });
        $(document).on('mouseup touchend', '.item', function(){ _this.updateNOrderOfItems() });
        $(document).on('mouseup touchend', '.confirm-btn', function(){ _this.updateNOrderOfItems() });
        $(document).on('mouseup touchend', '.delete-sublist-btn', function(){ _this.updateNOrderOfItems() });
        $('input.select-one').on('click', function(){
            _UIBehaviors.allowOnlyOneChecked.call(this, '.select-one-container', '.select-one'); // specify container and select one input class
        });
        // Save As
        $('.save-as-confirm-btn').on('click', this.handleSaveAs.bind(this) );
        $('#alert-recipe-exists .replace-recipe-btn').on('click', function(){
            _this.AjaxSaveAs();
        });
    }

    _RecipePresenter.prototype.subscribeToEvents = function(){
        var _this = this;
        _EventEmitter.subscribe('dynamic-width-dropdown-resized',function($dropdown){
            console.log('adjust custom dropdowns');
            _this.adjustCustomDropdowns($dropdown);
        })
    }

    return {
        getInstance: function(){
            if(!instance) instance = new _RecipePresenter();
            return instance;
        }
    }
})();
