$(document).ready(function () {

    var _ModalCore = new ModalCore();
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();

    
    UpdateIngredientModal = {};
    UpdateIngredientModal.$ = $('#modal-recipe-edit-ingredient');
    UpdateIngredientModal.rowChild;

    UpdateIngredientModal.refreshInstance = function () {
        UpdateIngredientModal.$ = $('#modal-recipe-edit-ingredient');
    }
    UpdateIngredientModal.getFormData = function () {
        var $form = UpdateIngredientModal.$.find('.modal-content');
        var data = {}; 
        data.ingredientId = $form.attr('data-nrecingid');
        data.name = UpdateIngredientModal.$.find('#ingredient_edit').val();
        data.prepNotes = UpdateIngredientModal.$.find('#prep-notes_edit').val();
        data.amt = UpdateIngredientModal.$.find('#amt_edit').val();
        data.unit = UpdateIngredientModal.$.find('#unit_edit').val();
        data.parentName = UpdateIngredientModal.$.find('#sublist-dropdown_edit').val();
        data.sublistName = UpdateIngredientModal.$.find('#sublist-dropdown_edit').val();
        console.log(data);
        return data;
    }

    UpdateIngredientModal.updateIngredient = function () {
        UpdateIngredientModal.refreshInstance();
        var formData = UpdateIngredientModal.getFormData();
        _MenuAndIngredientList.updateIngredient(UpdateIngredientModal.rowChild, formData, {
            newIngredient: false
        });
    }

    UpdateIngredientModal.deleteIngredient = function ( tgt ) {
        // var $form = UpdateIngredientModal.$.find('.modal-content');
        // var ingredientId = $form.attr('data-nrecingid');

        // var $delTarget = $( 'div.ingredients-list' ).find( "div.item[data-name='']" );

        $(tgt).closest("div.item").remove(  );
        
    }

    UpdateIngredientModal.updateForm = function () {
        $form = UpdateIngredientModal.$.find('.modal-content');
        $ingredient = UpdateIngredientModal.rowChild.closest('.item');

        var id = $ingredient.attr('data-nrecingid');
        var name = $ingredient.attr('data-name');
        var amt = $ingredient.attr('data-amt');
        var unit = $ingredient.find('.unit').text();
        var prepNotes = $ingredient.attr('data-txtprep');
        var sublist = $ingredient.closest('.sub-list').attr('data-name');

        $form.attr('data-nrecingid', id);
        $form.attr('data-ingredientname', name);
        $form.find('#ingredient_edit').val(name);
        $form.find('#amt_edit').val(amt);
        var unitId = $form.find('#unit_edit option').filter(function () { return $(this).html() == unit; }).val();
        $form.find('#unit_edit').val(unitId);
        $form.find('#prep-notes_edit').val(prepNotes);
        $form.find('#sublist_edit').val(sublist);

        // DROPDOWN UPDATE
        var $sublists = $('.sub-list').not('.placeholder');
        var namesArr = [];
        $sublists.each(function (i, el) {
            namesArr.push($(el).attr('data-name'));
        })
        var $dropdown = UpdateIngredientModal.$.find('#sublist-dropdown');
        $dropdown.find('option:not(:first)').remove();

        namesArr.forEach(function (name) {
            $dropdown.append('<option value="' + name + '">' + name + '</option>');
        })
        $dropdown.val(sublist)
    }

    UpdateIngredientModal.$.find('.delete-ingredient').on('mouseup keyup', function (e) {
        if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;
        UpdateIngredientModal.deleteIngredient(_MenuAndIngredientList.theOpener);
    })

    UpdateIngredientModal.$.find('.btn-lg-primary').on('click', function (e) {
        if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;

        var $modal = UpdateIngredientModal.$.closest('.modal-container');
        if(_ModalCore.isFormValid($modal)){ 
            UpdateIngredientModal.updateIngredient();
        } 
    })

    $(document).on('click', '.item:not(".sub-list") .edit-toggle', function (e) {
        UpdateIngredientModal.refreshInstance();
        UpdateIngredientModal.rowChild = $(this);
        UpdateIngredientModal.updateForm();
    });
});