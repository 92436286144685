$(document).ready(function () {
    // function sendRecipeEmail() {
    //     var title = $('.recipe-title').text() + '\n\n';
    //     var ingredientsHeader = 'Ingredients\n\n';
    //     var ingredients = '';

    //     $ingredients = $('.item');
    //     $ingredients.each(function (i, ingredient) {
    //         var amt = $(ingredient).find('.amount p').text();
    //         var name = $(ingredient).find('.name p').text()
    //         if (!name) name = $(ingredient).find('.name a').text();

    //         ingredients += name + ' ' + amt + '\n';
    //     })

    //     var formattedBody = title + ingredientsHeader + ingredients;
    //     formattedBody = encodeURIComponent(formattedBody);
    //     window.location.href = "mailto:alex.padilla@allthingsmedia.com?"
    //         + "subject="
    //         + title
    //         + "&body="
    //         + formattedBody;
    // }

    // $('a.email').on('click', function (e) {
    //     e.preventDefault()
    //     e.stopPropagation();
    //     sendRecipeEmail();
    // })
});