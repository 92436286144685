 // NEW UNIT:  
    //      {unitName: "oz", unitIndex: 0, quantity: 7.92, quantityFraction: "7 ⁹⁄₁₀", fail: false}
    // CURRENT UNIT:
    //      {name: "oz", type: "mass", system: "english", quantity: 7.92, divisor: 1}
    //      
var SmartConvert =    
    (function() {

        var _SmartConvert = function(  ){};
        
            var instance;
            var debug = 0;
        _SmartConvert.prototype.init = function(  )
        {
        	this.input = {};
        	this.input.currentUnit = {};
        	this.input.currentUnit.name = null;
        	this.input.currentUnit.type = null;
        	this.input.currentUnit.system = null;
            this.input.currentUnit.quantity = null;
            this.input.currentUnit.divisor = null;

        	this.input.newUnit = {};
            this.input.newUnit.unitName = null;
        	this.input.newUnit.unitIndex = null;
            this.input.newUnit.quantity = null;
            this.input.newUnit.quantityFraction = null;
            this.input.newUnit.fail = false;
            this.input.newUnit.uglyFraction = false;

        	this.smartConvert = {};
        	this.smartConvert.english = {};
        	this.smartConvert.metric = {};

        	this.smartConvert.english.volume = {};
            this.smartConvert.english.volume1 = {};
            this.smartConvert.english.volume2 = {};
            this.smartConvert.english.volume3 = {};
        	this.smartConvert.metric.volume = {};
        	this.smartConvert.english.mass = {};
        	this.smartConvert.metric.mass = {};
            this.smartConvert.english.whole = {};

        	this.smartConvertIndex = {};
        	this.smartConvertIndex.english = {};
        	this.smartConvertIndex.metric = {};
        	this.smartConvertIndex.english.volume1 = {};    	
            this.smartConvertIndex.english.volume2 = {};        
            this.smartConvertIndex.english.volume3 = {};        
        	this.smartConvertIndex.english.mass = {};    	
        	this.smartConvertIndex.metric.volume = {};    	
        	this.smartConvertIndex.metric.mass = {};   

            this.unitsEnglish = null;
            this.unitsMetric = null;
            this.unitsMass = null;
            this.unitsVolume = null;
            this.unitConversion = null;


            this.initConversionTables(  );
        };

    _SmartConvert.prototype.getUnitName = function( unitId )
    {
        return this.getName( unitId );
    };

    _SmartConvert.prototype.initConversionTables = function(  )
    {
        this.unitsEnglish =  ["pinch", 'cup', 'floz', 'gallon', 'quart',  'tbsp', 'tsp' , 'lb', 'oz', "slice", "per half", "half", "each", "dozen", 'per', 'serving' ,'pint'];
        this.unitsMetric =  ['litre', 'ml', 'g', 'kg' ];
        this.unitsMass = [ "pinch", 'lb', 'oz', 'g', 'kg' ];
        this.unitsVolume = ['xxxx'] ;
        this.unitsVolume1 = ['cup', 'tbsp', 'tsp' ] ;
        this.unitsVolume2 = ['floz', 'gallon', 'quart' ] ;
        this.unitsVolume3 = ['pint' ] ;
        this.unitsVolume4 = ['ml','litre' ] ;

        this.unitsWhole = [  "slice", "per half", "half", "each", "dozen", 'per', 'serving' ];

        this.unitConversion = { 
            '11'  : 'cup',
            '12'  : 'each',
            '13'  : 'floz',
            '14'  : 'g',
            '15'  : 'gallon',
            '18'  : 'kg',
            '19'  : 'litre',
            '20'  : 'lb',
            '23'  : 'ml',
            '25'  : 'oz',
            '26'  : 'pinch',
            '27'  : 'pint',
            '28'  : 'quart',
            '32'  : 'tbsp',
            '34'  : 'tsp',

            '36' : "slice", 
            '49' : "per half", 
            '60' : "half", 
            '50' : "dozen", 
            '47' : 'per', 
        //    '' : 'serving'
        };
         
        this.smartConvert.directReturnUnitIds       =[ 12, 36,49,60,12,50,47 ];
    	this.smartConvertIndex.english.volume1     = [ 'tsp', 'tbsp' , 'cup' ];
    	this.smartConvert.english.volume1.tsp      = [ 1 , 3 ,  48 ];
		this.smartConvert.english.volume1.tbsp     = [ 0.333 , 1 ,  16];
		this.smartConvert.english.volume1.cup      = [ 0.021 , 0.063 , 1];

        this.smartConvertIndex.english.volume2    = [ 'floz', 'quart', 'gallon' ];
        this.smartConvert.english.volume2.floz     = [ 1 , 32 , 128  ];
        this.smartConvert.english.volume2.quart    = [ 0.031 , 1 , 4 ];
        this.smartConvert.english.volume2.gallon   = [ 0.008 , 0.25 , 1 ];
		
        this.smartConvertIndex.english.volume3    = [ 'pint' ];
        this.smartConvert.english.volume3.pint     = [1];

        //this.smartConvertIndex.english.volume4    = [ 'pint' ];

        this.smartConvertIndex.metric.volume  = ['ml', 'litre' ];
        this.smartConvert.metric.volume.ml   = [ 1 ,  1000 ];
        this.smartConvert.metric.volume.litre   = [ 0.001 ,  1];

        this.smartConvertIndex.english.mass = [ "pinch", 'oz', 'lb' ];
        this.smartConvert.english.mass.pinch   = [ 1 ,  79.25, 1268 ];
        this.smartConvert.english.mass.oz   = [ 0.01262, 1 ,  16];
        this.smartConvert.english.mass.lb   = [ 0.00079, 0.0625 ,  1];

        this.smartConvertIndex.metric.mass  = ['mg', 'g', 'kg' ];
        this.smartConvert.metric.mass.mg   = [ 1, 100 ,  100000 ];
        this.smartConvert.metric.mass.g   = [ 0.001, 1 ,  1000 ];
        this.smartConvert.metric.mass.kg   = [ 0.00001, 0.001 ,  1];

        this.smartConvertIndex.english.whole  = ["slice", "per half", "half", "each", "dozen", 'per', 'serving' ];
        this.smartConvert.english.whole.slice   = [ 1, 4 , 8,  96 ];
        this.smartConvert.english.whole.half   = [ 0.25, 1, 2, 24 ];
        this.smartConvert.english.whole.perHalf   = [ 0.25, 1, 2, 24 ];
        this.smartConvert.english.whole.each   = [ 0.125, 0.5, 1, 12 ];
        this.smartConvert.english.whole.per   = [ 0.125, 0.5, 1, 12 ];
        this.smartConvert.english.whole.serving   = [ 0.125, 0.5, 1, 12 ];
        this.smartConvert.english.whole.dozen   = [ 0.0104, 0.04, 0.08, 1 ];
    };

     _SmartConvert.prototype.getUnitName = function( unitId )
    {
        var unitName = this.unitConversion[unitId];
        if( unitName == "floz" ) unitName = "fl oz";
        return unitName;

    }

    _SmartConvert.prototype.clear = function ( )
    { 
        this.input = {};
            this.input.currentUnit = {};
            this.input.currentUnit.name = null;
            this.input.currentUnit.type = null;
            this.input.currentUnit.system = null;
            this.input.currentUnit.quantity = null;
            this.input.currentUnit.divisor = null;

            this.input.newUnit = {};
            this.input.newUnit.unitName = null;
            this.input.newUnit.unitIndex = null;
            this.input.newUnit.quantity = null;
            this.input.newUnit.quantityFraction = null;
            this.input.newUnit.fail = false;

    }

    _SmartConvert.prototype.doConvert = function ( quantity, unitId, forceType )
    {
         if( quantity == "" || unitId == "" ) return this.NoUnit(  );
         if( quantity == null || unitId == null ) return this.NoUnit(  );
         var newQuantity = this.cleanNumber( quantity );
         if( isNaN ( newQuantity  ) ) return this.NoUnit( quantity );


         var nameForConvert;
        this.clear(  );
        if( debug )
            console.log( "%cEnter smart converter with %s, %s ",  'color: grey; background-color:green; font-weight: bold;',  newQuantity, unitId)

        try{
    	   nameForConvert = this.getInternalUnitId( unitId, forceType );
    		var system = this.input.currentUnit.system;
    		var type = this.input.currentUnit.type;
            this.input.currentUnit.quantity = newQuantity;
        }
        catch( e ){
             return this.NoUnit( newQuantity );
        }

       if( debug )
            console.log( "%cdoConvert Start - system: %s; type: %s, nameToConvert: %s ",  'color: green; font-weight: bold;', system, type, nameForConvert )

        if( nameForConvert == null || system == null || type == null || typeof(nameForConvert) == 'null' ) return this.NoUnit(  );
        else{
            //console.log( nameForConvert );
            //console.log( "Continue" );
        	var testArray = this.smartConvert[system][type][nameForConvert];
        	
    		this.input.newUnit.unitIndex  = this.doTest( newQuantity, testArray );
    		this.input.newUnit.unitName  = this.getUnitFromTest(  this.input.newUnit.unitIndex  );

            if( this.input.currentUnit.name == 'tbsp' && this.input.newUnit.unitIndex == 13 )
            {
                this.input.newUnit.unitIndex = 32;
                this.input.newUnit.unitName  =  this.getUnitFromTest(  this.input.newUnit.unitIndex  );
                this.input.currentUnit.divisor = 1;

            }

            if( this.input.currentUnit.name == 'pint' ||  this.input.currentUnit.name == 'p')
            {
                this.input.newUnit.unitIndex = 27;
                this.input.newUnit.unitName  =  this.getUnitFromTest(  this.input.newUnit.unitIndex  );
                this.input.currentUnit.divisor = 1;
            }


    		this.doMath( newQuantity, this.input.newUnit.unitIndex  );
            this.unconvertSmartFilter( this.input.newUnit.quantityFraction );

            var newUnitCode = this.reverseUnit( this.input.newUnit.unitName );
            this.input.newUnit.unitId  = newUnitCode;
            if( this.input.newUnit.unitName == "floz" )  this.input.newUnit.unitName = "fl oz";
        }
    	
        return this.input;
        

    };
    
    _SmartConvert.prototype.cleanNumber = function ( quantity )
    {
        if( isNaN( quantity ) || typeof(quantity) == "string" )
        {
			//console.log("quantity: "+quantity);
            quantity = quantity.replace(/\s/g,'');        
            quantity = quantity.replace( '¼', '.25' );
            quantity = quantity.replace( '½', '.5' );
            quantity = quantity.replace( '¾', '.75' );
            quantity = quantity.replace( '⅓',  '.3333' );
            quantity = quantity.replace( '⅔',  '.6666' );
            quantity = quantity.replace( '⅛',   '.125' );
            quantity = quantity.replace( '¹⁄₅',   '.2' );

            quantity = quantity.replace( '1/4', '.25' );
            quantity = quantity.replace( '1/2', '.5' );
            quantity = quantity.replace( '3/4', '.75' );
            quantity = quantity.replace( '1/3',  '.3333' );
            quantity = quantity.replace( '2/3',  '.6666' );
            quantity = quantity.replace( '1/8',   '.125' );
            quantity = quantity.replace( '1/5',   '.2' );

            quantity = quantity.replace(/[^\.\d]/g,'');
            
            if (quantity.charAt(quantity.length - 1) == '.') {
                 quantity = quantity.substr(0, quantity.length - 1);
            }
            quantity = eval( quantity );
            quantity = parseFloat( quantity );
        }
        return quantity;
    };

    _SmartConvert.prototype.reverseUnit = function( unitName )
    {
        for (var unitCode in this.unitConversion)
        {
            if( this.unitConversion[unitCode] == unitName )
                return unitCode;
        }
    }



    _SmartConvert.prototype.doTest = function( quantity, testArray )
    {
		var arrayLength = testArray.length;
        var lastDivisor;

		for (var i = 0; i < arrayLength; i++) 
		{
			testAgainst = testArray[i];
			lastDivisor = testAgainst; // for stuff that breaks out of our loop
            if( parseFloat(quantity) < testAgainst.toFixed( 3 ) ){
                this.input.currentUnit.divisor = priorDivisor;
                if( i === 0 ) {
                    this.input.currentUnit.divisor = lastDivisor;
                    return 0;
                }
				else return i - 1;

			} 
            var priorDivisor = testAgainst;
		}

		// if we get here, then it's not smaller than any of the test values, so return the max.
		this.input.currentUnit.divisor = lastDivisor;
        return arrayLength-1;

    };

    _SmartConvert.prototype.getUnitFromTest = function( testResult )
    {
    	if( testResult===null ) return;
		var system = this.input.currentUnit.system;
		var type = this.input.currentUnit.type;
		var arrayWithResultVals = this.smartConvertIndex[system][type];
		var maxVal = arrayWithResultVals.length - 1;
		if( result >maxVal ) throw "Something went wrong, test result " + result + " exceeds max # of possible results.";
		var result = arrayWithResultVals[testResult];
		return result;
    };

    _SmartConvert.prototype.doMath = function ( quantity )
    {
        quantity  = parseFloat( quantity );
        // we can use this then to just round & fractionalize
        if( typeof( this.input.currentUnit.divisor )  == 'undefined' ) this.input.currentUnit.divisor = 1;
        if( this.input.currentUnit.divisor  == null ) this.input.currentUnit.divisor = 1;

    	newAmount = quantity / this.input.currentUnit.divisor;
        this.input.newUnit.quantity = newAmount;
        
        if ( newAmount < 0.2 ) 
        {
            newAmount = Number(  newAmount.toFixed( 2 ) );
            this.input.newUnit.quantityFraction = newAmount;
            return newAmount.toString(  );
        }



        var roundAmount = newAmount;        //85.7344
        roundAmount = parseInt( roundAmount * 100 );    // 8573
        roundAmount = Math.round( roundAmount/5 )* 5;      // 8575
        roundAmount = parseFloat( roundAmount / 100 );

        var theDecimal;
        var numberParts = roundAmount.toString().split('.');
        if( numberParts.length == 2 )
        {
            theDecimal = numberParts[1];
            newDecimal = this.filterDecimals( theDecimal );
            roundAmount =  numberParts[0] +  newDecimal ;
        }

    	var frac = new Fraction( roundAmount );
        var fractionalizedResult   = frac.toString(  );
        fractionalizedResult   = this.fancyFractions( fractionalizedResult );
    	this.input.newUnit.quantityFraction =  fractionalizedResult ;

         return  fractionalizedResult ;
    }

    // NEW UNIT:  
    //      {unitName: "oz", unitIndex: 0, quantity: 7.92, quantityFraction: "7 ⁹⁄₁₀", fail: false}
    // CURRENT UNIT:
    //      {name: "oz", type: "mass", system: "english", quantity: 7.92, divisor: 1}
     _SmartConvert.prototype.unconvertSmartFilter = function(str) 
    {
        blackListFractions = [ "1/6", "5/6", "1/7", "1/9", "3/8", "5/8", "7/8", "1/9", "9/10", "1/10", "¹⁄₆", "⁵⁄₆", "¹⁄₇",  "¹⁄₉", "³⁄₈", "⁵⁄₈", "⁷⁄₈", "¹⁄₉", "⁹⁄₁₀", "¹⁄₁₀" ];
        //console.log( str );
        if( typeof(str) == "undefined" )return;
        if( !isNaN(str) ) str = str.toString(  );
        if( str.indexOf( " " ) > 0 )
        {
            var fractionParts = this.input.newUnit.quantityFraction.split( " " );
            if( fractionParts.length  > 1){
                var fractionActual = fractionParts[1];
                this.input.newUnit.uglyFraction = true;
            }

            if( blackListFractions.includes( fractionActual ) ){
                this.rollBack(  );
            }
        }

        if( ( this.input.currentUnit.name == "floz" || this.input.currentUnit.name == "fl oz" ) && ( this.input.newUnit.unitName == "tsp" || this.input.newUnit.unitName == "tbsp" 
                    || this.input.newUnit.unitName == "cup" ) )
        {
            this.rollBack(  );   
            return;
        }

        if(  this.input.currentUnit.name == "pint" )
        {
            this.rollBack(  );   
            return;
        }

         if(  this.input.currentUnit.name == "cup" && this.input.newUnit.unitName == 'tbsp' && this.input.newUnit.quantity < 6 )
        {
            this.rollBack(  );   
            return;
        }
        
        

        if( this.input.newUnit.quantity > 2 && this.input.newUnit.unitName == 'pinch' )
        {
            this.rollBack(  );   
            return;
        }

        if( this.input.newUnit.quantity <= 2 ){
            this.rollBack(  );   
            return;
        }

        //console.log( this.input.newUnit );
        if( typeof(this.input.newUnit.quantityFraction ) == "undefined" ||
         (typeof(this.input.newUnit.quantityFraction) == "string" && this.input.newUnit.quantityFraction.indexOf( "NaN" ) > -1 ) )
        {
            this.input.newUnit.quantityFraction = this.rollBack(  );   
        }        
    }

    _SmartConvert.prototype.reverseUnitGetIndex = function( name )
    {
        for( var unitIndex in this.unitConversion )
        {
            if(  this.unitConversion[unitIndex] == name ) return unitIndex;
        }
    }
    

     _SmartConvert.prototype.rollBack = function( ) 
     {
        
        this.input.currentUnit.divisor = 1;

        //this.input.newUnit.unitIndex = null;
        this.input.newUnit.unitName = this.input.currentUnit.name;
        this.input.newUnit.quantity = this.input.currentUnit.quantity;
        
        // , this.reverseUnitGetIndex( this.input.currentUnit.name );
        this.input.newUnit.quantityFraction = this.doMath( this.input.newUnit.quantity );

        this.input.newUnit.uglyFraction = false;
        
        var fractionParts = this.input.newUnit.quantityFraction.split( " " );
         if( fractionParts.length  > 1){
                var fractionActual = fractionParts[1];
                this.input.newUnit.uglyFraction = true;
            }
            if( blackListFractions.includes( this.input.newUnit.quantityFraction ) ){
                this.input.newUnit.uglyFraction = true;
            }

        if( this.input.newUnit.uglyFraction === true )
        {
            this.input.newUnit.quantityFraction = Number ( this.input.newUnit.quantity.toFixed( 1 ) );
        }
        //this.input.newUnit.unitIndex = 27;
        // this.input.newUnit.unitName  =  this.getUnitFromTest(  this.input.newUnit.unitIndex  );
        //this.input.currentUnit.divisor = 1;
     }

    _SmartConvert.prototype.encode = function(str) 
    {
            var buf = [];
            
            for (var i=str.length-1;i>=0;i--) {
                buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
            }
            
            return buf.join('');
        };
     _SmartConvert.prototype.fancyFractions = function ( fractionString )
    {
        fractionString = fractionString.replace("3/10", "1/3");
        fractionString = fractionString.replace("1/2", "¹⁄₂");
        fractionString = fractionString.replace("1/3", "¹⁄₃");
        fractionString = fractionString.replace("2/3", "²⁄₃");
        fractionString = fractionString.replace("1/4", "¹⁄₄");
        fractionString = fractionString.replace("3/4", "³⁄₄");
        fractionString = fractionString.replace("1/5", "¹⁄₅");
        fractionString = fractionString.replace("2/5", "²⁄₅");
        fractionString = fractionString.replace("3/5", "³⁄₅");
        fractionString = fractionString.replace("4/5", "⁴⁄₅");
        fractionString = fractionString.replace("1/6", "¹⁄₆");
        fractionString = fractionString.replace("5/6", "⁵⁄₆");
        
        fractionString = fractionString.replace("7/10", "²⁄₃");
        fractionString = fractionString.replace("1/7", "¹⁄₇");
        fractionString = fractionString.replace("1/8", "¹⁄₈");
        fractionString = fractionString.replace("1/9", "¹⁄₉");
        fractionString = fractionString.replace("3/8", "³⁄₈");
        fractionString = fractionString.replace("5/8", "⁵⁄₈");
        fractionString = fractionString.replace("7/8", "⁷⁄₈");
        fractionString = fractionString.replace("1/9", "¹⁄₉");
        fractionString = fractionString.replace("9/10", "⁹⁄₁₀");
        fractionString = fractionString.replace("1/10", "¹⁄₁₀");
        return fractionString;
    }

    _SmartConvert.prototype.filterDecimals = function ( decimal )
    {
        if( decimal == "05" ) return '.0';
        else if( decimal == "15" ) return ' 2/10';
        else if( decimal == "35" ) return ' 1/3';
        else if( decimal == "45" ) return '.4';
        else if( decimal == "55" ) return '.5';
        else if( decimal == "65" ) return ' 2/3';
        else if( decimal == "85" ) return ' 18/20';
        else if( decimal == "95" ) return ' 9/10';
        //else if( decimal == "55" ) return '.5';
        //else if( decimal == "55" ) return '.5';
        return "." + decimal;
    }

    _SmartConvert.prototype.NoUnit = function( theAmt )
    {
           if( debug )
                console.log( "%cNounit Failed. Quitting ",  'color: green; font-weight: bold;' );

            this.input.newUnit.unitName =  this.input.currentUnit.name;
            this.input.newUnit.quantity =  theAmt;
            
            frac = this.doMath( theAmt );
            this.input.newUnit.quantityFraction = frac;
            this.input.newUnit.fail = true;
            return this.input;

    }

    _SmartConvert.prototype.getInternalUnitId = function ( unit , forceType )
    {
        var unitText;
        // text version - 
        if( unit == "fl oz" ) unit = "floz";
        if( unit == "l" ) unit = "litre";
        if( Object.values(this.unitConversion).indexOf( unit ) > -1 )
            unitText = unit;
        else
    	   unitText = this.unitConversion[unit];

        if( !unitText ) this.NoUnit(  );
        this.input.currentUnit.name = unitText;

        var unitsEnglish        = this.unitsEnglish; 
        var unitsMetric         =  this.unitsMetric;
        //var unitsSystemSpecial  =  ['pint' ];

        var unitsVolume =   this.unitsVolume  ; 
        var unitsVolume1 =   this.unitsVolume1  ; 
        var unitsVolume2 =   this.unitsVolume2  ; 
        var unitsVolume3 =   this.unitsVolume3  ; 
        var unitsVolume4 =   this.unitsVolume4  ; 
        var unitsMass  = this.unitsMass ;   
       
        if( unitsEnglish.includes( unitText ) ) this.input.currentUnit.system = 'english';
        else if( unitsMetric.includes( unitText ) ) this.input.currentUnit.system = 'metric';
        //else if( unitsSystemSpecial.includes( unitText ) ) this.input.currentUnit.system = 'special';
        else throw "No system";

        if( unitsVolume.includes( unitText ) ) this.input.currentUnit.type = 'volume';
        else if( unitsVolume1.includes( unitText ) ) this.input.currentUnit.type = 'volume1';
        else if( unitsVolume2.includes( unitText ) ) this.input.currentUnit.type = 'volume2';
        else if( unitsVolume3.includes( unitText ) ) this.input.currentUnit.type = 'volume3';
        else if( unitsVolume4.includes( unitText ) ) this.input.currentUnit.type = 'volume';
        else if( unitsMass.includes( unitText ) ) this.input.currentUnit.type = 'mass';
    	else throw "No type";

        if( debug )
            console.log( "%c getInternalUnitId  - unitText: %s; system: %s, type: %s  ",  'color: green; font-weight: bold;', unitText, this.input.currentUnit.system , this.input.currentUnit.type );
        

    	// override
       	if( forceType == "mass" )  this.input.currentUnit.type = 'mass';
       	else if( forceType == "volume" )  this.input.currentUnit.type = 'volume';	    
        return     unitText;
    };

    return {
        getInstance: function(){
            if(!instance) instance = new _SmartConvert();
            instance.init(  );
            return instance;
        }
    }

    _SmartConvert.prototype.getName = function( theId )
    {
       if (theId==1) return '#10 Can';
        if (theId==2) return 'as needed';
        if (theId==3) return 'bag';
        if (theId==4) return 'bottle';
        if (theId==5) return 'box';
        if (theId==6) return 'bunch';
        if (theId==7) return 'can';
        if (theId==8) return 'case';
        if (theId==9) return 'clove';
        if (theId==10) return 'container';
        if (theId==11) return 'cup';
        if (theId==12) return 'ea';
        if (theId==13) return 'fl oz';
        if (theId==14) return 'g';
        if (theId==15) return 'gal';
        if (theId==16) return 'head';
        if (theId==17) return 'jar';
        if (theId==18) return 'kg';
        if (theId==19) return 'l';
        if (theId==20) return 'lb';
        if (theId==21) return 'leaf';
        if (theId==22) return 'loaf';
        if (theId==23) return 'ml';
        if (theId==24) return 'No Unit';
        if (theId==25) return 'oz';
        if (theId==26) return 'pinch';
        if (theId==27) return 'pt';
        if (theId==28) return 'qt';
        if (theId==29) return 'scoop';
        if (theId==30) return 'sheet';
        if (theId==31) return 'sprig';
        if (theId==32) return 'tbsp';
        if (theId==33) return 'to taste';
        if (theId==34) return 'tsp';
        if (theId==35) return 'wedge';
        if (theId==36) return 'slice';
        if (theId==37) return 'N/A';
        if (theId==38) return 'drop';
        if (theId==39) return 'Â½ sheet';
        if (theId==40) return 'round';
        if (theId==41) return 'hotel pan';
        if (theId==42) return 'recipe';
        if (theId==43) return 'hour';
        if (theId==44) return 'Â½ hotel pan';
        if (theId==45) return 'disk';
        if (theId==46) return 'stalk';
        if (theId==47) return 'per';
        if (theId==48) return 'portion';
        if (theId==49) return 'per half';
        if (theId==50) return 'dozen';
        if (theId==51) return 'in';
        if (theId==52) return 'a few drops';
        if (theId==53) return 'as desired';
        if (theId==54) return 'as needed';
        if (theId==55) return 'as needed for breading';
        if (theId==56) return 'as needed for garnish';
        if (theId==57) return 'as needed for storage';
        if (theId==58) return 'to color';
        if (theId==59) return 'dash';
        if (theId==60) return 'half';



    }



})( );