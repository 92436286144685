// These dependencies will be called on every page
var AllRecipesCtrl = function(){
    this.initDependencies();
}

AllRecipesCtrl.prototype.initDependencies = function(){
    FilterComponent.getInstance();
    _RecipePresenter = RecipePresenter.getInstance();
    _RecipePresenter.initRecipeCardLikeListener();

    new GoBackBtn();
    new Collapsibles();
    new FilterBadgeCollection();
    new FilterCheckbox(); //links filter checkbox state with the filterbadges
    new LibraryComponent();
    new SpinnerComponent();
    new DynamicWidthDropdown();

}
