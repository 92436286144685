var Tables = {}
// All tables work w cross conversions

// regular = american on left
// reverse = metric on left
Tables.CrossConversion = {
    // solid
    oz: { g: 28.3495 },
    lb: { kg: 0.453592 },
    // reverse
    g: { oz: 0.035274 },
    lb: { kg: 2.20462 },

    // liquid
    floz: { mL: 29.5735 },  
    tsp: { mL: 4.92892 },      
    tbsp: { mL: 14.7868 },
    cup: { mL: 236.588 },
    pt: { mL: 473.176 },
    qt: { mL: 946.353 },    
    gal: { L: 3.78541 },
    // reverse
    mL: { floz: 0.033814 },
    mL: { tsp: 0.202884 },
    mL: { tbsp: 0.067628 },
    mL: { cup: 0.00416667 },
    mL: { pt: 0.00211338 },
    mL: { qt: 0.00105669 },
    mL: { gal: 0.000264172 },
}

// Same system conversions
Tables.Conversion = {
    Metric: {
        // solid
        g: { kg: 0.001 },
        g: { mg: 1000 },
        // reverse
        kg: { g: 1000},
        kg: { g: 0.001},

        // Liquid
        L: { kL: 0.001 },
        L: { mL: 1000 },
        // reverse
        kL: { L: 1000 },
        mL: { L: 0.001 }
    },
    American: {
        // solid
        oz: { lb: .0625 },
        // reverse
        lb: { oz: 16 },

        // liquid
        floz: { tsp: 6 },        
        floz: { tbsp: 2 },
        floz: { cup: .123223 },
        floz: { pt: .0625 },
        floz: { qt: .03125 },
        floz: { gal: .00778125 },
        // reverse
        tsp: { floz: .2 },
        tbsp: { floz: .5 },
        cup: { floz: 8 },
        pt: { floz: 16 },
        qt: { floz: 32 },
        gal: { floz: 128 },
    }
}


/**
 * Convertible Units
 * 
 * Liquid
 * tsp = .2 fl oz
 * tbsp = .5 fl oz
 * cup = 8 fl oz
 * pt = 16 fl oz
 * qt = 32 fl oz
 * gal = 128 fl oz

 * Liquid US to Metric
 * ml = .033814 fl oz
 * L = 33.814 fl oz
 * 
 * 
 * 
 */
