$(document).ready(function () {
    var _RecipeCalculator;
    var _UnitCodes = UnitCodes;
    var _Util = Util;
    var UnitsTable = new UnitCodes().getTable();
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();

    var allIngredientsData;
    var totalCost = 0;
    MenuCostList = {};
    MenuCostList.$ = $('.modal-cost-list');
    var _localSmartConvert  = SmartConvert.getInstance();
    var startMCL =0;
    
    MenuCostList.init = function(){
        var onRecipe = $('.measurement-controls').length > 0;
        totalCost = 0;
        var _localSmartConvert  = SmartConvert.getInstance();
        if(onRecipe) {
            var $yieldCtrl = $('.yield-control');
            var $servingCtrl = $('.serving-control');
            var yieldUnitCode = $yieldCtrl.find('select').attr('data-selected');
            var yieldUnitName = $yieldCtrl.find('select option[value="'+yieldUnitCode+'"]:first').text();
            var servingUnitCode = $servingCtrl.find('select').attr('data-selected');
            var servingUnitName = $servingCtrl.find('select option[value="'+servingUnitCode+'"]').text();

            MenuCostList.$.find('.yield-unit').text(yieldUnitName);
            MenuCostList.$.find('.serving-size-unit').text(servingUnitName);
            // Populate ingredient list
            allIngredientsData = _MenuAndIngredientList.getAllData($('.ingredients-list'), {
                getSublists: false
            });
            allIngredientsData.forEach(function (ingredientData) {
                MenuCostList.addItemWData(ingredientData);
            });
        } else {
            var $items = MenuCostList.$.find('.item').not('.placeholder').not('.hidden');
            $items.each(function(i, el) {
                var itemPrice = $(el).attr('data-price');
                if( !isNaN( itemPrice ) ){
                    var price = parseFloat( $(el).attr('data-price') );
                    totalCost += price;
                }
            });

            $('.total-recipe-cost').text('$' + totalCost)
        }

    }
    MenuCostList.addItemToList = function ($item) {
        MenuCostList.$.find('.modal-body .ingredient-list').append($item);
    }

    MenuCostList.createItemWData = function (itemData) {
        var $item = MenuCostList.$.find('.item.placeholder').clone().removeClass('placeholder');
        for (var key in itemData) {
            if (itemData.hasOwnProperty(key))
                $item.data(key, itemData[key]); // add all original item data to shopping list item
        }
        var convertedUnit = UnitsTable[itemData.unit-1];


         if( noPriceUnitIds.includes( itemData.unit ) ) {
            $ourNewItem.find('.price').text( '-' );
            $ourNewItem.find('.amt .qty').text('');
        }
        else
        {
             unitUpscaleFraction =  _localSmartConvert.doConvert( itemData.amt, itemData.unit ) ;


            if( itemData.perunitprice ){
                var writePrice = parseFloat(itemData.perunitprice) * newIngAmt;
                // if( writePrice <= 0.01 )
                //     $ourNewItem.find('.price').text( '-' );    
                // else
                //     $ourNewItem.find('.price').text('$' + Util.convertToUSD( writePrice ) );
           }
            else{
                $ourNewItem.find('.price').text( '-' );
            }
        
            if(  unitUpscaleFraction.newUnit.fail==false && unitUpscaleFraction.newUnit.quantityFraction ){
                 $ourNewItem.find('.amt .qty').html( unitUpscaleFraction.newUnit.quantityFraction  );     
            }
            else
            {
               $ourNewItem.find('.amt .qty').text( Number(Number( newIngAmt ).toFixed( 2 )) );
            }
            if( unitUpscaleFraction.newUnit.fail==false && unitUpscaleFraction.newUnit.unitName )
            {
                $ourNewItem.find( '.amt .unit' ).html( unitUpscaleFraction.newUnit.unitName );
            //   $( this ).find( 'span.unit' ).html( '&#8531;' );
           }
           else
           {
                $ourNewItem.find( '.amt .unit' ).html( convertedUnit.single );
           }


            // $item.find('.name').text(itemData.name)
            // $item.find('.amt .qty').text(itemData.amt)
            // $item.find('.amt .unit').text(convertedUnit.single)
            // $item.find('.price').text('$' + Util.convertToUSD(parseFloat(itemData.price)))
        }
        return $item;
    }

    // Adds new items to list
    // NOTE: SEE RETURN STAEMENT. DOESNT NOT HANDLE DUPLICATE ITEM PROCESSING ie add item prices and ingredient amounts
    MenuCostList.addItemWData = function (itemData) {
        var $item = MenuCostList.$.find('[data-name="' + itemData.name + '"]');
        if (!itemData.price) return;
        if ($item.length === 0) $item = MenuCostList.createItemWData(itemData);
        else return; //TODO: In case of duplicate items this is where we would handle updates that require adding prices and quantities
        MenuCostList.addItemToList($item);
    }

    MenuCostList.initCalculator = function(){
        var onRecipe = $('.measurement-controls').length > 0;
        if(onRecipe) {
            var yieldAmt = $('#yield-input').val();
            var recipeCalcYieldAmt = _Util.fractionStrToDecimal($('.yield-control').attr('data-servingunitequivalent')*yieldAmt);
            var servingSizeAmt = _Util.fractionStrToDecimal($('.serving-amt').val())
            allIngredientsData.forEach(function(ingredientData){
                totalCost += parseFloat(ingredientData.price);
            })
    
            _RecipeCalculator = new RecipeCalculator({
                yield: recipeCalcYieldAmt,
                servingNumber: servingSizeAmt,
                servingSize: servingSizeAmt,
                recipeCost: totalCost
            });
            
        } else {
            var guests = $('.modal-shopping-list .guests .amount').text();

            //

            _RecipeCalculator = new RecipeCalculator({
                    yield: guests, // this way serving amount get calculated to guests/servingSize
                servingSize: 1,
                recipeCost: totalCost
            });           
        }
    }

    MenuCostList.updateUI = function(){
        var values = _RecipeCalculator.getAllProperties();
        if (values.recipeCost)
            MenuCostList.$.find('.total-recipe-cost').text('$' + _Util.convertToUSD(values.recipeCost));
        if (values.costPerServing)
            MenuCostList.$.find('.cost-per-serving').text('$' + _Util.convertToUSD(values.costPerServing));
        if (values.numberOfServings)
            MenuCostList.$.find('#number-of-servings').val(values.numberOfServings);
        
        if (values.menuPrice !== undefined && values.menuPrice !== null && values.menuPrice !== "" ){
            MenuCostList.$.find('.menu-price-edit').val('$' + _Util.convertToUSD(values.menuPrice));
            MenuCostList.$.find('.menu-price-edit').text('$' + _Util.convertToUSD(values.menuPrice));
        }
        else
        {
            var guestCount = values.numberOfServings;
            if( guestCount <1  ) guestCount = 1 ;
            MenuCostList.$.find('.menu-price-edit').val('$' + _Util.convertToUSD(values.recipeCost / guestCount ));
            MenuCostList.$.find('.menu-price-edit').text('$' + _Util.convertToUSD(values.recipeCost / guestCount ));
        }
        if (typeof values.margin !== 'undefined') {
            MenuCostList.$.find('.margin-measurement-edit').val('$' + _Util.convertToUSD(values.margin));
            MenuCostList.$.find('.margin-measurement-edit').text('$' + _Util.convertToUSD(values.margin));
        }
        if (values.foodCostPercentage) {
            var showVal = values.foodCostPercentage;
            if(showVal !== "< 1%" ) showVal = _Util.convertToPercent(showVal) + '%';

            MenuCostList.$.find('.food-cost-percent-edit').val(showVal);
            MenuCostList.$.find('.food-cost-percent-edit').text(showVal);
        }
        if (values.yield)
            MenuCostList.$.find('.recipe-yield').text(values.yield * $('.yield-control').attr('data-yieldmultiplier'));
        if (values.servingSize)
            MenuCostList.$.find('.serving-size').text(values.servingSize);

        console.log(values);
    }

    MenuCostList.validateInput = function (element, parent) {
        console.log('validate input');
        
        var input = $( element ).val( );
        if( input == "< 1%" )
        {
            $(element).siblings("p.error").text('');
            return 0;
        }

        input = _Util.regexGetNumbers(input);
        if (input === '' || isNaN(parseFloat(input))) {
            $(element).siblings("p.error").text('Invalid input, please enter a number');
            return 1;
        }
        else if ( input === 0 || input < 0 || input === "0")
        {
             $(element).siblings("p.error").text('Invalid input, please enter a number greater than zero.');
             return 1;
        } else {
            $(element).siblings("p.error").text('');
            return 0;
        }
    }

    MenuCostList.validateInputAnyNumber = function (element, parent) {
        console.log('validate input');
        
        var input = $( element ).val( );
        if( input == "< 1%" )
        {
            $(element).siblings("p.error").text('');
            return 0;
        }

        input = _Util.regexGetNumbers(input);
        if (input === '' || isNaN(parseFloat(input))) {
            $(element).siblings("p.error").text('Invalid input, please enter a number');
            return 1;
        }
        else {
            $(element).siblings("p.error").text('');
            return 0;
        }
    }

    MenuCostList.binds = function(){
        // Controls. On change will call recipe calculator to recalculate all values and update them in the UI
        MenuCostList.$.find('#menu-price').on('click', function(){
            var current = $(this).val(  );
            $( this ).attr("placeholder", current );
            $(this).val( "" );
        });

        MenuCostList.$.find('#menu-price').on('focusout', function(){
            var currentVal = $(this).val(  );
            var placeHolder = $( this ).attr("placeholder");
            if( currentVal === "" && placeHolder !== "" )
            {
                $( this ).val( placeHolder );
            }
        });

        MenuCostList.$.find('#food-cost-percent').on('click', function(){
            var current = $(this).val(  );
            $( this ).attr("placeholder", current );
            $(this).val( "" );
        });

        MenuCostList.$.find('#food-cost-percent').on('focusout', function(){
            var currentVal = $(this).val(  );
            var placeHolder = $( this ).attr("placeholder");
            if( currentVal === "" && placeHolder !== "" )
            {
                $( this ).val( placeHolder );
            }
        });

        

        MenuCostList.$.find('#margin-measurement').on('click', function(){
            var current = $(this).val(  );
            $( this ).attr("placeholder", current );
            $(this).val( "" );
        });

        MenuCostList.$.find('#margin-measurement').on('focusout', function(){
            var currentVal = $(this).val(  );
            var placeHolder = $( this ).attr("placeholder");
            if( currentVal === "" && placeHolder !== "" )
            {
                $( this ).val( placeHolder );
            }
        });


        MenuCostList.$.find('#number-of-servings').on('change', function(){
            var userInput = $(this).val();
            userInput = _Util.regexGetNumbers(userInput);
            var errorResult = MenuCostList.validateInput( this, { uiParent: '.calculations-2' });
            _RecipeCalculator.setNumberOfServings(userInput);
            MenuCostList.updateUI();
        });

        // this is price per guest
        MenuCostList.$.find('#menu-price').on('change', function(){
            var userInput = $(this).val();
            userInput = _Util.regexGetNumbers(userInput);
            var errorResult =MenuCostList.validateInput( this, { uiParent: '.calculations-1' });
            if( errorResult == 1  ) return;
            else ( MenuCostList.validateAllTheNumbers(  ) );

            _RecipeCalculator.setMenuPrice(userInput);
            MenuCostList.updateUI();
        });
 
        MenuCostList.$.find('#food-cost-percent').on('change', function(){
            var userInput = _Util.convertToDecimalPercent( $(this).val() );
            userInput = _Util.regexGetNumbers(userInput);
            var errorResult = MenuCostList.validateInput( this, { uiParent: '.calculations-1' });
            if( errorResult == 1  ) return;
            else ( MenuCostList.validateAllTheNumbers(  ) );

            _RecipeCalculator.setFoodCostPercentage(userInput);
            MenuCostList.updateUI();
        });

        // margin - can be negative or zero
        MenuCostList.$.find('#margin-measurement').on('change', function(){
            var userInput = $(this).val();
            userInput = _Util.regexGetNumbers(userInput);
            var errorResult = MenuCostList.validateInputAnyNumber( this, { uiParent: '.calculations-1' });        
            
            if( errorResult == 1  ) return;
            else ( MenuCostList.validateAllTheNumbers(  ) );
            _RecipeCalculator.setMargin(userInput);
            MenuCostList.updateUI();
        });
    };

     MenuCostList.validateAllTheNumbers = function(  )
     {
        MenuCostList.validateInput( MenuCostList.$.find('#menu-price') );       // price per customer
        MenuCostList.validateInput( MenuCostList.$.find('#food-cost-percent') );
        MenuCostList.validateInputAnyNumber( MenuCostList.$.find('#margin-measurement') );   
     };


    MenuCostList.updateAmountsInModal = function(  )
    {
            $( '#modal-menu-cost' ).find( '.menuModal-unitInfo' ).each( function( i, el )
            {
                var amt = $( el ).find( 'span.amt' ).text(  );
                if( !isNaN( parseFloat(amt) ) )
                {
                    var unitCode = $( el ).find('span.unit-code').attr( 'data-unit-code' );
                    var newAmounts = _localSmartConvert.doConvert( amt, unitCode ) ;
                    $( el ).find( 'span.amt' ).text( newAmounts.newUnit.quantityFraction );
                    // $( el ).find('span.unit-code').attr( 'data-unit-code', newAmounts.newUnit.unitIndex);
                    $( el ).find('span.unit-code').text( newAmounts.newUnit.unitName );
                }
            } );
    }

    // convert unit codes numbers to their corresponding text
    MenuCostList.renderUnitCodes = function() {
        // Units codes in spans
        var unitCodesTable = new UnitCodes().getTable();
        var $unitCodeSpans = MenuCostList.$.find('.unit-code');
        $unitCodeSpans.each(function (i, el) {
            var unitCode = $(el).attr('data-unit-code');
            var unit = unitCodesTable[unitCode - 1];
            $(el).text(unit.single);
        })
    }

     MenuCostList.initialOpenCostList = function(  ) 
     {
        var costPct = MenuCostList.$.find('#food-cost-percent').val(  );
        var price = MenuCostList.$.find('#menu-price').val(  );
        var serv = MenuCostList.$.find('#number-of-servings').val(  );
        var margin = MenuCostList.$.find('#margin-measurement').val(  );

        if( !costPct && !price && !margin )
        {

            var guestCount = _RecipeCalculator.getNumberOfServings(  );
            var recipeCost = _RecipeCalculator.getRecipeCost();
            if( recipeCost <= 0  ) recipeCost = 1;
            
            _RecipeCalculator.setFoodCostPercentage(1);
            //_RecipeCalculator.setMenuPrice(recipeCost / guestCount);
            //_RecipeCalculator.set _Util.convertToUSD(  );
        }

     };

    $('.menu-cost-btn').on('click', function(){
        MenuCostList.binds();
        MenuCostList.init();
        MenuCostList.initCalculator();
        MenuCostList.initialOpenCostList(  );

        MenuCostList.updateUI();
        if( this.startMCL != 1 ){
          MenuCostList.renderUnitCodes()
            MenuCostList.updateAmountsInModal(  );
        }
        this.startMCL = 1;
    })

    $('#modal-menu-cost').on('mouseup', function(){
        setTimeout(function() {
            var $modal = $('.cost-modal');
            var modalHidden = !$modal.hasClass('show');
            if(modalHidden) {
                // $modal.find('.item').not('.placeholder').remove();
            }  
        }, 500);
    })

});