// These dependencies will be called on every page
var GlossaryCtrl = function(){
    this.initDependencies();
}

GlossaryCtrl.prototype.initDependencies = function(){
    new Collapsibles();
    new GlossaryComponent();
	$(document).ready(function(){
    	var hash = window.location.hash.substr(1);
    	var got=0;
    	$("a").each(function( i ,el ) {
    		if(hash == $( el ).attr( 'name' )) {
    			got = 1;
    		}// do nothing we got there via browser
    		else
    		{
    			
    		}
    		//Do your work   
		});
		if( got ===0 ) 	window.location.href = "#"+hash.charAt(0).toUpperCase();;

    } );
}
