// These dependencies will be called on every page
var _Util

var GlobalCtrl = function(){
    this.initDependencies();
}

GlobalCtrl.prototype.initDependencies = function(){
    _Util = Util;
    _Util.scrollToAnimation(300); // sets up to .scroll-to element animations

    new AccessibilityCtrl();
    new CrossBrowserCtrl();
    new CrossDeviceCSSHelper();
    new Polyfills();
    new Footer();
    new Nav();
}
