
var EditIngredientValidation;
$(document).ready(function(){
    var _Validation = Validation.getInstance();
    var _EventEmitter = EventEmitter.getInstance();
    var _Util = Util;
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();



    EditIngredientValidation = function(){
        this.binds();
    }

    
    EditIngredientValidation.prototype.unit_edit = function () {
        var errFound = false;
        
        var qty = $('#unit_edit' ).val();
        var errMsg = '';
        if(qty === '') {
            errFound = true;
            errMsg = 'Please enter a unit quantity.';
             $err = $('#unit_edit' ).closest('.error');
        } else if( isNaN(_Util.fractionStrToDecimal(qty)) || !isFinite(_Util.fractionStrToDecimal(qty)) ) {
            errFound = true;
            errMsg = 'Please enter a numeric value for unit quantity.';
             $err = $('#unit_edit' ).closest('.error');
        }
        Validation.handleError(errFound, errMsg, $( $err ));


        var $modal = $('#modal-recipe-edit-ingredient');
        var $unitSelect = $modal.find('#unit_edit');
        var $err;

        if( $unitSelect[0].selectedIndex < 0  ||  $unitSelect[0].selectedIndex === "" || $unitSelect[0].selectedIndex === null )
        {
            errFound = true;
            errMsg = 'Please enter a unit name.'
            $err = $unitSelect[0].closest('.error');

        } 

        _Validation.handleError(errFound, errMsg, $( $err ));


    }

    EditIngredientValidation.prototype.unit_edit = function () {
        var errFound = false;
        var errMsg = '';

         if( $( '#modal-recipe-edit-ingredient' ).hasClass( 'show' ) )
            {
               // this.ingredientEditExists();
                 EditIngredientValidation.qtyEdit( );
                return;
            }
    
        if($(this).val() === ''  && $('#ingredient_edit').val() === '' ) {
            errFound = true;
            errMsg = 'Please enter an ingredient name.'
        } else {
            var found = false;
            var _this = this;
            // allIngredients comes FROM RECIPEMODALS.tpl
            ALL_INGREDIENTS.forEach(function(ingredient){                
                if( ingredient === $(_this).val() || ingredient.label === $(_this).val())
                {
                    found = true;
                }
            });
            if(!found) {
                errFound = true;
                errMsg = 'Please select an ingredient from one of the drop down values.'
            }
        }

        _Validation.handleError(errFound, errMsg, $(this));
    }
    
    EditIngredientValidation.prototype.qty = function () {
        var errFound = true;
        var errMsg = '';
        var qty = $(this).val();
        
        try{
            if( _MenuAndIngredientList.addOrEdit == "add" ) {
                if( $(this).attr("id") == "amt_edit" ) return;
            }

            if( _MenuAndIngredientList.addOrEdit == "edit" ) {
                if( $(this).attr("id") == "amt" ) return;
            }
        }
        catch( e ){}



        if( $( '#modal-recipe-edit-ingredient' ).hasClass( 'show' ) ) return;

        if(qty === '') {
            errFound = true;
            errMsg = 'Please enter a unit quantity.';
        } else if( isNaN(_Util.fractionStrToDecimal(qty)) || !isFinite(_Util.fractionStrToDecimal(qty)) ) {
            errFound = true;
            errMsg = 'Please enter a numeric value for unit quantity.';
        }

        console.log(_Util.fractionStrToDecimal(qty))
        
        _Validation.handleError(errFound, errMsg, $(this));
    }

    EditIngredientValidation.prototype.ingredientEditExists = function (  )
    {
        var errFound = true;
        var errMsg = '';

        if( $( '#ingredient_edit' ).val(  ) === "" )
        {
            errFound = true;
            errMsg = 'Please select an ingredient from one of the drop down values.';
        }

    };

    EditIngredientValidation.prototype.ingredientExists = function () {
        var errFound = true;
        var errMsg = '';
        
        // Get data
        var $modal = $('.ingredient-modal.show');
        var $ingredientsList = $('.ingredients-list .item').not('.placeholder').not('.hidden');
        var editedIngredientName = $modal.find('.edit-ingredient-name').val();
        // Check how many ingredient name matches there are in the ingredient list
        var ingredientExists = false;
        var matchesFound = 0;
        $ingredientsList.each(function(i, el){
            var name = $(el).attr('data-name');
            if(name === editedIngredientName) matchesFound++;
        });

        // check if we are in edit or add ingredient modal
        var editModalOpen = $modal.find('.modal-content').attr('data-ingredientname');
        var addModalOpen = typeof $modal.find('.modal-content').attr('data-ingredientname') === 'undefined';
        
        // Decide if the ingredient exists
        if(editModalOpen) {
            var openedFrom = $modal.find('.modal-content').attr('data-ingredientname');

            if(editedIngredientName === openedFrom && matchesFound > 1)
                ingredientExists = true;
            else if (editedIngredientName !== openedFrom && matchesFound > 0)
                ingredientExists = true;

        } else if (addModalOpen && matchesFound > 0) {
            ingredientExists = true;
        }
 
        if(ingredientExists) {
        //    errFound = true;
         //   errMsg = 'You already have this ingredient in your recipes.'
        }
        
        _Validation.handleError(errFound, errMsg, $modal.find('.error-container'));
    }
    
    EditIngredientValidation.prototype.unit = function () {
        var errFound = true;
        var errMsg = '';
        var $modal = $('.ingredient-modal.show');
        var $unitSelect = $modal.find('.edit-ingredient-unit');

        if( typeof($unitSelect) == 'undefined' ) return;

        if($unitSelect[0].selectedIndex < 0  ||  $unitSelect[0].selectedIndex === "" || $unitSelect[0].selectedIndex === null) {
            errFound = true;
            errMsg = 'Please enter a unit name.';
        } 

        _Validation.handleError(errFound, errMsg, $(this));
    }

    EditIngredientValidation.prototype.binds = function(){
        var _this = this;

             $('.edit-ingredient-name').on('blur', this.name);
             $('.edit-ingredient-amount').on('blur', this.qty);
             $('.edit-ingredient-unit').on('blur', this.unit);
            
            $submitBtn = $('.ingredient-modal .btn-lg-primary');

            $submitBtn.on('mousedown keydown click', function(e){

                if( _Util.isHumanClickEvent(e) ) return; // human click does a double validation so dont allow 

                 $('.edit-ingredient-name').trigger('blur');
                 $('.edit-ingredient-amount').trigger('blur');
                 $('.edit-ingredient-unit').trigger('blur');


            _this.ingredientExists();
            })
        };

    // INIT
    new EditIngredientValidation();

})