var EditSublistValidation;

$(document).ready(function(){
    var _Validation = Validation.getInstance();
    var _Util = Util;
    var deleteTarget;

    EditSublistValidation = function(){
        this.binds();
    };
    EditSublistValidation.prototype.name = function () {
        var errFound = false;
        var errMsg = '';
    
        if($(this).val() === '') {
            errFound = true;
            errMsg = 'Please enter a sublist name.';
        }

        _Validation.handleError(errFound, errMsg, $(this));
    };
    EditSublistValidation.prototype.removeSublist  = function( sublistName ){

        console.log( "Delete sublist", sublistName );
        $("div.item.sub-list").filterByData( 'name', sublistName ).remove(  );

        //.delete( );
    };

    EditSublistValidation.prototype.binds = function(){
        var _this = this;
        $('.sublist-edit').on('blur', this.name);

        $( '.sublist-modal .delete-sublist-btn' ).on('mousedown keydown click', function( e )
        {
           //  if( _Util.isHumanClickEvent(e) ) return; // human click does a double validation so dont allow 

            var thisList = this;
           var sublistName = $(thisList).parents('.modal').find('input#name').val();
            

            e.preventDefault();
            confirmDialog( "Are you sure you want to delete this sublist?",  "Delete Sublist",  _this.removeSublist, sublistName );
           // $('.sublist-modal .btn-lg-basic' ).click(  );
        });


        $submitBtn = $('.sublist-modal .btn-lg-primary');
        $submitBtn.on('mousedown keydown click', function(e){
            if( _Util.isHumanClickEvent(e) ) return; // human click does a double validation so dont allow 

            $('.sublist-edit').trigger('blur');
        });
    };

    // INIT
    new EditSublistValidation();
});