var AjaxPagination = function() {
    _AjaxBuilder = new AjaxBuilder();

    // url passed from callee function
    this.getItems = _AjaxBuilder.buildFn({
        eventSuccessKey: 'ajax-pagination:item-data-received',
        extraSuccessKey: 'filters-presenter:refresh-counts',
        eventFailKey: 'ajax-pagination:item-data-error',
        eventAbortKey: 'ajax-pagination:call-aborted'
    })

    // url passed from callee function
    this.getMoreItems = _AjaxBuilder.buildFn({
        eventSuccessKey: 'ajax-pagination:item-data-received',
        xtraSuccessKey: 'filters-presenter:refresh-counts',
        eventFailKey: 'ajax-pagination:item-data-error',
        eventAbortKey: 'ajax-pagination:call-aborted'
    })
}
