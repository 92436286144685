
// Copy the content of an input element
// Just add it to an event listener.
// $('input').on('click', Clipboard.copy)

var Clipboard = (function(){
    var instance;
    var _Clipboard = function(){}
    _Clipboard.prototype.copy = function(input) {
        if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
            this.iosCopy(input)
            return;
        }
        input.select();
        document.execCommand('copy');
    }
    
    // iOS doesn't support input.select(). Here's some reading on this
    // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
    _Clipboard.prototype.iosCopy = function(input) {
        var oldContentEditable = input.contentEditable,
            oldReadOnly = input.readOnly,
            range = document.createRange();

        input.contentEditable = true;
        input.readOnly = false;
        range.selectNodeContents(input);

        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        input.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
        document.execCommand('copy');
        
        selection.removeAllRanges();

        input.contentEditable = oldContentEditable;
        input.readOnly = oldReadOnly;
    }
    
    return {
        getInstance: function(){
            if(!instance)
                instance = new _Clipboard();
            return instance;
        }
    }
})()

