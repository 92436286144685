// The only function we use here is renderLines.
// In short, it find each row and add a lines after it.
// It's just a bit tricky because row needs to change dynamically based on how many items are showing per row.

var _EventEmitter;
var _Util;

var browseTitlesCollection = {};
    browseTitlesCollection = function (){
        this.$lastInRowElements = null;
        this.lineHTML = '<div class="line"></div>';
        this.binds();
        this.eventSubscriptions();
    };

    browseTitlesCollection.initDependencies = function()
    {
        _EventEmitter = EventEmitter.getInstance();
        _Util = Util;
    };

    browseTitlesCollection.renderLines = function(){
        this.removeAllLines();
        this.refreshLastElements();
        this.insertLineAfterEachRow();
        this.makeLinesFullWidth();
    };

    browseTitlesCollection.removeAllLines = function ()
    {
        $('.list').find('.line').remove();
    };

    browseTitlesCollection.refreshLastElements = function ()
    {
        Util.tagLastElementsInRow($('.book-card').not('.placeholder'));
        this.$lastInRowElements = $('.pagination-component .last-element');
    };

    browseTitlesCollection.insertLineAfterEachRow = function()
    {
        const _this = this;

        this.$lastInRowElements.each(function (i, el) {
            var notLastRow = i != this.$lastInRowElements.length - 1
            if (notLastRow) {
                $(el).after(_this.lineHTML);
            }
        })
    };

    browseTitlesCollection.makeLinesFullWidth = function(){
        var $container = $('.list');
        $lines = $container.find('.line');
        var containerWidth = $container.outerWidth(true);
        var marginLeft = parseInt($container.css('marginLeft'));
        var paddingLeft = parseInt($container.css('paddingLeft'));
        var offset = marginLeft + paddingLeft;

        $lines.css({
            "width": containerWidth,
            "marginLeft": offset * -1
        })
    };

    browseTitlesCollection.binds = function(){
        $(window).resize(this.renderLines.bind(this));
        $('body').on('click', this.renderLines.bind(this));
    }

    browseTitlesCollection.eventSubscriptions = function (){
        _EventEmitter.subscribe('cards-rendered-after-ajax', this.renderLines.bind(this));
    }
