// Used in pages requiring live unit conversion

var MetricConversionHelper = function(){
    var _AjaxConversion = new AjaxConversion();
    var _Util = Util;
    var _ConversionModel = ConversionModel.getInstance();
    var onEditPage = ( $('.yield-control select').length && $('.serving-control select').length);
    var onGetPage = ( !$('.yield-control select').length || !$('.serving-control select').length);
    var _localSmartConvert  = SmartConvert.getInstance();
    var currentSystem;
    var $yieldCtrl = $('.yield-control');
    var $servingCtrl = $('.serving-control');

    var shareUrl = "";

    this.init = function(){
        currentSystem = $('.system-selection input:checked').val();
    }

    this.scrapeMeasurementControlValues = function(){
        var $yieldCtrl = $('.yield-control');
        var $servingCtrl = $('.serving-control');

        // GET AMOUNTS
        var yieldAmt = $('.yield-control .yield-amt').val();


        var servingSizeAmt;
        var numOfServings;
        if (onGetPage) {
            servingSizeAmt = $('.serving-control .amt').text();
            numOfServings = _Util.fractionStrToDecimal($('.number-of-servings-edit').val())                
        } else if(onEditPage){
            servingSizeAmt = $('.serving-control .serving-amt').val();
            numOfServings = $('.servings .num-of-servings').text();
        }

        // Get UNIT DATA
        var yieldUnitCode = $yieldCtrl.find('select').attr('data-selected');
        var yieldUnitName = $yieldCtrl.find('select option[value="'+yieldUnitCode+'"]:first').text();
        var servingUnitCode;
        var servingUnitName;
        var servingCurrentConversion;

        if(onGetPage) {
            servingUnitCode = $servingCtrl.find('.serving-unit').attr('data-unit-code');
            servingUnitName = $servingCtrl.find('.serving-unit').text();
            servingCurrentConversion = $servingCtrl.attr('data-currentconversion');
        } else if (onEditPage) {
            servingUnitCode = $servingCtrl.find('#serving-unit-select').attr('data-selected');
            servingUnitName = $servingCtrl.find('#serving-unit-select option[value="'+servingUnitCode+'"]').text();

            servingCurrentConversion = $servingCtrl.attr('data-currentconversion');

        }

        return {
            yieldAmt: yieldAmt,
            yieldUnitCode: yieldUnitCode,
            yieldUnitName: yieldUnitName,
            servingSizeAmt: servingSizeAmt,
            servingUnitCode: servingUnitCode,
            servingUnitName: servingUnitName,
            currentConversion: servingCurrentConversion,
            numOfServings: numOfServings,
        }
    }

    this.cacheInitialConversionValues = function(measurementData){
        var measurementData = (typeof measurementData !== 'undefined') 
            ? measurementData
            : this.scrapeMeasurementControlValues();
			
		var currentConversion = 1;
		if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
			currentConversion = 2;
		}
        
        // Cache conversion 1 values
		if(currentConversion == 1){
			if( typeof( measurementData.currentConversion ) == "undefined" || measurementData.currentConversion == 1 )
			{
			   
				$servingCtrl.attr('data-amt1', measurementData.servingSizeAmt);
				$servingCtrl.attr('data-unitname1', measurementData.servingUnitName);
				$servingCtrl.attr('data-unitcode1', measurementData.servingUnitCode);
			}
	
	
			$yieldCtrl.attr('data-amt1', measurementData.yieldAmt);
			$yieldCtrl.attr('data-unitname1', measurementData.yieldUnitName);
			$yieldCtrl.attr('data-unitcode1', measurementData.yieldUnitCode);
		} else {
			$yieldCtrl.attr('data-amt2', measurementData.yieldAmt);
			$yieldCtrl.attr('data-unitname2', measurementData.yieldUnitName);
			$yieldCtrl.attr('data-unitcode2', measurementData.yieldUnitCode);
		}
        
    }

    // Call this every time the user changes an field in the metric conversion system so we can restart the system with the new values
    this.cacheCtrlUnitConversions  = function(){
        var measurementData = this.scrapeMeasurementControlValues();
        this.cacheInitialConversionValues();

        // LOOP through them and make the necessary AJAX calls to get & cache the second conversions
        var conversionData = [
            { $ctrl: $yieldCtrl, amt: measurementData.yieldAmt, unitCode: measurementData.yieldUnitCode, unitName: measurementData.yieldUnitName },
            { $ctrl: $servingCtrl, amt: measurementData.servingSizeAmt, unitCode: measurementData.servingUnitCode, unitName: measurementData.servingUnitName }
        ]

        conversionData.forEach(function(data){
        var ajaxAmt = _localSmartConvert.cleanNumber( data.amt );
            _AjaxConversion.getCombinedConversion( {
                params: {
                    unitName: data.unitName.replace(/\s/g, ''),
                    fromId: data.unitName,
                    amount: ajaxAmt
                }
             }).then(function(res){
                    ajaxResult = JSON.parse( res );

                     //if(!ajaxResult.Data) return;
                      if( !ajaxResult.Data || (ajaxResult.Response['Status-code'] != "0" &&  ajaxResult.Response['Status-code'] != "200") ){

                            var conv = ajaxAmt;
                           
                            var amount = conv;
                            var originalUnit = ingredientUnitName;
                            var oppositeUnit = originalUnit;
                            var originalId = ingredientUnitId;
                            var oppositeId = originalId;

                        }
                        else
                        {

                            ajaxResult.Data = JSON.parse( ajaxResult.Data );
                            var conv = ajaxResult.Data.conversion;
                            conv = conv * ajaxAmt;

                            var amount = _Util.convertToTwoDecimals(conv);
                            var oppositeUnit = ajaxResult.Data.originalUnit;
                            var originalUnit =  ajaxResult.Data.oppositeUnit;
                            var originalId = ajaxResult.Data.originalId;
                            var oppositeId = ajaxResult.Data.oppositeId;
                            converted = _localSmartConvert.doConvert( amount, oppositeUnit );
                       
                        }
						
						if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
							data.$ctrl.attr('data-amt1', amount);
							data.$ctrl.attr('data-amt', amount);
							data.$ctrl.attr('data-unitname1', oppositeUnit);
							data.$ctrl.attr('data-unitcode1', originalId);
							data.$ctrl.attr('data-currentconversion', '2');  
							data.$ctrl.attr('data-currentconversion', '2');
						} else {
							data.$ctrl.attr('data-amt2', amount);
							data.$ctrl.attr('data-unitname2', oppositeUnit);
							data.$ctrl.attr('data-unitcode2', originalId);
							data.$ctrl.attr('data-currentconversion', '1');  
							data.$ctrl.attr('data-currentconversion', '1');
						}

                        //if( !data.$ctrl.attr('data-original2' ) ) data.$ctrl.attr('data-original2',amount );
        

             });
        }); 
    };

    // UPDATE UI
    // Convert units from US to metric or vice versa
    // These conversion should already be saved via HTML data attributes
    // These are updated on input change & initial page load via
    // cacheInitialConversionValues (initial page load)
    // && cacheCtrlUnitConversions (user changes input)
    this.crossConvertCtrlUnits = function(){
		var currentConversion = 2;
		if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
			currentConversion = 1;
		}
		
        if(onGetPage) {
            var $controllers = [$yieldCtrl];
            // Update serving controller manually
            //var currentConversion = $servingCtrl.attr('data-currentconversion');

            if(currentConversion == 1) {
                $servingCtrl.find('.amt').text($servingCtrl.attr('data-amt2'));
                $servingCtrl.find('.serving-unit').text($servingCtrl.attr('data-unitname2'));
                $servingCtrl.attr('data-currentconversion', '2'); 
				$servingCtrl.attr( 'data-currentconversion', '2');

            } else if (currentConversion == 2) {
                $servingCtrl.find('.amt').text($servingCtrl.attr('data-amt1'));
                $servingCtrl.find('.serving-unit').text($servingCtrl.attr('data-unitname1'));
                $servingCtrl.attr('data-currentconversion', '1'); 
				$servingCtrl.attr( 'data-currentconversion', '1');
            }
            // console.log(currentConversion);
        } else if (onEditPage) {
            var $controllers = [$yieldCtrl, $servingCtrl];
        }

        $controllers.forEach(function($ctrl) {
            //var currentConversion = $ctrl.attr('data-currentconversion');
            if(currentConversion == 1) {
                $ctrl.find('.amt-input').val($ctrl.attr('data-amt2'));
                $ctrl.find('select').attr('data-selected', $ctrl.attr('data-unitcode2'));
                $ctrl.find('select').val($ctrl.attr('data-unitcode2'));
                $ctrl.attr('data-currentconversion', '2');
				$ctrl.attr( 'data-currentconversion', '2');

                 $( '#main > div.container > div.measurement-controls > div > div > div.measurement-group.yield.col-6.col-sm-4 > div.print-only > p > span.yield' ).text( $ctrl.attr('data-amt2') );
                $( '#main > div.container > div.measurement-controls > div > div > div.measurement-group.yield.col-6.col-sm-4 > div.print-only > p > span.unit-code' ).text( $ctrl.attr('data-unitname2') );


            } else if (currentConversion == 2) {
                $ctrl.find('.amt-input').val($ctrl.attr('data-amt1'));
                $ctrl.find('select').attr('data-selected', $ctrl.attr('data-unitcode1'));
                $ctrl.find('select').val($ctrl.attr('data-unitcode1'));                
                $ctrl.attr('data-currentconversion', '1');
				$ctrl.attr( 'data-currentconversion', '1'); 


                $( '#main > div.container > div.measurement-controls > div > div > div.measurement-group.yield.col-6.col-sm-4 > div.print-only > p > span.yield' ).text( $ctrl.attr('data-amt1') );
                $( '#main > div.container > div.measurement-controls > div > div > div.measurement-group.yield.col-6.col-sm-4 > div.print-only > p > span.unit-code' ).text( $ctrl.attr('data-unitname1') );

            }
        

     
         });  

    }

    this.crossConvertIngredients = function(){
        var $ingredients = $('.ingredients-list.ingredients').find('.item').not('.placeholder').not('.hidden');
        $ingredients.each(function(i, ingredient){

            var _this = this;
            // Initial conversion then just use data- attributes to toggle values
            if(typeof $(ingredient).attr('data-amt1') === 'undefined') {
				var ingredientUnitId = $(ingredient).attr('data-unit');
				_AjaxConversion.getUnitNameFromId( {
					params: {
						unitId: ingredientUnitId
					}
				}).then(function(res2){
					var ajaxResult = JSON.parse( res2 );
					//console.log( ajaxResult );
					
					var ingredientAmount = $(ingredient).closest("div.item").attr("data-amt");
					var ingredientUnitName = ajaxResult.Data.replace(/['"]+/g, '');//$(ingredient).find('.amount .unit').text();
					if(typeof( ingredientUnitName ) == "undefined"){
						ingredientUnitName = $(ingredient).find('.amount .unit').text();
					}
					
					
					if( !ingredientAmount || typeof( ingredientAmount ) == "undefined" )
						ingredientAmount = $(ingredient).find('.amount .amt').text();
	
				   // ingredientAmount = _localSmartConvert.cleanNumber( ingredientAmount );
					
					var ajaxAmt = _localSmartConvert.cleanNumber(   ingredientAmount );
					
					$(ingredient).attr('data-amt1', ajaxAmt);
					$(ingredient).attr('data-unit1', ingredientUnitName);
					// var isRecipe = $(ingredient).attr( 'data-category').toLowerCase().includes('recipe'); 
					// var ingredientUnitId = $(ingredient).attr( 'data-unit');
					// var ingredientId = $(ingredient).attr( 'data-nrecingid');
					// if(isRecipe) ingredientId = -1;
					
					
	
					_AjaxConversion.getCombinedConversion( {
						params: {
							unitName: ingredientUnitName.replace(/\s/g, ''),
							amount: ajaxAmt
						}
					 }).then( function( res ){
							console.log( "Ingredient convert returned" );
							var ajaxResult = JSON.parse( res );
							console.log( ajaxResult );
							//if(!ajaxResult.Data) return;
							if( !ajaxResult.Data || (ajaxResult.Response['Status-code'] != "0" &&  ajaxResult.Response['Status-code'] != "200") ){
	
								var conv = ajaxAmt;
							   
								var amount = conv;
								var originalUnit = ingredientUnitName;
								var oppositeUnit = originalUnit;
								var originalId = ingredientUnitId;
								var oppositeId = originalId;
	
							}
							else
							{
								ajaxResult.Data = JSON.parse( ajaxResult.Data );
								var conv = ajaxResult.Data.conversion;
								conv = conv * ajaxAmt;
	
								var amount = _Util.convertToTwoDecimals(conv);
								var oppositeUnit = ajaxResult.Data.originalUnit;
								var originalUnit = ajaxResult.Data.oppositeUnit;
								var originalId = ajaxResult.Data.originalId;
								var oppositeId = ajaxResult.Data.oppositeId;
	
								converted = _localSmartConvert.doConvert( amount, oppositeUnit );
	
							$(ingredient).find( '.amount .amt' ).text( converted.newUnit.quantityFraction );
							$(ingredient).find( '.amount .unit' ).text( converted.newUnit.unitName );
							}
						   
							$(ingredient).attr( 'data-amt2', amount );
							$(ingredient).attr( 'data-unit2', oppositeUnit );
							$(ingredient).attr( 'data-currentconversion', '2' );
							$(ingredient).attr('data-unitcode2', oppositeId);
					 });
				});
            } else {
                //var currentConversion = $(ingredient).attr('data-currentconversion');
				var currentConversion = 2;
				if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
					currentConversion = 1;
				}
                window.currentConversion = currentConversion;
                if(currentConversion == 1) {
                    var amt2 = $(ingredient).attr('data-amt2');
                    var unit2 = $(ingredient).attr('data-unit2');
                    
                    var show2 = _localSmartConvert.doConvert( amt2, unit2 );

                    $(ingredient).find('.amount .amt').text( show2.newUnit.quantityFraction );
                    $(ingredient).find('.amount .unit').text( show2.newUnit.unitName );


                    $(ingredient).attr('data-currentconversion', '2');
					$(ingredient).attr( 'data-currentconversion', '2');
                } else if (currentConversion == 2) {
                    var amt1 = $(ingredient).attr('data-amt1');
                    var unit1 = $(ingredient).attr('data-unit1');

                    var show1 = _localSmartConvert.doConvert( amt1, unit1 );

                   $(ingredient).find('.amount .amt').text( show1.newUnit.quantityFraction );
                    $(ingredient).find('.amount .unit').text( show1.newUnit.unitName );
                    $(ingredient).attr('data-currentconversion', '1');
					$(ingredient).attr( 'data-currentconversion', '1');
                }
            }
        })
    }


    // convert unit codes numbers to their corresponding text
    this.renderUnitCodes = function() {
        // Units codes in spans
        var unitCodesTable = new UnitCodes().getTable();
        var $unitCodeSpans = $('.unit-code');
        // console.log($unitCodeSpans);
        $unitCodeSpans.each(function (i, el) {
            var unitCode = $(el).attr( 'data-unit-code');
            var unit = unitCodesTable[unitCode - 1];
            $(el).text(unit.single);
        })
    }

    // If the user clicks on metric hide american values and vice versa
    this.handleMeasurementOptionHiding = function(){
        var selectedSystem = $('.measurement-system-radio:checked').val();
        if(selectedSystem === 'american'){
            var unitsToHide = _ConversionModel.getMetricUnits();
            this.hideUnitsFromSelect(unitsToHide)
        } else if (selectedSystem === 'metric') {
            var unitsToHide = _ConversionModel.getAmericanUnits();
            this.hideUnitsFromSelect(unitsToHide)
        }
    }
    this.hideUnitsFromSelect = function(unitsToHide){
        $('.measurement-controls option').show();
        unitsToHide.forEach(function(unit){
            var $matchingOptions = $('.measurement-controls option').filter(function () { 
                return $(this).text().toLowerCase() == unit.toLowerCase(); 
            });
            $matchingOptions.hide();
        })
    }

    this.generateShareLink = function(  )
    {
        // get id from url
        var currentUrl = window.location.href;
        var urlParts = currentUrl.split( "/" );
        var recipeId = urlParts.pop(  );
        recipeId = recipeId.replace( "#","" );

        // get the system in use
        var active =$( "input[name=measurement-system]:checked" ).val(  );
        var systemCodeForLink = "A";
        if( active == "metric" ) systemCodeForLink = "M";

        shareUrlTemp = SITE_URL + "kitchen/recipe/" + systemCodeForLink  +  "/" + recipeId + "?n=";
        return shareUrlTemp;
    }

    this.binds = function(){
        var _this = this;

        $('.measurement-system-radio').on('click', function () {
        var systemSelectedName =$( "input[name=measurement-system]:checked" ).val(  );
        var selectedSystem = systemSelectedName;

            $('#email-share-url').val( _this.generateShareLink(  ) );

            $('#modal-share-recipe').on('show.bs.modal', function () {
                    $(this).find('#email-share-url').val( _this.generateShareLink(  ) );
            });

            if(selectedSystem !== currentSystem) {
                currentSystem = selectedSystem;
                _this.crossConvertCtrlUnits();
                _this.crossConvertIngredients();
            }
            _this.handleMeasurementOptionHiding()
        });
		$('#number-of-servings').on('change', function () {
			setTimeout(function() {
				_this.cacheCtrlUnitConversions();
			}, 100);
        });
        $('#yield-input').on('change', function () {
			setTimeout(function() {
				_this.cacheCtrlUnitConversions();
			}, 100);
        });
        $('#serving-input').on('change', function () {
            setTimeout(function() {
				_this.cacheCtrlUnitConversions();
			}, 100);
        });
		$('#number-of-servings_recipe').on('change', function () {
			setTimeout(function() {
				_this.cacheCtrlUnitConversions();
			}, 200);
        });
        $('#yield-unit-select').on('change', function () {
            setTimeout(function() {
                _this.cacheCtrlUnitConversions();
            }, 100);
        });
        $('#serving-unit-select').on('change', function () {
            // console.log('caching');
            setTimeout(function() {
                _this.cacheCtrlUnitConversions();
            }, 100);
        });
    }

}

     