
// /**
//  * Category Filter 
//  * that works in conjuction with JPLIST
//  * 
//  * Filter basically does 3 things.
//  * 1) Keeps track of activated filters
//  * 2) Refreshes items (UI) whenever a new filter is added or removed. Filter.refreshItems is from Base class(filter.js)
//  * 3) FilterCheckbox at the bottom handles extra UI whenever filters are added or removed. It adds or removes the badge icons on top of the items. 
//  * 
//  * Has a small dependency on Util.convertToCSSClass(str) from util.js
//  * 
//  */
// Filter.Category = {
//     $filteredItems: null,
//     filtersArr: null,
//     init: function () {
//         this.initFilters();
//         this.binds();
//     },
//     // These binds are used to add and remove filters
//     binds: function () {
//         $(document).on('click', '.filter-badge .filter-close', function () {
//             var filterName = $(this).closest('.filter').text();
//             var filterClass = Util.convertToCSSClass(filterName)
//             Filter.Category.removeFilter(filterClass);
//             // if()
//             $("label[for='" + filterName + "'].selected").closest('.custom-checkbox').trigger('click');
//         })

//         $(document).on('click', '.clear-all-badges', function () {
//             $('.filter-badge-collection .filter').each(function () {
//                 var filter = Util.convertToCSSClass($(this).text());
//                 Filter.Category.removeFilter(filter);
//             });

//         });

//         // More complex - the checkboxes in the filter component works in conjuction custom badge section on the right side
//         // This function binds their click responses together
//         $('.filters-component .category-filter').mouseup(function (e) {
//             var $this = $(this);
//             setTimeout(function () {
//                 var $input = $this.find('input');
//                 if (!$input.prop('checked')) {
//                     FilterCheckbox.removeAssociatedFilters($this);
//                 } else {
//                     var category = $this.closest('.collapse-content').siblings('.collapse-ctrl').find('p').text();
//                     var categoryClass = Util.convertToCSSClass(category)
//                     var $filterBadgeCategory = $('.' + categoryClass);
//                     var categoryExists = $filterBadgeCategory.length > 0

//                     if (categoryExists)
//                         FilterCheckbox.addFilterToExistingCategory($this);
//                     else
//                         FilterCheckbox.addCategoryAndFilter($this);
//                 }
//             }, 1)
//         });
//     },
//     // Get array of filters in view
//     getCurrentFilters: function () {
//         var filters = []
//         $('.filter-badge .filter').each(function () {
//             filters.push(Util.convertToCSSClass($(this).text()))
//         })
//         return filters;
//     },
//     // Get items that either match or dont match filters
//     filterItems: function (options) {
//         var $items = options.$items;
//         var filtersArr = options.filtersArr;
//         var getItemsThatMatch = options.getItemsThatMatch;
//         var cb = options.callback || function () { }

//         return $items.filter(function (i) {
//             var $this = $(this);
//             var matchedAllFilters = false;
//             var filtersMatched = 0;
//             var filtersToMatch = filtersArr.length;

//             filtersArr.forEach(function (filter) {
//                 var hasFilter = $this.find('.' + filter).length !== 0;
//                 if (hasFilter) filtersMatched++;
//                 if (filtersMatched === filtersToMatch) {
//                     matchedAllFilters = true;
//                     cb($this)
//                 }
//             });
//             if (!getItemsThatMatch) return !matchedAllFilters;
//             if (getItemsThatMatch) return matchedAllFilters;
//         })
//     },
//     // Get filters from UI
//     initFilters: function () {
//         this.filtersArr = this.getCurrentFilters();
//     },

//     addFilter: function (newFilter) {
//         var filtersOn = (this.filtersArr.length) ? true : false;
//         this.filtersArr.push(newFilter);
//         Filter.refreshItems();
//     },

//     removeFilter: function (filter) {
//         var i = this.filtersArr.indexOf(filter);
//         if (i > -1) this.filtersArr.splice(i, 1);
//         Filter.refreshItems();
//     },

//     // Go through all the filters and get the items that match them
//     // Called by base class (Filter.js)
//     runFilter: function ($items) {
//         if (this.filtersArr.length === 0) return $items;
//         return this.filterItems({
//             $items: $items,
//             filtersArr: this.filtersArr,
//             getItemsThatMatch: true
//         })
//     },

// };


// /**
//  * FILTER CHECKBOX SYSTEM
//  * 
//  * It connects Filter Component Checkboxes UI with Filter Badges UI & the Category Filter functionality
//  * 
//  */
// var FilterCheckbox = {};
// FilterCheckbox.removeAssociatedFilters = function ($checkbox) {
//     var category = $checkbox.closest('.collapse-content').siblings('.collapse-ctrl').find('p').text();
//     var categoryClass = Util.convertToCSSClass(category)
//     var filterName = $checkbox.find('input').attr('id');
//     var $filterBadgeCategory = $('.' + categoryClass);
//     var $filters = $filterBadgeCategory.siblings('.filter');
//     var filterExists = false;
//     $filters.each(function (i, filter) {
//         if ($(filter).text() === filterName) {
//             filterExists = true;
//             $(filter).find('.filter-close').trigger('click', {
//                 clickFromOutsideComponent: true
//             });
//         }
//     });
// };

// FilterCheckbox.addFilterToExistingCategory = function ($checkbox) {
//     var category = $checkbox.closest('.collapse-content').siblings('.collapse-ctrl').find('p').text();
//     var categoryClass = Util.convertToCSSClass(category)
//     var filterName = $checkbox.find('input').attr('id');
//     var filterClass = Util.convertToCSSClass(filterName);
//     var $filterBadgeCategory = $('.' + categoryClass)
//     var $filterBadge = $filterBadgeCategory.closest('.filter-badge');

//     var filterOnAlready = $filterBadgeCategory.find('.' + filterClass).length > 0;
//     if (filterOnAlready) return;

//     var filterHTML = '<div class="filter ' + filterClass + '">'
//         + filterName
//         + '<img class="filter-close" src="..//assets/images/style/Icon-Remove-sm@2x.png" alt="" tabindex="0" role="button" aria-label="Press Enter key to Remove Filter 1 Filter">'
//         + '</div>';

//     $filterBadge.append(filterHTML)

//     Filter.Category.addFilter(filterClass);
// }

// FilterCheckbox.addCategoryAndFilter = function ($checkbox) {
//     var category = $checkbox.closest('.collapse-content').siblings('.collapse-ctrl').find('p').text();
//     var categoryClass = Util.convertToCSSClass(category)
//     var filterName = $checkbox.find('input').attr('id');
//     var filterClass = Util.convertToCSSClass(filterName);
//     var $filterBadgeCollection = $('.filter-badge-collection');
//     var filterHTML = '<div class="filter-badge">'
//         + '<div class="group-name ' + categoryClass + '">' + category + ':</div>'
//         + '<div class="filter ' + filterClass + '">'
//         + filterName
//         + '<img class="filter-close" src="..//assets/images/style/Icon-Remove-sm@2x.png" alt="" tabindex="0" role="button" aria-label="Press Enter key to Remove Filter 1 Filter">'
//         + '</div>'
//         + '</div>'
//         + '</div>';

//     $filterBadgeCollection.find('.filters').append($(filterHTML))
//     Filter.Category.addFilter(filterClass);
// }
