var RecipePresenter;

$(document).ready(function(){
    var _UnitCodes = new UnitCodes().getTable();
    var _AjaxMenu = new AjaxMenu();
    var _LikeBtn = LikeBtn;
    var _Util = Util;
    var _GenericAlert = new GenericAlert();
    var _EventEmitter = EventEmitter.getInstance();

    MenuPresenter = (function(){
        var instance;

        var _MenuPresenter = function(){
            this.savedMenuId;
        }

        _MenuPresenter.prototype.scrapEditMenuInfo = function(){
            var menuId = $('#nMenuId').text();
            var menuTitle = $('.menu-title-edit').val();
            var menuGuests = $('.guests-edit').val();
            var menuHours = $('.hours-edit').find(":selected").text();
            var menuMinutes = $('.minutes-edit').find(":selected").text();
            var menuMeridiem = $('.meridiem-edit').find(":selected").text();
            var menuDate = $('.date-edit').val();
            var menuLocation = $('.location-edit').val();

            // Get the ingredients
            var recipesList = [];
            var $recipes = $('.ingredients-list.menu .item').not('.placeholder').not('.hidden')
            $recipes.each(function(i, el){
                $recipe = $(el);
                
                var recipeObj = {
                    nRecipeId: $recipe.attr('data-id'),
                    vcName: $recipe.attr('data-name'),
                    dNumberOfServings: parseInt($recipe.attr('data-servings')),
                }
                recipesList.push(recipeObj);
            })

            return {     
                menuId: menuId,
                menuTitle: menuTitle,
                menuGuests: menuGuests,
                menuHours: menuHours,
                menuMinutes: menuMinutes,
                menuMeridiem: menuMeridiem,
                menuDate: menuDate,
                menuLocation: menuLocation,
                recipesList: recipesList
                // nRecIngId: nRecIngId,
                // dBakersPercentage: dBakersPercentage,
                // oIngredientsList: ingredientList
            }
        }
    
        _MenuPresenter.prototype.AjaxSaveMenu = function(){
            var _this = this;

            var menuInfo = this.scrapEditMenuInfo()
            var menuJson =  JSON.stringify(menuInfo)
            console.log(menuInfo);
            _AjaxMenu.save({
                params: {
                    authToken: '12345',
                    menu: menuJson
                }
            }).then(function(res){
                console.log(res);
                var data = JSON.parse(res).Data;
                _this.savedMenuId = data.nMenuId;
                console.log(data);
                if(data) {
                    _GenericAlert.show({
                        title: 'Menu Saved',
                        msg: menuInfo.menuTitle + ' has been saved successfully.'
                    });
                }
            })
        }
        
        _MenuPresenter.prototype.AjaxDeleteMenu = function(){
            var menuId = $('#nMenuId').text();
            
            _AjaxMenu.delete({
                params: {
                    authToken: '12345',
                    menuId: menuId
                }
            }).then(function(res){ 
                var noError =  _Util.isJSON(res);                
                
                if(noError) {
                    _GenericAlert.show({
                        title: 'Menu Deleted',
                        msg: 'Your menu has been deleted.'
                    })
                    setTimeout(function(){
                        window.location.href = SITE_URL + 'menu/myMenus'; 
                    },1000)
                }          
            })
        }

        _MenuPresenter.prototype.redirectToMenu = function(){
            if(typeof this.savedMenuId !== 'undefined') {
                window.location.href = SITE_URL + 'menu/getMenu/' + this.savedMenuId; 
            }
        }

        return {
            getInstance: function(){
                if(!instance) instance = new _MenuPresenter();
                return instance;
            }
        }
    })();
    
    // INIT
    
    if(document.location.href.includes('/menu/')) {
        
        var _MenuPresenter = MenuPresenter.getInstance();
        var _EditMenuValidation = new EditMenuValidation();
        var _UIBehaviors = new UIBehaviors();

        _EventEmitter.subscribe('ajax-menu:checkIfExists-success', _MenuPresenter.processMenuExistsRes);
        $('#alert-generic').on('click', function(){ _MenuPresenter.redirectToMenu(); })

        $('.save-menu-btn').on('click', function(){
            var errorsOnPage = false;
            $('.container .error').not('.main-error').each(function(i, el) {
                if( $(el).text() !== '') 
                    errorsOnPage = true;
            })
            if(errorsOnPage)
                $('.product-controls .error').text('Please correct the errors below before saving.')
            else {
                $('.product-controls .error').text('');
                _MenuPresenter.AjaxSaveMenu()
            }
        });

        // $('#alert-menu-exists .replace-btn').on('click', function(){
        //     _MenuPresenter.AjaxSaveMenu();
        // });
        $('.delete-menu-btn').on('click', function(){
            _MenuPresenter.AjaxDeleteMenu();
        });
    }
});

