/**
 * Paginator is in charge of getting more items from the backend.
 * It uses AjaxPagination, which is just a layer to deal purely with the ajax call.
 * It uses EventEmitter to let it's subscribers know if it got the data or if it got no results
 * 
 * Flow is:
 *      1) A higher layer creates a new Paginator w correct parameters
 *      2) A higher layer calls getPageResults to get the items they want
 *      3) Paginator goes to a loop. GetMorePageResults, where if you have ALL the results you need you will emit a "success event". The items we need
 *         are inside this.currentPageData.itemsArr
 *      4) Otherwise we call GetMoreItems, which queries ajax again, and does some validation. If we successfully get more data
 *         we go back to step 3 GetMorePage results and start over. until we get the amount of data requested.
 *
 */     



var Paginator;
$(document).ready(function () {
    var E = {
        DB_RETURNED_EMPTY_RESULTS: 'DB returned empty results',
        NO_MORE_AJAX_PAGES: 'No more ajax pages to query.',
        ERROR_RETRIEVING_ITEMS: 'error retrieving items'
    }

    var _AjaxPagination = new AjaxPagination();
    var _EventEmitter = EventEmitter.getInstance();

    /**
     * CONSTRUCTOR
     * @param {Object} options
     * @param {Int} options.perPage - how many results per page
     * @param {Int} options.batchSize - doesn't matter I think backend just gets 200
     * @param {string} options.getItemsUrl - ajax url to get items
     * @param {string} options.getMoreItemsUrl - ajax url to get items
     * @param {Object} options.ajaxParams - params passed on ajax url
     * 
     */
    Paginator = function Paginator(options) {
        this.currentPage = 0;
        this.totalPages = 0;
        this.perPage = options.perPage;
        this.batchSize = options.batchSize;
        this.items = [];
        this.currentPageData = {
            itemsArr: [],
            needMoreItems: true
        };
        this.indexStart = 0;
        this.indexEnd = 0;

        this.AjaxService = _AjaxPagination;
        this.ajaxPage = 0;  
        this.ajaxUrl = options.url;
        this.ajaxParams = options.ajaxParams;
        // set these w the initial request data
        this.ajaxPageCount = 0;
        this.ajaxItemCount = 0;
        this.getItemsUrl = options.getItemsUrl;
        this.getMoreItemsUrl = options.getMoreItemsUrl;

        this.err = '';
        // reset event subscriptions
        _EventEmitter.unsubscribe('ajax-pagination:item-data-received');
        _EventEmitter.unsubscribe('ajax-pagination:item-data-error');
    }

    // TODO: Make state more specific not just return this
    Paginator.prototype.getState = function(){
        return this;
    }

    Paginator.prototype.updateState = function (options) {
        // update all properties passed
        for (var prop in this) {
            for (var propToUpdate in options) {
                if (prop === propToUpdate) {
                    this[prop] = options[propToUpdate];
                }
            }
        }
        // update - special update operations
        if (options.currentPageData) {
            this.currentPageData.itemsArr = options.currentPageData.itemsArr;
            this.currentPageData.needMoreItems = this.currentPageData.itemsArr.length !== this.perPage;
        }
    }
    // Wrapper - Easier to know what's going on if this function is called instead of the updateState function in here
    Paginator.prototype.refreshCurrentPageItems = function () {
        this.updateState({
            currentPageData: {
                itemsArr: this.items.slice(this.indexStart, this.indexEnd)
            }
        })
    };

    /**
     * Gets items for options.pageNum. If not store in paginator it queries the server for the next batch of items
     * It knows when you need extra items by checking missing slots in the current results array.
     * 
     * @param {Obj} options
     * @param {Int} options.perPage 
     * @param {String} options.direction - next, previous, same, first
     */
    Paginator.prototype.getPageResults = function (options) {
        // edge case
        var gettingNegativePage = options.direction === 'previous' && this.currentPage === 1;
        if (gettingNegativePage){ return; } 

        var notInitialized = this.ajaxItemCount === 0;
        var changingPage = options.direction === 'next' || options.direction === 'prev'
        if(notInitialized && changingPage) return;

        this.updateState({
            perPage: options.perPage,
            totalPages: Math.ceil(this.ajaxItemCount / this.perPage),
            currentPage: this.getPageNum(options.direction),
        });
        // TODO: right now these updates are dependent on previous updates...create better structure
        this.updateState({ indexStart: this.perPage * (this.currentPage - 1)  })
        this.updateState({ indexEnd: this.indexStart + this.perPage });

        this.refreshCurrentPageItems();
        this.checkForMoreItems();
		$('.pagination-component').find('.list .list-item:nth-child(3) a:first-child').focus();
    }

    Paginator.prototype.getPageNum = function (direction) {
        if (direction === 'next') return ++this.currentPage;
        if (direction === 'previous') return --this.currentPage;
        if (direction === 'same') return this.currentPage;
        if (direction === 'first') return 1;
    }

    Paginator.prototype.checkForMoreItems = function(){
        if (this.currentPageData.needMoreItems && this.err === '') {
            this.getMoreItems();
        } else {
            // edge case
            var noNewResults = this.currentPageData.itemsArr.length === 0;
            var nextPageDoesntExist = noNewResults && this.currentPage !== 1
            if (nextPageDoesntExist) {
                this.getPageResults({
                    perPage: this.perPage,
                    direction: 'previous'
                });
                return;
            }
            // For scenarios where we already have the items locally, refresh the count here instead of after the AJAX success event
            var totalAppPages = Math.ceil(this.ajaxItemCount / this.perPage);
            this.updateState({ totalPages: totalAppPages });
            // console.log('paginator success item count');
            // console.log(this);

            // if (this.err) {
            //     _EventEmitter.emit('paginator:data-error', this.err);
            // } 

            if(this.currentPageData.itemsArr.length === 0) {
                _EventEmitter.emit('paginator:no-results');            
            } else {
                // console.log('paginator ÷data received:');
                // console.log(this);
                _EventEmitter.emit('paginator:data-received', this.currentPageData.itemsArr);
            }
        }
    }

    /**     
     * ie Need 5 results per page but result = [6];
     * So get more items via ajax call to fill the empty slots (4 in this case)
     */
    Paginator.prototype.getMoreItems = function () {
        // edge case
        var noMoreAjaxPages = this.ajaxPageCount && this.ajaxPage + 1 > this.ajaxPageCount;
        if (noMoreAjaxPages) {
            this.updateState({ err: E.NO_MORE_AJAX_PAGES });
            console.warn(this.err);
            return this.checkForMoreItems();
        }

        this.updateState({ ajaxPage: this.ajaxPage + 1 });
        var onFirstAjaxPage = this.ajaxPage === 1;
        var _paginator = this;

        // These EventEmitter events will be called every time  ajax finishes processing,
        // SUBSCRIBE TO THEM ONLY ONCE
        if (onFirstAjaxPage) { 
            this.AjaxService.getItems({
                url: this.getItemsUrl,
                params: this.ajaxParams
            });
            // every time you make an ajax call (above) and receive the data this is called
            _EventEmitter.subscribe('ajax-pagination:item-data-received', function(res){
                var res = JSON.parse(res);
                _paginator.getMoreItemsSuccess(res);
                _paginator.checkForMoreItems();
                _EventEmitter.unsubscribe('ajax-pagination:item-data-received'); //To avoid duplication after reload the page when filter is applied.
            });
            _EventEmitter.subscribe('ajax-pagination:item-data-error', function(res){
                _paginator.updateState({ err: E.ERROR_RETRIEVING_ITEMS });
                _paginator.checkForMoreItems();
            });
        }  else {
            this.AjaxService.getMoreItems({
                url: this.getMoreItemsUrl,
                params: this.ajaxParams
            });
        }
    }

    Paginator.prototype.getMoreItemsSuccess = function(res){
        try{
        var items = JSON.parse(res.Data.Data);
        var onFirstAjaxPage = this.ajaxPage === 1;
        var ajaxItemCount = (onFirstAjaxPage) ? parseInt(res.Data.Count) : this.ajaxItemCount;
        // console.log(res.Data);
        var ajaxPageCount = (onFirstAjaxPage) ? Math.ceil(ajaxItemCount / this.batchSize) : this.ajaxPageCount;
        var totalAppPages = Math.ceil(ajaxItemCount / this.perPage);
        
        // edge case
        var receivedNoItems = !items || items.length === 0;
        if (receivedNoItems) {
            this.updateState({ err: E.DB_RETURNED_EMPTY_RESULTS });
            // console.warn(this.err);
            return;
        }
        this.updateState({
            items: this.items.concat(items),
            ajaxPageCount: ajaxPageCount,
            ajaxItemCount: ajaxItemCount,
            totalPages: totalAppPages
        });

        this.refreshCurrentPageItems();
    }
    catch( ex )
    {
        this.updateState({ err: E.DB_RETURNED_EMPTY_RESULTS });
            // console.warn(this.err);
            return;
    }
    }
});


