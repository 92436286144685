$(document).ready(function(){
    // var $jpList = $('.pagination-component');
    
    // shared/category-filter.js - needs to be initiated before JPlist or we wont be able to get a list of all items before they are hidden
    // Not used anymore since filtering is done server side
    // if(typeof Filter !== 'undefined') {
        // Filter.init($jpList, '.list-item', [Filter.Category, Filter.Search, Filter.Liked]); 
    // }  

    // $jpList.jplist({
    //     itemsBox: '.list',
    //     itemPath: '.list-item',
    //     panelPath: '.jplist-panel'
    // });   


})