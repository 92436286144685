// This is just extra UI for collapsible bootstrap component.
// Just make sure that caretUp & caretDown link to real images


var Collapsibles = function(){
    this.$ = $('.collapse-ctrl');
    this.initBinds();
    this.caretDown = ASSET_DIR + '/style/Icon-Caret-Down@2x.png';
    this.caretUp = ASSET_DIR + '/style/Icon-Caret-Up@2x.png';
};

Collapsibles.prototype.toggleCaret = function ($collapse) {
    var $content = $collapse.siblings('.collapse-content');

    if (!$content.hasClass('collapsing')) {
        var $img = $collapse.find('img.caret');

        if ($img.attr('src').includes('Down')) 
            $img.attr('src', this.caretUp);
        else 
            $img.attr('src', this.caretDown);
    }
}   
Collapsibles.prototype.initBinds = function(){
    var _this = this;
    this.$.each(function (i, el) {
        $(el).on('click', function(){
            _this.toggleCaret($(this));
        });
    });
}
