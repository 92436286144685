$(document).ready(function(){
    // Converts arr rows into CSV formatted content
    var CSV = {
        name: '',
        data: [],
        csvContent: '\uFEFF',
        type: 'data:text/csv;charset=utf-8',
        processContent: function(){
            var _this = this;
            console.log(this);
            this.data.forEach(function (rowArr) {
                rowArr.forEach(function removeAllCommas(el, i) {
                    if(typeof el !== 'undefined') rowArr[i] = el.replace(/,/g, '');
                    else rowArr[i] = '';
                })
                var row = rowArr.join(",");
                _this.csvContent += row + "\r\n";
            }); 
        },
        download: function(){
            var blob = new Blob([this.csvContent], { type: this.type });
            saveAs(blob, this.name + ".csv");
        },
        reset: function(){
            this.name = '';
            this.data = [];
            this.csvContent = '';
        }
    }



    /* Creates a unicode literal based on the string */    
  /*  function unicodeLiteral(str){
        var i;
        var result = "";
        for( i = 0; i < str.length; ++i){
            /* You should probably replace this by an isASCII test * /
            if(str.charCodeAt(i) > 126 || str.charCodeAt(i) < 32)
                result += "\\u" + fixedHex(str.charCodeAt(i),4);
            else
                result += str[i];
        }

        return result;
    }
*/
    function generateAndDownloadCSV() {
        var yield = $('input#yield-input').val() +' '+$('#yield-unit-select option:selected').text();

        
        var conversion = $('div.serving-size.serving-control').attr( "data-currentconversion" );
        var servingSizeAmount;

       if( conversion == 2 )
        { 
            if($('div.serving-size.serving-control').data( "amt2" )) {
                servingSizeAmount = $('div.serving-size.serving-control').data( "amt2" );

            }else{
                servingSizeAmount = $('div.serving-size.serving-control').data( "amt1" );
            }
        }
        else
        {
            servingSizeAmount = $('div.serving-size.serving-control').data( "amt1" );
        }

        var servingSizeUnit = ' ' +$('div.serving-control').find('.serving-unit').text( );

        var servingSize =servingSizeAmount + servingSizeUnit;
        var servings = $('#number-of-servings').val();

        var metric = $('.system-selection input:checked').val();
        var directions = '';
        var directionAdd = [];
        
        directions  = $('.directions-section').find("ul").last().html();
        if(directions){
            directions = directions.replace( /<\/li>/g, "||" );
            directions = directions.replace( /<.*?>/g, "" );
            var dSplit = directions.split( "\|\|" );

            for (var i = 0; i <= dSplit.length; i++) {
                if( dSplit[i] ){
                    dSplit[i] = dSplit[i].replace( /^\s{1,}/g, "" );
                    dSplit[i] = dSplit[i].replace( /\s{1,}$/g, "" );
                    dSplit[i] = dSplit[i].replace( /\n/g, "" );

                    directionAdd.push( new Array( "", dSplit[i], "") ) ;
                }
            }
        }
                

        CSV.name = $('.recipe-title').text();
        CSV.data = [
            [CSV.name, '', ''],
            ['Yield', yield, ''],
            ['Serving Size', servingSize, ''],
            ['Number of Servings', servings, ''],

            ['Measurement System', metric, ''],
            ['', '', ''],
            ['Ingredients', '', ''],
            ['', 'Name:', 'Value:'],
            ['', '', ''],
            ['Directions', '', '']
        ];
        CSV.data.push.apply( CSV.data, directionAdd );


        // Use these indexes to insert the 
        var ingredientsIndex = 8;
        var nutritionFactsIndex = CSV.data.length - 1;
        addIngredientsToCSV(CSV.data, ingredientsIndex);

        CSV.data.push( new Array( "","","" ) );
        CSV.data.push( new Array( "Nutrition Data","","" ) );
        addNutritionFactsToCSV(CSV.data);
        CSV.processContent();
        CSV.download();
        CSV.reset();

        // HELPERS -----------------------------------------------------------------------------------
        function addIngredientsToCSV(csvData, atIndex) {
            var $items = $('.ingredients-list .item').not('.placeholder');
            $items.each(function (i, el) {

                var ingredientName = $(el).find('> .name p').text();
                var ingredient_ele = $(el).find('> .name');
                if (ingredientName.length === 0) ingredientName = $(el).find('.name a').text();
                var amt = $(el).find('.amt').text();
                var unit = $(el).find('.unit').text();
				
				//replace subscript, superscript numbers
				var super_sub_script_dict = {'\u2070': '0', '\u00B9': '1', '\u00B2': '2', '\u00B3': '3', '\u2074': '4', '\u2075': '5', '\u2076': '6', '\u2077': '7', '\u2078': '8', '\u2079': '9', '\u2080': '0', '\u2081': '1', '\u2082': '2', '\u2083': '3', '\u2084': '4', '\u2085': '5', '\u2086': '6', '\u2087': '7', '\u2088': '8', '\u2089': '9', '/':'/'};
				var regex = new RegExp('[' + Object.keys(super_sub_script_dict).join("") + ']', 'g');
				amt = amt.replace(regex, function(x) { 
					return super_sub_script_dict[x];
				});


                var newRow = ['', '', '']; 
                if(ingredientName)
                    newRow[1] = ingredientName;

                // WTF
                if(!ingredient_ele.hasClass('sub-name')){
                    if( amt ){
                        newRow[2] = amt.toString(  ) + ' ' + unit;
                    }
                    else { 
                        if( unit )
                            newRow[2] = unit;
                        else
                            newRow[2] = "";
                    }
                }
                
                // add to ingredient list in CSV in correct spot
                csvData.splice(atIndex + i, 0, newRow);
            })

        }

        function addNutritionFactsToCSV(csvData) {
            var itemCount = $('.nutrition-facts-section .key').length;
            var $key = $('.nutrition-facts-section .key');
            var $value = $('.nutrition-facts-section .value');
            for (var i = 0; i < itemCount; i++) {
                ingredient = $($key.get(i)).text();
                amt = $($value.get(i)).text();
                var newRow = ['', '', ''];
                newRow[1] = ingredient;
                newRow[2] = amt;
                csvData.push(newRow);
            }
        }
    }

    $('a.export').on('click', generateAndDownloadCSV);

});