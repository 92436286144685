var AjaxGroup = function() {
    var _AjaxBuilder = new AjaxBuilder();

    this.save = _AjaxBuilder.buildFn({
        url: SVC_URL + 'groupApi/saveGroup/',
        eventSucessKey: 'ajax-group:saved-success',
        eventFailKey: 'ajax-group:saved-error'
    })

    this.delete = _AjaxBuilder.buildFn({
        url: SVC_URL + 'groupApi/deleteGroup',
        eventSucessKey: 'ajax-deleteGroup:search-success',
        eventFailKey: 'ajax-deleteGroup:search-error'
    })

    this.unlock = _AjaxBuilder.buildFn({
        url: SVC_URL + 'groupApi/updateUnlock',
    })

    this.resetGroupRecipe = _AjaxBuilder.buildFn({
        url: SVC_URL + 'groupApi/resetGroupRecipe',
        eventSucessKey: 'ajax-deleteGroup:search-success',
        eventFailKey: 'ajax-deleteGroup:search-error'
    })

    this.nameExistsOrNot = _AjaxBuilder.buildFn({
        url: SVC_URL + 'groupApi/nameExistsOrNot',
    })
    

}