var AjaxRecipe = function() {
    var _AjaxBuilder = new AjaxBuilder();

    this.like = _AjaxBuilder.buildFn({
        url: SVC_URL + 'searchFilterSort/likeRecipe',
        eventSucessKey: 'ajax-recipe:liked-success',
        eventFailKey: 'ajax-recipe:liked-error'
    })

    this.recipeLimitReached = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/test500/',
        eventSucessKey: 'ajax-recipe:recipeLimitReached-success',
        eventFailKey: 'ajax-recipe:recipeLimitReached-error'
    })

    this.save = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/saveRecipe/',
        eventSucessKey: 'ajax-recipe:saved-success',
        eventFailKey: 'ajax-recipe:saved-error'
    })

    this.saveAs = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/saveAs/',
        eventSucessKey: 'ajax-recipe:saveAs-success',
        eventFailKey: 'ajax-recipe:saveAs-error'
    })

    this.getRecipeListing = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/getRecipeListing/',
        eventSucessKey: 'ajax-recipe:getRecipeListing-success',
        eventFailKey: 'ajax-recipe:getRecipeListing-error'
    })

    this.recipeExistsInMenu = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/checkRecipeInMenu/',
        eventSucessKey: 'ajax-recipe:recipeExistsInMenu-success',
        eventFailKey: 'ajax-recipe:recipeExistsInMenu-error'
    })

    this.addToMenu = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/addToMenu/',
        eventSucessKey: 'ajax-recipe:addToMenu-success',
        eventFailKey: 'ajax-recipe:addToMenu-error'
    })

    this.share = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/share/',
        eventSucessKey: 'ajax-recipe:share-success',
        eventFailKey: 'ajax-recipe:share-error'
    })

    this.checkIfExists = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/checkRecipeName/',
        eventSucessKey: 'ajax-recipe:checkIfExists-success',
        eventFailKey: 'ajax-recipe:checkIfExists-error'
    })

    this.checkIfMyRecipeExists = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/checkDuplicateEntryByNameUserId/',
        eventSucessKey: 'ajax-recipe:checkIfMyRecipeExists-success',
        eventFailKey: 'ajax-recipe:checkIfMyRecipeExists-error'
    })

    this.getAllUnits = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/getUnitTable/',
        eventSucessKey: 'ajax-recipe:getAllUnits-success',
        eventFailKey: 'ajax-recipe:getAllUnits-error'
    })

    this.getAllUnitsForIngredient = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/getUnitTableForIngredient/',
        eventSucessKey: 'ajax-recipe:getUnitTableForIngredientv-success',
        eventFailKey: 'ajax-recipe:getAllUnits-error'
    })

    this.delete = _AjaxBuilder.buildFn({
        url: SVC_URL + 'recipeApi/deleteRecipe',
        eventSucessKey: 'ajax-deleteRecipe:search-success',
        eventFailKey: 'ajax-deleteRecipe:search-error'
    })
}


