// These dependencies will be called on every page
var EditRecipeCtrl = function(){
    this.initDependencies();
}

EditRecipeCtrl.prototype.initDependencies = function(){
    new DynamicWidthDropdown();

    MenuAndIngredientList.getInstance();
    _RecipePresenter = RecipePresenter.getInstance();
    _RecipePresenter.init();

    new GoBackBtn();
    new DropdownMenu();
    new MeasurementControls();
    new DragNDrop();
    new TinyMceWrapper();

    var _AwesompleteWrapper = new AwesompleteWrapper();
    _AwesompleteWrapper.initIngredients();

    var _MetricConversionHelper = new MetricConversionHelper();
    _MetricConversionHelper.init();
    _MetricConversionHelper.renderUnitCodes();
    _MetricConversionHelper.binds();
    setTimeout(function(){
        _MetricConversionHelper.cacheCtrlUnitConversions();
        _MetricConversionHelper.handleMeasurementOptionHiding();
    }, 500)

}