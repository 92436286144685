var AjaxBuilder = function(){}

// Builds the ajax functions
// Pass in route, eventSuccessKey & eventFailKey
AjaxBuilder.prototype.buildFn = function(outerOptions){
    var url = outerOptions.url;
    var eventSuccessKey = outerOptions.eventSuccessKey;
    var extraSuccessKey = outerOptions.extraSuccessKey;
    var eventFailKey = outerOptions.eventFailKey;
    var eventAbortedKey = outerOptions.eventAbortedKey;

    return function (innerOptions) {
        //console.log(innerOptions)
        var _EventEmitter = EventEmitter.getInstance();
        url = (typeof innerOptions.url !== 'undefined') ? innerOptions.url : url;
        var cType = 'application/x-www-form-urlencoded';

        if( typeof( innerOptions.params ) != "undefined" ){
            if(innerOptions.params.contentType) cType = innerOptions.params.contentType;
        }

        var req;
        return req = $.ajax({
            type: "POST",
            data: innerOptions.params,
            contentType: cType,
            url: url,
            beforeSend: function(){
                if(req == null) return;
                
                req.abort();
                _EventEmitter.emit(eventAbortedKey);
            }
        })
        .done(function(res){
            if(typeof extraSuccessKey !== 'undefined') _EventEmitter.emit(extraSuccessKey, res);
            _EventEmitter.emit(eventSuccessKey, res);
        })
        .fail(function (res, textStatus, errorThrown){
            _EventEmitter.emit(eventFailKey, res);
        })
        
    }
}
    
