// Use div.spinner in HTML
// And call new SpinnerComponent() in JS
// Voila!

var SpinnerComponent = function(){
    this.$ = $('.spinner');
    this.rotateOnTimeout();
    this.rotation = 0;
    this.rotationSpeed = 100;
}

SpinnerComponent.prototype.rotate = function(){
    this.rotation += 45;
    this.$.css({
        '-moz-transform':'rotate('+ this.rotation +'deg)',
        '-webkit-transform':'rotate('+ this.rotation +'deg)',
        '-o-transform':'rotate('+ this.rotation +'deg)',
        '-ms-transform':'rotate('+ this.rotation +'deg)',
        'transform':'rotate('+ this.rotation +'deg)'
    });
}

SpinnerComponent.prototype.rotateOnTimeout = function(){
    var _this = this;

    setTimeout(function(){
        _this.rotate();
        _this.rotateOnTimeout();
    }, this.rotationSpeed)
}

