$(document).ready(function(){
    var RecipeMenuDetails = function(){}
    RecipeMenuDetails.prototype.addCurrentDateToDatepicker = function(){
        var $datePicker = $('#date-picker');
        if($datePicker.length) {
            $datePicker.val(new Date().toDateInputValue());
        }
    }
    RecipeMenuDetails.prototype.initDatePicker = function(){
        console.log('init date picker');

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();
        if(dd<10){
                dd='0'+dd
            } 
            if(mm<10){
                mm='0'+mm
            } 
        today = yyyy+'-'+mm+'-'+dd;
        var datePicker = document.getElementById("date-picker");
        if(typeof datePicker !== 'undefined' && datePicker != null)
            datePicker.setAttribute("min", today);
        // $(datePicker).datepicker();   
    }

    var Print = function(){}
    Print.prototype.printPageHandler = function(e){
        e.preventDefault();
        e.stopPropagation();
        window.print(e);
        return false;
    }

    Date.prototype.toDateInputValue = (function () {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    });

    // INIT
    var _RecipeMenuDetails = new RecipeMenuDetails();
    var _Print = new Print();

    // _RecipeMenuDetails.addCurrentDateToDatepicker();
    _RecipeMenuDetails.initDatePicker();
    $('a.print').on('click', _Print.printPageHandler)
    console.log('asdfasdf');

})