var LibraryComponent = function(){
    this.$ = $('.library-component');
    this.setupDynamicHeight();
}

// We add EXTRA height if there is not enough content to reach the footer.
// Added this because of the requirement that the footer be fixed at the bottom even if there is not enough content to push it down 
LibraryComponent.prototype.setupDynamicHeight = function(){
    var bodyHeight = $('body').outerHeight();
    var navHeight = $('nav').outerHeight();
    var subnavHeight = $('.sub-navbar').outerHeight();
    var footerHeight = $('footer').outerHeight();
    var libraryHeight = bodyHeight - (navHeight + subnavHeight + footerHeight );

    this.$.css('min-height', libraryHeight);
}

