
var AjaxSearch = function () {
    var _AjaxBuilder = new AjaxBuilder();

    this.getMatchingIngredientsAndRecipes = _AjaxBuilder.buildFn({
        url: SVC_URL + 'search/getMatchingIngredientsAndRecipes',
        eventSucessKey: '',
        eventFailKey: ''
    })

    this.getMatchingRecipes = _AjaxBuilder.buildFn({
        url: SVC_URL + 'search/getMatchingRecipes',
        eventSucessKey: '',
        eventFailKey: ''
    })

}
