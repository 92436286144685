var TinyMceWrapper = function(){
    if(typeof tinymce !== 'undefined') {
        this.initEditor();
    }
}

TinyMceWrapper.prototype.initEditor = function(){
    tinymce.init({
        selector: 'textarea.tiny-mce',
        height: 280,
        theme: 'modern',
        menubar: false,
        statusbar: false,
        branding: false,
        verify_html: false,
        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: '',
        plugins: ['lists', 'autoresize'],
        toolbar1: 'bold italic underline | numlist bullist',
        content_css : SITE_URL + 'app/theme/public/clientPKM/build/assets/css/styles.css',
        body_class: 'tiny-mce-custom'
    });    
}