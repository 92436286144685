var AnchorSearch
$(document).ready(function(){
    AnchorSearch = function(){}
    AnchorSearch.prototype.buildUrlFor = function(query, page){
        var url = ''
        
        console.log('pageName: ' + page);
        if(page.includes('video')) {
            url = 'video/#' + query
        } else if(page.includes('glossary')) {
            url = 'glossary/#' + query;
        } else if(page.includes('recipe-library')) {
            url = 'recipe/getAllRecipes/?query=' + query;
        } else if(page.includes('browse-titles')) {
            url = 'book/titles/?query=' + query;
        } else if(page.includes('my-menus')) {
            url = 'menu/myMenus/?query=' + query;
        } else if(page.includes('my-recipes')) {
            url = 'recipe/myRecipes/?query=' + query;
        }else if(page.includes('my-groups')) {
            url = 'group/myGroups/?query=' + query;
        }

        console.log(url);
        
        return url
    }

    // INIT
    var _AnchorSearch = new AnchorSearch();

    $('.site-wide-search .search-btn').on('click', function(){
        var pageName = $('.site-wide-search .page-name').val().toLowerCase();
        var query = $('.site-wide-search .query').val();
        var searchPath = _AnchorSearch.buildUrlFor(query, pageName);
        // var siteUrl = window.location.href
        // var urlArr = siteUrl.split("/");
        // var baseUrl = urlArr[0] + "//" + urlArr[2]
        
        window.location.href = SITE_URL + searchPath;
        console.log(SITE_URL + searchPath);
        console.log(searchPath);

    })
});