// Base Validation Singleton
// Handles displaying p.errors related to its inputs
// This class is used in the other validation classes after you've come gone through a validation procress & just want to display or hide the err

var Validation = (function(){
    var instance;

    var _Validation = function(){}
    _Validation.prototype.handleError = function(errFound, errMsg, $this){
        if (errFound) this.showError($this, errMsg);
        else this.hideError($this);
    }


    _Validation.prototype.showError = function ($el, errMsg) {
        $el.addClass('validation-error');
        if( $el.hasClass( 'error' ) ){
            $el.text( errMsg );
            $el.addClass( 'warning-danger' );
        }
        else
        {
            var $fieldInput = $el.closest('.field-input')
            
            if($fieldInput.length > 0)
                $el.closest('.field-input').find('p.error').text(errMsg);
            else if($el.find('p.error').length)
                $el.find('p.error').text(errMsg);
            else
                $el.siblings('p.error').text(errMsg);
        }
    }
    _Validation.prototype.hideError = function ($el) {
        $el.removeClass('validation-error');
        if( $el.hasClass( 'error' ) ){
            $el.text( '' );
            $el.removeClass( 'warning-danger' );
        }
        else
        {
            var $fieldInput = $el.closest('.field-input')
            
            if($fieldInput.length > 0)
                $el.closest('.field-input').find('p.error').text('');
            else if($el.find('p.error').length)
                $el.find('p.error').text('');
            else
                $el.siblings('p.error').text('');
        }
    }

    return {
        getInstance: function(){
            if(!instance) instance = new _Validation();
            return instance;
        }
    }
})()