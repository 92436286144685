var EditRecipeValidation;
$(document).ready(function(){
    var _Validation = Validation.getInstance();

    EditRecipeValidation = function(){
        this.binds();
    };
    EditRecipeValidation.prototype.name = function () {
        var errFound = false;
        var errMsg = '';
        var $this = $('.recipe-name');

        if($this.val() === '') {
            errFound = true;
            errMsg = 'Please enter a recipe name.';
        }

        _Validation.handleError(errFound, errMsg, $this);
        return errFound;
    };

    EditRecipeValidation.prototype.yieldQty = function () {
        var errFound = false;
        var errMsg = '';
        var $yieldInput = $('#yield-input');

        if($yieldInput.val() === '') {
            errFound = true;
            errMsg = 'Please enter a quantity.';
        } else if(isNaN( $yieldInput.val() ) ) {
            errFound = true;
            errMsg = 'Please enter a numeric value.';
        }
        _Validation.handleError(errFound, errMsg, $yieldInput);
        return errFound;
    };

    EditRecipeValidation.prototype.testPostiveNumber = function ( el, msg_head, errorEl ) {
        var errFound = false;
        var errMsg = '';
        
        var v = el.val(  );
        v = parseFloat( v );
        if( v === '') {
            errFound = true;
            errMsg = msg_head + ' Please enter a quantity.';
        } else if( isNaN( v ) ) {
            errFound = true;
            errMsg = msg_head + ' Please enter a numeric value.';
        }
        else if (  v === 0 || v === "0" || v < 0   )
        {
            errFound = true;
            errMsg = msg_head + ' Please enter a value greater than 0.';
        }
        else if ( v === null || typeof( v ) == "undefined" )
        {
            errFound = true;
            errMsg = msg_head + ' Please enter a value greater than 0.';
        }

        error_target_element = el;
        if( errorEl ) error_target_element = errorEl;
        _Validation.handleError(errFound, errMsg, error_target_element );
        return errFound;
    }


    EditRecipeValidation.prototype.servingSizeQty = function () {
        var errFound = false;
        var errMsg = '';
        var $servingInput = $('#serving-input');
        if($servingInput.val() === '') {
            errFound = true;
            errMsg = 'Please enter a quantity.';
        } else if(isNaN( $servingInput.val() ) ) {
            errFound = true;
            errMsg = 'Please enter a numeric value.';
        }
        else if (  $servingInput.val() == 0 ||  $servingInput.val() == "0" ||  $servingInput.val() < 0   )
        {
            errFound = true;
            errMsg = 'Please enter a value greater than 0.';
        }

        _Validation.handleError(errFound, errMsg, $servingInput);
        return errFound;
    }

    EditRecipeValidation.prototype.ingredientList = function () {
        var errFound = false;
        var errMsg = '';

        $ingredientList = $('.ingredients-list');
        $ingredients = $ingredientList.find('.item').not('.placeholder').not('.hidden');
        if($ingredients.length === 0) {
            errFound = true;
            errMsg = 'Please add at least one ingredient.';
        }
        _Validation.handleError(errFound, errMsg, $ingredientList);
        return errFound; 
    }

    EditRecipeValidation.prototype.instructions = function () {
        var errFound = false;
        var errMsg = '';

        /*var iframe = document.getElementById('tiny-mce-dir_ifr');
        var $innerDoc = $(iframe.contentDocument) || $(iframe.contentWindow.document);
        var $directionsTextarea = $innerDoc.find('#tinymce');*/
		var $directionsTextarea = document.getElementById('tiny-mce-dir');

        if($directionsTextarea.innerHTML === '') {
            errFound = true;
            errMsg = 'Please enter a description.';
        }
        
        _Validation.handleError(errFound, errMsg, $('.directions-section'));
        return errFound;
    }


    EditRecipeValidation.prototype.all = function () {
        this.name();
        this.yieldQty();
        this.servingSizeQty();
        this.ingredientList();
        this.instructions();
    }

    EditRecipeValidation.prototype.binds = function(){
        $('.recipe-name').on('blur', this.name);
        $('#yield-input').on('blur', this.yieldQty);
        $('#serving-input').on('blur', this.servingSizeQty);
        $('.confirm-btn').on('blur', this.ingredientList);
        $('.grouprecipe').on('blur',this.grouprecipe);
        
        var iframe = document.getElementById('tiny-mce-dir');//('tiny-mce-dir_ifr'); 
        if(iframe) {           
            //var $innerDoc = $(iframe.contentDocument) || $(iframe.contentWindow.document);
            //var $directionsTextarea = $innerDoc.find('#tinymce');
            //$directionsTextarea.on('blur', this.instructions);
			$('#tiny-mce-dir').on('blur', this.instructions);
        }
    }

    // INIT
    new EditRecipeValidation();
})