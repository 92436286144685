    var FiltersPresenter;
$(document).ready(function(){
    var _EventEmitter = EventEmitter.getInstance();
    var _AjaxFilters = new AjaxFilters();
    var _Util = Util;

    var CATEGORY_MAP = {
        'cuisine':'#cuisine',
        'courses':'#courses',
        'cookingMethod':'#cooking-method',
        'books':'#acf-garde-manager',
        'favorites':'#liked-filter-container'
    }

    FiltersPresenter = function(){}
    FiltersPresenter.prototype.AjaxUpdateFilterCount = function(){
        var _this = this;
        var noFiltersActivated = $('.filter-badge-collection.hidden').length > 0;
        var noSearchTermActive = $('.search-results-info.hidden').length > 0;
        var onGetAllRecipesPage = window.location.pathname.toLowerCase().includes('getallrecipes');
        var onMyMenusPage = window.location.pathname.toLowerCase().includes('mymenus');
        var likedChecked =  $('input#liked-filter:checked') > 0;
        
        if(noFiltersActivated && noSearchTermActive && onGetAllRecipesPage && likedChecked ) {
            _AjaxFilters.getFilterCountBaseline({
                params: {
                    authToken: '12345'
                }
            }).then(function(res){                
                var filterData = JSON.parse(res).Data   
                // console.log(filterData);             
                _this.updateFilterCountWData(filterData);
            })
        } else if (onMyMenusPage) {
            console.log('on my menu');
            _AjaxFilters.calcMenuFilters({
                params: {
                    authToken: '12345'
                }
            }).then(function(res){
                var filterData = JSON.parse(res).Data
                // console.log(filterData);
                console.log( 'updateFilterCountWData' );
                _this.updateFilterCountWData(filterData);
            })
        } else {
            _AjaxFilters.getFilterCount({
                params: {
                    authToken: '12345'
                }
            }).then(function(res){
                var filterData = JSON.parse(res).Data
                // console.log(filterData);
                console.log( 'updateFilterCountWData' );
                _this.updateFilterCountWData(filterData);
            })
        }

    }

    FiltersPresenter.prototype.updateFilterCountWData = function(data){
        $('label .filter-count').text('(0)');
		$('label .filter-count').css("color", "#666");
         function fixBadgeText( badgeFind, numOverride )
        {
            var currentBadgeText = $( '.filter-badge' ).find( badgeFind ).text(  );
            var currentBadgeHtml = $( '.filter-badge' ).find( badgeFind ).html(  );


            if(currentBadgeText){
                var $result = currentBadgeText.match( /\((\d{1,})\)/ );
             
                var numToReplace = $result[1];
                
                var replace = "\\(" + numToReplace + "\\)"; 
                var re = new RegExp(replace,"g");   
				var  newcount = "(" + count + ")";
				if( typeof(numOverride) !== 'undefined' && numOverride !== "" ) 
				{
					newcount = "(" + numOverride +")";
				}
                var newBadgeHtml = currentBadgeHtml.replace(re, newcount );
                $( badgeFind ).html( newBadgeHtml );
            }
        }

        function fix_books( bookArray  )
        {
            for( var bookId in bookArray )
            {
                for( var bookChap in bookArray[bookId]  )
                {
                    var badgeFind = ".filter-badge-book-" + bookId  +"-" + bookChap;
                    newCount = bookArray[bookId][bookChap];
                    fixBadgeText( badgeFind, newCount );
                }
            }
        }



        for(var category in data) {
            var categoryId = CATEGORY_MAP[category];
            
            if(categoryId.includes('liked')) {
                var count = data[category];
                $( categoryId ).find( '.filter-count' ).text( "(" + count + ")" );
                // console.log('like');
                // console.log(count);
                // console.log(categoryId);
             
            }
            else if (categoryId.includes('acf'))  //acf-garde-manager ( books )
            {
                for(var bookId in data[category])
                {
                    // TODO: IMPORTANT, loop through each for additional books!
                    console.log(data[category][bookId]);
                    for(var k in data[category][bookId]) {
                        var filterId = k;
                        var count = data[category][bookId][k];
                        // console.log('category:');
                        // console.log(categoryId);
                        // console.log(filterId);
                        // console.log(count);
                        if(count != 0){
							$(categoryId +"-"+ bookId).find('label[data-chapter="' + filterId + '"]').find('.filter-count').parent().css("color", "#212529");
							$(categoryId +"-"+ bookId).find('label[data-chapter="' + filterId + '"]').find('.filter-count').text('('+count+')');
						} else {
							$(categoryId +"-"+ bookId).find('label[data-chapter="' + filterId + '"]').find('.filter-count').text('');
						}
                    }
                }

                 fix_books( data[category] );
                
            }
            else {
                for(var i in data[category]){
                    var filterId = i;
                    var count = data[category][i];
                    // console.log('category:');
                    // console.log(categoryId);
                    // console.log(filterId);
                    // console.log(count);
                    // filter filter-badge-course-1
                    // 
                    // filter filter-badge-cuisine-51  ="cuisine-51"
                    // filter-badge-book-2-29   book-2-11
                    
                    var fixCategory = null;
                    if( category == "courses" ) {
                        fixCategory = 'course';
                    }
                    if( category == "cookingMethod" ) {
                        fixCategory = 'cooking-method';
                    }
                    if( category == "cuisine" ){
                        fixCategory = 'cuisine';
                    }
                    if( category == "books" ) {
                        fixCategory = 'book';
                        fix_books( data['books'] );
                        continue;
                    }
                    var badgeFind = ".filter-badge-" + fixCategory +"-" + i;
                    fixBadgeText( badgeFind );
					
					if(count != 0){
						$(categoryId).find('label[data-id="' + filterId + '"]').find('.filter-count').parent().css("color", "#212529");
						$(categoryId).find('label[data-id="' + filterId + '"]').find('.filter-count').text('('+count+')');
					} else {
						$(categoryId).find('label[data-id="' + filterId + '"]').find('.filter-count').text('');
					}
                }
            }
        }
 
    }

    // INIT
    var _FiltersPresenter = new FiltersPresenter();
    _EventEmitter.subscribe('filters-presenter:refresh-counts',function(){
        setTimeout(function(){
            _FiltersPresenter.AjaxUpdateFilterCount();
        }, 250)
    });

});