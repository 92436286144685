// These dependencies will be called on every page
var AllTitlesCtrl = function(){
    this.initDependencies();
}

AllTitlesCtrl.prototype.initDependencies = function(){
    FilterComponent.getInstance();

    new GoBackBtn();
    new Collapsibles();
    new FilterBadgeCollection();
    new FilterCheckbox(); //links filter checkbox state with the filterbadges
    new LibraryComponent();
    new SpinnerComponent();
    new DynamicWidthDropdown();

}
