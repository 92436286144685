var ShareRecipeValidation;

$(document).ready(function(){
    var _Validation = Validation.getInstance();
    var _Util = Util;
    

    ShareRecipeValidation = function(){}

    ShareRecipeValidation.prototype.name = function () {
        var errMsg = '';
        var errFoundLcl;
        var $nameInput = $('.modal-share-recipe .name-edit');
           
        if($nameInput.val() === '') {
            this.errFound = true;
            errFoundLcl = true;
            errMsg = 'Please enter your name.';
        }

        _Validation.handleError(errFoundLcl, errMsg, $nameInput);
    }

    ShareRecipeValidation.prototype.from = function () {
        var errMsg = '';
        var errFoundLcl;
        var $fromInput = $('.modal-share-recipe .from-edit');
    
        if($fromInput.val() === '') {
             this.errFound = true;
             errFoundLcl = true;
            errMsg = 'Please enter your email.';
        } else if(!_Util.validateEmail($fromInput.val())) {
             this.errFound = true;
             errFoundLcl = true;
            errMsg = 'Please enter a valid email address.';
        }
        _Validation.handleError(errFoundLcl, errMsg, $fromInput);
    }
    ShareRecipeValidation.prototype.to = function () {
        var errMsg = '';
        var errFoundLcl;
        var $toInput = $('.modal-share-recipe .to-edit')
    
        if($toInput.val() === '') {
             this.errFound = true;
             errFoundLcl = true;
            errMsg = 'Please enter your recipient\'s email.';
        } else if(!_Util.validateEmail($toInput.val())) {
             this.errFound = true;
             errFoundLcl = true;
            errMsg = 'Please enter a valid email address.';
        }
        _Validation.handleError(errFoundLcl, errMsg, $toInput);
    }
    ShareRecipeValidation.prototype.message = function () {
        var errMsg = '';
        var errFoundLcl;
        var $msgInput = $('.modal-share-recipe .message-edit');
    
        if($msgInput.val() === '') {
             this.errFound = true;
             errFoundLcl = true;
            errMsg = 'Please enter your message.';
        }
        _Validation.handleError(errFoundLcl, errMsg, $msgInput);
    }
    ShareRecipeValidation.prototype.all = function(){
         this.errFound = false;
        this.name();
        this.to();
        this.from();
        this.message();
    }
    var _ShareRecipeValidation = new ShareRecipeValidation();
    $('.modal-share-recipe .name-edit').on('blur', _ShareRecipeValidation.name);
    $('.modal-share-recipe .to-edit').on('blur', _ShareRecipeValidation.to);
    $('.modal-share-recipe .from-edit').on('blur', _ShareRecipeValidation.from);
    $('.modal-share-recipe .message-edit').on('blur', _ShareRecipeValidation.message);

    $('.modal-share-recipe .share-recipe-btn').on('click', function(){
        console.log('validate share form');
         
        _ShareRecipeValidation.all();
        if(  _ShareRecipeValidation.errFound == false )
        {
            setTimeout(function() {$('.modal-share-recipe .message-edit').val( '' )}, 250);
         //   $(  )
        }
    });


})