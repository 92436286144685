var allRecipes;
var ALL_INGREDIENTS = [];
var ajaxRecipeService;
var _EventEmitter;
var _AjaxSearch;

var KEY = {
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    ESCAPE: 27,
    SPACE: 32,
    ARROWUP: 38,
    ARROWDOWN: 40,
}

var AwesompleteWrapper = function(){
    this.initDependencies();
    this.binds();
    this.awesompleLists = [];
    console.log('init awesompleteWrapper');
}

AwesompleteWrapper.prototype.binds = function(){
    var _this = this;
    $(document).on('click', '.ingredients-list.ingredients .edit-toggle', function(){
        var ingredientVal = $(this).closest('.item').find('.name > div > p').text()
        _this.refreshIngredientsList(ingredientVal);
    })

    // Event listener to search for ingredients and recipes that match input val
    $('.edit-ingredient-name').on('keyup', function(e){
        if(e.keyCode === KEY.ARROWDOWN || e.keyCode === KEY.ARROWUP) return;
        var query = $(this).val();
        _this.refreshIngredientsList(query);
    });

    // Event listener to search for ingredients and recipes that match input val
    $('.modal-edit-recipe input#recipe').on('keyup', function(e){
        if(e.keyCode === KEY.ARROWDOWN || e.keyCode === KEY.ARROWUP) return;
        var query = $(this).val()
        _this.refreshRecipeList(query)
    });
}

AwesompleteWrapper.prototype.initDependencies = function(){
    allRecipes = [];
    ajaxRecipeService = new AjaxRecipe();
    _AjaxSearch = new AjaxSearch();
    _EventEmitter = EventEmitter.getInstance();
}

AwesompleteWrapper.prototype.initIngredients = function(){
    var _this = this;
    // Setup in array because there will be more than one input w awesomplete (add and edit modals)
    this.awesompleLists['ingredients'] = [];

    // setup awesomeplete on each input
    $('.edit-ingredient-name').each(function(i, el) {
        _this.awesompleLists['ingredients'][i] = new Awesomplete(el, {
            list: [],
            filter: Awesomplete.FILTER_STARTSWITH,
            minChars: 1,
            maxItems: 10
        }) 
    });
    console.log(this)
}

AwesompleteWrapper.prototype.refreshIngredientsList = function(query){
    var _this = this;

    _AjaxSearch.getMatchingIngredientsAndRecipes({
        authToken: '12345',
        params: {
            query: query,
        }
    }).then(function(res) {
        // console.log(res)
        var results = JSON.parse(res).Data;
        console.log(_this);
       
       if( $( '#modal-recipe-edit-ingredient' ).hasClass( 'show' ) )
        return;

        // Update the awesomplete list for the dropdown suggestions
        _this.awesompleLists['ingredients'].forEach(function(awesompleList) {
            awesompleList.list = {};
            if( results[0] && results[0].value )
            {  
                // the only function you need to override:
                awesompleList.replace =  function(suggestion) {
                    this.input.value = suggestion.label; // default replace() inserts suggestion.value to input
                    
                    var unitNames = {};
                    if( suggestion.value )
                    {
                        var parts = suggestion.value.split( ":" );
                        var keepUnit = '';

                        if( typeof parts[1] != 'undefined' )
                        {
                            unitNames = parts.pop().split( "|" );
                            console.log( "Running through parts" );
                            // first check what is in the unit box
                            var currentUnit;
                            if( $( '#unit' ).val(  ) )
                                currentUnit = $( '#unit' ).val(  );

                            // next clear it
                            $( '#unit' ).empty(  );

                            var firstUnit;

                            unitNames.forEach( function( itemName )
                            {
                                console.log( "Unit name: ", itemName );
                                var selectThis;
                                if(  itemName == currentUnit )
                                {
                                     selectThis = 1;    
                                     firstUnit = itemName;
                                 }
                                else selectThis = 0;
                                $( '#unit' ).append(  new Option( itemName, itemName, 0 , selectThis ) );
                            }  );

                            if( firstUnit )
                            {
                                $( '#unit' ).val( firstUnit );
                            }
                            else
                            {
                                $( '#unit' ).find('option:eq(0)').prop('selected', true);
                            }
                        }
                        else
                        {
                           
                        }
                        suggestion.value = parts.join(":");
                    }

                    $( '#recipe_id_save' ).val( suggestion.value );
                }
            }
            else
            {
                 awesompleList.replace =  function(suggestion) {
                    this.input.value = suggestion.label; // default replace() inserts suggestion.value to input
                }
            }
            console.log( "REPLACER: " );
            console.log( results );
            awesompleList.list = results;                     
        })
        ALL_INGREDIENTS = results;
    });
}
 

AwesompleteWrapper.prototype.initRecipes = function(){
    var _this = this;
    // Setup in array because there will be more than one input w awesomplete (add and edit modals)
    this.awesompleLists['recipes'] = [];

    // setup awesomeplete on each input    
    $('.modal-edit-recipe input#recipe').each(function(i, el) {
        _this.awesompleLists['recipes'][i] = new Awesomplete(el, {
            list: [],
            filter: Awesomplete.FILTER_STARTSWITH,
            minChars: 1,
            maxItems: 10
        });
    });
}

AwesompleteWrapper.prototype.refreshRecipeList = function(query) {
    // ajax call to search recipes and ingredients that match the query
    // limit of 40 set on backend so search is fast
    var _this = this;
    _AjaxSearch.getMatchingRecipes({
        authToken: '12345',
        params: {
            query: query,
        }
    }).then(function(res) {
        var results = JSON.parse(res).Data;
        //console.log( "RECIPE LOOKUP RESULTS" );
        //console.log( results );

        // Update the awesomplete list for the dropdown suggestions
        _this.awesompleLists['recipes'].forEach(function(awesompleList) {
            awesompleList.list = {};
            if( results[0] && results[0].value )
            {
                // the only function you need to override:
                awesompleList.replace =  function(suggestion) {
                    this.input.value = suggestion.label; // default replace() inserts suggestion.value to input
                    $( '#recipe_id_save' ).val( suggestion.value );
                    //$( '#recipe_id_save' ).val( suggestion.value );
                }
            }
            console.log( "REPLACER: " );
            console.log( results );
            awesompleList.list = results;            
        })
        allRecipes = results;
    });
}
        




