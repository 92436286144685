var ConversionModel = (function(){
    var instance;

    var _ConversionModel = function(){}
    
    _ConversionModel.prototype.getAmericanUnits = function(){
        return ["oz", "lb", "tsp", "tbsp", "fl oz", "cup", "pt", "qt", "gal"]
        $mass =  Array(  );
        if( in_array( $fromUnitName, $mass ) &&  in_array( $toUnitName, $mass )  ) $result = 1;

    }
    _ConversionModel.prototype.getMetricUnits = function(){
        return [ "ml", "l", "g", "kg"]
    }
    
    return {
        getInstance: function(){
            if(!instance) instance = new _ConversionModel();
            return instance;
        }
    }
})();
