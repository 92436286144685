$(document).ready(function () {
    var _ModalCore = new ModalCore()
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();

    AddIngredientModal = {};
    AddIngredientModal.$ = $('#modal-add-ingredient');

    AddIngredientModal.refreshInstance = function () {
        AddIngredientModal.$ = $('#modal-add-ingredient');
    }
    AddIngredientModal.getFormData = function () {
        var $form = AddIngredientModal.$.find('.modal-content');
        var data = {}; 
        data.ingredientId = $form.attr('data-nrecingid');
        data.name = AddIngredientModal.$.find('#ingredient').val();
        data.prepNotes = AddIngredientModal.$.find('#prep-notes').val();
        data.amt = AddIngredientModal.$.find('#amt').val();
        data.unit = AddIngredientModal.$.find('#unit').val();
        data.unitName = AddIngredientModal.$.find('#unit').val();
        data.parentName = AddIngredientModal.$.find('#sublist-dropdown').val();
        data.sublistName = AddIngredientModal.$.find('#sublist-dropdown').val();

        //data.unitName = AddIngredientModal.$.find('#unit > option').map(function() {
        data.unitList = AddIngredientModal.$.find('#unit > option').map(function() {
                            // var opt = {};
                            // opt[$(this).val()] = $(this).text();
                            var opt = [];
                            opt.push( $(this).text() );
                            return opt;
                    }).get();

        data.unitList = data.unitList.join( "|" ) ;
        return data;
    }  
    AddIngredientModal.create = function () {
        AddIngredientModal.refreshInstance();
        var formData = AddIngredientModal.getFormData();
        _MenuAndIngredientList.addIngredient(formData);
    }

    AddIngredientModal.updateForm = function () {
        var $sublists = $('.sub-list').not('.placeholder');
        var namesArr = [];
        $sublists.each(function (i, el) {
            namesArr.push($(el).attr('data-name'));
        })

        var $dropdown = AddIngredientModal.$.find('#sublist-dropdown');
        $dropdown.find('option:not(:first)').remove();

        namesArr.forEach(function (name) {
            $dropdown.append('<option value="' + name + '">' + name + '</option>');
        })
        $dropdown.val('')
    }

    AddIngredientModal.$.find('.btn-lg-primary').on('click', function (e) {
        if (e.keyCode && !(e.keyCode === 13 || e.keyCode === 27)) return;



        var $modal = AddIngredientModal.$.closest('.modal-container');
        if(_ModalCore.isFormValid($modal)){    
            AddIngredientModal.create();
        } 
    });

    $('.btn-add-ingredient').on('click', function () {
        _MenuAndIngredientList.addOrEdit = "add";
        AddIngredientModal.updateForm();
        $( "select#unit" ).empty(  );
    });
});