/**
 * Bind services/paginator.js w UI
 */
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


function IsImageOk(img) {
    // During the onload event, IE correctly identifies any images that
    // weren’t downloaded as not complete. Others should too. Gecko-based
    // browsers act like NS4 in that they report this incorrectly.
    if (!img.complete) {
        return false;
    }

    // However, they do have two very useful properties: naturalWidth and
    // naturalHeight. These give the true size of the image. If it failed
    // to load, either of these should be zero.
    if (img.naturalWidth === 0) {
        return false;
    }

    // No other way of checking: assume it’s ok.
    return true;
}

function sqlToJsDate(sqlDate){
    //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
    var sqlDateArr1 = sqlDate.split("-");
    //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
     if( typeof( sqlDateArr1[0] ) == 'undefined' ) sqlDateArr1[0] = "2018-01-01";
    var sYear = sqlDateArr1[0];
    var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
    var sqlDateArr2 = sqlDateArr1[2].split(" ");
    //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
    var sDay = sqlDateArr2[0];
    
    if( typeof( sqlDateArr2[1] ) == 'undefined' ) sqlDateArr2[1] = "00:00:00";
    var sqlDateArr3 = sqlDateArr2[1].split(":");
    
    //format of sqlDateArr3[] = ['hh','mm','ss.ms']
    var sHour = sqlDateArr3[0];
    var sMinute = sqlDateArr3[1];
    var sqlDateArr4 = sqlDateArr3[2].split(".");
    
    //format of sqlDateArr4[] = ['ss','ms']
    var sSecond     = sqlDateArr4[0];
    var sMillisecond = sqlDateArr4[1] ? sqlDateArr4[1] : 0;
    
    var d = new Date(sYear,sMonth,sDay,sHour,sMinute,sSecond,sMillisecond);
    var mName = monthNames[d.getMonth()];
    var day = d.getDate(  );
    var y = d.getFullYear();
    return mName +" " +day +", " + y;
}


var PaginationDataHandler;
$(document).ready(function(){
    var instance;

    // var _CacheManager = CacheManager.getInstance();
    // var cachedFilters = _CacheManager.getValue('filterIds:' + window.location.pathname + '-'+ USER_ID);

    var _Util = Util;


    // Very simple basically this links the UI with the Paginator Service. The Paginator Service just determines how many items we need and calls AJAX if needed
    // In binds you'll see all the handler functions. These handler functions modify data in this class and send it to paginator. to retrieve items based on new parameters
    PaginationDataHandler = (function(){
        // Once paginator is done we run the functions in subscribeToPaginatorEvents(). These events are triggered depending on the outcome of paginator's attempt to get more items
        var _PaginationDataHandler = function(options){
            var _Paginator = Paginator; // services/paginator.js
            var _EventEmitter = EventEmitter.getInstance(); // event.js
            var _LikeBtn = LikeBtn; // components/like-btn.js
            var _Util = Util;
            
            var activePaginationCalls = 0;
            var itemToRender = options.itemToRender;
            var getItemsUrl = options.getItemsUrl;
            var getMoreItemsUrl = options.getMoreItemsUrl;
            var defaultReq = options.ajaxRequestDefaults
            var defaultReqCopy = options.ajaxRequestDefaults;
            var onResultsQueue = [];

            console.log( defaultReq );
            var ajaxParams = {
                authToken: defaultReq.authToken,
                request: JSON.stringify({
                    batchSize: defaultReq.batchSize,
                    activeFilters: { 
                        title: [
                            {
                                nTextBookId: defaultReq.bookFilters.bookIds,
                                nChapterNum: defaultReq.bookFilters.chapterNums
                            }
                        ],
                        category: { 
                            nCourseId: defaultReq.categoryFilters.courseIds,
                            nCookingMethodId: defaultReq.categoryFilters.cookingMethodIds,
                            nCuisineId: defaultReq.categoryFilters.cuisineIds
                        }
                    },
                    likeStatus: defaultReq.likeStatus,
                    searchString: defaultReq.searchQuery,
                    orderSelection: defaultReq.orderSelection
                })
            }  

            // ASYNC
            function subscribeToPaginatorEvents(){
                _EventEmitter.subscribe('paginator:data-received', renderResults);
                _EventEmitter.subscribe('paginator:data-error', renderError);
                _EventEmitter.subscribe('paginator:no-results', renderNoResults);
                _EventEmitter.subscribe('ajax-pagination:call-aborted', ajaxCallAborted);
            }

            function binds(){
                // No new Queries - Paginator handles this with existing items
                $('.jplist-next').on('click', nextPageHandler);
                $('.jplist-prev').on('click', prevPageHandler);
				//$('#applySortBtn').on('click', itemsPerPageHandler);
                $('#applySortBtn').on('click', applySortHandler);
                //$('#items-per-page option').on('click touchstart', itemsPerPageHandler);
                // Trigger new AJAX Queries
                $('.sub-navbar .search-btn').on('click', searchHandler);
                //$('#sort option').on('click touchstart', sortByHandler);
                $('.liked-filter input').on('click', likedItemsHandler)
                $('button.clear-all').on('click', restartPaginator);
                $('button.clear-all-badges').on('click', restartPaginator);
                $('.search-results-info .close-icon').on('click', function(){
                    $('#pagination-search').val('');
                    searchHandler();
                });
				/*$('#items-per-page').on('focus',function(){
					$('#sort').attr('aria-expanded', 'false');
					$('#sort').find('.jplist-submenu').each(function () {
						$(this).hide();
					});						 
				});*/
				$('.jplist-next').on('focus',function(){
					$('#items-per-page').attr('aria-expanded', 'false');
					$('#items-per-page').find('.jplist-submenu').each(function () {
						$(this).hide();
					});						 
				});
				$('.jplist-prev').on('focus',function(){
					$('#items-per-page').attr('aria-expanded', 'false');
					$('#items-per-page').find('.jplist-submenu').each(function () {
						$(this).hide();
					});						 
				});

                var $categoryCheckboxes = $('.filters-component .categories .checkbox-normal');
                var $bookChapterCheckboxes = $('.filters-component .book-titles .checkbox-normal');
                $categoryCheckboxes.on('click', categoryHandler);
                $bookChapterCheckboxes.on('click', bookChapterHandler);
            }

            // Event Handlers. They send Paginator new Data.
            function categoryHandler( e ){
                
                //console.log( e );
                  

                // If we cache category filters let's not make an AJAX call for each of them. 
                // Update all the filters and THEN call the new Pagination() from an outside class
                var $categoryCheckboxes = $('.filters-component .categories .checkbox-normal');
                var cancelAjaxCall = false;

                // empty all filters
                for(var key in defaultReq.categoryFilters){
                    defaultReq.categoryFilters[key] = [];
                }
        
                // Put new filters in default Request to keep state
                $categoryCheckboxes.each(function(){
                    var $checkbox = $(this);
                    
                    if ($checkbox.is(':checked')){
                        var filterType = $(this).data('filtertype');
                        if($checkbox.attr('data-cancelajax') === 'true') {
                            cancelAjaxCall = true;
                            $checkbox.attr('data-cancelajax', 'false');
                        } 
                        if(filterType === 'course')
                            defaultReq.categoryFilters.courseIds.push($checkbox.data('id'))
                        if(filterType === 'cooking-method')
                            defaultReq.categoryFilters.cookingMethodIds.push($checkbox.data('id'))                    
                        if(filterType === 'cuisine')
                            defaultReq.categoryFilters.cuisineIds.push($checkbox.data('id'))                    
                    }
                });
                // Put new filters into paginator request
                var request = JSON.parse(ajaxParams.request);
                request.activeFilters.category.nCourseId = defaultReq.categoryFilters.courseIds;
                request.activeFilters.category.nCookingMethodId = defaultReq.categoryFilters.cookingMethodIds;
                request.activeFilters.category.nCuisineId = defaultReq.categoryFilters.cuisineIds;
                ajaxParams.request = JSON.stringify(request);
                
                //if( !_Util.isHumanClickEvent(e) ) return; // human click does a double validation so dont allow 
                // Call paginator and then refresh results
                // books render if the filter is set.
                if( typeof( window._CACHEDFILTERS ) === "undefined" ) window._CACHEDFILTERS = [];
                var blockerForBook = false;
                for (var i = 0; i < window._CACHEDFILTERS.length; i++) {
                    if( window._CACHEDFILTERS[i].indexOf( "book" ) > -1 ) blockerForBook = true;
                    //cancelAjaxCall
                }

                for( i = 0; i < defaultReq.categoryFilters.cookingMethodIds.length; i++ )
                {
                    method_id = "cooking-method-" + defaultReq.categoryFilters.cookingMethodIds[i];  
                    for ( var j=0; j < window._CACHEDFILTERS.length; j++ )
                    {
                        activeFilterName = window._CACHEDFILTERS[j];
                        if( activeFilterName ==  method_id )
                        {
                            window._CACHEDFILTERS.splice( j,1  );
                            cancelAjaxCall = true;
                        }
                    }
                }

                for( i = 0; i < defaultReq.categoryFilters.cuisineIds.length; i++ )
                {
                    cuisine_id = "cuisine-" + defaultReq.categoryFilters.cuisineIds[i];  
                    for ( var j=0; j < window._CACHEDFILTERS.length; j++ )
                    {
                        activeFilterName = window._CACHEDFILTERS[j];
                        if( activeFilterName ==  cuisine_id )
                        {
                            window._CACHEDFILTERS.splice( j,1  );
                            cancelAjaxCall = true;
                        }
                    }
                }

                for( i = 0; i < defaultReq.categoryFilters.courseIds.length; i++ )
                {
                    course_id = "course-" + defaultReq.categoryFilters.courseIds[i];  
                    for ( var j=0; j < window._CACHEDFILTERS.length; j++ )
                    {
                        activeFilterName = window._CACHEDFILTERS[j];
                        if( activeFilterName ==  course_id )
                        {
                            window._CACHEDFILTERS.splice( j , 1);
                            cancelAjaxCall = true;
                        }
                    }
                }

                // reset cus we only use for loading

                
                 // if( window.RUNWICH == "FIRST" ||window.RUNWICH == "SECOND" ){
                 //    window.RUNWICH = "SECOND";
                 //     return; // human click does a double validation so dont allow 
                 // }
                if(!cancelAjaxCall && blockerForBook == false)
                   {
                    newPaginator();
                }
            }

            function bookChapterHandler( e ){
                var $bookChapterCheckboxes = $('.filters-component .book-titles .checkbox-normal');
                console.log( "Book chapter handler entry" );
                console.log( defaultReq );
                var cancelAjaxCall = false;
                // empty all filters
                for(var key in defaultReq.bookFilters){
                    defaultReq.bookFilters[key] = [];
                }
        
                // Put new filters in default Request to keep state
                $bookChapterCheckboxes.each(function(){
                    var $checkbox = $(this);
                    var book_id = $checkbox.attr( "id" );
                    for ( var j=0; j < window._CACHEDFILTERS.length; j++ )
                    {
                        activeFilterName = window._CACHEDFILTERS[j];
                        if( activeFilterName ==  book_id )
                        {
                            window._CACHEDFILTERS.splice( j,1  );
                            cancelAjaxCall = true;
                        }
                    }

                    if ($checkbox.is(':checked'))
                    {
                        defaultReq.bookFilters.bookIds.push($checkbox.data('id'));
                        defaultReq.bookFilters.chapterNums.push($checkbox.data('chapter'));

                    }
                });
                // Put new filters into paginator request
                var request = JSON.parse(ajaxParams.request);
                request.activeFilters.title[0].nChapterNum = defaultReq.bookFilters.chapterNums;
                request.activeFilters.title[0].nTextBookId  = defaultReq.bookFilters.bookIds;
                ajaxParams.request = JSON.stringify(request);

                if( cancelAjaxCall===false ){
                    // Call paginator and then refresh results
                    newPaginator();
                }
            }
            function nextPageHandler(){
                var paginatorData = paginator.getState();
                if(paginatorData.ajaxItemCount !== 0 && activePaginationCalls === 0) {
                    activePaginationCalls++;
                    paginator.getPageResults({
                        perPage: defaultReq.perPage,
                        direction: 'next'
                    });
                    renderPaginationCount();
                }
            }
            function prevPageHandler(){
                var paginatorData = paginator.getState();
                if(paginatorData.ajaxItemCount !== 0 && activePaginationCalls === 0 && paginatorData.currentPage > 1) {
                    activePaginationCalls++;
                    paginator.getPageResults({
                        perPage: defaultReq.perPage,
                        direction: 'previous'
                    });
                    renderPaginationCount();
                }
            }

            function sortByHandler(){
                //var sortBy = $(this).find('span').data('sortby');
				var sortBy = $('#sort').val();
                var request = JSON.parse(ajaxParams.request);
				request.perPage = parseInt( $('#items-per-page').val() );
                request.orderSelection= sortBy;
                ajaxParams.request = JSON.stringify(request);
        
                newPaginator();
            }
            function itemsPerPageHandler(){
                //defaultReq.perPage = parseInt( $(this).find('span').text() );
				defaultReq.perPage = parseInt( $('#items-per-page').val() );
                // activePaginationCalls++;
                var request = {
                    perPage: defaultReq.perPage,
                    direction: 'same'
                }
                onResultsQueue.push(request);

                if(activePaginationCalls > 0) {
                    // wait for active request to complete to run the queue
                } else {
                    runPaginatorQueue();
                }
            }
			function applySortHandler(){
				defaultReq.perPage = parseInt( $('#items-per-page').val() );
				var sortBy = $('#sort').val();
				var request = JSON.parse(ajaxParams.request);
				request.orderSelection= sortBy;
				request.perPage = parseInt( $('#items-per-page').val() );
				ajaxParams.request = JSON.stringify(request);
		
				newPaginator();
			}

            function runPaginatorQueue(){
                for(var i = onResultsQueue.length; i > 0; i--) {
                    activePaginationCalls++;
                    var request = onResultsQueue.pop();

                    paginator.getPageResults(request);
                }
                
            }
            function searchHandler(){
                var searchStr =  $('#pagination-search').val();
        
                var request = JSON.parse(ajaxParams.request);
                request.searchString= searchStr;
                ajaxParams.request = JSON.stringify(request);
                newPaginator();
            }
            function likedItemsHandler(){
                var $likedFilter = $('#liked-filter');
                var likedChecked = $likedFilter.prop('checked');
                // var cancelAjaxCall = false;
                // console.log($likedFilter.attr('data-cancelajax'));
                // if($likedFilter.attr('data-cancelajax') === 'true') {
                //     cancelAjaxCall = true;
                //     console.log('cancel ajax');
                //     $likedFilter.attr('data-cancelajax', 'false');
                // } 

                 if(/*!cancelAjaxCall*/activePaginationCalls == 0) {
                    var request = JSON.parse(ajaxParams.request);
                    request.likeStatus= +likedChecked;
                    ajaxParams.request = JSON.stringify(request);
                    newPaginator();
                 }
            }
            function restartPaginator(){
                refreshLocalRequestData();
                handleSearchRendering();
                newPaginator();
            }
            function refreshLocalRequestData(){
                defaultReq = defaultReqCopy;
        
                // Empty recipe requet you have to parse and stringify again
                var request = JSON.parse(ajaxParams.request);
                request.activeFilters.category.nCourseId = [];
                request.activeFilters.category.nCookingMethodId = [];
                request.activeFilters.category.nCuisineId = [];
                request.activeFilters.title.nChapterNum = [];
                request.likeStatus = 0;
                request.searchString = '';
                ajaxParams.request = JSON.stringify(request);
            }
            function newPaginator(){
                activePaginationCalls++;
                removeAllItems();
                $('.pagination-component').show();
                console.log('show pagination component');
                console.log('NEW paginator one more call:');
                console.log(activePaginationCalls);
                paginator = null;
                paginator = new _Paginator({
                    getItemsUrl: getItemsUrl,
                    getMoreItemsUrl: getMoreItemsUrl,
                    perPage: defaultReq.perPage,
                    batchSize: defaultReq.batchSize,
                    ajaxParams: ajaxParams
                }); 
                paginator.getPageResults({
                    perPage: defaultReq.perPage,
                    direction: 'first'
                });
                _EventEmitter.emit('global-paginator-update', paginator);
            }

            // Renderers
            function renderResults(dataArr){                 
                activePaginationCalls--;
                $('.spinner-component').hide();
                $('.list').show();

                console.log('Results: Recipe list');
                console.trace( );
                
                // render results w data here
                $('.pagination-component .list').show();
                $('.card-locator').not('.placeholder, .placeholderNoClick').remove();
                $('.jplist-no-results').hide();
                renderPaginationCount();
                handleSearchRendering();

                var paginatorData = paginator.getState();
                console.log(dataArr);
                var searchQuery = JSON.parse(paginatorData.ajaxParams.request).searchString;
                var itemsFound = paginatorData.ajaxItemCount;
                dataArr.forEach(function(data){
                    if(itemToRender === 'recipeCard') {
                        RecipeCardRenderer.render(data);
                    } else if(itemToRender === 'bookCard') {
                        BookCardRenderer.render(data);
                    } else if(itemToRender === 'menuCard') {
                        MenuCardRenderer.render(data);
                    }
                });
                _EventEmitter.emit('cards-rendered-after-ajax');
                runPaginatorQueue();
            }


            function removeAllItems(){
                $('.card').not('.placeholder, .placeholderNoClick').not('.always-show').remove();
                $('.spinner-component').show();
                $('.pagination-component .list').hide();
                $('.jplist-no-results').hide()
                $('.jplist-none-created').hide();
            }

            
            function handleSearchRendering() {
                var paginatorData = paginator.getState()
                var searchQuery = JSON.parse(paginatorData.ajaxParams.request).searchString;
                var itemsFound = paginatorData.ajaxItemCount;
                
                if(searchQuery) {
                    renderSearchTerm(searchQuery, itemsFound);
                } else {
                    removeSearchTerm();
                }
            }
            function renderSearchTerm(searchQuery, itemsFound){
                $results = $('.controls .search-results-info');
                $results.removeClass('hidden'); 
                $results.find('.search-query').text('“'+searchQuery+'”'); 
                $results.find('.qty').text(itemsFound); 
            }
            function removeSearchTerm(){
                $('.search-results-info').addClass('hidden');
            }

            function renderError(data){
                // activePaginationCalls--;
                $('.spinner-component').hide();
                renderPaginationCount();
                handleSearchRendering();
                $('.pagination-component').hide();
                $('.jplist-no-results').show();
            }

            function renderNoResults(data){
                activePaginationCalls--;
                $('.spinner-component').hide();
                // console.log('NO RESULTS active calls:');
                // console.log(activePaginationCalls);
                renderPaginationCount();
                handleSearchRendering()
                $('.pagination-component').hide();

                var onBooksPage = window.location.href.includes('/book/titles');
                if(onBooksPage){
                    $('.jplist-no-results').show();
                } else if($('.search-results-info').is(':visible') || $('.filter-badge-collection').is(':visible'))
                    $('.jplist-no-results').show();
                else
                    $('.jplist-none-created').show();
            }

            function ajaxCallAborted(){
                activePaginationCalls--;
                // console.log('ABORTED Ajax');
                // console.log(activePaginationCalls);
            }
        
            function renderPaginationCount(){
                var totalPages = (paginator.totalPages!== 0) ? paginator.totalPages : 1;
				$('.jplist-panel .page-counter').text("Page " + paginator.currentPage + " of " + totalPages);
                //$('.jplist-panel .current-page').text(paginator.currentPage);
                //$('.jplist-panel .total-pages').text(totalPages);

                if( totalPages == 1 ) 
                {
                    $( '.jplist-pagination-controls' ).hide(  );

                }
                else
                {
                    $( '.jplist-pagination-controls' ).show(  );  
					if(paginator.currentPage == 1){
						$('.jplist-prev').hide();
					} else {
						$('.jplist-prev').show();
					}
					if(paginator.currentPage == totalPages){
						$('.jplist-next').hide();
					} else {
						$('.jplist-next').show();
					}
                } 
            }

            var RecipeCardRenderer = (function(){
                var render = function (data) {
                    var recipeCard = new RecipeCard(data);
                    recipeCard.fillCard(data);
                    recipeCard.syncLikeBtn(); // sync after cause we need some of the data filled above
                    $('.pagination-component .list').append(recipeCard.$);
                }
                var RecipeCard = function RecipeCard(data){
					if(data.permission == 1 || window.location.href.indexOf( 'myRecipes' ) >0){
                    	this.$ = $('.card-locator.placeholder').clone();
						this.$.removeClass('placeholder');
					} else {
						this.$ = $('.card-locator.placeholderNoClick').clone();
						this.$.removeClass('placeholderNoClick');
					}
                    
                }
                RecipeCard.prototype.syncLikeBtn = function(){
                    var likeBtn = new _LikeBtn( this.$.find('.like-icon-btn') );
                }
                RecipeCard.prototype.fillCard = function(data) {              
                    var recipeImg;
					
                    if( data.vcImageNameFull ) data.vcImageNameFull = data.vcImageNameFull.replace( ".tif", ".jpg" );
                    
                    // if we needed a custom override for a thumbnail, then use the override dir. Need _override in the filename
                    if( data.vcImageNameThumb && data.vcImageNameThumb.indexOf( '_override' ) > 0 )
                    {
                        recipeImg = ASSET_DIR + '/thumb_override/' + data.vcImageNameThumb;
                    }
                    else 
                    {
                        if(data.vcImageNameFull == 'N/A' || data.vcImageNameFull == '' || data.vcImageNameFull == null || data.vcImageNameFull == 'null') {             
                             recipeImg = ASSET_DIR + "/recipe-thumbnail-square.png";
                        }
                        else{
                            recipeImg =  'https://media.pearsoncmg.com/ph/chet/kitchenmanager_v2/images/' + data.imgPath + data.vcImageNameFull;
                        }

                    }
					
                    data.dtModified =  data.dtModified;
                    if( window.location.href.indexOf( 'myRecipes' ) >0 )
                    {
                        var newDate = sqlToJsDate ( data.dtModified );
                        this.$.find('.detail').text('Last Updated: ' + newDate );
                    }
                    else
                    {
                        if(data.vcTextbookName == 'N/A' || data.vcTextbookName == '' || data.vcTextbookName == null || data.vcTextbookName == 'null'){
                            this.$.find('.detail').text('');
                        } else {
                            this.$.find('.detail').text(data.vcTextbookName);
                        }
                    }

                    // Debug - this prints out every recipe pulled from the getRecipes API
                    // console.log( data );
               
                    this.$.find('.card-img').attr('src', recipeImg);
                    this.$.find('.card-img').attr('alt', data.vcImageNameThumb);
                    this.$.find('.title').html(_Util.truncateStr(data.vcName, 78));
                    this.$.find('.id').text(data.nRecipeId);

                    // Set URL to recipe
					if(data.permission == 1 || window.location.href.indexOf( 'myRecipes' ) >0){
						this.$.find('.recipeLinkBottom').attr('aria-label', data.vcName);
						if(document.location.href.toLowerCase().includes('myrecipes'))
							this.$.find('a').attr('href', '/pearson_pkm/recipe/'  + 'getMyRecipe/' + data.nRecipeId);
						else if(document.location.href.toLowerCase().includes('getallrecipes'))
							this.$.find('a').attr('href', '/pearson_pkm/recipe/'  +'getRecipe/' + data.nRecipeId);
					}
                    
                    this.$.find('.card-img').on('error', 
                        function() { $(this).attr( "src",  ASSET_DIR + "/recipe-thumbnail-square.png" ); });
                        
                    // Set like status
                    var nLikeStatus = (data.nLikeStatus != null) ? data.nLikeStatus : 0;
                    this.$.find('.liked').text(nLikeStatus);
                }

                return {
                    render: render
                }
            })();

            var BookCardRenderer = (function(){
                var render = function (data) {
                    var bookCard = new BookCard();
                    bookCard.fillCard(data);
                    $('.pagination-component .list').append(bookCard.$);
                }
                var BookCard = function BookCard(){
                    this.$ = $('.card-locator.placeholder').clone();
                    this.$.removeClass('placeholder');
                }
                BookCard.prototype.fillCard = function(data) {
                    console.log( data );
                    this.$.find('a').attr('href','/pearson_pkm'  + '/book/getBook/' + data.nTextbookId );
                    this.$.find('.card-img').attr('src', IMG_ROOT_URL + data.tableImgDir  + "/cover_small.jpg");
                    this.$.find('.card-img').attr('alt', data.imgAlt);
                    this.$.find('.title').text(_Util.truncateStr(data.vcTextbookName, 100));
                    this.$.find('.authors').text(data.authors);
                }

                return {
                    render: render
                }
            })(); 

            var MenuCardRenderer = (function(){
                var render = function (data) {
                    var menuCard = new MenuCard();
                    menuCard.fillCard(data);
                    $('.pagination-component .list').append(menuCard.$);
                }
                var MenuCard = function MenuCard(){
                    this.$ = $('.card-locator.placeholder').clone();
                    this.$.removeClass('placeholder');
                }
                MenuCard.prototype.fillCard = function(data) {
                    this.$.closest('a').attr('href','/pearson_pkm'  + '/menu/getMenu/' + data.nMenuId);
                    console.log( data );
                    
                    var dateString = '';
                    
                    if(  data.dtModified  ){
                        dateString = 'Last Updated: ' + sqlToJsDate ( data.dtModified );
                    }
                    else if (  data.dtCreated  ){
                        dateString = 'Last Updated: ' + sqlToJsDate ( data.dtCreated );
                    }
                    
                    this.$.find('p.detail').html(dateString);

                    this.$.find('.card-img').attr('src', data.vcImageThumb);
                    this.$.find('.card-img').attr('alt', data.imgAlt);
                    this.$.find('.title').text(_Util.truncateStr(data.nvMenuTitle, 78));
                    this.$.find('.id').text(data.nMenuId);
                }

                return {
                    render: render
                }
            })();
        
            function getMonthText( $monthNo )
            {
                var month = new Array();
                month[0] = "January";
                month[1] = "February";
                month[2] = "March";
                month[3] = "April";
                month[4] = "May";
                month[5] = "June";
                month[6] = "July";
                month[7] = "August";
                month[8] = "September";
                month[9] = "October";
                month[10] = "November";
                month[11] = "December";
                return month[$monthNo];
            }

            function formatDate( $dt )
            {
                var day = new Date( $dt );
                var $ret = getMonthText( day.getMonth(  ) ) +" " + day.getDay(  ) +", " + day.getFullYear(  );
                return $ret;
            }

            function init(){
                binds();
                console.log( "%cInitialize Paginator" , "color:white;background-color:darkgreen;" );
                subscribeToPaginatorEvents();
                // check filters make sure that none are selected and if so then run this 
                if( typeof( window._CACHEDFILTERS ) == "undefined" ) window._CACHEDFILTERS = [];
                if( $('.category-filter').find( 'input:checked' ).length + $('input#liked-filter:checked').length == 0)//+  window._CACHEDFILTERS.length == 0 )
                {
                    console.log( "%cNew paginator","color:white;background-color:darkgreen;" );
                    newPaginator();
                }
//                
                $('.list').hide();
            }

            init();
        }

        return {
            getInstance: function(options){
                if(!instance) new _PaginationDataHandler(options)
                return instance;
            }
        }
    })()
});