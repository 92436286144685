Ajax.User = (function () {
    var instance;

    function AjaxUser() {

    }

    return {
        getInstance: function () {
            if (!instance)
                instance = new AjaxUser();

            return instance;
        }
    }
})();