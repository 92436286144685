/*
    This is used for filter badges. It handles their relationships.

    A filter badge group has a title, and badges to its right.

    The binds basically let you
        Remove a filter
        Remove all filters
        Hide if Empty is application specific so not really needed for reusing.
*/

var _Util = Util;

var FilterBadgeCollection = function(){
    _Util = Util;
    this.$ = $('.filter-badge-collection');
    this.$removeAllBtn = $('button.clear-all-badges');
    this.binds();
}

FilterBadgeCollection.prototype.binds = function () {
    var _this = this;

    $(document).on('click', '.filter .filter-close', function (e, params) {
        var filterCloseBtn = this;
        _this.removeFilters(e, params, filterCloseBtn);
    });

    $(document).on('mouseup keyup', function () {
        setTimeout(_this.hideIfEmpty.bind(_this), 10);
    });

    this.$removeAllBtn.on('click', function () {
        setTimeout(_this.removeAll.bind(_this), 100)
    });
}

FilterBadgeCollection.prototype.removeFilters = function(e, params, filterCloseBtn) {
    var clickFromOutsideComponent = (params && typeof params.clickFromOutsideComponent !== 'undefined')
            ? params.clickFromOutsideComponent
            : false;
    this.removeFilter($(filterCloseBtn).parent(), clickFromOutsideComponent);
}


FilterBadgeCollection.prototype.removeFilter = function ($filter, clickFromOutsideComponent) {
    var _this = this;
    var $group = $filter.parent();

    $filter.remove();
    this.removeEmptyGroup($group);
    
    // IE 11 sends focus back to top of page
    // So we focus on the first tabbable element
    if (!clickFromOutsideComponent) {
        var $firstTabbableEl = _Util.getFirstTabbableEl(_this.$); 
        $firstTabbableEl.focus();
    }
}

FilterBadgeCollection.prototype.removeAll = function () {
    var _this = this;
    $('.filter').each(function (i, el) {
        _this.removeFilter($(el));
    });
}

FilterBadgeCollection.prototype.hideIfEmpty = function () {
    if (this.$.find('.filter').length === 0) {
        this.$.addClass('hidden');
        $('.clear-all-badges').addClass('hidden');
    } else {
        this.$.removeClass('hidden');
        $('.clear-all-badges').removeClass('hidden');
    }
}

FilterBadgeCollection.prototype.removeEmptyGroup = function ($group) {
    if ($group.find('.filter').length === 0)
        $group.remove();
 
   
}
