// // These dependencies will be called on every page
// var AddRecipeCtrl = function(){
//     this.initDependencies();
// }

// AddRecipeCtrl.prototype.initDependencies = function(){
//     new DynamicWidthDropdown();

//     MenuAndIngredientList.getInstance();
//     _RecipePresenter = RecipePresenter.getInstance();
//     _RecipePresenter.init();

//     new GoBackBtn();
//     new DropdownMenu();
//     new MeasurementControls();
//     new DragNDrop();
//     new TinyMceWrapper();

//     var _AwesompleteWrapper = new AwesompleteWrapper();
//     _AwesompleteWrapper.initIngredients();

// }