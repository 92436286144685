var AddToMenuValidation;
$(document).ready(function(){
    var _Validation = Validation.getInstance();
    var _Util = Util;

    AddToMenuValidation = function(){
        this.binds();
    }

    AddToMenuValidation.prototype.selectOne = function () {
        var errFound = false;
        var errMsg = '';
        var $checkedBox = $('.add-to-menu-modal input[type="radio"]:checked');

        if($checkedBox.length === 0) {
            errFound = true;
            errMsg = 'Please select where you want to add your recipe.';
        }

        _Validation.handleError(errFound, errMsg, $('.add-to-menu-modal .modal-errors'));
    }


    AddToMenuValidation.prototype.selectExistingMenu = function () {
        var errFound = false;
        var errMsg = '';
        var $modal = $('.add-to-menu-modal');
        var $checkedBox = $modal.find('input[type="radio"]:checked');
        var onSelectExistingMenu = $checkedBox.attr('id') === 'add-to-existing';
        var noOptionSelected =  $modal.find('#add-to-existing-dropdown option:selected').text() === '';
        if(onSelectExistingMenu && noOptionSelected) {
            errFound = true;
            errMsg = 'Please select one of your menus from the dropdown.';
        }

        _Validation.handleError(errFound, errMsg, $('#add-to-existing-dropdown'));
    }

    AddToMenuValidation.prototype.newMenuName = function () {
        var errFound = false;
        var errMsg = '';
        var $checkedBox = $('.add-to-menu-modal input[type="radio"]:checked');
        var $newMenuInput = $('.add-to-menu-modal .new-menu-edit');
        var newMenuSelected = $checkedBox.attr('id') === 'create-new-menu';
        console.log($checkedBox);
        if($newMenuInput.val() === '' && newMenuSelected) {
            errFound = true;
            errMsg = 'Please enter the name of your new menu.';
        }
        _Validation.handleError(errFound, errMsg, $newMenuInput);
    }

    AddToMenuValidation.prototype.all = function(){
        this.selectOne();
        this.newMenuName();
        this.selectExistingMenu();
    }

    AddToMenuValidation.prototype.binds = function() {
        var _this = this;
        $('.add-to-menu-modal .new-menu-edit').on('blur', this.newMenuName);
        $('.add-to-menu-modal .btn-lg-primary').on('mousedown keydown click', function(e){
            if( _Util.isHumanClickEvent(e) ) return; // human click does a double validation so dont allow 

            _this.all();
        });
    }


    // INIT
    new AddToMenuValidation();

});