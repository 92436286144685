$(document).ready(function () {
    var _RecipeCalculator;
    var _EditRecipeValidation;
    var _UnitCodes = UnitCodes;
    var _Util = Util;
    var UnitsTable = new UnitCodes().getTable();
    var allIngredientsData;
    var _MenuAndIngredientList = MenuAndIngredientList.getInstance();

    var servingChangePct = null;
    var _localSmartConvert  = SmartConvert.getInstance();

    CostList = {};
    CostList.$ = $('.modal-cost-list');
    
    CostList.init = function(){
        CostList.fillYieldAndServing();
        CostList.populateIngredientList();
        CostList.initCalculator();
        CostList.updateUI();
        CostList.binds();
        _EditRecipeValidation = new EditRecipeValidation(  );
    }

    CostList.fillYieldAndServing = function(){
        var $yieldCtrl = $('.yield-control');
        var $servingCtrl = $('.serving-control');

        var yieldAmt = $yieldCtrl.find('.yield-amt').val();
        var yieldUnitCode = $yieldCtrl.find('select').attr('data-selected');
        var yieldUnitName = $yieldCtrl.find('select option[value="'+yieldUnitCode+'"]:first').text();
        var servingAmt = $servingCtrl.find('.amt').text();
        var servingUnitName = $servingCtrl.find('.serving-unit').text();

        CostList.scaleChanged = false;
        CostList.$.find('.recipe-yield').text(yieldAmt);
        CostList.$.find('.yield-unit').text(yieldUnitName);
        CostList.$.find('.serving-size').text(servingAmt);
        CostList.$.find('.serving-size-unit').text(servingUnitName);
    }

    CostList.populateIngredientList = function(){
        allIngredientsData = _MenuAndIngredientList.getAllData($('.ingredients-list'), {
            getSublists: false
        });
        allIngredientsData.forEach(function (ingredientData) {
            CostList.addItemWData(ingredientData);
        });
    }
    CostList.addItemToList = function ($item) {
        CostList.$.find('.modal-body .ingredient-list').append($item);
    }

    CostList.updateIngredientCosts = function (  )
    {
        var talley = 0;
         if( !servingChangePct  )return;
        //CostList.$.find('.ingredient-list').find( '.row.item' ).each( function( i, e )
        
        CostList.scaleChanged = true;
         //$( '.ingredients-list div.nested'  ).children( '.item' ).each( function( i, obj ){
        CostList.$.find('.ingredient-list').find( '.row.item' ).each( function( i, e )
        {
            var thePrice =  $( e ).attr( 'data-perunitprice' );
			var currentConversion = 1;
			console.log($(e).find('.name').text());
			console.log($(e).attr('data-amt2'));
			if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric' && typeof($(e).attr('data-amt2')) !== 'undefined'){
				currentConversion = 2;
			}
			if(currentConversion == 2){
				var theQty = $( e ).attr( 'data-amt2' );
            	var theUnit = $( e ).attr( 'data-unit2' );
			} else {
            	var theQty = $( e ).attr( 'data-amt' );
            	var theUnit = $( e ).attr( 'data-unit' );
				console.log("converting in standard, "+theQty+" "+theUnit);
			}


                //////////////////////////////////////////////////////
            var newIngAmt;
			var curIngAmt = theQty;
            if( servingChangePct && typeof(theQty) != "undefined" && theQty !== "--"  && theQty !== "-" )
            {
                var cleanQuantity = _localSmartConvert.cleanNumber(  theQty ); 
            
                newIngAmt = Number( parseFloat( curIngAmt ) * servingChangePct + parseFloat( curIngAmt ) ).toFixed( 2 );
            }
            else
            {
                newIngAmt = curIngAmt;               
            }

            //newIngAmt = curIngAmt;
			var theAmtNoDollar = thePrice.toString(  ).replace( "$",'' );
            if( noPriceUnitIds.includes( Number( $(e).attr('data-unit') ) )  ) {
                 $( e ).find('.price').text( '' );
                 $( e ).find('.qty').text('');
            }
            else
            {
				unitUpscaleFraction =  _localSmartConvert.doConvert( newIngAmt, theUnit ) ;
				if(thePrice != "-" && thePrice != "--"){
					$( e ).find('.price').text('$' + Util.convertToUSD( parseFloat( theAmtNoDollar ) * newIngAmt ));
					talley = talley +  ( parseFloat( theAmtNoDollar ) * newIngAmt );
				}

                if( unitUpscaleFraction.newUnit.fail==false && unitUpscaleFraction.newUnit.quantityFraction ){
                     $( e ).find('.qty').html( unitUpscaleFraction.newUnit.quantityFraction  );
                    // $( e ).attr('data-amt', unitUpscaleFraction.newUnit.quantity );  
					if(currentConversion == 2){
						$( e ).attr( 'data-amt2', newIngAmt);
					} else {
                    	$( e ).attr( 'data-amt', newIngAmt);
						$( e ).attr( 'data-amt1', newIngAmt);
					}
                    //$( e ).attr('data-unit', unitUpscaleFraction.newUnit.unitId );     
                }
                else
                {
					if(currentConversion == 2){
						$( e ).attr( 'data-amt2', newIngAmt);
					} else {
                    	$( e ).attr( 'data-amt', newIngAmt);
						$( e ).attr( 'data-amt1', newIngAmt);
					}

                     $( e ).find('.qty').text( Number(Number( newIngAmt ).toFixed( 2 )) );
                }
                if(  unitUpscaleFraction.newUnit.fail==false && unitUpscaleFraction.newUnit.unitName )
                {
                     $( e ).find( '.unit' ).html( unitUpscaleFraction.newUnit.unitName );
                //   $( this ).find( 'span.unit' ).html( '&#8531;' );
               }
            }
                //////////////////////////////////////////////////////

        } );
        $( '.total-recipe-cost' ).html('$' + Util.convertToUSD( parseFloat( talley )));
        servings = parseFloat( CostList.$.find('.number-of-servings-edit').html(  )  );
        var perServing = talley / servings;
        $( '.cost-per-serving' ).html('$' + Util.convertToUSD( parseFloat( perServing )));
		
		var currentConversion = $('.serving-control').attr('data-currentconversion');
		if(currentConversion == 2){
			var newYieldAmt = parseFloat( $('.yield-control').attr('data-amt2') ) * servingChangePct +  parseFloat( $('.yield-control').attr('data-amt2') );
			var unitname1 = $('.yield-control').attr('data-unitname2');
		} else {
			var newYieldAmt = parseFloat( $('.yield-control').attr('data-amt') ) * servingChangePct +  parseFloat( $('.yield-control').attr('data-amt') );
			var unitname1 = $('.yield-control').attr('data-unitname1');
		}
         

        newYieldAmt =
            Number( 
                parseFloat( 
                    Number( newYieldAmt   ) .toFixed( 2 )
                )
            );
         

        $( 'div#modal-recipe-cost   p.recipe-yield' ).text( newYieldAmt );
        $( 'div#modal-recipe-cost   p.yield-unit' ).text( unitname1  );


        servingChangePct = null;
    };

    CostList.createItemWData = function ( itemData ) {
        var $ourNewItem = CostList.$.find('.item.placeholder').clone();
        $ourNewItem = $ourNewItem.removeClass('placeholder');

        console.log( "> Creating new items for COST LIST" );
        console.log( itemData );
        for (var key in itemData) {
            if (itemData.hasOwnProperty(key))
                $ourNewItem.attr( "data-" + key, itemData[key] ); // add all original item data to shopping list item
        }
        
        console.log( $ourNewItem );
        var convertedUnit = UnitsTable[itemData.unit-1];

        $ourNewItem.find('.name').text(itemData.name);  
        var useThisUnitName ;
        var useThisUnitId ;
        
    

        // First do all the usual stuff to make sure we get the right price...then clobber the unit info if we need to w/ scaled metric
        $ourNewItem.find('.amt .unit').text(convertedUnit.single);
        $ourNewItem.find('.amt .qty').text('');
        var newIngAmt;
        if( servingChangePct )
        {
			if (typeof( itemData.amt2 ) == "undefined" ) {
            	var curIngAmt = parseFloat( _localSmartConvert.cleanNumber(itemData.amt) );
			} else {
				var curIngAmt = parseFloat( _localSmartConvert.cleanNumber(itemData.amt1) );
			}
            newIngAmt = Number( parseFloat( curIngAmt ) * servingChangePct + parseFloat( curIngAmt ) ).toFixed( 2 );
        }
        else
        {
			if (typeof( itemData.amt2 ) == "undefined" ) {
				console.log("New ing use amt");
            	newIngAmt = _localSmartConvert.cleanNumber( itemData.amt );
			} else {
				console.log("New ing use amt1");
				newIngAmt = _localSmartConvert.cleanNumber( itemData.amt1 );
			}
        }

        if( noPriceUnitIds.includes( Number(itemData.unit) ) || noPriceUnitNames.includes( itemData.unit1 ) ) {
            $ourNewItem.find('.price').text( '-' );
            $ourNewItem.find('.amt .qty').text('');
        }
        else
        {
			if (typeof( itemData.amt2 ) == "undefined" ) {
				unitUpscaleFraction =  _localSmartConvert.doConvert( itemData.amt, itemData.unit ) ;
			} else {
				unitUpscaleFraction =  _localSmartConvert.doConvert( itemData.amt1, itemData.unit1 ) ;
			}
           if( itemData.perunitprice && itemData.perunitprice !== "--" ){
                var writePrice = parseFloat(itemData.perunitprice) * newIngAmt;
                if( writePrice <= 0.01 )
                    $ourNewItem.find('.price').text( '-' );    
                else
                    $ourNewItem.find('.price').text('$' + Util.convertToUSD( writePrice ) );
           }
            else{
                $ourNewItem.find('.price').text( '-' );
            }
        
            if(  unitUpscaleFraction.newUnit.fail==false && unitUpscaleFraction.newUnit.quantityFraction ){
                 $ourNewItem.find('span.qty').html( unitUpscaleFraction.newUnit.quantityFraction  );     
            }
            else
            {
               $ourNewItem.find('span.qty').text( Number(Number( newIngAmt ).toFixed( 2 )) );
            }
            if( unitUpscaleFraction.newUnit.fail==false && unitUpscaleFraction.newUnit.unitName )
            {
                $ourNewItem.find( 'span.unit' ).html( unitUpscaleFraction.newUnit.unitName );
            //   $( this ).find( 'span.unit' ).html( '&#8531;' );
           }
           else
           {
                $ourNewItem.find( 'span.unit' ).html( convertedUnit.single );
           }
		   
		   // here we do the clobber...
			var currentConversion = 1;
			if( $( "input[name=measurement-system]:checked" ).val(  ) == 'metric'){
				currentConversion = 2;
			}
			console.log("unit1 is "+itemData.unit1);
			if( currentConversion == 2 && !wholeUnitNames.includes( itemData.unit1 ))
			{
				unitUpscaleFractionMetric =  _localSmartConvert.doConvert( itemData.amt2, itemData.unit2 ) ;
				if(unitUpscaleFractionMetric.newUnit.fail != true){
					$ourNewItem.find('.amt .unit').text( unitUpscaleFractionMetric.newUnit.unitName );
					$ourNewItem.find('.amt .qty').text( unitUpscaleFractionMetric.newUnit.quantityFraction );
				}
			}
		}

        return $ourNewItem;
    }

    // Adds new items to list
    // NOTE: SEE RETURN STAEMENT. DOESNT NOT HANDLE DUPLICATE ITEM PROCESSING ie add item prices and ingredient amounts
    CostList.addItemWData = function ( itemData ) {
        var $item = CostList.$.find('[data-name="' + itemData.name + '"]');
        //if (!itemData.price) return;
        var $addItem = null;
        if ($item.length === 0)
        { 
            $addItem = CostList.createItemWData(itemData);
            console.log( $addItem.data(  ) );
        }
        else
        {
         return;
         } //TODO: In case of duplicate items this is where we would handle updates that require adding prices and quantities
        CostList.addItemToList($addItem);
    }


     CostList.recalcPrice = function(){
        var talley =0;
        CostList.$.find('.ingredient-list').find( '.row.item' ).each( function( i, e )
        {
            var thePrice =  $( e ).attr( 'data-perunitprice' );  // price has already been calculated...
            
            if( thePrice == '-' ) return;
            if( thePrice == '--' ) return;
            
            var theQty = $( e ).attr( 'data-amt' );
            var theUnit = $( e ).attr( 'data-unit' );
            
            if( noPriceUnitIds.includes( Number( theUnit ) ) ) return;
            thePrice = thePrice.toString(  ).replace( "$",'' );
            if( thePrice ==="" ) thePrice = 0;

            var theAmtNoDollar = _localSmartConvert.cleanNumber(  thePrice ); ;
            if( !thePrice || isNaN( thePrice )) thePrice = 0;

            var cleanQuantity = _localSmartConvert.cleanNumber(  theQty ); 
            talley = talley + ( theAmtNoDollar * cleanQuantity );
        });

         $( '.total-recipe-cost' ).html('$' + Util.convertToUSD( parseFloat( talley )));
        servings = parseFloat( CostList.$.find('.number-of-servings-edit').html(  )  );
        var perServing = talley / servings;
        $( '.cost-per-serving' ).html('$' + Util.convertToUSD( parseFloat( perServing )));

     };


    CostList.initCalculator = function(){
        var yieldAmt = $('#yield-input').val();

        var recipeCalcYieldAmt = _Util.fractionStrToDecimal($('.yield-control').attr('data-servingunitequivalent')*yieldAmt);
        var servingSizeAmt = _Util.fractionStrToDecimal($('.serving-control .amt').text())
        
        var recipeCost = 0;
        allIngredientsData.forEach(function(ingredientData){
            recipeCost += parseFloat(ingredientData.price);
        })

        console.log(recipeCalcYieldAmt);
        console.log(servingSizeAmt);
        console.log(recipeCost);

        var servingNumber = 0;
        if( $('input#number-of-servings_recipe').val(  ) ) servingNumber = $('input#number-of-servings_recipe').val(  );
        _RecipeCalculator = new RecipeCalculator({
            yield: recipeCalcYieldAmt,
            servingSize: servingSizeAmt,
            recipeCost: recipeCost,
            numberOfServings:  servingNumber
        });
    }

    CostList.updateUI = function(){
        var values = _RecipeCalculator.getAllProperties();

        factor = null;
        if( values.servingChangePct ) factor = values.servingChangePct;
        servingChangePct = factor;

        // if ( CostList.scaleChanged == false )
        // {
        //     if( values.recipeCost )
        //         CostList.$.find('.total-recipe-cost').text('$' + _Util.convertToUSD(values.recipeCost));

        //     CostList.$.find('.cost-per-serving').text('$' + _Util.convertToUSD(values.recipeCost / values.numberOfServings));
        // }
         
        
        if (values.numberOfServings) {
            CostList.$.find('.number-of-servings-edit').text(values.numberOfServings);
            CostList.$.find('.number-of-servings-edit').val(values.numberOfServings);
        }
       

        if (values.menuPrice){
            CostList.$.find('.menu-price-edit').val('$' + _Util.convertToUSD(values.menuPrice));
            CostList.$.find('.menu-price-edit').text('$' + _Util.convertToUSD(values.menuPrice));
        }
        if (typeof values.margin !== 'undefined' && values.margin ) {
            CostList.$.find('.margin-measurement-edit').val('$' + _Util.convertToUSD(values.margin));
            CostList.$.find('.margin-measurement-edit').text('$' + _Util.convertToUSD(values.margin));
        }
        if (values.foodCostPercentage) {
            if( values.foodCostPercentage == "< 1%" )
            {
                CostList.$.find('.food-cost-percent-edit').val( "< 1%");
                CostList.$.find('.food-cost-percent-edit').text("< 1%");
            }
            else
            {
                CostList.$.find('.food-cost-percent-edit').val(_Util.convertToPercent(values.foodCostPercentage) + '%');
                CostList.$.find('.food-cost-percent-edit').text(_Util.convertToPercent(values.foodCostPercentage) + '%');
            }
        }
        if (values.yield)
            CostList.$.find('.recipe-yield').text( 
                Number( 
                    parseFloat( 
                        Number( values.yield * $('.yield-control').attr('data-yieldmultiplier' )
                            ) .toFixed( 2 )
                        )
                    )
                );
        if (values.servingSize)
            CostList.$.find('.serving-size').text(values.servingSize);

        
        CostList.updateIngredientCosts( );
        CostList.recalcPrice(  );

    }

    CostList.validateInput = function (options) {
        if (options.userInput === '' || isNaN(parseFloat(options.userInput))) {         
            $(options.input).siblings('.error').text('Invalid input, please enter a number')
        } else {           
            $(options.input).closest(options.uiParent).find('.error').text('')
        }
    }

    CostList.binds = function(){
        // Controls. On change will call recipe calculator to recalculate all values and update them in the UI
        CostList.$.find('#number-of-servings_recipe').on('change', function(){
            var userInput = $(this).val();

            userInput = _Util.regexGetNumbers(userInput);
            
            if( _EditRecipeValidation.testPostiveNumber( $(this) , "Serving size invalid." , $( "#recipe-modal-errors" ))) return -1;


            CostList.validateInput({ 
                input: $(this),
                userInput: userInput,
                uiParent: '.calculations-2' 
            });

            $( '#number-of-servings' ).val( userInput );
            _RecipeCalculator.setNumberOfServings(userInput);
            CostList.updateUI();
        })
        CostList.$.find('#menu-price').on('change', function(){
            var userInput = $(this).val();
            userInput = _Util.regexGetNumbers(userInput);
            CostList.validateInput({ 
                input: $(this),
                userInput: userInput,
                uiParent: '.calculations-1' 
            });
            _RecipeCalculator.setMenuPrice(userInput);
            CostList.updateUI();
        })
        CostList.$.find('#food-cost-percent').on('change', function(){
            var theAmt = $(this).val();
            theAmt = _Util.regexGetNumbers(theAmt);
            var userInput = _Util.convertToDecimalPercent( theAmt );
            userInput = _Util.regexGetNumbers(userInput);
            CostList.validateInput({ 
                input: $(this),
                userInput: userInput,
                uiParent: '.calculations-1' 
            });
            _RecipeCalculator.setFoodCostPercentage(userInput);
            CostList.updateUI();
        })
        CostList.$.find('#margin-measurement').on('change', function(){
            var userInput = $(this).val();
            userInput = _Util.regexGetNumbers(userInput);
            CostList.validateInput({ 
                input: $(this),
                userInput: userInput,
                uiParent: '.calculations-1' 
            });
            _RecipeCalculator.setMargin(userInput);
            CostList.updateUI();
        })
    }

    $('.recipe-cost-btn').on('click', function( e){
        if( $( '#recipe-errors' ).hasClass( 'warning-danger' ) )
        {
            e.stopPropagation(  );
            if(  $( '#recipe-errors' ).text().indexOf( "Cannot open model" ) < 0 )
            {
                var newText =  $( '#recipe-errors' ).text() +"  Please correct before opening cost list.";
                 $( '#recipe-errors' ).text(newText);
            }
            return -1;
        }
        CostList.init();
    })

    // REMOVE ALL ITEMS ON MODAL CLOSE
    $('.modal-container#print-recipe-cost').on('mouseup', function(){
        setTimeout(function() {
            var $modal = $('.cost-modal');
            var modalHidden = !$modal.hasClass('show');
            if(modalHidden) {
                $modal.find('.item').not('.placeholder').remove();
            }  
        }, 500);
    })

    // CostList.init();


});