// Used to convert from Cross(ie US to Metric) System & Same System(ie metric to metric) conversions

// Data from tables.js
var tables = Tables;
var util = Util;
var UnitConverter;
$(document).ready(function(){
    UnitConverter = function() {
        // link string to proper conversion table
        var crossSystemTables = [];
        crossSystemTables['metric'] = tables.CrossConversion;
        crossSystemTables['american'] = tables.CrossConversion;

        var systemTables = {};
        systemTables['metric'] = tables.Conversion.Metric;
        systemTables['american'] = tables.Conversion.American;

        // same as this.conversion with very slight modifications
        // TOOD: merge both functions. since they do essentially the same thing
        // 4 scenarios in this table
        // 1 american to metric - direct table match
        // 2 american to metric - direct table match AFTER converting the metric unit to something else
        // 3 metric to american - reverse table match
        // 4 metric to american - reverse table match AFTER converting the metric unit to something else
        this.crossConversion = function (options) {
            var result = {};
            var qty = options.qty;
            var unit = options.unit;
            var targetUnit = options.toUnit;
            var system = options.toSystem;
            var conversionTable = crossSystemTables[system];
            var americanToMetricConversion = conversionTable[unit]
            var metricToAmericanConversion = !conversionTable[unit]
            var edgeCases = unit === targetUnit;

            // Perform a regular conversion left to right 
            // ie floz: {gal: .0071234} -- floz to gal
            if (americanToMetricConversion && !edgeCases) {
                var noTargetUnit = typeof targetUnit === 'undefined';
                var directTargetConversionExists = conversionTable[unit][targetUnit];
                var metricUnit = Object.keys(conversionTable[unit])[0]; // first key of table (string) ---- oz:{g:100} ---- conversionTable[unit] = {g:100}
                var metricQty = conversionTable[unit][metricUnit] * qty;

                if (noTargetUnit || directTargetConversionExists) {
                    result.qty = metricQty;
                    result.unit = metricUnit;
                } else {
                    // try converting with metric table
                    // ie oz to g exists, but you want kg, which is not available in the crossConversion table
                    // so try g to kg in metric table
                    var conversionResult = this.conversion({
                        qty: metricQty,
                        unit: metricUnit,
                        toUnit: targetUnit,
                        withSystem: system // metric
                    });

                    var successfulConversion = conversionResult.unit === targetUnit
                    if(successfulConversion)
                        result = conversionResult;
                }
            }

            // Perform a reverse conversion
            // Use the same table but reverse the values
            //  
            // ie floz: {gal: .0071234} -- gal to floz
            // 
            // Traverse the table to find a right side key match and perform a reverse conversion
            // americanUnit = floz
            // rightObj = {gal: .0071234}
            // metricUnit = gal
            // 
            // reverse conversion formula for gal to floz would be 1/.0071234. Always divide by 1.
            if (metricToAmericanConversion && !edgeCases) {
                
                function reverseTableLookup(unitToMatch, bool){
                    for (var americanUnit in conversionTable) {
                        var metricObj = conversionTable[americanUnit];
                        if(metricObj){
                            var metricUnit = Object.keys(metricObj)[0]
                            // we either convert to the first metricUnit match or the one that also matches the left side targetUnit
                            var unitsMatch = (typeof targetUnit === 'undefined')
                                ? metricUnit === unitToMatch
                                : metricUnit === unitToMatch && americanUnit === targetUnit;
                            if (bool) console.log(unitToMatch)

                            // console.log(targetUnit)
                            if (unitsMatch) {
                                // perform reverse conversion
                                var metricVal = metricObj[metricUnit];
                                var metricConversion = (1 / metricVal);
                                result.qty = metricConversion * qty; // reverse conversion formula
                                result.unit = americanUnit;
                            }
                        }
                    }
                }
                reverseTableLookup(unit);


                // If we have no results in our table, try converting our UNIT to a matching SAME TABLE UNIT, and get a result with 
                // this new unit. 
                // ie no results for kg to oz. So try converting kg to g &  try g to oz
                var noResult = Object.getOwnPropertyNames(result).length == 0;
                if (noResult) {
                    var metricObj = conversionTable[targetUnit];
                    if(metricObj) {
                        var desiredConversion = Object.keys(metricObj)[0];
                        var conversionResult = this.conversion({
                            qty: qty,
                            unit: unit,
                            toUnit: desiredConversion,
                            withSystem: system // metric
                        });
                        var successfulConversion = conversionResult.unit === desiredConversion
                        if (successfulConversion) {
                            reverseTableLookup(conversionResult.unit, true)
                        }
                    }

                }
            }

            // No results founds set to original. No changes
            if (Object.getOwnPropertyNames(result).length == 0) {
                result.qty = qty;
                result.unit = unit;
            }
            return result;
        }

        // same as this.crossConversion with very slight modifications
        // TOOD: merge both functions. since they do essentially the same thing
        this.conversion = function (options) {
            var result = {};
            var qty = options.qty;
            var unit = options.unit;
            var targetUnit = options.toUnit;
            var system = options.withSystem;
            var conversionTable = systemTables[system];
            var edgeCases = unit === targetUnit;

            // Perform a regular conversion left to right 
            // ie floz: {gal: .0071234} -- floz to gal
            if (conversionTable[unit] && !edgeCases) {
                var metricUnit = Object.keys(conversionTable[unit])[0]; // first key of table (string) ---- oz:{g:100} ---- conversionTable[unit] = {g:100}
                var metricQty = conversionTable[unit][metricUnit] * qty;
                result.qty = metricQty;
                result.unit = metricUnit;
                // perform target unit result as well
            }

            // Perform a reverse conversion 
            // ie floz: {gal: .0071234} -- gal to floz
            // 
            // Traverse the table to find a right side key match and perform a reverse conversion
            // leftUnit = floz
            // rightObj = {gal: .0071234}
            // rightUnit = gal
            // 
            // reverse conversion formula for gal to floz would be 1/.0071234. Always divide by 1.
            if (!conversionTable[unit] && !edgeCases) {
                function reverseTableLookup(unitToMatch){
                    for (var leftUnit in conversionTable) {
                        var rightObj = conversionTable[leftUnit];
                        var rightUnit = Object.keys(rightObj)[0];
                        
                        if (rightUnit === unitToMatch && leftUnit === targetUnit) {
                            // perform reverse conversion
                            var rightVal = rightObj[rightUnit];
                            result.qty = 1 / rightVal * qty; // reverse conversion formula
                            result.unit = targetUnit;
                        }
                    }
                }
                reverseTableLookup(unit);
            }

            // No results founds set to original. No changes
            if (Object.getOwnPropertyNames(result).length == 0) {
                result.qty = qty;
                result.unit = unit;
            }
            return result;
        }

        // // NOT USED YET
        // var specialUnits = [
        //     {'fl oz': 'floz'}
        // ]

        this.preProcessSpecialUnits = function (unit) {
            var isSpecialUnit = unit.includes('fl oz');
            if (isSpecialUnit) return unit.replace(/\s/g, '');
            return unit;
        }
        this.postProcessSpecialUnits = function (unit) {
            var isSpecialUnit = unit.includes('floz');
            if (isSpecialUnit) return 'fl oz';
            return unit;
        }

        /**
         * 
         * @param {Object Array} unitsToConvert 
         * @param {Int} unitsToConvert[].qty 
         * @param {String} unitsToConvert[].unit 
         * @param {String} unitsToConvert[].to
         * 
         */
        this.bulkConversion = function (unitsToConvert) {
            var result = [];
            var _this = this;

            unitsToConvert.forEach(function (data) {
                var unitObj;
                var isCrossConversion = typeof data.withSystem === 'undefined';

                data.unit = _this.preProcessSpecialUnits(data.unit);

                if (isCrossConversion) {
                    unitObj = _this.crossConversion({
                        qty: data.qty,
                        unit: data.unit,
                        toSystem: data.to,
                        toUnit: data.toUnit
                    });
                } else {
                    unitObj = _this.conversion({
                        qty: data.qty,
                        unit: data.unit,
                        toUnit: data.to,
                        withSystem: data.withSystem
                    });
                }
                unitObj.unit = _this.postProcessSpecialUnits(unitObj.unit);

                result.push(unitObj);
            });
            return result;
        }

    }

    // // TEST
    // var unitsToConvert = [
    //     // cross system conversions
    //     { qty: 1, unit: 'oz', to: 'metric' },
    //     { qty: 3, unit: 'tbl', to: 'metric' },
    //     { qty: 3, unit: 'tsp', to: 'metric' },
    //     { qty: 1, unit: 'g', to: 'american' },
    //     { qty: 1, unit: 'mL', to: 'american' },
    //     // same system conversions
    //     { qty: 1, unit: 'oz', to: 'lb', withSystem: 'american' },
    //     { qty: 1, unit: 'lb', to: 'oz', withSystem: 'american' },
    //     { qty: 3, unit: 'oz', to: 'oz', withSystem: 'american' },
    //     { qty: 4, unit: 'lb', to: 'oz', withSystem: 'american' },
    // ]

    // var unitConverter = new UnitConverter();
    // var result = unitConverter.bulkConversion(unitsToConvert);
    // console.log(result);
})
