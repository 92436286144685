$(document).ready(function(){
    var _EventEmitter = EventEmitter.getInstance();

    var MetricConversionValidation = function(){
        this.binds();
    }

    MetricConversionValidation.prototype.displayGeneralErr = function(errMsg){
        $('.measurement-controls > p.error').text(errMsg);
    }

    MetricConversionValidation.prototype.binds = function(){
        var _this = this;
        _EventEmitter.subscribe('recipe-measurement-err', function(errMsg){
            _this.displayGeneralErr(errMsg);
        });
    }

    // INIT
    new MetricConversionValidation();


})