$(document).ready(function(){

    // Remove # of results text
    function removeResultsMsg() {
        $('.search-results-info .close-icon').closest('.search-results-info').addClass('hidden');
        // $('.clear-all-badges').click();
    }

    // Place Clear button beside Add filter button < 768
    function repositionClearBtn(width){
        $clearBtn = $('.clear-all-badges');

        if(width < 768) {
            $('.filter-toggle').append($clearBtn);
        } else {
            $('.filter-badge-collection').append($clearBtn);
        }
    }

    // Using this to meet margin design specs
    function handleControlsDynamicMargin(){
        if($('.controls').height() == 0) {
            $('.controls').removeClass('dynamic-margin');
        } else {
            $('.controls').addClass('dynamic-margin');
        }
    }

    $(document).on('keyup touchend mouseup', function(){
        setTimeout(handleControlsDynamicMargin, 150);
    })
    $(window).on('resize', function(){
        setTimeout(handleControlsDynamicMargin, 150);
    })

    // INIT
    $('.search-results-info .close-icon').on('click', removeResultsMsg);
    $(window).on('resize', function(){
        repositionClearBtn($(this).outerWidth());
    });
    repositionClearBtn($(window).outerWidth());
})