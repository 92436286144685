// Believe it or not this is not possible w width auto. Here's a work around.
// Add .width-dynamic class to inputs that need this
// <input class="dynamic-width"></input>
var DynamicWidthInputs = function(){
    this.createJqueryFn();
    this.binds();
}

// create a prototype function for all jquery objects
DynamicWidthInputs.prototype.createJqueryFn = function(){
    $.fn.textWidth = function(text, font) {
    
        if (!$.fn.textWidth.fakeEl) $.fn.textWidth.fakeEl = $('<span>').hide().appendTo(document.body);
        
        $.fn.textWidth.fakeEl.text(text || this.val() || this.text() || this.attr('placeholder')).css('font', font || this.css('font'));
        
        return $.fn.textWidth.fakeEl.width();
    };
}

DynamicWidthInputs.prototype.binds = function(){
    $('.dynamic-width').on('input change load', function() {
        var inputWidth = $(this).textWidth();
        $(this).css({
            width: inputWidth + 60
        })
    }).trigger('input');
}