/*
    Recipe Like STAR button

    1) It updates the like image
    2) It emits 'recipe-card-like-btn:clicked', which you can use elsewhere to handle this event.
       In this scenario we link to RecipePresenter.likeRecipe, which makes more sense that 
       putting that function here

*/

var _EventEmitter;

var LikeBtn = function($likeBtn){
    var _this = this;
    this.initDependencies();
    this.srcNotLiked = ASSET_DIR+'/components/Button-Like-Outline@2x.png';
    this.srcLiked = ASSET_DIR +'/components/Button-Like-Solid@2x.png';

    this.$ = $likeBtn;
    this.$.on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        _this.toggleRecipeLike($(this));
        _this.updateLikeImage($(this));
        _EventEmitter.emit('recipe-card-like-btn:clicked', $(this));
    });
    this.updateLikeImage(this.$);
};

LikeBtn.prototype.initDependencies = function(){
    _EventEmitter =  EventEmitter.getInstance();
}

LikeBtn.prototype.updateLikeImage = function($likeBtn) {
    var liked = parseInt( $likeBtn.closest('.card').find('.liked').text() );
	var title = $likeBtn.closest('.card').find('.title').text();
    if (liked) {
        $likeBtn.attr('src', this.srcLiked);
        $likeBtn.attr('alt', 'Like Button. Item Liked for ' + title);
		$likeBtn.attr('title', 'Unlike');
    } else {
        $likeBtn.attr('src', this.srcNotLiked);
        $likeBtn.attr('alt', 'Like Button. Item Not Liked for ' + title);
		$likeBtn.attr('title', 'Like');
    }
}

LikeBtn.prototype.toggleRecipeLike = function($likeBtn) {
    $likedDiv = $likeBtn.closest('.card').find('.liked');
    var liked = parseInt( $likedDiv.text() );
    if (liked)
        $likedDiv.text(0);
    else
        $likedDiv.text(1);
}
