// Gives a dropdown dynamic width

// HTML STRUCTURE FOR THIS TO WORK
/*  <div class="dwd-container">
        <select class="dynamic-width-dropdown">
            <option>All</option>
            <option>Longer</option>
            <option>A very very long string...</option>
        </select>
        <select class="temp-select">
            <option class="temp-option"></option>
        </select>
    </div> */

var _EventEmitter;

var DynamicWidthDropdown = function(){
    this.initDependencies();
    this.binds();
}

DynamicWidthDropdown.prototype.initDependencies = function(){
    _EventEmitter = EventEmitter.getInstance();
}

DynamicWidthDropdown.prototype.binds = function(){
    var _this = this;
    console.log('dwd binds');
    $('.dynamic-width-dropdown').change(function(){ _this.refreshWidth( $(this) ) });
}

DynamicWidthDropdown.prototype.refreshWidth = function($dropdown) {
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    var $parentContainer = $dropdown.closest('.dwd-container');
    var $selected = $parentContainer.find('.dynamic-width-dropdown option:selected');
    var $tempSelect = $parentContainer.find('.temp-select')
    var $tempOption = $parentContainer.find('.temp-option');


    var offset = 0;
    if(isFirefox) offset = 50

    $tempOption.html($selected.text());
    $dropdown.width($tempSelect.width()-offset);

    // If you need to further edit this dropdown afterwards, then subscribe to this event from another class
    _EventEmitter.emit('dynamic-width-dropdown-resized', $dropdown);
}