// /**
//  * Category Filter 
//  * that works in conjuction with JPLIST
//  * 
//  * Filter basically does 3 things.
//  * 1) Keeps track of whether likedItems should be showed with filterOn.
//  * 2) Refreshes items (UI) whenever the liked checkbox is toggled.
//  * 
//  * Has a small dependency on Util.convertToCSSClass(str) from util.js
//  * 
//  */
// Filter.Liked = {
//     filterOn: false,
//     init: function () {
//         this.binds();
//     },
//     // These binds are used to add and remove filters
//     binds: function () {
//         $(document).on('click', '.filter-badge .filter-close', function () {
//             Filter.Liked.filterOn = false;
//             Filter.refreshItems();
//         })
//         $(document).on('click', '.clear-all-badges', function () {
//             Filter.Liked.filterOn = false;
//             Filter.refreshItems();
//         });
//         $(document).on('mouseup', '.liked-filter', function () {
//             Filter.Liked.filterOn = !Filter.Liked.filterOn;
//             Filter.refreshItems();
//         })
//     },

//     // Get items that are liked by the user
//     filterLikedItems: function (options) {
//         return options.$items.filter(function (i) {
//             return $(this).find('img.liked').length > 0;
//         })
//     },

//     // Run liked filter if like is currently toggled
//     runFilter: function ($items) {
//         if (!this.filterOn) return $items;

//         return this.filterLikedItems({
//             $items: $items,
//         })
//     },
// };
