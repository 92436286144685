var Util = {}

Util.tagLastElementsInRow = function ($items) {
    var $lastElement = false;
    $items.removeClass('last-element');

    $items.each(function (i, el) {
        var $currentElement = $(el);
        var sameTopOffset = $lastElement && $lastElement.offset().top != $currentElement.offset().top;
        if (sameTopOffset) {
            $lastElement.addClass('last-element');
        }

        $lastElement = $currentElement;
    }).last().addClass('last-element'); 
}

Util.removeEmptyDecimal = function(str) {
    return str.replace(/\.00([^\d])/g,'$1');
}

Util.countDecimals = function (num) {
    if(Math.floor(num.valueOf()) === num.valueOf()) return 0;
    var decimalNumbers = num.toString().split(".")[1];
    if(typeof decimalNumbers == 'undefined') return 0;

    return decimalNumbers.length || 0; 
}

Util.isHumanClickEvent = function(e) {
    var humanEvent = typeof e.originalEvent !== 'undefined' && e.originalEvent.screenX !== 0;
    var clickEvent = e.type === 'click';
    return humanEvent && clickEvent;
}

Util.validateEmail = function(email){
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

Util.convertToTwoDecimals = function(num) {
    return parseFloat(num).toFixed(2);
}

Util.convertToFourDecimals = function(num) {
    return parseFloat(parseFloat(num).toFixed(4));
}

Util.fileName = function(fullPath) {
    return fullPath.replace(/^.*[\\\/]/, '');

}

// Replace character in string at index
Util.replaceAt = function(str, index, replacement) {
    return str.substr(0, index) + replacement+ str.substr(index + replacement.length);
}

Util.convertToUSD = function(num) {
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

Util.numberWithCommas = function(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

Util.toMetricFormat = function(num) {
    var isDecimal = Util.countDecimals(num) > 0;

    num = Util.numberWithCommas(num);
    num = num.replace(/,/g, '.');
    if(isDecimal) {
        var i = num.lastIndexOf('.');
        num = Util.replaceAt(num, i, ',');
    }

    return num;
}

// Removes strings, letters, symbols, empty decimals & then checks for infinite
Util.cleanNumber = function(num){
    if( typeof( num ) == 'undefined' )
        return 0;
    var number = parseFloat( num.toString().replace(/[^\d.-]/g, '') );
    number = parseFloat( Util.removeEmptyDecimal(number.toString()) );
    var isDecimalNumber = Util.countDecimals(number) > 0;
    if(isDecimalNumber) {
        number = Util.convertToTwoDecimals(number)
    }
    number = (isFinite(number)) ? number : 0 ;
    return parseFloat(number);
}

Util.isJSON = function(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

Util.truncateStr = function(str, n){
	str = str.replace(/\\(.)/mg, "$1");
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}

Util.cleanForwardSlashFromSelector = function(str) {
    return str.replace('/', '\\\\/');
}

Util.keyLoop = function(obj, cb){
    for(var key in obj) {
        if(obj.hasOwnProperty(key)) cb(key);
    }
}

// Will convert a number in fraction form '1/2' or '1 1/2' to its decimal counterpart
// if invalid '1/2/2/2/2' it returns the original str
Util.fractionStrToDecimal = function(theStr) {
    if( typeof( theStr ) == 'undefined' )
        return 0;
    var newStr = '';
    var isFraction = theStr.toString().includes('/');
    var validFraction = theStr.toString().split('').filter(function(x){return x=='/'}).length === 1;

    if(isFraction && validFraction) {
        numbers = theStr.split(' ');
        numbers.forEach(function(n){
        newStr += n.split('/').reduce(
            function(p, c) { 
                return (p / c).toString().replace('0.', '.')
            }
        );
        })
    } else {
        newStr = theStr;
    }

    return newStr;
}

/** 
 * Recursive function.
 * 
 * Runs a callback function on $item passed. Recurses with the $item's parent until the root node is reached.
 * 
 * @param {jquery obj} $item
 *      item passed to callback
 * @param {function} cb
 *      callback function to be called on every loop 
 * @param {int} i
 *      iteration count
 * @param {boolean} breakRecursion
 *      boolean value that can be returning from the callback to break the recursion manually
 */
Util.traverseEach = function($item, cb, i, breakRecursion) {
    if (breakRecursion) return;
    var i = (typeof i === 'undefined') ? 0 : i;
    var breakRecursion = cb($item, i);
    var $parent = $item.parent();
    if($parent.length === 0) return;
    Util.traverseEach($parent, cb, i, breakRecursion);
}

// Get the first element that is tabbable by keyboard. Start from $el
Util.getFirstTabbableEl = function($el) {
    var $firstTabbableEl;

    Util.traverseEach($el, function($el, i){
        if(i === 0) {
            $firstTabbableEl = $el.find('[tabindex="0"]:visible').first();
            if ($firstTabbableEl.length) return true;
            $firstTabbableEl = $el.find('a:visible').first();
            if ($firstTabbableEl.length) return true;
            $firstTabbableEl = $el.find('button:visible').first();
            if ($firstTabbableEl.length) return true;
        }
        $firstTabbableEl.siblings('[tabindex="0"]:visible').first();
        if ($firstTabbableEl.length) return true;
        $firstTabbableEl.siblings('a:visible').first();
        if ($firstTabbableEl.length) return true;
        $firstTabbableEl.siblings('button:visible').first();
        if ($firstTabbableEl.length) return true;
    })

    return $firstTabbableEl;
}

Util.scrollToAnimation = function(speed){
    $(".scroll-to").on('click', function() {
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, speed);

        // IE doesn't shift keyboard focus position. This moves focus to the first tabbable el or a tag after element scrolled to 
        var $this = $(this);
        setTimeout(function(){
            var $firstTabbableEl = Util.getFirstTabbableEl( $($this.attr('href')) );
            $firstTabbableEl.focus();
        }, speed + 10)
    });
}

Util.camelCaseToDash = function(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

Util.getUrlParam = function(param){
    var pageURL = decodeURIComponent(window.location.search.substring(1)),
        URLVariables = pageURL.split('&'),
        parameterName;
        
    for (var i = 0; i < URLVariables.length; i++) {
        parameterName = URLVariables[i].split('=');

        if (parameterName[0] === param) {
            return parameterName[1] === undefined ? true : parameterName[1];
        }
    }
}

Util.cleanStr = function(str) {
    return str.replace('/', '');
}

Util.convertToCSSClass = function(str) {
    return Util.cleanStr(str).trim().replace(/ /g, '-').toLowerCase()
}

Util.convertToDecimalPercent = function(percent) {
    return parseFloat(percent) / 100.0;
}
Util.convertToPercent = function (float) {
    return (parseFloat(float) * 100.0).toFixed(2);
}

Util.regexGetNumbers = function (val) {
    return parseFloat(val.toString().replace(/[^\d.-]/g, ''));
}

Util.removeSpaces = function(str) {
    return str.replace(/\s/g, '');
}

// Shoulnt be called  here...but leaving here for convenience
$(document).ready(function(){
    Util.scrollToAnimation();
})


// attached to bootstrap collapse for safari fix
// https://stackoverflow.com/questions/33074160/bootstrap-collapse-half-working-on-iphone
function none() { }


