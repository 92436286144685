var Polyfills = function(){
    this.init();
}

Polyfills.prototype.init = function(){
    // IE 11 
    if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
            if (typeof start !== 'number') {
                start = 0;
            }

            if (start + search.length > this.length) {
                return false;
            } else {
                return this.indexOf(search, start) !== -1;
            }
        };
    }

    // IE 11
    Number.isNaN = Number.isNaN || function (value) {
        return value !== value;
    }
}

